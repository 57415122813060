import React, { useEffect, useState } from 'react'
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo'
import SelectBox from 'components/Inputs/SelectBox'
import PriceChartMulti from 'components/Charts/PriceChartMulti'
import { useAppInfo } from 'AppState'
import { getPortfolioVsBenchmarkOverview } from './utils/getPortfolioPerformance'
import TabLine from 'components/Tabs/TabLine'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import TableGen from 'components/Table/TableGen'
import { PageMainContentTitle } from 'components/Text/Title'



export default function PortfolioVsBenchmark({sx={}}) {
    const {statePortfolio,dispatchPortfolio,stateStock} = useAppInfo()
    const {portfolioValue} = statePortfolio
    const [benchmarkClose,setBenchmarkClose] = useState([])
    const [benchmark,setBenchmark] = useState("SPY")
    const {close} = useMultiTickerInfo([benchmark])
    const [overviewResults,setOverviewResults] = useState()
    const tabList = ["Trend","Performance","Summary Stats"]
    const [tabIndex,setTabIndex] = useState(0)
  
    useEffect(()=>{
      if (close){
        setBenchmarkClose(close)
      }
    },[close])
  
    useEffect(()=>{
      if (benchmarkClose.length>0 && portfolioValue.length>0){
        const result = getPortfolioVsBenchmarkOverview(portfolioValue,benchmarkClose,["Portfolio"],benchmark)
        setOverviewResults(result)
      }
    },[benchmarkClose,portfolioValue])

    const tabContent = []
    tabContent[0] = portfolioValue.length>0 && <div className='chart_wrapper'>
    <PriceChartMulti zoneRGSeries={true} chartType='areaspline' benchmarkClose={benchmarkClose} 
    close={portfolioValue} marketInfoLoading={false}/>
    </div>

    tabContent[1] = overviewResults && <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
    <PerformanceChart data={overviewResults?.detailedResults} title={"Annual Performance"}/>
    <PerformanceChart data={overviewResults?.detailedResults} dataKey='quarterly_returns' title={"Quarterly Performance"}/>
    <PerformanceChart data={overviewResults?.detailedResults} dataKey='monthly_returns' title={"Monthly Performance"}/>
    
    </div>

    tabContent[2] = overviewResults && <TableGen data={overviewResults?.tableData}/>

   
    
  return (
    <div className='containerColumnFlex' style={{...sx}}>
    
    <div style={{padding:"0.25rem",display:"flex",gap:"0.5rem",alignItems:"center"}}>
    <PageMainContentTitle>Portfolio Vs</PageMainContentTitle>
    <SelectBox 
    width='30%'
    variant={"outlined"}
    options={["SPY","XLU","XLC","XLY"]} label={"Benchmark"} 
    value={benchmark} onChange={(e,v)=>setBenchmark(v)}/>
    </div>

<TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>

    <div className='containerColumnFlex1'>
        {tabContent[tabIndex]}
    </div>
    
    </div>
  )
}





 function PerformanceTable({data}){
    const keys = [
        "ticker",
        'daily_volatility',
        'weekly_volatility',
        'monthly_volatility',
        'quarterly_volatility',
        'yearly_volatility',
        'daily_var',
        'weekly_var',
        'monthly_var',
        'quarterly_var',
        'yearly_var',
        'beta'
      ];

    const table = getSpecificArrayObjKeys(data,keys)

    return (
        <TableGen data={table}/>
    )
 }
  

 const transformData = (data, key, referenceTicker) => {
    // Find the reference ticker
    const referenceTickerData = data.find(item => item.ticker === referenceTicker);
  
    if (!referenceTickerData) {
      throw new Error(`Ticker ${referenceTicker} not found in data`);
    }
  
    // Get the dates from the reference ticker
    const referenceDates = referenceTickerData[key].map(ret => ret.date);
  
    // Transform data for all tickers but only include the reference dates
    return data.map(item => ({
      name: item.ticker,
      data: item[key]
        .filter(ret => referenceDates.includes(ret.date))
        .map(ret => ({
          name: ret.date,
          y: ret.return * 100 // Convert to percentage
        }))
    }));
  };
  
  const PerformanceChart = ({ data, dataKey = 'annual_returns', referenceTicker="Portfolio",title}) => {
    // Transform data for chart
    const chartData = transformData(data, dataKey, referenceTicker);
  
    const options = {
      chart: {
        type: 'column'
      },
      title: {
        text: title
      },
      xAxis: {
        type: 'category',
        categories: chartData[0].data.map(point => point.name),
        title: {
          text: 'Period'
        }
      },
      yAxis: {
        title: {
          text: 'Return (%)'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.1f}%</b>'
      },
      plotOptions: {
        column: {
          grouping: true,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%'
          }
        }
      },
      series: chartData,
      credits:{enabled:false}
    };
  
    return (
      <div style={{width:"100%",height:"100%"}}>
        <HighchartsReact highcharts={Highcharts} options={options} 
        containerProps={{ style: { height: '100%' , width:"100%"} }}
        />
      </div>
    );
  };
  