import { useAppInfo } from 'AppState';
import CircleScore from 'components/Charts/CircleScore';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { formatNumber } from 'utils/utilGeneral';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { Button, Tooltip } from '@mui/material';

export default function GoalsSuccessRate({displayType="detailed"}) {
    const {stateMyFinancials} = useAppInfo()
    const {goalAnalytics} = stateMyFinancials
    const { goalSuccessRateBeforeWithdrawal,goalSuccessRateAfterWithdrawal,goalFunding} = goalAnalytics;
    const [goalSuccessRate,setGoalSuccessRate] = useState(0)
    const navigate = useNavigate()

    useEffect(()=>{
      let gSuccessRate = 0
      if (goalFunding === "netCashAfterWithdrawal"){
        gSuccessRate = goalSuccessRateAfterWithdrawal || 0
      }
      else{
        gSuccessRate = goalSuccessRateBeforeWithdrawal || 0
      }
      setGoalSuccessRate(gSuccessRate)

    },[goalFunding,goalSuccessRateBeforeWithdrawal,goalSuccessRateAfterWithdrawal])

    
  function handleGoal(){
    navigate("/MyFinancials/Goals")
  }

  return (
    <>
    {displayType === "icon" && 
    <Tooltip title="Goal">
    <Button 
    onClick={()=>handleGoal()}
    sx={{
      minWidth:"0",marginLeft:"auto",padding:"0 0.25rem"
      ,flexDirection:"column",textTransform:"none",color:"green"}}>
      <div style={{display:"flex"}}><CrisisAlertIcon/></div>
      
      <span style={{fontSize:"0.8rem"}}>
      Goal {goalSuccessRate && `${formatNumber(goalSuccessRate)}%`}</span>
    </Button>
    </Tooltip>

    }

    {displayType === "big" &&  <CircleScore 
    score={goalSuccessRate}
    totalScore={100} title='Goals Success Rate' />}

    {displayType === "detailed" && 
    <div style={{background:"#fafafa",display:"flex",gap:"1rem"
    ,alignItems:"center",padding:"0.5rem",borderRadius:"5px",width:"100%",boxSizing:"border-box"}}>
    <CircleScore 
    score={goalSuccessRate || 0}
    totalScore={100} title='Goals Success Rate' />
    <div>
    <h4 style={{fontWeight:"bold"}}>Your overall goal success rate is: {formatNumber(goalSuccessRate || 0)}%</h4>
    <h5>Success rate is based on how you fund the goals and the projections of income,
      expenses,liabilities and assets.</h5>
    </div>
    </div>}
    </>
   
  )
}