import React from 'react';

export default function MarketImpact(){
  const calculateOverallScore = (personalScore, marketScore) => {
    return (personalScore + marketScore) / 2;
  };

  const data = economicData
  return (
    <table>
      <thead>
        <tr>
          <th>Category</th>
          <th>Metric</th>
          <th>Metric Value</th>
          <th>Impact on Personal Finance</th>
          <th>Impact on Investment Opportunities & Portfolio</th>
          {/* <th>Overall Score</th> */}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.category}</td>
            <td>{item.metric}</td>
            <td>{item.metricValue || 'N/A'}</td>
            <td>
              <div>{item.impactPersonalFinance.comment}</div>
              <div>Impact: {item.impactPersonalFinance.impact}</div>
              <div>Score: {item.impactPersonalFinance.impactScore}</div>
            </td>
            <td>
              <div>{item.impactMarket.comment}</div>
              <div>Impact: {item.impactMarket.impact}</div>
              <div>Score: {item.impactMarket.impactScore}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};


const economicData = [
    {
      category: "Inflation",
      metric: "Inflation",
      metricValue: 3.5, // Example value representing the current inflation rate
      impactPersonalFinance: {
        comment: "Increases expenses, reduces purchasing power",
        impact: "negative",
        impactScore: 7
      },
      impactMarket: {
        comment: "Affects bond yields, stock prices, commodity prices",
        impact: "negative",
        impactScore: 6
      }
    },
    {
      category: "Interest Rates",
      metric: "Interest Rates",
      metricValue: 5.25, // Example value representing the current interest rate
      impactPersonalFinance: {
        comment: "Affects mortgages, car loans, credit card debt",
        impact: "negative",
        impactScore: 8
      },
      impactMarket: {
        comment: "Influences bond prices, stock valuations, bank stocks",
        impact: "mixed",
        impactScore: 5
      }
    },
    {
      category: "Employment",
      metric: "Unemployment Rate",
      metricValue: 4.2, // Example value representing the current unemployment rate
      impactPersonalFinance: {
        comment: "Affects job security, income stability",
        impact: "negative",
        impactScore: 9
      },
      impactMarket: {
        comment: "Impacts consumer spending, corporate earnings",
        impact: "negative",
        impactScore: 7
      }
    },
    {
      category: "Economic Growth",
      metric: "GDP Growth",
      metricValue: 2.1, // Example value representing the current GDP growth rate
      impactPersonalFinance: {
        comment: "Reflects overall economic health, job prospects",
        impact: "positive",
        impactScore: 8
      },
      impactMarket: {
        comment: "Drives market sentiment, corporate profits",
        impact: "positive",
        impactScore: 8
      }
    },
    {
      category: "Federal Reserve",
      metric: "Interest Rate Adjustment",
      metricValue: 5.0, // Example value representing the recent rate adjustment by the Federal Reserve
      impactPersonalFinance: {
        comment: "Affects interest rates, borrowing costs",
        impact: "negative",
        impactScore: 7
      },
      impactMarket: {
        comment: "Influences market liquidity, stock and bond markets",
        impact: "negative",
        impactScore: 6
      }
    },
    {
      category: "Federal Reserve",
      metric: "Money Supply Adjustment",
      metricValue: 6.5, // Example value representing the money supply growth rate
      impactPersonalFinance: {
        comment: "Influences inflation, purchasing power",
        impact: "negative",
        impactScore: 6
      },
      impactMarket: {
        comment: "Impacts liquidity, interest rates, asset bubbles",
        impact: "mixed",
        impactScore: 5
      }
    },
    {
      category: "Government Debt",
      metric: "Government Debt",
      metricValue: 120, // Example value representing the debt-to-GDP ratio in percentage
      impactPersonalFinance: {
        comment: "Affects future tax rates, public services",
        impact: "negative",
        impactScore: 6
      },
      impactMarket: {
        comment: "Can impact bond yields, currency value",
        impact: "negative",
        impactScore: 7
      }
    },
    {
      category: "Government Spending",
      metric: "Government Spending",
      metricValue: 3.2, // Example value representing government spending as a percentage of GDP
      impactPersonalFinance: {
        comment: "Influences public services, infrastructure, and taxes",
        impact: "positive",
        impactScore: 7
      },
      impactMarket: {
        comment: "Can drive economic growth, impact bond yields",
        impact: "positive",
        impactScore: 6
      }
    },
    {
      category: "Consumer Confidence",
      metric: "Consumer Confidence",
      metricValue: 85, // Example value representing consumer confidence index
      impactPersonalFinance: {
        comment: "Affects spending behavior, savings",
        impact: "positive",
        impactScore: 8
      },
      impactMarket: {
        comment: "Influences retail stocks, market sentiment",
        impact: "positive",
        impactScore: 7
      }
    },
    {
      category: "Housing Market",
      metric: "Housing Market",
      metricValue: 4.5, // Example value representing annual property value growth rate
      impactPersonalFinance: {
        comment: "Affects property values, mortgage rates",
        impact: "positive",
        impactScore: 7
      },
      impactMarket: {
        comment: "Impacts real estate stocks, mortgage-backed securities",
        impact: "positive",
        impactScore: 6
      }
    },
    {
      category: "Stock Market Performance",
      metric: "Stock Market Performance",
      metricValue: 6.3, // Example value representing stock market index growth
      impactPersonalFinance: {
        comment: "Affects retirement savings, wealth perception",
        impact: "positive",
        impactScore: 8
      },
      impactMarket: {
        comment: "Drives overall market sentiment, investment inflows",
        impact: "positive",
        impactScore: 8
      }
    },
    {
      category: "Commodity Prices",
      metric: "Commodity Prices",
      metricValue: 2.8, // Example value representing the increase in average commodity prices
      impactPersonalFinance: {
        comment: "Affects cost of goods (e.g., oil, food)",
        impact: "negative",
        impactScore: 7
      },
      impactMarket: {
        comment: "Influences energy stocks, agriculture stocks",
        impact: "positive",
        impactScore: 5
      }
    },
    {
      category: "Exchange Rates",
      metric: "Exchange Rates",
      metricValue: 1.15, // Example value representing USD to EUR exchange rate
      impactPersonalFinance: {
        comment: "Impacts cost of imported goods, travel expenses",
        impact: "mixed",
        impactScore: 5
      },
      impactMarket: {
        comment: "Affects multinational companies, currency trading",
        impact: "mixed",
        impactScore: 6
      }
    },
    {
      category: "Tax Policies",
      metric: "Tax Policies",
      metricValue: 25, // Example value representing effective tax rate in percentage
      impactPersonalFinance: {
        comment: "Affects disposable income, investment decisions",
        impact: "negative",
        impactScore: 6
      },
      impactMarket: {
        comment: "Impacts corporate earnings, investor sentiment",
        impact: "negative",
        impactScore: 7
      }
    },
    {
      category: "Savings Rates",
      metric: "Savings Rates",
      metricValue: 6.7, // Example value representing personal savings rate
      impactPersonalFinance: {
        comment: "Affects personal wealth accumulation",
        impact: "positive",
        impactScore: 8
      },
      impactMarket: {
        comment: "Influences consumer spending, investment levels",
        impact: "positive",
        impactScore: 6
      }
    },
    {
      category: "Credit Availability",
      metric: "Credit Availability",
      metricValue: 4.5, // Example value representing credit growth rate
      impactPersonalFinance: {
        comment: "Affects borrowing costs, financial flexibility",
        impact: "positive",
        impactScore: 7
      },
      impactMarket: {
        comment: "Impacts consumer finance companies, housing markets",
        impact: "positive",
        impactScore: 7
      }
    }
  ];
  