import React from 'react';

import Questionnaire from 'resource/guidance/RiskManagement/Questionnaire';
import RiskToleranceResult from 'resource/guidance/RiskManagement/RiskToleranceResult';

import {
  Alert,
  Button,
} from '@mui/material';

import { useAppInfo } from 'AppState';
import {
  questionaireRiskTolerance,
  riskInfo,
} from 'content/contentRiskTolerance';
import useAddFinancials from 'data/user/financials/useAddFinancials';
import { PageMainContentTitle } from 'components/Text/Title';

export default function RiskTolerance() {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {riskTolerance,investmentPreference} = stateMyFinancials
    const {addFinancials} = useAddFinancials()

 
    function handleSubmit(data){
        const {score,submitted,updatedQuestionnaire} = data
        const userCategory = calculateRiskCategory(score,questionaireRiskTolerance.questionnaire);
        const riskToleranceScore = {"score":score,"test":submitted}
        dispatchMyFinancials({"riskTolerance":riskToleranceScore})
        const progressUpdate = stateMyFinancials?.profileCompletion
        progressUpdate["riskTolerance"] = true
        dispatchMyFinancials({"profileCompletion":progressUpdate});
        addFinancials( progressUpdate,"profileCompletion")
        setTimeout(()=>addFinancials( riskToleranceScore,"riskTolerance"),1000)
        const updatedIP = mergeArrays(userCategory?.assetClasses,investmentPreference)
        // setUpdatedIP(updatedIP)
        setTimeout(()=>addFinancials(updatedIP,"investmentPreferenceRiskTolerance"),2000)
        dispatchMyFinancials({"investmentPreferenceRiskTolerance":updatedIP})
       }

  return (
    <>

    {!riskTolerance.test && 
        <Questionnaire onSubmit={handleSubmit} data={questionaireRiskTolerance} 
        title={"Test your Risk Tolerance"}/>}
        
   {riskTolerance.test && 
        <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"0.5rem"}}>
        <RiskToleranceResult questionnaire={questionaireRiskTolerance.questionnaire} 
        score={riskTolerance.score}/>
        
    
    <div style={{padding:"0.5rem",borderRadius:"5px",display:"flex",gap:"1rem",flexDirection:"column",background:"var(--colorSuccess)"}}>
    <PageMainContentTitle>Key Points</PageMainContentTitle>
    <Alert severity="success">
        It is important to test your risk tolerance periodically, especially when your life circumstances or financial experience changes.
      </Alert>
      <Alert severity="success">
        Lower risk tolerance does not mean lower returns, and higher risk does not necessarily mean higher returns.
      </Alert>
      <Alert severity="success">
        Understanding your risk tolerance helps you stay emotionally balanced, preventing unnecessary and impulsive decisions.
      </Alert>
      <div>
    </div>
   </div>
   <Button 
    variant='contained' 
    sx={{width:"max-content"}}
    onClick={()=>dispatchMyFinancials({"riskTolerance":{"score":0,"test":false}})}>
        Retake Test</Button>
    </div>}

    </>
  )
}

const calculateRiskCategory = (score,questionnaire) => {
  const scoreWeight = questionnaire.length * 3; // assuming each question has 3 possible answers each weighing 1 point
  const totalScore = score / scoreWeight * 100;
  return riskInfo.find(info => totalScore <= info.maxPercentile);
};

const mergeArrays = (firstArray, secondArray) => {
  return secondArray.map(secondItem => {
      const firstItem = firstArray.find(firstItem => firstItem.mainType === secondItem.type);
      if (firstItem) {
          return {
              ...secondItem,
              selected:true,
              risk: firstItem.assetRisk,
              liquidity: firstItem.liquidity,
              targetAllocation: firstItem.targetAllocation,
              criterias: firstItem.criterias
          };
      }
      return secondItem;
  });
};

// "Risk appetite" and "risk tolerance" are two concepts in finance and investing that are related but distinct, especially when it comes to individual investors.

// Risk Appetite:
// Definition: Risk appetite refers to the level of risk an investor is willing to take in pursuit of their investment goals. It's about how much risk an investor wants to take.
// Context: This is generally a broader, more strategic view of risk and can be thought of as the amount of risk an individual is not just willing to accept but is comfortable with in order to achieve potential higher returns.
// Example: An investor with a high risk appetite might seek out high-growth stocks or venture into more volatile markets because they want substantial growth, understanding that there's a higher potential for significant losses.
// Risk Tolerance:
// Definition: Risk tolerance, on the other hand, is the degree of variability in investment returns that an investor is willing to withstand in their investment portfolio. It's about how much risk an investor can handle.
// Context: This concept is more about the investor's capacity to endure losses without affecting their financial situation and emotional well-being. It takes into account financial stability, investment horizon, and personal circumstances.
// Example: An investor may desire high returns (high risk appetite) but only have a moderate risk tolerance because significant downturns in the market could jeopardize their financial goals, such as retirement planning or education funding.
// In summary, while risk appetite is about the desire for risk in pursuit of greater rewards, risk tolerance is about the capacity to handle that risk without jeopardizing one’s financial health or emotional peace. For individual investors, these concepts help in shaping a balanced and personalized investment strategy.