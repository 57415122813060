import React, { useEffect, useState, useRef } from 'react'
import SendIcon from '@mui/icons-material/Send';
import { Button, Paper, IconButton, Tooltip } from '@mui/material'
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { useAppInfo } from 'AppState';
import useHandleUserEvents from './useHandleUserEvents';
import { debounce } from 'lodash';
import styles from "./MessageBar.module.css"
import { defaultMessageList, messagePlaceholders } from 'appState/initialStateAssistant';
import { Explore } from '@mui/icons-material';

export default function MessageBar() {
  const { stateApp, dispatchApp,stateAssistant,dispatchAssistant} = useAppInfo();
  const [input,setInput] = useState()
  const placeholders = messagePlaceholders
  
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(-1); // Add focusedIndex state
  const { handleSend, handleVoiceRecording, handleShowContent, handleStopChat,handleExploreQuestions} = useHandleUserEvents();
  const { showContent, voiceRecording, chatStatus,chatGptMessage} = stateAssistant;
  
  useEffect(() =>{  
    setInput(chatGptMessage)
    },[chatGptMessage])

  const buttonRefs = useRef([]); // To store button references

  // Debounce the input to avoid firing on every keystroke
  const debouncedFilterMessages = debounce((value) => {
    if (value) {
      try {
        const regex = new RegExp(value, 'i'); // Case-insensitive matching
        const matches = defaultMessageList.filter((msg) => regex.test(msg));
        setFilteredMessages(matches);
      } catch (error) {
        console.error("Invalid regular expression:", error);
        setFilteredMessages([]);
      }
    } else {
      setFilteredMessages([]);
    }
  }, 300); // 300ms delay

  useEffect(() => {
    debouncedFilterMessages(input);
  }, [input]);

  useEffect(() => {
    if (focusedIndex >= 0 && focusedIndex < buttonRefs.current.length) {
      buttonRefs.current[focusedIndex]?.focus(); // Focus the current button
    }
  }, [focusedIndex]);

    function handleSendMessage() {
    if (stateApp.isLoggedIn) {
      if (chatStatus !== "running"){
           setInput("")
      } 
      // dispatchAssistant({"chatGptMessage":input})
      handleSend(input);
      handleShowContent(true);
    } else {
      dispatchApp({ "loginModal": true });
    }
  }

  
  const handleKeyDown = (e) => {
    if (filteredMessages.length > 0) {
      if (e.key === "ArrowDown") {
        setFocusedIndex((prevIndex) => 
          (prevIndex + 1) % filteredMessages.length
        );
      } else if (e.key === "ArrowUp") {
        setFocusedIndex((prevIndex) =>
          (prevIndex - 1 + filteredMessages.length) % filteredMessages.length
        );
      } 
    }
    if (e.key === "Enter" && filteredMessages.length === 0) {
      handleSendMessage();
    }
  };

  return (
    <>
      <div
        onKeyDown={handleKeyDown}
        onClick={() => handleShowContent(true)}
        style={{
          display: "flex", 
          width: "35vw",
          minWidth:"300px", 
          gap: "0.5rem",
          boxSizing: "border-box", 
          background: "#f7f7f7",
          borderRadius: "25px", 
          padding: "0.5rem 0.5rem",
          position: "relative",
          background: "white",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)"
        }}
      >
          <Button 
          sx={{ minWidth: '0', padding: 0 }}
          onClick={handleExploreQuestions}
        >
          <Tooltip title="Explore Questions">
          <Explore/>
          </Tooltip>
        </Button>


        <Button 
          className={voiceRecording ? "recording" : ""} 
          sx={{ minWidth: '0', padding: 0 }}
          onClick={handleVoiceRecording}
        >
          <MicIcon />
        </Button>

       
        <div 
          className='fade-in-text'
          style={{
            transition: 'transform 0.5s ease',
            whiteSpace: 'nowrap',
            position: 'relative',
            flex: 1,
            overflow: "hidden", 
            display: "flex", 
            alignItems: "center"
          }}
        >
          <input
            style={{
              boxSizing: "border-box", 
              outline: "none", 
              border: "none", 
              fontSize: "1.2rem", 
              flex: 1, 
              background: "none", 
              width: '100%'
            }}
            type='text' 
            placeholder={placeholders[placeholderIndex]}
            value={input || ""}
            onChange={(e) => setInput(e.target.value)} 
          />
        </div>
        
        {chatStatus === "running" ? (
          <Button 
            onClick={() => handleStopChat()}
            sx={{ minWidth: 0, margin: 0 }}
          >
            <StopIcon/>
          </Button>
        ) : (
          <Button 
            onClick={() => handleSendMessage()}
            sx={{ minWidth: 0, margin: 0 }}
          >
            <SendIcon />
          </Button>
        )}

        {filteredMessages.length > 0 && (
          <Paper 
            style={{
              position: "absolute",
              zIndex: 100, 
              bottom: "115%",
              left: 0,
              width: "100%", 
              maxHeight: "150px", 
              overflowY: "auto",
              display: "flex", 
              flexDirection: "column",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fafafa",
              zIndex:1000
            }}
          >
            {filteredMessages.map((message, index) => (
              <Button 
                disableFocusRipple
                key={index} 
                ref={(el) => (buttonRefs.current[index] = el)}  // Assign button refs
                onClick={(e) => {e.preventDefault();dispatchAssistant({chatGptMessage:message});handleSend(message);setInput("")}}
                sx={{
                  textAlign: 'left', 
                  width: '100%',
                  color: '#333',
                  justifyContent:"flex-start",
                  padding:"0.25rem 1rem",
                  background: focusedIndex === index ? '#f0f0f0' : 'transparent'
                }}
              >
                {message}
              </Button>
            ))}
          </Paper>
        )}
      </div>
    </>
  );
}

