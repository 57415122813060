import { Tab, Tabs, tabsClasses } from '@mui/material'
import React from 'react'

export default function TabLine({tabList,tabIndex=0,handleTabChange,height="40px"
  ,fontSize="1rem",children,settings,settingsContent,variant="line",tabsSx={},tabSx={},centered=false}) {
  return (
    <Tabs 
    centered={centered}
    value={tabIndex}
    variant="scrollable"
    scrollButtons="auto"
    TabIndicatorProps={{ sx: { bgcolor: "var(--selectBlue)",display:variant==="line"?"visible":"none",} }}
    sx={{
      [`& .${tabsClasses.scrollButtons}`]: {
        '&.Mui-disabled': { opacity: 0.3 },
      },gap:"0.25rem",minHeight:height,textTransform:"none",
      // ,background:"#fafafa"
      borderBottom:variant==="line"&&"1px solid #ddd",...tabsSx
      
    }}
    >
    {tabList.map((tabs,index)=>
    <Tab label={tabs} onClick={()=>handleTabChange(index)} 
    sx={{fontSize:fontSize,height:height
      ,minHeight:height,textTransform:"none",...tabSx, '&.Mui-selected': {
        color: 'var(--selectBlue)', // Change the color for selected tab
      },
     }}
    // className={`btnLine ${tabIndex === index && `activeButton`}`} 
    
    key={`${tabs}-${index}`}>
    </Tab>)}
    {children}
    {settings && <div style={{marginLeft:"auto"}}>
     {settingsContent}
    </div>}
    </Tabs>
  )
}
