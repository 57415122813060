export const FinancialProductInfo = [
    {
      "productName": "CreditCard",
      "heading": "Credit Cards",
      "url": "credit_card",
      "financialTipPositive": [
        "Owning a credit card can help build your credit score and earn valuable rewards.",
        "Invest in a credit card with a low APR, low balance, and high rewards.",
        "Owning a credit card can help build your credit score and earn valuable rewards.",
        "It’s wise to pay off your balance by the end of each month to avoid high interest charges and potential debt."
      ],
      "financialTipNegative": [
        "Carrying a balance can lead to high-interest charges.",
        "Overspending with credit cards can lead to significant debt."
      ],
      "disclaimerFor": "credit cards",
      "columns": ["Card", "Rating", "Best For", "Reward Rate", "Reward Description"],
    },
    {
      "productName": "PersonalLoans",
      "heading": "Personal Loans",
      "url": "personal_loans",
      "financialTipPositive": [
        "Personal loans can help consolidate debt and lower interest payments.",
        "They can be useful for funding large expenses like home improvements."
      ],
      "financialTipNegative": [
        "High interest rates can make personal loans costly.",
        "Missing payments can negatively impact your credit score."
      ],
      "disclaimerFor": "personal loans",
      "columns": ["Loan Type", "Rating", "Best For",]
    },
    {
      "productName": "SavingsAccount",
      "heading": "Savings Account",
      "url": "savings_accounts",
      "financialTipPositive": [
        "Savings accounts are a safe place to grow your emergency fund.",
        "Some accounts offer competitive interest rates."
      ],
      "financialTipNegative": [
        "Low interest rates may not keep up with inflation.",
        "Excessive withdrawals may lead to fees."
      ],
      "disclaimerFor": "savings accounts",
      "columns": ["Savings Account", "Rating", "Best For"]
    },
    {
      "productName": "CarInsurance",
      "heading": "Car Insurance",
      "url": "car_insurance",
      "financialTipPositive": [
        "Having adequate car insurance can protect you from financial loss in accidents.",
        "Look for discounts and compare rates annually to save money."
      ],
      "financialTipNegative": [
        "Low coverage options may leave you financially vulnerable.",
        "Higher coverage levels come with increased premiums."
      ],
      "disclaimerFor": "car insurance",
      "columns": ["Car Insurance", "Rating", "Best For"]
    },
    {
      "productName": "LifeInsurance",
      "heading": "Life Insurance",
      "url": "life_insurance",
      "financialTipPositive": [
        "Life insurance can provide financial security for your loved ones.",
        "It’s a good tool for estate planning."
      ],
      "financialTipNegative": [
        "Some policies can be expensive.",
        "Not all life insurance products build cash value."
      ],
      "disclaimerFor": "life insurance",
      "columns": ["Life Insurance", "Rating", "Best For"]
    },
    {
      "productName": "HomeInsurance",
      "heading": "Home Insurance",
      "url": "home_insurance",
      "financialTipPositive": [
        "Home insurance protects against financial losses from disasters and theft.",
        "Bundling home and auto insurance can save money."
      ],
      "financialTipNegative": [
        "Not all damages may be covered under standard policies.",
        "Higher coverage limits increase premiums."
      ],
      "disclaimerFor": "home insurance",
      "columns": ["Home Insurance", "Rating", "Best For"]
    },
    {
      "productName": "Mortgages",
      "heading": "Mortgages",
      "url": "mortgage",
      "financialTipPositive": [
        "A mortgage can help you afford a home without paying upfront.",
        "Consider shorter loan terms to save on interest."
      ],
      "financialTipNegative": [
        "Long-term mortgages can lead to high total interest costs.",
        "Missing payments may lead to foreclosure."
      ],
      "disclaimerFor": "mortgages",
      "columns": ["Mortgages", "Rating", "Best For"]
    },
    {
      "productName": "StudentLoans",
      "heading": "Student Loans",
      "url": "student_loans",
      "financialTipPositive": [
        "Student loans allow you to invest in education and future earning potential.",
        "Many loans offer flexible repayment plans."
      ],
      "financialTipNegative": [
        "High loan amounts can lead to significant debt after graduation.",
        "Defaulting on student loans can hurt your credit score."
      ],
      "disclaimerFor": "student loans",
      "columns": ["Student Loans", "Rating", "Best For"]
    }
  ];
  