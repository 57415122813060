import React from 'react'
import ProductIntro from './product/ProductIntro'
import { Typography } from '@mui/material'
import Logo from '../logo/Logo'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppInfo } from 'AppState'
import SocialMedia from './SocialMedia'

export default function HomeFooterBig() {
    const {stateApp} = useAppInfo()
    const navigate = useNavigate()
    const location = useLocation()
    const page = location.pathname.split("/")[1]
    function handleNavigate(v){
        navigate(`/${v}`)
    }
    const menuOptions = stateApp.isLoggedIn ? 
    ["Home","Product","Pricing","Profile","Support","About","Contact","Disclaimer","Terms"] : 
    ["Features","Pricing","Support","About","Contact","Disclaimer","Terms"]
 
  return (
    <div style={{width:"100%",background:"black"
    ,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"1rem",padding:"4rem",boxSizing:"border-box",zIndex:100,position:"relative"}}>
            {/* <div style={{top:"50%",zIndex:10,height:"50%",overflow:"hidden",width:"100%"
        ,position:"absolute"}}> 
     
        <img 
        style={{height:"100%",width:"100%",objectFit:"cover",zIndex:-2}}
        src="https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/images%2FHome%2Faa875fe5c73b259176bb3d0cc2e9630a.png?alt=media&token=68f791bc-dcdf-4e7f-8387-7cdd0dc63fcf">
        
        </img>
       
      </div> */}
      
    {/* <ProductIntro/> */}
    <Logo height='25px' width="150px" type='long'/>
    <Typography style={{color:"white",width:"40%",textAlign:"center"}}>This is a Beta Version.</Typography>
    
    <div style={{display:"flex",gap:"2rem",alignItems:"center",position:"relative"}}>
            {menuOptions.map(obj=>
            <Typography 
            onClick={()=>handleNavigate(obj)}
            key={obj} 
            style={{cursor:"pointer",color:obj===page ? 
            "var(--lightBlue)":"white"}}>{obj}</Typography>)}
          
          </div>
          <Typography style={{color:"white",width:"40%",textAlign:"center",padding:"2rem"}}>Please read the Terms and Disclaimer before using the product.</Typography>
    <SocialMedia/>
    </div>
  )
}
