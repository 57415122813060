import { Alert, Button } from '@mui/material'
import { useAppInfo } from 'AppState'
import { defaultFieldBlank, goalTypes } from 'appState/initialStateMyFinancials'
import AddFinancialsModal from 'myFinancials/Utils/AddFinancialsModal'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FieldFormGen from 'myFinancials/FormFields/FieldFormGen'
import React, { useEffect, useState } from 'react'
import TabLine from 'components/Tabs/TabLine'
import GoalIcons from './GoalIcons'
import { InputAmount, InputDate, InputForecastPeriod, InputGrowthRate, InputName, InputPeriod, InputPriority, InputType } from 'myFinancials/FormFields/FieldFormCells';
import useFieldForm from 'myFinancials/FormFields/useFieldForm';
import CashflowChart from 'myFinancials/Cashflow/CashflowChart';
import { extrapolateCashflow } from 'myFinancials/Utils/utils';
import { generateUniqueId } from 'utils/utilGeneral';
import Modal from 'components/Modal/Modal';



export default function GoalAdd({variant="contained"}){
  const [addGoals,setAddGoals] = useState(false)
  const [step,setStep] = useState(0)
  const [mouseEnter,setMouseEnter] = useState(-1)
  const [goalSelected,setGoalSelected] = useState(0)
  const [data,setData] = useState([defaultFieldBlank?.Goals])
  const [chartData,setChartData] = useState([])
  const {
    fields,
    error,
    handleChange,
    handleSubmit,
  } = useFieldForm({storeName:"goalsPeriodic",data:data,defaultField:defaultFieldBlank?.Goals})
  
  useEffect(() => {
    const updateData = data.map(obj => {
      return {...obj, type: goalTypes[goalSelected],id:generateUniqueId()};
    });
    
    setData(updateData);
  }, [goalSelected]);

  useEffect(()=>{
    if (fields.length>0){
      setChartData(extrapolateCashflow({fields}))

    }
   
  },[fields])

  
  return (
  <>
  <Button onClick={()=>setAddGoals(true)} variant={variant}>+ Goal</Button>
  {addGoals && <Modal sx={{minWidth:"60%"}} width='80%' height='70%' onClose={()=>{setAddGoals(false);setStep(0)}}>

  
  {step===0 && <div style={{display:"flex",flexDirection:"column"
    ,gap:"1rem",alignItems:"center",width:"100%",height:"100%",overflow:"auto"}}>
        <h2 style={{textAlign:"center",padding:"2rem"}}>
    Select a Goal Type
    </h2>
  <div style={{display:"flex",gap:"1rem",flex:1,width:"100%",flexWrap:"wrap"
    ,justifyContent:"center"}}>
      {goalTypes.map((goal,index)=>
      <div key={goal} 
      onClick={()=>setGoalSelected(index)}
      onMouseEnter={()=>setMouseEnter(index)}
      onMouseLeave={()=>setMouseEnter(-1)}
      style={{background:"#fafafa"
      ,padding:"0.5rem",alignItems:"center"
      ,justifyContent:"center",maxHeight:"250px",display:"flex",flexDirection:"column"
      ,borderRadius:"5px",boxShadow:(mouseEnter === index || goalSelected === index) && "var(--boxShadowGeneral)",border:goalSelected===index && "2px solid green"}}>
      <CheckCircleIcon style={{color:goalSelected===index?"green":"gray"}}/>
      <div style={{display:"flex",alignItems:"center",flex:1}}>
      <GoalIcons iconType={goal} width='200px'/></div>
      <h3 style={{marginTop:"auto"}}>{goal}</h3>
      </div>)}
      </div>
      <div style={{position:"sticky",bottom:"0",zIndex:"100",background:"white",padding:"1rem"}}>
      <Button variant='contained' onClick={()=>setStep(1)}>Next: Enter Details</Button>
      </div>
</div>}
{step===1 && <>
<div style={{display:"flex",gap:"1rem",width:"100%",flexWrap:"wrap"}}>
  <div style={{background:"#fafafa",width:"30%",minWidth:"300px",borderRadius:"5px"}}>
  <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
  {fields.map((fieldObj,index)=>
  <form onSubmit={(e) => {
    const isSuccess = handleSubmit(e);
    if (isSuccess) {
      setAddGoals(false); // Close the modal
      setStep(0); // Reset the step
    }
  }}>
  <div style={{position:"relative",display:"flex",flexDirection:"column",gap:"1rem",padding:"0.5rem"}}>
  <div style={{flex:1,display:"flex",flexDirection:"column",gap:"0.5rem"}}>
  <h3>
    Enter Goal Details
    </h3>
  <InputType typeOptions={goalTypes} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputPriority label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputName label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputGrowthRate label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputAmount label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputDate label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputPeriod label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputForecastPeriod label fieldObj={fieldObj} handleChange={handleChange}/>
  {error && <Alert severity='warning'>{error}</Alert>}
  </div>
  <Button sx={{position:"sticky",bottom:0,zIndex:6}} variant='contained' type='submit'>Add Goal</Button>
  </div>
    </form>)}
  
  </div>
  
  
  </div>
  <div style={{background:"#fafafa",flex:1,minWidth:"400px"}}>
  {chartData.length>0 && <CashflowChart data={chartData}/>}
  
  </div>
  

</div>

{/* 
<Button onClick={()=>setStep(0)}>Back</Button> */}

</>}
</Modal>}
</>

  )
}


export function GoalAddMulti({variant="highlight"}) {
  const {stateMyFinancials} = useAppInfo()
  const {goalsPeriodic} = stateMyFinancials
  const [tabIndex,setTabIndex]  = useState(0)
  const [addGoals,setAddGoals] = useState(false)

  return (
        <>
        <Button onClick={()=>setAddGoals(true)} variant={variant}>+ Goals</Button>
          {addGoals && 
          <AddFinancialsModal title="Financial Goals" onClose={()=>setAddGoals(false)}>
           <TabLine tabIndex={tabIndex} tabList={["Periodic","One Time"]} handleTabChange={(v)=>setTabIndex(v)}>
          </TabLine>
          <FieldFormGen showPriority 
  defaultField={defaultFieldBlank.Goals} 
  forecastPeriod={true} 
  date={true} 
  showType={true} 
  typeOptions={goalTypes} 
  growthRate={true} 
  period={true} 
  formName='Goals' 
  storeName={"goalsPeriodic"} 
  data={goalsPeriodic} 
  categoryName='Goals'/>
          </AddFinancialsModal>}
          
      </>)
}
