import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import BorderBox from 'components/Layout/BorderBox';
import NetWorthHeatMap from './NetWorthHeatMap';
import TabLine from 'components/Tabs/TabLine';
import NetWorthBalanceSheet from './NetWorthBalanceSheet';
import NetworthProjection from './NetworthProjection';
import NetworthStepsToGrow from './NetworthStepsToGrow';
import NoFinancialData from 'myFinancials/Template/NoFinancialData';




export default function NetWorth({info,displayType="sidebar"}) {

  const {stateMyFinancials} = useAppInfo()
  const {summaryStats,currency,financialsLoaded,assets, liabilities,} = stateMyFinancials
  const { netWorth} = summaryStats;
  const [tabIndex,setTabIndex] = useState(0)
  const tabList = ["Overview","Steps to grow Networth","Projections","Breakdown"]


  useEffect(()=>{
    if (info && displayType !== "tabs"){
      setTabIndex(tabList.indexOf(info))
    }
  },[info])
  
  const tabContent = []
  tabContent[0] = <NetWorthBalanceSheet projection header={false} barChart border/>
  tabContent[1] = <NetworthStepsToGrow/>
  tabContent[2] = <NetworthProjection/>
  tabContent[3] = <BorderBox><NetWorthHeatMap/></BorderBox>
  
  return (
    <div style={{position:"relative",width:"100%",height:"100%"}}>
    <NoFinancialData financialType="networth"/>
    {financialsLoaded && (assets.length > 0 || liabilities.length > 0) && 
    <>
    {displayType === "tabs" && <div>
    
     <>
     <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>
   
    <div className='containerColumnFlex1'>
    {tabContent[tabIndex]}
    </div></>
    </div> }

    {displayType === "sidebar" && 
    tabContent[tabIndex]
    }
    </>
  }

  </div>
  )
}
