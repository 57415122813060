import React from 'react';
import MessageBar from './MessageBar';
import ChatContentArea from './ChatContentArea';
import ChatContent from './ChatContent';
import { useAppInfo } from 'AppState';
import TSAssistantMainLayout from './TSAssistantMainLayout';

export default function TSAssistant({children,displayType="page"}) {
    const {stateAssistant,dispatchAssistant} = useAppInfo()

    const {showContent} = stateAssistant

  return (
    <>
    {displayType === "main" && <TSAssistantMainLayout/>}
     
    {displayType === "page" && 
     <div id={"assistant"} style={{
        width: "100%", position: "relative"
        , boxSizing: "border-box", display: "flex"
        , justifyContent: "center", alignItems: "center", padding: "1rem", 
        position: "sticky", bottom: "0px",
        zIndex: 1000
      }}>
        {children}
        
        
        {showContent && 
        <ChatContentArea>
            <ChatContent/>
        </ChatContentArea>}
        
        <MessageBar/>
    </div>}

    
    </>
  )
}


  