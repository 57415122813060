import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import { List, ListItem, Checkbox, FormControlLabel, Paper } from "@mui/material";

function MultiSelectBox({ values, options, onChange, handleClose, label, key }) {
  const [searchQuery, setSearchQuery] = useState("");
  const modalRef = useRef();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal, close it
        handleClose();
      }
    }

    // Add an event listener to handle outside clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  const filteredOptions = options.filter(
    (option) =>
      option && option.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderOptions = () => {
    const optionsToRender = values && values.concat(filteredOptions.filter((option) => !values.includes(option)));
    return optionsToRender.map((option) => (
      <ListItem key={option} sx={{ padding: "0 0 0 1rem", border: "none" }}>
        <FormControlLabel
          control={
            <Checkbox
              sx={{ padding: "0.2rem", margin: 0 }}
              checked={values.includes(option)}
              onChange={() => onChange(option, key)}
            />
          }
          label={option}
        />
      </ListItem>
    ));
  };

  return (
    <div
      ref={modalRef}
      style={{
        position: "absolute",
        zIndex: "1000",
        maxWidth: "400px",
        boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end", background: "white" }}>
        <TextField
          value={searchQuery}
          onChange={handleSearchChange}
          variant="filled"
          type="search"
          placeholder="Search"
          InputProps={{
            style: { background: "white", color: "black", width: "100%", padding: 0,margin:0,border:"none"},
          }}
          style={{ width: "100%", padding: 0 }}
        />
      </div>
      <div style={{ maxHeight: "200px", overflow: "auto", width: "100%" }}>
        <Paper elevation={3} sx={{ borderRadius: "4px" }}>
          <List>{renderOptions()}</List>
        </Paper>
      </div>
    </div>
  );
}

export default MultiSelectBox;