import React, { act, useEffect, useRef, useState } from 'react'
import HomeFooterBig from './HomeFooterBig'
import HomePageChatLanding from './HomePageChatLanding'
import Video from 'components/Media/Video'
import QuestionBox from 'components/Layout/QuestionBox'
import UseCases from './UseCases'
import Logo from 'logo/Logo'
import GetStartedButton from './product/GetStartedButton'
import FadeIn from './FadeIn'
import BackgroundVideo from 'components/Media/BackgroundVideo'
import TabLine from 'components/Tabs/TabLine'
import GlowBackground from './GlowBackground'
import GrowFinanciallyToday from './GrowFinanciallyToday'
import QuestionsHome from './QuestionsHome'
import FeatureAskAI from './product/FeatureAskAI'
import Features from './product/Features'


export default function HomeLandingPage() {

  return (
    <div style={{width: "100%",display:"flex",
        flexDirection:"column",justifyContent:"center",minHeight:"calc(100vh)"
        ,boxSizing:"border-box",background:"white"}}>
          
          <HomePageChatLanding/>

          <Features/>
           
           <HomeFooterBig/>
          </div>

  )
}




const Typewriter = ({ message = '', onComplete, speed = 30,fontSize="2rem"}) => {
  const [displayedText, setDisplayedText] = useState('');
  const indexRef = useRef(0);
  const rafIdRef = useRef(null);
  const startTimeRef = useRef(null);

  useEffect(() => {
    if (!message) return;

    setDisplayedText('');
    indexRef.current = 0;
    startTimeRef.current = null;

    const typeCharacter = (timestamp) => {
      if (!startTimeRef.current) {
        startTimeRef.current = timestamp;
      }

      const elapsed = timestamp - startTimeRef.current;

      // Check if it's time to display the next character
      if (elapsed >= speed * indexRef.current) {
        setDisplayedText(message.slice(0, indexRef.current + 1));
        indexRef.current += 1;
      }

      if (indexRef.current < message.length) {
        rafIdRef.current = requestAnimationFrame(typeCharacter);
      } else {
        if (onComplete) onComplete();
        cancelAnimationFrame(rafIdRef.current);
        rafIdRef.current = null;
      }
    };

    rafIdRef.current = requestAnimationFrame(typeCharacter);

    // Cleanup on unmount or when message changes
    return () => {
      if (rafIdRef.current) {
        cancelAnimationFrame(rafIdRef.current);
      }
    };
  }, [message, speed]);

  return <h1 style={{ color: '#000',fontSize:fontSize}}>{displayedText}</h1>;
};



// Main Component
const BackgroundMessageVideoSection = () => {
  return (
    <FullWidthCarousel sections={sections} />
  );
};

const sections = [
  {
    message: "How am I doing financially?",
    videoUrl: "https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/videos%2FHowAmIdoing_.mp4?alt=media&token=dce37109-2817-4eab-b933-15c165f48270",
    imageUrl: "https://images.unsplash.com/photo-1518130242561-edb760734bee?q=80&w=2535&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    messageAlign: "left",
  },
  {
    message: "Can I afford a million-dollar house?",
    videoUrl: "https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/videos%2FCanIBuyaHouse.mp4?alt=media&token=1bfa24db-8387-4628-87f5-1079c326f5a2",
    imageUrl: "https://images.unsplash.com/photo-1501004318641-b39e6451bec6?q=80&w=2273&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    messageAlign: "left",
  },
  {
    message: "Am I beating the market?",
    videoUrl: "https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/videos%2FAmIbeatingMarket.mp4?alt=media&token=85b336ee-c549-40c6-b94d-87c40cd6dc18",
    imageUrl: "https://images.unsplash.com/photo-1466781783364-36c955e42a7f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cGxhbnR8ZW58MHx8MHx8fDA%3D",
    messageAlign: "left",
  },
  {
    message: "Find me opportunities aligned with my portfolio strategy?",
    videoUrl: "https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/videos%2FOpportunityPortfolio.mp4?alt=media&token=bbb80045-2a31-44ab-81a8-42d406e350d2",
    imageUrl: "https://images.unsplash.com/photo-1604762525343-62309354f8a1?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    messageAlign: "left",
  },
];


// "https://images.unsplash.com/photo-1605449670493-ca1d812d0488?q=80&w=2536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"


const CarouselSection = ({ section, active }) => {
  const [typingCompleted, setTypingCompleted] = useState(false);
  const [messageInView, setMessageInView] = useState(false);

  useEffect(() => {
    if (active) {
      setMessageInView(true);
    } else {
      setMessageInView(false);
      setTypingCompleted(false); // Reset typing state on slide change
    }
  }, [active]);


  return (
    <div
      style={{
        minHeight: '120vh',
        width: '100%',
        background: "white",
        position: 'relative',
        top: 0,
        left: active ? '0%' : '100%',
        // transition: 'left 1s ease-in-out',
        padding: "3rem",
        boxSizing: "border-box",
        zIndex: active ? 1 : 0,
      }}
    >

        {messageInView && (
          <div
            style={{
              backdropFilter: "blur(30px)",
              width: '100%',
              zIndex: 200,
              height: "100%",
              position: 'relative',
              padding: '20px',
              // borderRadius: '10px',
              // display: 'flex',
              // flexDirection: 'column',
              gap: '1rem',
              opacity: messageInView ? 1 : 0,
              transition: 'opacity 1s ease-in-out',
              display:"flex",flexDirection:"column",alignItems:"center",gap:"1rem"
            }}
          >
            <div
              style={{
                background: '#f7f7f7',
                borderRadius: '5px',
                padding: '1rem',
                width: 'max-content',
                // marginLeft: section.messageAlign === 'left' ? 'auto' : '0',
                maxWidth: '600px',
                opacity: messageInView ? 1 : 0,
                transition: 'opacity 1s ease-in-out',
              }}
            >
              <Typewriter
                message={section.message}
                onComplete={() => setTypingCompleted(true)}
              />
            </div>

            

            {typingCompleted && (
              
              <div
                className='fade-in'
                style={{
                  borderRadius: '5px',
                  // padding: '1rem',
                  height:"auto",
                  width: '80%',
                  // marginLeft: section.messageAlign !== 'left' ? 'auto' : '0',
                  opacity: typingCompleted ? 1 : 0,
                  transition: 'opacity 4s ease-in-out',
                }}
              >
                <GlowBackground>
                <Video 
                  src={section.videoUrl}
                  sx={{height:"auto"}}
                />
                </GlowBackground>
                {/* <img src={section.imageUrl} style={{height:"auto",width:"100%",objectFit:"cover"}}/>
                 */}
              </div>
            )}
          </div>
        )}

    </div>
  );
};

const FullWidthCarousel = ({ sections, delay = 14000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  // useEffect(() => {
  //   if (!isPaused) {
  //     const intervalId = setInterval(() => {
  //       setActiveIndex((prevIndex) => (prevIndex + 1) % sections.length);
  //     }, delay); // Change every 14 seconds (14,000 ms)

  //     return () => clearInterval(intervalId); // Clean up on unmount
  //   }
  // }, [sections.length, delay, isPaused]);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % sections.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? sections.length - 1 : prevIndex - 1
    );
  };
  const questions = sections.map((section) => section.message);
 
  return (
    <div style={{ width: '100%', minHeight: '100vh', position: 'relative', 
    overflow: 'hidden',display:"flex",flexDirection:"column",alignItems:"center"}}>
      <>
      <TabLine 
      variant='noLine'
      // tabSx={{background:"black",color:"white"}}   
      // tabsSx={{background:"black"}}
      tabList={questions} tabIndex={activeIndex} handleTabChange={(i)=>setActiveIndex(i)}/></>
      {sections.map((section, index) => (
        <>
        {index === activeIndex &&
        <CarouselSection
          key={index}
          section={section}
          active={index === activeIndex}
        />}
        </>
      ))}

      {/* Navigation Buttons */}
      {/* <div style={{ position: 'absolute', 
        top: '50%',display: 'flex', 
        justifyContent: 'flex-end',gap:"1rem",padding: '0 2rem',right:"10px",zIndex:100}}>
        <button 
          onClick={() => {
            handlePrev();
            setIsPaused(true); // Pause auto-transition when manually navigating
          }}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
          }}
        >
          Previous
        </button>
        <button 
          onClick={() => {
            handleNext();
            setIsPaused(true); // Pause auto-transition when manually navigating
          }}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
          }}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};
