function extrapolateCashflow({
  fields = [],
  years = 20,
  growth = 0,
  growthKey = "growthRate",
  amountType = "totalValue",
  volatility = 0,
  showVolatility = false,
  period = "Monthly"
} = {}) {
  let results = [];

  function getLastDayOfMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  function simulateVolatility(baseGrowth, volatility) {
    return baseGrowth + volatility * (Math.random() * 2 - 1);
  }

  function monthsDifference(date1, date2) {
    return (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
  }

  fields.forEach(field => {
    let fieldPeriod = field.period || period;
    
    // Handle the "One Time" period by calculating the inflated amount
    if (fieldPeriod === 'One Time') {
      let startDate = new Date(field?.date) || new Date();
      let currentDate = new Date();

      if (startDate > currentDate) {
        let monthsDiff = monthsDifference(currentDate, startDate);
        let yearsDiff = monthsDiff / 12;
        let effectiveGrowth = field[growthKey] ? field[growthKey] : growth;
        let inflatedAmount = field.amount * (1 + effectiveGrowth / 100) ** yearsDiff;

        results.push({
          ...field,
          amount: inflatedAmount,
          date: startDate
        });
      } else {
        results.push({ ...field });
      }
      return;
    }

    let totalYears = field.forecastPeriod || years;

    for (let year = 0; year <= totalYears; year++) {
      let monthsToAdd = 1; // Default to monthly calculations

      switch (fieldPeriod) {
        case 'Monthly':
          monthsToAdd = 1;
          break;
        case 'Quarterly':
          monthsToAdd = 3;
          break;
        case 'Semi-Annually':
          monthsToAdd = 6;
          break;
        case 'Annually':
          monthsToAdd = 12;
          break;
      }

      
    // Convert the annual growth rate to a monthly growth rate
    let effectiveGrowth = field[growthKey] ? field[growthKey] : growth;
    if (fieldPeriod === 'Daily') {
      effectiveGrowth = (1 + effectiveGrowth / 100) ** (1 / 365) - 1; // Convert annual growth to daily
      effectiveGrowth = (1 + effectiveGrowth) ** 30 - 1; // Convert daily growth to monthly
    } else if (fieldPeriod === 'Weekly') {
      effectiveGrowth = (1 + effectiveGrowth / 100) ** (1 / 52) - 1; // Convert annual growth to weekly
      effectiveGrowth = (1 + effectiveGrowth) ** 4.33 - 1; // Convert weekly growth to monthly
    } else {
      effectiveGrowth = effectiveGrowth/100;
    }

      let volatilityRate = field.volatility/100 || volatility/100;
      let adjustedGrowthRate = showVolatility ? simulateVolatility(effectiveGrowth, volatilityRate) : effectiveGrowth;

      let startDate = new Date(field?.date) || new Date();
      let startAmount = field.amount;
      let currentDate = new Date();

      if (startDate > currentDate) {
        let monthsDiff = monthsDifference(currentDate, startDate);
        let yearsDiff = Math.floor(monthsDiff / 12);
        let preGrowthRate = (1 + adjustedGrowthRate) ** yearsDiff;
        startAmount *= preGrowthRate;
      }

      for (let month = 1; month <= 12; month += monthsToAdd) {
        const monthOffset = startDate.getMonth() + month - 1 + 12 * year;
        const yearAdjusted = startDate.getFullYear() + Math.floor(monthOffset / 12);
        const monthAdjusted = monthOffset % 12;

        const lastDay = getLastDayOfMonth(yearAdjusted, monthAdjusted + 1);
        const date = new Date(yearAdjusted, monthAdjusted, lastDay);
        const factor = (1 + adjustedGrowthRate) ** year;

        let projectionData = {};
        if (amountType === "growth") {
          projectionData = {
            ...field,
            amount: startAmount * factor - startAmount,
            date
          };
        } else {
          projectionData = {
            ...field,
            amount: startAmount * factor,
            date
          };
        }

        results.push(projectionData);

        if (year === totalYears && monthAdjusted === 11) break;  // Ensure loop ends in December
      }
    }
  });

  return results;
}





// function extrapolateCashflow({
//   fields = [],
//   years = 20,
//   growth = 0,
//   growthKey = "growthRate",
//   amountType = "totalValue",
//   volatility = 0,
//   showVolatility = false,
//   period = "Monthly"
// } = {}) {
//   let results = [];

//   function getLastDayOfMonth(year, month) {
//     return new Date(year, month, 0).getDate();
//   }

//   function simulateVolatility(baseGrowth, volatility) {
//     return baseGrowth + volatility * (Math.random() * 2 - 1);
//   }

//   function monthsDifference(date1, date2) {
//     return (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
//   }

//   fields.forEach(field => {
//     let totalYears = field.forecastPeriod || years;

//     for (let year = 0; year <= totalYears; year++) {
//       let monthsToAdd;
//       switch (field.period || period) {
//         case 'Monthly':
//           monthsToAdd = 1;
//           break;
//         case 'Quarterly':
//           monthsToAdd = 3;
//           break;
//         case 'Semi-Annually':
//           monthsToAdd = 6;
//           break;
//         case 'Annually':
//           monthsToAdd = 12;
//           break;
//         default:
//           monthsToAdd = 1;
//       }

//       let baseGrowthRate = field?.[growthKey] ? field?.[growthKey] : growth;
//       let volatilityRate = field?.volatility ? field?.volatility : volatility;
//       let adjustedGrowthRate = showVolatility ? simulateVolatility(baseGrowthRate, volatilityRate) : baseGrowthRate;

//       for (let month = 1; month <= 12; month += monthsToAdd) {
//         let startDate = new Date(field?.date) || new Date();
//         let startAmount = field.amount;
//         let currentDate = new Date();

//         if (startDate > currentDate) {
//           let monthsDiff = monthsDifference(currentDate, startDate);
//           let yearsDiff = Math.floor(monthsDiff / 12);
//           let preGrowthRate = (1 + (baseGrowthRate / 100)) ** yearsDiff;
//           startAmount *= preGrowthRate;
//         }

//         const monthOffset = startDate.getMonth() + month - 1 + 12 * year;
//         const yearAdjusted = startDate.getFullYear() + Math.floor(monthOffset / 12);
//         const monthAdjusted = monthOffset % 12;

//         // Ensure that for the final year, the projection continues until December
//         if (year === totalYears && monthAdjusted === 0 && month < 12) {
//           monthsToAdd = 12 - month;
//         }

//         const lastDay = getLastDayOfMonth(yearAdjusted, monthAdjusted + 1);
//         const date = new Date(yearAdjusted, monthAdjusted, lastDay);
//         const factor = (1 + adjustedGrowthRate / 100) ** year;

//         let monthlyData = {};
//         if (amountType === "growth") {
//           monthlyData = {
//             ...field,
//             amount: startAmount * factor - startAmount,
//             date
//           };
//         } else {
//           monthlyData = {
//             ...field,
//             amount: startAmount * factor,
//             date
//           };
//         }

//         results.push(monthlyData);

//         if (year === totalYears && monthAdjusted === 11) break;  // Ensure loop ends in December
//       }
//     }
//   });

//   return results;
// }





const getPerformanceColor = (value) => {
  return value >= 0 ? "green" : "red";
};


function calculateNetCashFlow(incomeDist, expenseDist,fieldName="Net Cash Flow",type="all") {
  const netCashFlowMap = new Map();

  // Process each income entry
  if (type === "income" || type === "all"){
  incomeDist.forEach(({ date, amount }) => {
    if (new Date (date) instanceof Date && !isNaN(amount)) {
      const current = netCashFlowMap.get(normalizeDate(date)) || { date, amount: 0, name: fieldName };
      current.amount += amount; // Add income to the existing amount
      netCashFlowMap.set(normalizeDate(date), current);
    } else {
      console.error('Invalid income entry:', { date, amount });
    }
  });}

  if (type === "expense" || type === "all"){
  // Process each expense entry
  expenseDist.forEach(({ date, amount }) => {
    if (new Date (date) instanceof Date && !isNaN(amount)) {
      const current = netCashFlowMap.get(normalizeDate(date)) || { date, amount: 0, name: fieldName };
      current.amount -= amount; // Subtract expense from the existing amount
      netCashFlowMap.set(normalizeDate(date), current);
    } else {
      console.error('Invalid expense entry:', { date, amount });
    }
  });}

  // Convert Map values to an array, ensuring dates are sorted
  const netCashFlowArray = Array.from(netCashFlowMap.values());
  netCashFlowArray.sort((a, b) => new Date(a.date) - new Date(b.date));

  return netCashFlowArray;
}


function calculateMonthlyNetCashFlow(incomeDist, expenseDist, fieldName="Net Cash Flow") {
  const netCashFlowMap = new Map();

  // Helper function to format date as the first day of its month

  // Process each income entry
  incomeDist.forEach(({ date, amount }) => {
    const dateObject = new Date(date);
    if (dateObject instanceof Date && !isNaN(amount)) {
      const monthStart = getMonthStart(dateObject);
      const current = netCashFlowMap.get(normalizeDate(monthStart)) || { date: dateObject, amount: 0, name: fieldName };
      current.amount += amount; // Add income to the existing amount
      netCashFlowMap.set(normalizeDate(monthStart), current);
    } 
  });

  // Process each expense entry
  expenseDist.forEach(({ date, amount }) => {
    const dateObject = new Date(date);
    if (dateObject  instanceof Date && !isNaN(amount)) {
      const monthStart = normalizeDate(getMonthStart(dateObject));
      if (netCashFlowMap.has(normalizeDate(monthStart))) {
        const current = netCashFlowMap.get(monthStart);
        current.amount -= amount;
        netCashFlowMap.set(normalizeDate(monthStart), current);
      }
      // const monthStart = getMonthStart(dateObject);    
      // const current = netCashFlowMap.get(normalizeDate(monthStart)) || { date: dateObject, amount: 0, name: fieldName };
      // current.amount -= amount; // Subtract expense from the existing amount
      // netCashFlowMap.set(normalizeDate((monthStart)), current);
    } 
  });

  // Convert Map values to an array, ensuring months are sorted
  const netCashFlowArray = Array.from(netCashFlowMap.values());
  netCashFlowArray.sort((a, b) => a.date - b.date);

  return netCashFlowArray;
}

function calculateMonthlyGoals(incomeDist, expenseDist, fieldName="Net Cash Flow") {
  const netCashFlowMap = new Map();

  // Helper function to format date as the first day of its month
  function getMonthStart(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  // Populate netCashFlowMap with expenses, marking relevant months
  expenseDist.forEach(({ date, amount }) => {
    const dateObject = new Date(date);
    if (dateObject instanceof Date && !isNaN(amount)) {
      const monthKey = normalizeDate(getMonthStart(dateObject));
      const current = netCashFlowMap.get(monthKey) || { date: dateObject, amount: 0, name: fieldName };
      current.amount -= amount;
      netCashFlowMap.set(monthKey, current);
    }
    // else{
    //   console.log("Error Expense")
    // }
  });

  // Process incomes that match months in netCashFlowMap
  incomeDist.forEach(({ date, amount }) => {
    const dateObject = new Date(date);
    if (dateObject instanceof Date && !isNaN(amount)) {
      const monthKey = normalizeDate(getMonthStart(dateObject));
      if (netCashFlowMap.has(monthKey)) {
        const current = netCashFlowMap.get(monthKey);
        current.amount += amount;
        netCashFlowMap.set(monthKey, current);
      }
    }
    // else{
    //   console.log("Error Expense")
    // }
  });

  // Convert Map to sorted array
  return Array.from(netCashFlowMap.values()).sort((a, b) => a.date - b.date);
}


export function getMonthStart(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function normalizeDate(date) {
  // Check if the input is already a Date object
  if (!(date instanceof Date)) {
    // If it's not a Date object, try to create a Date object from it
    date = new Date(date);
  }

  // Check if the created date is valid
  if (isNaN(date)) {
    throw new Error("Invalid date", date);
  }
  // Return the normalized date string in 'YYYY-MM-DD' format
  return date.toISOString().split('T')[0];
}

function calculateAmortizationSchedule(props) {
  const {name,date, amount, interestRate, loanTerm, compounding, paybackPeriod} = props;

  const periodOptions = {
    'daily': 365,
    'weekly':52,
    'bi-weekly':26,
    'bi-monthly':6, 
      'monthly': 12, 
      'quarterly': 4,
      'semi-annually':2,
      'annually': 1
  };
  const periodDayOptions = {
    "daily": 1,
    "weekly": 7,
    "bi-weekly": 14,
    "monthly": 30,
    "bi-monthly": 60,
    "quarterly": 90,
    "semi-annually": 180,
    "annually": 365,
  }

  const loanTermAdjustments = {
    'daily': (y, m) => y * 365 + m * 30,
    'weekly':(y, m) => y * 365/7 + m * 4,
    'bi-weekly':(y, m) => y * 365/7 + m * 2,
    'bi-monthly':(y, m) => y * 6 + Math.floor(m / 2),
    'monthly': (y, m) => y * 12 + m,
    'semi-annually': (y, m) => y * 2 + Math.floor(m / 6),
      'quarterly': (y, m) => y * 4 + Math.floor(m / 3),
      'annually': (y, m) => y + Math.floor(m / 12)
  };

  function calculateCompoundingPeriods(compounding, paybackPeriod) {
    const compoundingType = compounding.toLowerCase()
    const paybackType = paybackPeriod.toLowerCase()
    const compoundingDays = periodDayOptions[compoundingType];
    const paybackDays = periodDayOptions[paybackType];
  
    // Calculate the effective number of days by finding how many compounding periods fit within one payback period
    const ratio = paybackDays / compoundingDays;
  
    return ratio;
  }


  if (!periodOptions[paybackPeriod.toLowerCase()] || !loanTermAdjustments[paybackPeriod.toLowerCase()]) {
      throw new Error('Unsupported payback period or compounding frequency');
  }

  const periods = loanTermAdjustments[paybackPeriod.toLowerCase()](Number(loanTerm.y), Number(loanTerm.m));
  const compoundingPeriodsPerYear = periodOptions[compounding.toLowerCase()];
  const annualInterestRate = interestRate / 100;
  const pRate = annualInterestRate / compoundingPeriodsPerYear;
  const compoundingPeriod = calculateCompoundingPeriods(compounding, paybackPeriod)
  const periodicInterestRate = (Math.pow(1 + pRate, compoundingPeriod) - 1); 
  const periodicPayment = amount * periodicInterestRate / (1 - Math.pow(1 + periodicInterestRate, -periods));
  if (periods === 0 || amount === 0) return [];

  let amortizationSchedule = [];
  let currentBalance = amount;
  let paymentDate = new Date(date);
  let totalInterest = 0
  let totalPrincipal = 0
  let totalAmount = periodicPayment * periods

  for (let i = 0; i < periods; i++) {
      const interest = currentBalance * periodicInterestRate;
      const principal = periodicPayment - interest;
      const beginningBalance = currentBalance;
      totalInterest += interest
      totalPrincipal += principal
      // Adjust payment date
      adjustDate(paymentDate, paybackPeriod);

      currentBalance -= principal;

      amortizationSchedule.push({
          name:name,
          date: new Date(paymentDate),
          beginningBalance: beginningBalance,
          period: i + 1,
          amount: periodicPayment,
          principal: principal,
          interest: interest,
          endingBalance: currentBalance > 0 ? currentBalance : 0
      });

      if (currentBalance <= 0) break;
  }

  return {totalAmount:totalAmount || 0,periodicPayment:periodicPayment || 0,totalInterest:totalInterest || 0,totalPrincipal: totalPrincipal || 0,amortizationSchedule:amortizationSchedule || []};
}


function adjustDate(date, period) {
  switch (period.toLowerCase()) {
      case 'daily':
          date.setDate(date.getDate() + 1);
          break;
      case 'weekly':
          date.setDate(date.getDate() + 7);
          break;
      case 'bi-weekly':
          date.setDate(date.getDate() + 14);
          break;
      case 'monthly':
          date.setMonth(date.getMonth() + 1);
          break;
      case 'bi-monthly':
          date.setMonth(date.getMonth() + 2);
          break;
      case 'quarterly':
          date.setMonth(date.getMonth() + 3);
          break;
      case 'semi-annually':
          date.setMonth(date.getMonth() + 6);
          break;
      case 'annually':
          date.setFullYear(date.getFullYear() + 1);
          break;
      default:
          throw new Error(`Unsupported period: ${period}`);
  }
}



function calculateTimeHorizon(data) {
  const currentDate = new Date();

  function calculateYears(entry) {
      const startDate = new Date(entry.date);
      const forecastYears = entry.forecastPeriod || 0;
      const endDate = new Date(startDate);
      endDate.setFullYear(startDate.getFullYear() + forecastYears);
      const yearsDifference = (endDate - currentDate) / (365.25 * 24 * 60 * 60 * 1000); // Convert milliseconds to years
      return yearsDifference;
  }

  const yearsList = data.map(entry => calculateYears(entry));
  const averageYears = yearsList.reduce((sum, years) => sum + years, 0) / yearsList.length;
  const maxYears = Math.max(...yearsList);

  // Round to the nearest whole number
  const roundedAverageYears = Math.round(averageYears);
  const roundedMaxYears = Math.round(maxYears);

  // return {
  //     averageYears: roundedAverageYears,
  //     maxYears: roundedMaxYears
  // };
  return roundedMaxYears
}



export {extrapolateCashflow,getPerformanceColor,
  calculateNetCashFlow,calculateAmortizationSchedule,
  calculateMonthlyNetCashFlow,calculateMonthlyGoals,calculateTimeHorizon}