import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import FieldFormGen from '../FormFields/FieldFormGen'
import TabLine from 'components/Tabs/TabLine'
import CashflowChart from './CashflowChart'
import { defaultFieldBlank, expenseTypes} from 'appState/initialStateMyFinancials'
import PieChartCategory from 'components/Charts/PieChartCategory'
import AddExpense from './AddExpense'
import NoFinancialData from 'myFinancials/Template/NoFinancialData'

export default function ExpenseDetails() {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {expenseMonthly,cashflowDistribution,financialsLoaded} = stateMyFinancials
    const {expenseDist} = cashflowDistribution

  const [tabIndex,setTabIndex]  = useState(0)
  const tabList = ["Allocation/Budgeting","Projection",]

  if (financialsLoaded && expenseMonthly.length === 0) {
    return (<NoFinancialData financialType='expenses'/>)
  }
  
  return (
    <div style={{overflow:"auto",display:"flex",flexDirection:"column",width:"100%"}}>
       <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>

       {tabIndex === 0 && 
<div style={{width:"100%",height:"400px",minHeight:"400px"}}>
<PieChartCategory 
weightType='weighted' weightCategory={"amount"} 
data={expenseMonthly} 
category={"name"} showConnectors={false}/>
</div>}

{tabIndex === 1 &&     
<div style={{width:"100%",height:"400px",minHeight:"400px"}}>
<CashflowChart data={expenseDist} title="Projected Expense"/>
</div>}

<div style={{flex:1,width:"100%"}}>
   
    <FieldFormGen 
  defaultField={defaultFieldBlank.Expense} 
  showType
  typeOptions={expenseTypes}
  date={true}
  allocation={tabIndex===0} forecastPeriod={tabIndex===1} growthRate={tabIndex===0} period={true} 
  storeName={"expenseMonthly"} data={expenseMonthly} 
  formName='Expense' formHeading='Expense Category'
  editDetails={false}
  />
    </div>
    <div style={{padding:"0.5rem"}}>
      <AddExpense/>
      </div>
    </div>
  )
}
