import { useAppInfo } from 'AppState'
import { handleSignOut } from 'auth/useHandleSignout'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Logo({height="25px",width="25px",type="short"}) {
  const [mouseEnter,setMouseEnter] = useState(false)
  const {stateApp} = useAppInfo()
  
  function handleLogout(){
    if (stateApp.isLoggedIn && !stateApp.onboarding?.setDisplayName) {
      handleSignOut()
    }
   }
  return (
    <div onClick={()=>handleLogout()} style={{width:width,height:height}}  onMouseEnter={()=>setMouseEnter(true)} 
    onMouseLeave={()=>setMouseEnter(false)} 
    >
    <Link to="/"> 
    {type==="short" && <img src='\LogoShort.png' height={height} width={width} style={{padding:0,borderRadius:"2px"}}
    />}
    {type==="long" && <img src='\LogoLong.png' height={height} width={width} style={{padding:0,borderRadius:"0px"}}
    />}
    {/* <div className={`logo ${mouseEnter && `logoHover`}`} style={{height:{height},width:{width}}}>
    <LogoSVG width={width} height={height}/>
    </div> */}
    </Link> 
    </div>
  )
}