import QuestionBox from 'components/Layout/QuestionBox'
import React from 'react'

export default function QuestionsHome() {
  return (
    <div style={{padding:"2rem 4rem"}}>
          <h1 style={{fontSize:"4rem"}}>Questions?</h1>
          <QuestionBox/>    
          </div>
          
  )
}
