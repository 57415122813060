import React from 'react';

import PriceChartMulti from 'components/Charts/PriceChartMulti';

import { useAppInfo } from 'AppState';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import Beta from 'analytics/technicals/general/Beta';
import IndicatorUI from 'analytics/technicals/indicators/IndicatorUI';
import PerformanceClose from 'analytics/technicals/general/PerformanceClose';
import TrendTable from 'analytics/technicals/general/TrendTable';
import Volatility from 'analytics/technicals/general/Volatility';
import BondYieldCurve from 'analytics/ticker/bonds/BondYieldCurve';
import ForexExchangeRate from 'analytics/ticker/forex/ForexExchangeRate';
import IndexStock from './IndexStock';
import MarketToday from './MarketToday';
import Correlation from 'analytics/technicals/general/Correlation';


export default function MarketInfoTemplate({info}) {
  
  const { stateMarket} = useAppInfo();
  const {tickerList,tickerSelected,tickerDict} = stateMarket;
  const {close,trendTable,marketInfoLoading,priceData} = useMultiTickerInfo(tickerSelected)

  const tabContent = {
    "Market Today":<MarketToday/>,
    "Trend Table":<TrendTable key={tickerList[0]} 
    data={trendTable} tickerPrice={close} tickerDict={tickerDict} 
    marketInfoLoading={marketInfoLoading}/>,
    "Trend Chart":<PriceChartMulti 
    tickerDict={tickerDict}
    close={close} 
    marketInfoLoading={marketInfoLoading} />,
    "Performance":<PerformanceClose
    tickerDict={tickerDict}
    tickerList={tickerSelected}
    close={close} 
    marketInfoLoading={marketInfoLoading} />,
    "Volatility":<Volatility
    tickerDict={tickerDict}
    tickerList={tickerSelected}
    close={close} 
    marketInfoLoading={marketInfoLoading} />,
    "Correlation":<Correlation
    tickerDict={tickerDict}
    tickerList={tickerSelected}/>,
    "Beta":<Beta tickerList={tickerSelected} tickerDict={tickerDict}/>,
    "Indicators":<IndicatorUI
    tickerDict={tickerDict}
    tickerList={tickerSelected}
    close={close} 
    marketInfoLoading={marketInfoLoading} />,
    "Index Constituents":<IndexStock info={"Heatmap"}/>,
    "Yield Curve":<BondYieldCurve/>,
    "Market Breadth":<IndexStock info={info}/>,
    "Constituents":<IndexStock info={info}/>,
    "Scatter":<IndexStock info={info}/>,
    "Exchange Rate":<ForexExchangeRate/>,
  }
  return (
    <>
    {tabContent[info]}
    </>
  )
}
