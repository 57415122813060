import React, {useEffect, useState } from 'react';
import SelectBox from 'components/Inputs/SelectBox';
import { useAppInfo } from 'AppState';
import { Button } from '@mui/material';
import useHandleWatchlist from './useHandleWatchlist';
import PageSettingsModal from 'components/Template/PageSettingsModal';



const WatchlistAdd = ({tickerName,openWl,handleWlModal}) => {
 
 
  const {stateTicker,dispatchWatchlist,stateWatchlist} = useAppInfo()
  const [addAllPeers,setAddAllPeers] = useState(false)
  const {handleAddToWatchlist} = useHandleWatchlist()
  const [watchlistTickers,setWatchlistTickers] = useState([tickerName])
  const {watchlistData} = stateWatchlist
  const watchlistNames = watchlistData ? Object.keys(watchlistData) : []

  useEffect(()=>{
    if (addAllPeers){
    setWatchlistTickers(stateTicker.peerSelectedDisplay)}
  },[addAllPeers])
  

  function handleAddWatchlist(e){
    e.preventDefault();
    handleAddToWatchlist(watchlistTickers)
    handleWlModal();
  }
  return (
    <>
    {(openWl) &&
    <PageSettingsModal
      title='Add To Watchlist' 
      onClose={handleWlModal}>
    
    <form onSubmit={(e)=>{handleAddWatchlist(e)}} style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
    
    
   <div style={{display:"flex",gap:"1rem"}}>
      {(watchlistNames.length>0) &&
      <SelectBox 
      variant='outlined'
      label={"Watchlists"} 
      options={watchlistNames} value={stateWatchlist.watchlistSelected} 
      onChange={(e,v)=>dispatchWatchlist({watchlistSelected:v})} size='large' width='100%'/> 
      }
      
    </div>

     
    {stateTicker.peerSelected.length>1 && 
      <div style={{display:"flex",gap:"0.5rem"}}>
      
      <input onChange={()=>setAddAllPeers(!addAllPeers)} type="checkbox"  name="AddAll" value={addAllPeers} style={{width:"20px",height:"20px"}}/>
      <label>Add all tickers showing in PeersIn?</label>
      </div>
      }
    
    <Button type='submit' variant='contained' className='btn'>Add to Watchlist</Button>
      </form>
    </PageSettingsModal>}
    </>
  );
};

export default WatchlistAdd;
