import React, { useState } from 'react';
import ImageWithTextBottom from './ImageWithTextBottom';
import { Tabs, Tab, Card, CardContent, Typography } from '@mui/material';
import TextWithBorders from 'components/Text/TextWithBorders';
import { takestockReportContent } from './reportContent';
import EconSeriesChartCard from 'analytics/economy/EconSeriesChartCard';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';


export default function TakeStockReportHome() {
  return (
    <ReportDetails/>
    
  )
}




const ReportTabs = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate()
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    document.getElementById(`category-${newValue}`).scrollIntoView({ behavior: 'smooth' });
  };

  const handleScroll = () => {
    data.forEach((category, index) => {
      const element = document.getElementById(`category-${index}`);
      const rect = element.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
        setSelectedTab(index);
      }
    });
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const categories = [...new Set(data.map(report => report.category))];

  return (
    <div style={{padding:"2rem"}}>
      <Tabs sx={{position:"sticky",top:0,background:"white",zIndex:2}} value={selectedTab} onChange={handleTabChange}>
        {categories.map((category, index) => (
          <Tab key={index} label={category} />
        ))}
      </Tabs>
      <div style={{padding:"2rem",display:"flex",gap:"1rem",flexDirection:"column"}}>
        
      
      {categories.map((category, index) => (
        <div id={`category-${index}`} key={index}>
          <TextWithBorders text={category}/>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {data
              .filter(report => report.category === category)
              .map((report, i) => (
                <Card 
                key={i} 
                onClick={()=>navigate(`/Report/TSReport/${report.link}`)}
                style={{ 
                  width: '300px', 
                  margin: '10px', 
                  borderRadius: '12px', 
                  overflow: 'hidden', 
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  cursor:"pointer"
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'translateY(-6px)';
                  e.currentTarget.style.boxShadow = '0 10px 24px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'translateY(0)';
                  e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
                }}
              >
                <img 
                  src={report.imgUrl} 
                  alt={report.title} 
                  style={{ 
                    width: '100%', 
                    height: '200px', 
                    objectFit: 'cover', 
                    transition: 'transform 0.3s ease',
                    borderRadius: '12px 12px 0 0'
                  }} 
                />
                <CardContent style={{ padding: '16px',display:"flex",flexDirection:"column",gap:"0.5rem"}}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>{report.title}</Typography>
                  <Typography variant="body2" style={{ color: '#555', marginBottom: '10px',flex:1}}>{report.description}</Typography>
                  <Link
                    to={`/Report/TSReport/${report.link}`} 
                    style={{ 
                        textDecoration: 'none', 
                        color: '#1E88E5', 
                        fontWeight: 'bold'
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.textDecoration = 'underline';
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.textDecoration = 'none';
                    }}
                    >
                    View Report
                    </Link>
                </CardContent>
              </Card>
              ))}
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export function ReportDetails() {
    const {idReportDetails} = useParams();
  
    const report = takestockReportContent.find(r => r.link === idReportDetails);

    if (!report) {
      return <div style={{display:"flex",flexDirection:"column"}}>
      <ImageWithTextBottom imageUrl={"https://images.unsplash.com/photo-1445116572660-236099ec97a0?q=80&w=2371&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
      text={"TakeStock Reports"}
      >
      </ImageWithTextBottom>
  
      <ReportTabs data={takestockReportContent}/>
      </div>;
    }
  
    return (
      <div style={{display:"flex",flexDirection:"column"}}>
    <ImageWithTextBottom showDate source={report?.source} showBack imageUrl={report?.imgUrl}
      text={report?.title}
      >
      </ImageWithTextBottom>
      <div style={{padding:"2rem 2rem"}}>
      {report?.component}
      </div>
     
      </div>
    );
  }