import TSAssistant from 'chatGPT/TSAssistant'
import React from 'react'

export default function AskAI() {
  return (
    <>
    <TSAssistant displayType={"main"}/>

    </>
  )
}
