import React, { useEffect, useState } from "react";
import { useAppInfo } from "AppState";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { applyFilters,NumberFilter, TextFilter } from "./utilsScreener";
import SearchBar2 from "components/Inputs/SearchBar2";
import { dfin } from "content/constants";
import TabLine from "components/Tabs/TabLine";
import styles from "./FilterFields.module.css"

export default function FilterNew({ data,metrics,screenCategories={},onUpdateFilteredData,filterScreener="filterETF"}) {

  const {stateScreener,dispatchScreener,stateStock} = useAppInfo()
  const stockFilters = stateScreener?.screenerDataUser?.[stateScreener.screenerNameSel] ? stateScreener?.screenerDataUser?.[stateScreener.screenerNameSel] : stateScreener[filterScreener]
  const filters = filterScreener === "filters" ? stockFilters : stateScreener[filterScreener]
  const [filterCategorySelected,setFilterCategorySelected] = useState("All")
  const [filterMetrics,setFilterMetrics] = useState([])
  const [filterCategories,setFilterCategories] = useState(["All"])
  const [filterCategoriesData,setFilterCategoriesData] = useState({})

  useEffect(()=>{
    if (filterCategoriesData){
        setFilterMetrics(filterCategoriesData[filterCategorySelected])
    }
  },[filterCategoriesData,filterCategorySelected])


  
  useEffect(() => {
    let allMetrics = metrics ? metrics : Object.keys(data[0])
    let filterCategoriesDataUpdate = {...screenCategories,"All": allMetrics};
    let filterCategoriesUpdate = [...Object.keys(screenCategories),...filterCategories];
    setFilterCategorySelected(filterCategoriesUpdate[0])
    setFilterCategories(filterCategoriesUpdate);
    setFilterCategoriesData(filterCategoriesDataUpdate);
  }, []);


  useEffect(() => {
    setFilterCategories(prevCategories => {
      let updatedCategories = [...prevCategories];
      let hasSelected = updatedCategories.includes("Selected");

      if (Object.keys(filters).length > 0) {
        if (!hasSelected) {
          updatedCategories.push("Selected");
        }
      } else {
        if (hasSelected) {
          updatedCategories = updatedCategories.filter(cat => cat !== "Selected");
        }
      }
      return updatedCategories;
    });

    setFilterCategoriesData(prevData => {
      let updatedData = { ...prevData };
      if (Object.keys(filters).length > 0) {
        updatedData["Selected"] = Object.keys(filters);
      } else {
        delete updatedData["Selected"];
      }
      return updatedData;
    });
  }, [filters]);
  
  

  function handleSearchMetric(searchInput){
    const filteredArray = filterCategoriesData[filterCategorySelected].filter(item => item.toLowerCase().includes(searchInput.toLowerCase()));
    setFilterMetrics(filteredArray) 
  }

  const handleFilterChange = (key, filterType, value) => {  
    const updatedFilters = { ...filters };
    const updatedFilter = {
      filterType: filterType || '', 
      value,
    };
    updatedFilters[key] = updatedFilter;
    dispatchScreener({[filterScreener]:updatedFilters });

    // saving screenerData
    if (filterScreener === "filters") {
      const screenerDataUserUpdate = {...stateScreener.screenerDataUser}
      const screenerNameSel = stateScreener.screenerNameSel
      screenerDataUserUpdate[screenerNameSel] = updatedFilters
      dispatchScreener({"screenerDataUser":screenerDataUserUpdate})
    }
   
  };

  function handleApplyFilters(e){
    e.preventDefault()
    const filteredData =  applyFilters(filters,data)
    onUpdateFilteredData(filteredData)
  }
 
  
  function handleClearFilterAll(){
    dispatchScreener({[filterScreener]:{}})
    setFilterCategorySelected(filterCategories[0])
    if (filterScreener === "filters") {
      const screenerDataUserUpdate = {...stateScreener.screenerDataUser} 
      const screenerNameSel = stateScreener.screenerNameSel
      screenerDataUserUpdate[screenerNameSel] = {}
      dispatchScreener({"screenerDataUser":screenerDataUserUpdate})
    }
  }

  function handleClearFilter(key){
   
    const filterUpdate = filters
    if (filters.hasOwnProperty(key)) {
        delete filterUpdate[key];
        dispatchScreener({[filterScreener]:filterUpdate})
      }
    if (filterScreener === "filters") {
        const screenerDataUserUpdate = {...stateScreener.screenerDataUser} 
        const screenerNameSel = stateScreener.screenerNameSel
        screenerDataUserUpdate[screenerNameSel] = filterUpdate
        dispatchScreener({"screenerDataUser":screenerDataUserUpdate})
      }
    
  }

//   const filterCategories = Object.keys(stateScreener.screenFilters)
function handleFilter(v){
    setFilterCategorySelected(v)
}

  return (
    <form onSubmit={handleApplyFilters} style={{width:"100%",height:"100%",display:"flex",flexDirection:"column"}}>
        {!data.length>0 && <CircularProgress/>}

        <div style={{display:"flex",gap:"1rem",borderBottom:"1px solid #ddd",marginBottom:"0.5rem"}}>
        
        <div style={{flex:1}}>
        <TabLine tabList={filterCategories} 
        tabIndex={filterCategories.indexOf(filterCategorySelected) || 0}
        handleTabChange={(index)=>handleFilter(filterCategories[index])}
        />
        </div>
        {Object.keys(filters).length>0 &&
        <Tooltip title="Reset All Filters" arrow sx={{zIndex:"10000"}}>
          <Button sx={{marginRight:"1rem"}} 
          onClick={handleClearFilterAll} startIcon={<RefreshIcon/>}> Reset All</Button>
       </Tooltip>}
        </div>


        {data.length>0 &&
    <>
    
    <div style={{width:"100%"}}><SearchBar2 handleSearch={handleSearchMetric} placeholder="search metrics"/></div>
    
    <div style={{flex:1,width:"100%",overflow:"auto"}}>
      <div style={{width:"100%",display:"flex",flexWrap:"wrap",boxSizing:"border-box"}}>
      {filterMetrics && filterMetrics.map((filterMetric,index) => (
        <React.Fragment key={index}>        
        {(![dfin.ticker,"Ticker",dfin.coName,dfin.coLogo,dfin.tickerCode].includes(filterMetric) && filterMetric?.includes(filterMetric)) &&
        <div 
        className={filters && filters?.[filterMetric] && filters?.[filterMetric].value ? styles.filterFieldCellActive : styles.filterFieldCell}>
          <label style={{width:"30%"}}>{filterMetric}</label>
          { 
         <div style={{flex:1,maxWidth:"100%"}}>
          {typeof data[0][filterMetric] === "number" ?
          (<NumberFilter filters={filters} filterMetric={filterMetric} handleFilterChange={handleFilterChange}/>) 
          : (<TextFilter data={data} filters={filters} filterMetric={filterMetric} handleFilterChange={handleFilterChange}/>)
          } 
          </div>}
          {filters && Object.keys(filters).includes(filterMetric) && 
          <Tooltip title="Clear Filter">          
            <RefreshIcon onClick={()=>handleClearFilter(filterMetric)}/>
            </Tooltip>}  
        </div>}
        </React.Fragment>
      ))
      }
      </div>
     
    </div>

    {/* Submit */}
    <div style={{display:"flex",justifyContent:"center",borderTop:"1px solid #ddd",paddingTop:"0.5rem"}}>
    <Button variant="contained" type="submit">Apply Filters</Button>
    </div>
    </>
    }
    </form>
  )
}
