import React, { useEffect, useState } from 'react'
import useCompanyTranscripts from 'data/financialModelingPrep/useCompanyTranscripts'
import { useAppInfo } from 'AppState'
import { Typography } from '@mui/material';
import useCompanyTranscriptsDates from 'data/financialModelingPrep/useCompanyTransctiptsDates';
import SelectBox from 'components/Inputs/SelectBox';
import NoTickerInfo from 'components/NoTickerInfo';
import { MetricSettingsBar } from 'components/Template/MetricSettings';
import AccordionTS from 'components/Surface/AccordionTS';

export default function CompanyEarningTranscripts({props}) {
  const {stateTicker} = useAppInfo()
  const [tickerSel,setTickerSel] = useState()
  
  useEffect(()=>{
    if (props){
      setTickerSel(props?.ticker)
    }
    else{
      setTickerSel(stateTicker.tickerStock)
    }
  },[props,stateTicker.tickerStock])
  
  const [yearSel,setYearSel] = useState()
  const [years,setYears] = useState([])
  const {companyTranscripts} = useCompanyTranscripts(tickerSel,yearSel)
  const {companyTranscriptsDates}=useCompanyTranscriptsDates(tickerSel)
  
 
  useEffect(()=>{
    if (companyTranscriptsDates.length>0){
    const years = Array.from(new Set (companyTranscriptsDates.map(entry => entry[1])))
    const currentYear = new Date().getFullYear()
    setYears(years)

    if (years.includes(currentYear)){
        setYearSel(currentYear)
    }
    else{setYearSel(years[0])}
    
    }
  },[companyTranscriptsDates])


  return (
    <div style={{width:"100%",height:"100%",boxSizing:"border-box"}}>
    {!companyTranscriptsDates.length>0 && <NoTickerInfo/>}
    {companyTranscriptsDates.length>0 && 
    <MetricSettingsBar title='Earning Transcripts'
    childrenAlign='right'
    menu={false}
    >
      <SelectBox variant='outlined' width='200px' label={"Year"} options={years} value={yearSel} onChange={(e,v)=>setYearSel(v)}/>
      </MetricSettingsBar>}
    {companyTranscripts.map((obj,index)=>
    <React.Fragment key={index}>
      <AccordionTS summary={<>Earning Transcripts: {obj?.year}-Q{obj?.quarter}</>}>
      <Typography dangerouslySetInnerHTML={{ __html: obj?.content.replace(/\n/g, '<br><br>') }} />
      </AccordionTS>
    </React.Fragment>
    )}


    </div>
  )
}
