import { useAppInfo } from 'AppState'
import { PageMainContentTitle } from 'components/Text/Title'
import React from 'react'
import { formatNumber } from 'utils/utilGeneral';

export default function CashflowPageHeader({title,type="Income"}) {
    const {stateMyFinancials} = useAppInfo()
    const {currency,summaryStats} = stateMyFinancials
    const {totalIncome,totalExpense,totalNetCashflow} = summaryStats

    let info = totalIncome
    let color = "green"
    if (type === "Income"){
        info = totalIncome
    }
    else if (type === "Expense"){
        info = totalExpense
        color = "red"
    }

    else {
        info = totalNetCashflow
        color = totalNetCashflow?.m > 0 ? "green":"red"
    }

  return (
    <PageMainContentTitle>
        {title}: <span style={{color:color}}>
            {currency?.symbol} {formatNumber(info?.m)} (month)</span></PageMainContentTitle>
  )
}
