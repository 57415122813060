import { useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../apiConfig';
import { useAppInfo } from 'AppState';

const useFindSettings = () => {
  const {dispatchApp,stateApp,dispatchSidebar} = useAppInfo()
  const {userId,isLoggedIn} = stateApp


  const findOnboarding = async () => {
    dispatchSidebar({settingsLoaded:false});
    try {
      if (userId) {
        const response =  await axios.post(`${BASE_URL}/findUserSettings/`, {
          userId,
          userId
        });;
        // Process the response data as needed
       const data = response.data;
       if (data?.navLinkSelected){
        dispatchSidebar({navLinksSelected:data?.navLinkSelected});
       }
     
      }
    } catch (err) {
      // Handle the error
      console.log(err)
    }
    finally {
        dispatchSidebar({settingsLoaded:true});
    }

  };

  useEffect(() => {
    findOnboarding();
  }, [userId]);

  return null; // Since this hook is used for side effects, you don't need to return anything
};

export default useFindSettings;
