import React, { useState } from 'react';
import { Alert, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function FinancialTips({ financialTipPositive, financialTipNegative }) {
  const [alertExpanded, setAlertExpanded] = useState(true);
  const [positiveExpanded, setPositiveExpanded] = useState(false);
  const [negativeExpanded, setNegativeExpanded] = useState(false);

  const toggleAlertExpand = () => setAlertExpanded(!alertExpanded);
  const togglePositiveExpand = (e) => {
    e.stopPropagation();
    setPositiveExpanded(!positiveExpanded);
  };
  const toggleNegativeExpand = (e) => {
    e.stopPropagation();
    setNegativeExpanded(!negativeExpanded);
  };

  const visiblePositiveTips = positiveExpanded ? financialTipPositive : financialTipPositive.slice(0, 3);
  const visibleNegativeTips = negativeExpanded ? financialTipNegative : financialTipNegative.slice(0, 3);

  return (
    <Alert severity="success" 
    style={{ cursor: 'pointer'}}
    action={
        <Button 
        sx={{fontSize:"0.8rem",padding:0}}
        onClick={toggleAlertExpand} 
        color="inherit" size="small">
          {alertExpanded ? <RemoveIcon /> : <AddIcon />}
        </Button>
      }
    >
      <strong>Financial Tips:</strong>
      {alertExpanded && (
        <div style={{ marginTop: '8px' }}>
          <div>
            <strong style={{ color: '#4caf50' }}>Recommended Actions:</strong>
            <ul style={{ margin: '8px 0 8px 16px', padding: 0, listStyleType: 'none' }}>
              {visiblePositiveTips.map((tip, index) => (
                <li key={`positive-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <CheckCircleIcon style={{ fontSize: "0.8rem", color: '#4caf50', marginRight: '8px' }} />
                  {tip}
                </li>
              ))}
              {!positiveExpanded && financialTipPositive.length > 3 && (
                <li style={{ color: '#4caf50', cursor: 'pointer' }} onClick={togglePositiveExpand}>
                  <ExpandMoreIcon style={{ verticalAlign: 'middle' }} /> Show more
                </li>
              )}
              {positiveExpanded && (
                <li style={{ color: '#4caf50', cursor: 'pointer' }} onClick={togglePositiveExpand}>
                  <ExpandLessIcon style={{ verticalAlign: 'middle' }} /> Show less
                </li>
              )}
            </ul>
          </div>
          <div>
            <strong style={{ color: '#f44336' }}>Cautionary Advice:</strong>
            <ul style={{ margin: '8px 0 8px 16px', padding: 0, listStyleType: 'none' }}>
              {visibleNegativeTips.map((tip, index) => (
                <li key={`negative-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <CancelIcon style={{ fontSize: "0.8rem", color: '#f44336', marginRight: '8px' }} />
                  {tip}
                </li>
              ))}
              {!negativeExpanded && financialTipNegative.length > 3 && (
                <li style={{ color: '#f44336', cursor: 'pointer' }} onClick={toggleNegativeExpand}>
                  <ExpandMoreIcon style={{ verticalAlign: 'middle' }} /> Show more
                </li>
              )}
              {negativeExpanded && (
                <li style={{ color: '#f44336', cursor: 'pointer' }} onClick={toggleNegativeExpand}>
                  <ExpandLessIcon style={{ verticalAlign: 'middle' }} /> Show less
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </Alert>
  );
}

export default FinancialTips;
