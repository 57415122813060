import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./SearchButton.module.css";
import { Button, Tooltip } from "@mui/material";

export default function SearchButton({variant = "big", onClick, placeholder = "" }) {
  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      {variant === "small" && <Button sx={{minWidth:"0",color:"black"}} onClick={onClick}><SearchIcon/></Button>}
      {variant === "big" &&  <Tooltip title="Search (ctrl + k)">

        <button onClick={onClick} className={styles["searchButton"]}>
          <SearchIcon />
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            Search {placeholder}
          </span>
        </button>
      </Tooltip>}
    </div>
  );
}
