import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SocialPost from './SocialPost';
import SocialMediaFeed from './SocialMediaFeed';
import TabLine from 'components/Tabs/TabLine';
import { Button } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import WhatshotIcon from '@mui/icons-material/Whatshot';

export default function SocialMedia() {
  const socialMenu = [
    {icon: <ExploreIcon />,name:"Explore"},
    {icon: <BookmarkIcon />,name:"Saved"},
    {icon: <WhatshotIcon />,name:"Trending"},]
  const topics = ["Investing", "Stocks", "Crypto", "Personal Finance", "Top Voices", "High Networth"];
  const [selectedCategory, setSelectedCategory] = useState(0);

  const handleCategoryChange = (newValue) => {
    setSelectedCategory(newValue);
  };

  return (
    <div style={{ display: 'flex',flex:1,padding:"0 0.5rem",boxSizing:"border-box"
    ,width:"100%", gap: '1rem',height:"100%",overflow:"auto",position:"relative"}}>
      <div style={{padding:"1rem",display:"flex"
          ,flexDirection:"column",gap:"1rem",borderRadius:"5px",minWidth:"200px"
          ,position:"sticky",top:"0px",zIndex:"1000"}}>
        {socialMenu.map((category,index)=>
          <Button 
          key={index}  
          sx={{color:"black",minWidth:"0",padding:"0.25rem",justifyContent:"flex-start"}}
          startIcon={category.icon}>{category.name}</Button>
        )}

        <div
        style={{display:"flex",flexDirection:"column",gap:"0.25rem"}}
        >
        {topics.map((category,index)=>
          <Button sx={{color:"gray",padding:"0.25rem",textAlign:"left",alignSelf:"flex-start",justifyContent:"flex-start"}} 
          variant="text" key={index}>
            {`# ${category}`}
            </Button>

        )}</div>
      </div>
   
    
    
      <div style={{ padding: '0.5rem',flex:1,display:"flex",flexDirection:"column",gap:"1rem"
        ,alignItems:"center",width:"auto"}}>

        <div style={{flex:1}}>
        <SocialMediaFeed />
        </div>
        <div style={{position:"sticky",bottom:"0px",padding:"1rem",paddingBottom:"2rem",display:"flex",alignItems:"center"
        ,justifyContent:"center",background:"white",zIndex:"1000",width:"100%",boxSizing:"border-box"}}>
        <SocialPost />
        </div>
      
      </div>
     

      <div style={{padding:"1rem",display:"flex"
          ,flexDirection:"column",gap:"1rem",borderRadius:"5px",minWidth:"200px",height:"max-content",background:"white",position:"sticky",top:"0px",zIndex:"1000"}}>
      </div>
    </div>
  );
}
