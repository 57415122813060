import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import React, { useEffect } from 'react'

export default function useSetTickerInfo() {
  const {stateTicker,dispatchTicker} = useAppInfo()

  const {
   close: closeStock,
   trendTable: trendTableStock,
   marketInfoLoading: marketInfoLoadingStock,
 } = useMultiTickerInfo(stateTicker.peerSelected);
 
 useEffect(() => {
   dispatchTicker({
     close: closeStock,
     trendTable: trendTableStock,
     marketInfoLoading: marketInfoLoadingStock
   });
 }, [closeStock, trendTableStock, marketInfoLoadingStock]);

 useEffect(() => {
  if (stateTicker.tickerSelInfo && stateTicker.tickerInfo.length>0) {
    const tickerInfo = stateTicker.tickerInfo.filter(obj=>obj[dfin.tickerCode]===stateTicker.tickerCode || obj[dfin.ticker]===stateTicker.tickerSelected)[0]
    dispatchTicker({"tickerSelInfo":tickerInfo})
  }
}, [stateTicker.tickerSelected,stateTicker.tickerCode,stateTicker.tickerInfo]);

}
