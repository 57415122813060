import React, { useEffect, useMemo, useState } from 'react'
import useFetchResponse from './useFetchResponse';
import { useAppInfo } from 'AppState';
import { assistantDetails, assistants, openaiAPI } from 'appState/initialStateAssistant';

export default function useFetchAssistant() {
    const { dispatchAssistant, stateAssistant } = useAppInfo();
    const {threadId, assistantId, openai, chatStatus,runId} = stateAssistant;
    const {fetchResponse} = useFetchResponse()


    async function fetchAssistant({message,chats}) {
        dispatchAssistant({ loading: true, chatStatus: "running",loadingMessage:"Connecting..."});

        try {
            let thread = threadId;
            // Create a new thread if not present
            if (!thread) {
                const threadNew = await openai.beta.threads.create();
                thread = threadNew.id;
                dispatchAssistant({ threadId: thread });
            }

            // Send the message to the assistant
            await openai.beta.threads.messages.create(thread, {
                role: 'user',
                content: message,
            });

            // Start streaming the assistant's response
            const stream = await openai.beta.threads.runs.create(thread, {
                assistant_id: assistantId,
                stream:true
            });
            let assistantMessage = "";

            for await (const event of stream) {           
                if (event.event === 'thread.run.created') {
                  dispatchAssistant({runId:event.data.id})
                }

                if (event?.data?.delta?.content) {
                    const content = event.data?.delta?.content[0]?.text?.value || "";
                    assistantMessage += content;}
            }

            const assistantToUse = JSON.parse(assistantMessage)?.assistant_to_use
            let assistantName;
            if (assistants?.[assistantToUse]){
              assistantName = assistantDetails.filter(obj=>obj.assistant === assistantToUse)[0]?.name
              dispatchAssistant({"loadingMessage":`Connecting to AI-${assistantName}`})
            }
           
            let assistantIdUpdate = assistants?.[assistantToUse] || assistants?.GeneralAssistant
            await fetchResponse({message,assistantId:assistantIdUpdate,assistantName,chats})
           
        }
        catch (error){
            console.log(error)
            const updatedChats = [...chats];
            updatedChats[updatedChats.length - 1] = {
                role: "assistant",
                content: 'Sorry, something went wrong.',
            };
            await cancelRun({threadId, runId})
            dispatchAssistant({ chats: updatedChats,loading:false,chatStatus:"stop",runId:null,threadId:null});
        }
        finally {
          dispatchAssistant({loading:false,loadingMessage:""})
        }
    }


    return {fetchAssistant}
}

async function cancelRun({threadId, runId}) {
    if (threadId && runId) {
      try {
        await openaiAPI.beta.threads.runs.cancel(threadId, runId);
        // console.log("Run canceled successfully");
      } catch (error) {
        console.log(error);
        // Do nothing if an error occurs
      }
    }
  }