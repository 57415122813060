import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange'
import { useAppInfo } from 'AppState'
import { fetchCompanyTranscripts } from 'data/financialModelingPrep/useCompanyTranscripts'
import { fetchCompanyTranscriptDates } from 'data/financialModelingPrep/useCompanyTransctiptsDates'
import React from 'react'
import { generateUniqueId } from 'utils/utilGeneral'

export default function useAssistantStockAanlyst() {
  const {dispatchAssistant} = useAppInfo()  
  const handleTickerChange = useHandleTickerChange()

 
    async function GetStockEarningTranscript({ ticker, yearUser }) {
      handleTickerChange({ticker:ticker,modal:false,goToPage:false})
      try {
        // Fetch the earning transcript dates for the company
        const earningDates = await fetchCompanyTranscriptDates(ticker);
    
        // Extract the unique years from the earningDates
        const years = Array.from(new Set(earningDates.map(entry => entry[1])));
    
        // Get the current year
        const currentYear = new Date().getFullYear();
    
        // Determine the year to select
        let yearSel = yearUser;
        if (!yearUser) {
          if (years.includes(currentYear)) {
            yearSel = currentYear;
          } else {
            yearSel = years[0]; // Default to the first year if the current year isn't available
          }
        }
    
        // Fetch the earnings transcripts based on the selected year
        const earningTranscripts = await fetchCompanyTranscripts({ ticker, year: yearSel });
        const react_id = generateUniqueId()
        dispatchAssistant({componentProps:{id:react_id,ticker}})
        // Return the transcripts
        return {year:years[0],react_id,details:earningTranscripts,"learn_more":"/Analytics/Ticker","react_component":"EarningTranscript"};
      } catch (error) {
       return {details:"Couldn't find the information!","learn_more":"/Analytics/Ticker","react_component":"EarningTranscript"};
      }
    }
    

    function GetStockFinancials({ticker,peers}) {
      handleTickerChange({ticker:ticker,modal:false,goToPage:false})
      const info = peers ? {"react_component":"FinancialsStockPeers"} : {"react_component":"FinancialsStock"}
      return info
      }

      
    function GetStockValuation({ticker,valuationType}) {
        handleTickerChange({ticker:ticker,modal:false,goToPage:false})
        let info = {"react_component":"ValuationDCF"}
        if (valuationType === "Price Multiple"){
          info = {"react_component":"PriceMultiple"}
        }
        else if (valuationType === "EV Multiple"){
          info = {"react_component":"EVMultiple"}
        }

        return info
      }

    function GetStatsVsIndustry({ticker}) {
      handleTickerChange({ticker:ticker,modal:false,goToPage:false})
      const info = {"react_component":"StockVsIndustry"}
        return info
      }

    return {GetStatsVsIndustry,GetStockFinancials,GetStockValuation,GetStockEarningTranscript}
}
