import { useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindFinancials = () => {
  const { dispatchMyFinancials, stateApp,stateMyFinancials } = useAppInfo()
  const userId = stateApp.userId
  const {financialView} = stateMyFinancials
  const url = financialView === "personal" ? "findFinancials" : "findDemoFinancials"
  const findFinancials = async () => {
    try {
      dispatchMyFinancials({'financialsLoaded':false });
      if (userId) {
        const response = await axios.post(`${BASE_URL}/${url}/`, { userId });
        const data = response.data;
        Object.entries(data).map(([name,value])=>{
            {!["_id","userId","username"].includes(name) 
                && dispatchMyFinancials({[name]:value });
            }
        })

        dispatchMyFinancials({'financials':data,'financialsLoaded':true,'noDataFound':false});
      }
    } catch (err) {
      if (err.response && err.response.data.error === 'No data found') {
        dispatchMyFinancials({'noDataFound':true});
      } else {
        console.log('Error', err);
        // dispatchMyFinancials({'noDataFound':true});
      }
      dispatchMyFinancials({'financialsLoaded':true });
    }
  };

  useEffect(() => {
    if (userId && financialView === "personal") {
      findFinancials();
    }
  }, [userId, financialView]);

  return null;
};

export default useFindFinancials;