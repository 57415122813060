import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useAddScreener = () => {
  const {stateScreener,dispatchScreener,stateApp} = useAppInfo()
  const userId = stateApp.userId
const username = stateApp.username
  const [error, setError] = useState(null);
  
  const addScreener = async (screenerName) => {
    try {
      const response = await axios.post(`${BASE_URL}/addScreeners/`, {
        userId,
        username,
        screenerData:stateScreener.screenerDataUser[screenerName],
        screenerName:screenerName
      });
      
     
      setError("Success")
    } catch (err) {
      setError('Error adding Screener data');
    }
  };

  return { addScreener, error };
};

export default useAddScreener;