import React, { useEffect, useState } from 'react'
import TableGen from 'components/Table/TableGen';
import { dfin } from 'content/constants';
import useInsiderTransaction from 'data/company/useInsiderTransaction';
import { useAppInfo } from 'AppState';
import TabLine from 'components/Tabs/TabLine';
import InsiderTransactionStats from './InsiderTransactionStats';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import {PageMainContentTitle} from 'components/Text/Title';


export default function CompanyInsider() {
  const {stateTicker,stateStock} = useAppInfo()
  const companySelected = stateStock.companySelected
  const companyName = stateStock.coInfo?.[dfin.coName]
  const peerInfo = stateStock.peerInfo
  const peerSelected = stateTicker.peerSelected
  const [data,setData] = useState([])
  const {insiderTransaction,loadingInsiderTransaction} = useInsiderTransaction(peerSelected)

  useEffect(()=>{ 
    if (insiderTransaction.length>0 && !loadingInsiderTransaction){
    const insTran = insiderTransaction.filter(obj => obj[dfin.ticker] === companySelected)
    const columnHeadings = insTran.length > 0 &&  Object.keys(insTran[0]).filter((key) => key !== dfin.ticker);
    const data = insTran.length > 0 && getSpecificArrayObjKeys(insTran,columnHeadings)
    setData(data)}
  },[companySelected,peerInfo,insiderTransaction])
  
  const [tabIndex,setTabIndex] = useState(0)
  const tabList = ["Overview","Details"]
 
  return (

    <div className='containerColumnFlexNoGap'>
      <PageMainContentTitle>{companyName}'s Insider Transactions</PageMainContentTitle> 
      <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>
  
      {tabIndex === 0 && <InsiderTransactionStats/>}
      {tabIndex === 1 && 
      <TableGen data={data}/>}
      
    </div>
  );
};