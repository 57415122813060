import { Button, IconButton, Typography } from '@mui/material'
import { useAppInfo } from 'AppState'
import ChatContent from 'chatGPT/ChatContent'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useHandleUserEvents from 'chatGPT/useHandleUserEvents'
import AddIcon from '@mui/icons-material/Add';
import MessageBar from 'chatGPT/MessageBar'
import ChatHistory from 'chatGPT/ChatHistory'
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating'
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreQuestions from './ExploreQuestions'
import AssistantIcon from '@mui/icons-material/Assistant';
import ExploreAssitants from './ExploreAssistants'
import { assistantDetails } from 'appState/initialStateAssistant'
import TextWithBorders from 'components/Text/TextWithBorders'


export default function ChatAIMenu() {
    const {stateApp,stateAssistant,dispatchAssistant} = useAppInfo()
    const {handleShowContent,handleNewChat,handleExploreQuestions,handleAssistantSelectionWindow} = useHandleUserEvents()
    const {chatHistory,assistantSelected} = stateAssistant
    const [showChatHistory,setShowChatHistory] = useState(false)
    const assistantSelectedName = useMemo(()=>assistantDetails.filter(obj=>obj.id === assistantSelected)[0]?.name,[assistantSelected])
  
  
  return (
    <>
    <div style={{position:"sticky",top:0
        ,zIndex:10000,background:"white",display:"flex",gap:"1rem",flexWrap:"wrap",paddingLeft:"1rem"}}>
    
    {chatHistory.length > 0 && 
    <Button style={{justifyContent:"flex-start"}} 
    onClick={()=>setShowChatHistory(!showChatHistory)} 
    variant='highlight'>Chat History</Button>}

    <Button style={{justifyContent:"flex-start"}} onClick={handleNewChat} 
    variant='highlight' startIcon={<AddIcon fontSize='small'/>}>New Chat</Button>
   
   
    {/* <Button style={{justifyContent:"flex-start",textAlign:"left",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}} 
    onClick={()=>handleAssistantSelectionWindow(true)} 
    variant='highlight' startIcon={<AssistantIcon fontSize='small'/>}>
    {`Assistant: ${assistantSelectedName}`}
    </Button> */}

    
  <Button style={{justifyContent:"flex-start"}} 
    onClick={()=>handleExploreQuestions(true)} variant='highlight' 
    startIcon={<ExploreIcon fontSize='small'/>}>Explore Questions</Button>
    
    </div>
        
   {showChatHistory && 
   <div
   onMouseLeave={()=>setShowChatHistory(false)}
   style={{position:"absolute",top:"100%",left:0,maxWidth:"300px",minWidth:"300px",zIndex:"1000",background:"#fafafa"
   ,height:"calc(100vh - 55px)",overflow:"auto",boxShadow: "10px 0px 15px rgba(0, 0, 0, 0.1)"}}>
   <TextWithBorders text={"Chat History"}/>
   <ChatHistory/>
   </div>}
  
    </>
  )
}


export function ChatAIMenuLeftSidebar(){
    const {stateApp,stateAssistant,dispatchAssistant} = useAppInfo()
    const {handleShowContent,handleNewChat,handleExploreQuestions,handleAssistantSelectionWindow} = useHandleUserEvents()

  return (

    <LeftSidebarFloating header={"Chats"}>
        
    <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
    <Button style={{justifyContent:"flex-start"}} onClick={handleNewChat} variant='highlight' startIcon={<AddIcon fontSize='small'/>}>New Chat</Button>
    
    <Button style={{justifyContent:"flex-start"}} onClick={()=>handleExploreQuestions(true)} variant='highlight' startIcon={<ExploreIcon fontSize='small'/>}>Explore Questions</Button>

    {/* <Button style={{justifyContent:"flex-start",textAlign:"left",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}} 
    onClick={()=>handleAssistantSelectionWindow(true)} 
    variant='highlight' startIcon={<AssistantIcon fontSize='small'/>}>
    {`Assistant: ${assistantSelectedName}`}
    </Button> */}

   <ChatHistory/>
    </div>
  
   
    </LeftSidebarFloating>
  )
}