import { Button, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WatchlistAdd from './WatchlistAdd';
import useHandleWatchlist from './useHandleWatchlist';
import { useAppInfo } from 'AppState';

export function WatchListAddButton({tickerName}) {
    const [addWatchlist, setAddWatchlist] = useState(false);

  return (
    <>
    <Tooltip title="Add to Watchlist">
    <Button style={{minWidth:"0"}} onClick={()=>setAddWatchlist(true)}>
      <AddIcon />
    </Button>
  </Tooltip>
  {addWatchlist && 
  <WatchlistAdd 
  handleWlModal={()=>setAddWatchlist(false)}
  openWl={addWatchlist} 
  tickerName={tickerName}/>}
  </>
  )
}

export function WatchListAddTableButton({ticker}) {
  const {stateWatchlist} = useAppInfo()
  const {handleAddToWatchlist} = useHandleWatchlist()
  const watchlistData = stateWatchlist.watchlistData;
  const wlName = stateWatchlist.watchlistSelected;
  const selectedItems = watchlistData ? watchlistData[wlName] : []

return (
  <>
  {selectedItems && selectedItems.includes(ticker) ? (
    <CheckCircleOutlineIcon
      className="check-icon"
      style={{ color: "green" }}
      onClick={(e) =>{e.stopPropagation(); handleAddToWatchlist(ticker)}}
    />
  ) : (
    <AddIcon
      className="add-icon"
      onClick={(e) =>{e.stopPropagation(); handleAddToWatchlist(ticker)}}
    />
  )}
  </>
)
}

