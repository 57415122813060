import { Button } from '@mui/material'
import AccordionTSCustom from 'components/Surface/AccordionTSCustom'
import NetCashAllocation from 'myFinancials/Cashflow/NetCashAllocation'
import MyFinancialSteps from 'myFinancials/Template/MyFinancialSteps'
import React from 'react'
import ReinvestingBenefits from './ReinvestingBenefits'
import RiskTolerance from 'myFinancials/RiskManagement/RiskTolerance'
import PortfolioInfoList from 'myFinancials/Portfolio/PortfolioInfoList'
import AssetAllocation from './AssetAllocation'
import { Link, useNavigate } from 'react-router-dom'

export default function AssetStepsToGrow() {
  const navigate = useNavigate()
  return (
   <div>
    <h3 style={{padding:"1rem",color:"green"}}>Steps to grow your Assets</h3>
    <div style={{display:"flex",gap:"0.5rem",flexDirection:"column",flex:1}}>
    <AccordionTSCustom title={"1. Allocate Net Cash periodically to build long-term assets"}>
      <NetCashAllocation/>
    </AccordionTSCustom>
    <AccordionTSCustom title={"2. Reinvest returns on savings & investments"}>
      <ReinvestingBenefits/>
    </AccordionTSCustom>
    <AccordionTSCustom  title={"3. Allocate Assets based on risk tolerance, investment preference and goals"}>
      <RiskTolerance/>
    </AccordionTSCustom>
    <AccordionTSCustom  title={"4. Build & Manage Portfolio"}>
      <PortfolioInfoList  menu={"Overview"} />
      <div style={{padding:"1rem"}}>
      <Button variant='contained' onClick={()=>navigate("/MyFinancials/Portfolio")}>Manage Portfolio</Button>
      </div>
    </AccordionTSCustom>
    <AccordionTSCustom  title={"5. Rebalance periodically to Target Allocation"}>
    <AssetAllocation/>
    </AccordionTSCustom>
    
    <AccordionTSCustom title={"6. Find Opportunities to grow your Portfolio"}>
      <div style={{padding:"1rem"}}>
      <Button variant='contained' onClick={()=>navigate("/Opportunities/PortfolioFit")}>
      Get Portfolio Fit Opportunities 
      </Button>
      </div>
    </AccordionTSCustom>
    </div>
  </div>
  )
}