import { Alert, Button } from '@mui/material'
import { defaultFieldBlank, goalTypes } from 'appState/initialStateMyFinancials'
import React, { useEffect, useMemo, useState } from 'react'
import { InputAmount, InputDate, InputForecastPeriod, InputGrowthRate, InputName, InputPeriod, InputPriority, InputType } from 'myFinancials/FormFields/FieldFormCells';
import { Delete } from '@mui/icons-material'
import GoalIcons from './GoalIcons'
import RatingBar from 'components/RatingBar'
import PopoverButton from 'components/Modal/PopoverButton'
import { formatNumber, getDateString, getSpecificArrayObjKeys } from "utils/utilGeneral"
import CashflowChart from '../Cashflow/CashflowChart'
import { useAppInfo } from 'AppState'
import { IconButton, Tooltip } from '@mui/material'
import useFieldForm, { useHandleDeleteField } from 'myFinancials/FormFields/useFieldForm'
import GoalIndividualDetailEdit from './GoalIndividualDetailEdit'
import useCheckNewGoalSuccessFailure from './useCheckNewGoalSuccessFailure';
import useAssistantFinancialAdvisor from 'chatGPT/useAssistantFinancialAdvisor';
import InfoBox from 'components/Layout/InfoBox';
import { useNavigate } from 'react-router-dom';


export default function GoalIndividualDetailNew({props}) {
  const {stateMyFinancials} = useAppInfo()
  const {currency,cashflowDistribution} = stateMyFinancials  
  const {netGoalCashflowDist,netGoalAferWithdrawal} = cashflowDistribution
  const navigate = useNavigate()
  
  const [data,setData] = useState([]) 
  
  useEffect(()=>{
    if (props){
      setData([props])
    }
  },[props])
  
 
  const {
    fields,
    error,
    handleChange,
    handleDeleteField,
    handleAddField,
    handleRemoveField,
    handleSubmit,
  } = useFieldForm({storeName:"goalsPeriodic",data:data,defaultField:defaultFieldBlank?.Goals})
  
  const {processGoalDetails} = useCheckNewGoalSuccessFailure()
  
 
  const [details,setDetails] = useState([])

  useEffect(()=>{
    if (fields.length>0 && netGoalAferWithdrawal.length>0 && netGoalAferWithdrawal.length>0){
    const {perGoalDetails} = processGoalDetails(fields,netGoalCashflowDist,netGoalAferWithdrawal)
    setDetails(perGoalDetails)}
  },[fields,netGoalCashflowDist,netGoalAferWithdrawal])



  const postWithdrawal =  false
  // goalFunding === "netCashAfterWithdrawal" ? true : false


  const [goalSubmitted,setGoalSubmitted] = useState(false)

  return (
  <div style={{display:"flex",gap:"1rem",flexWrap:"wrap"}}>
  
{ <>
<div style={{display:"flex",gap:"1rem",minWidth:"350px",maxWidth:"700px",flexWrap:"wrap",height:"100%"}}>
  <InfoBox sx={{width:"100%"}}>
  <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
  {fields.length>0 && fields.map((fieldObj,index)=>
  <form  onSubmit={(e)=>{handleSubmit(e);setGoalSubmitted(true)}} key={index} style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0.5rem"}}>
  <InputType typeOptions={goalTypes} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputPriority label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputName label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputGrowthRate label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputAmount label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputDate label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputPeriod label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputForecastPeriod label fieldObj={fieldObj} handleChange={handleChange}/>
  {error && <Alert severity='warning'>{error}</Alert>}
  <Button variant='contained' type='submit'>Save Goal</Button>
    </form>)}
  
  </div> 
  </InfoBox>
 
 
</div>
</>}

<div style={{position:"relative",display:"flex",flexDirection:"column",gap:"1rem",flex:1,minWidth:"400px"}}>
{goalSubmitted && 
<>
<Alert severity='success'>Goal Saved</Alert>
<Button onClick={()=>navigate("/MyFinancials/Goals")}>View All Goals</Button>
</>
}
    <InfoBox>
    <table style={{tableLayout:"fixed"}}>
      <thead>
        <tr>
          
          <th style={{width:"auto"}}>Goal</th>   
          <th>Priority</th> 
          {/* <th style={{width:"auto"}}>Success Rate</th>   */}
          <th>Cash Outflow</th> 
          <th>Period</th>
        </tr>
      </thead>
      <tbody>
      {details.length>0 && details.map((perGoal,index)=>
        <tr 
        key={index} 
        style={{padding:"0.25rem"}}>
          
          <td>
          <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
          <div style={{width:"50px"}}>
          <GoalIcons width='60px' iconType={perGoal?.type}/>
          </div>
          <div style={{minWidth:"80px"}}>  
          <h4 style={{fontWeight:"bold"}}>{perGoal?.name}</h4>
          <h5 style={{fontWeight:"bold",color:"gray"}}>{perGoal?.type}</h5>
          </div>
          <div style={{paddingLeft:"1rem"}}>
          
          <h5 style={{fontWeight:"bold"}}>Success Rate:{formatNumber(postWithdrawal? perGoal?.successRateAfterWithdrawal:perGoal?.successRateBeforeWithdrawal)}%</h5>
          <RatingBar
          totalScore={100}
          width='60px'
          score={postWithdrawal? perGoal?.successRateAfterWithdrawal:perGoal?.successRateBeforeWithdrawal} scoreMax={100}/>
          </div>
          </div>
         
          </td>
          
        <td>
          {perGoal?.priority}
          </td>
         
          <td>
          {currency?.symbol}{perGoal?.amount} 
            </td>
          <td>
          <h5>
            {perGoal?.period === "One Time" ? 
            `${perGoal?.period} - ${getDateString(perGoal?.date)}`:
            `${perGoal?.period} for ${perGoal?.forecastPeriod} years starting ${getDateString(perGoal?.date)}`
            }
            </h5>
          </td>
          
        </tr>
    
    )}
      </tbody>
    </table>
   
    {details.length>0 && details.map((perGoal,index)=>
    <div key={index} style={{height:"500px",width:"100%"}}>
    <CashflowChart data={perGoal?.cashflow || []} chartType='area' /></div>      
      )}
</InfoBox>
    </div>
</div>
  )
}