import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

export default function ImageCarousel({ images=imageUrls }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [touchStart, setTouchStart] = useState(0);

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchStart) return;
    const touchEnd = e.touches[0].clientX;

    if (touchStart - touchEnd > 5) {
      goToNextImage();
    } else if (touchStart - touchEnd < -5) {
      goToPrevImage();
    }
    setTouchStart(0);
  };

  return (
    <Box
      sx={styles.carouselContainer}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <Box
        sx={{
          ...styles.imageContainer,
          backgroundImage: `url(${images[currentIndex]})`,
          backgroundSize: "contain", // Makes the image contained within the element
          backgroundRepeat: "no-repeat", // Ensures the image doesn't repeat
          backgroundPosition: "center", // Centers the image within the container
        }}
      >
        {(isHovering && currentIndex > 0) &&(
          <IconButton sx={styles.leftArrow} onClick={goToPrevImage}>
            <KeyboardArrowLeft />
          </IconButton>
        )}
        {(isHovering) && (
          <IconButton sx={styles.rightArrow} onClick={goToNextImage}>
            <KeyboardArrowRight />
          </IconButton>
        )}
      </Box>
      <Box sx={styles.dotsContainer}>
        {images.map((_, index) => (
          <Box
            key={index}
            onClick={() => handleDotClick(index)}
            sx={{
              ...styles.dot,
              backgroundColor: currentIndex === index ? '#f8f8f8' : '#ddd',
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

const styles = {
  carouselContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    objectFit: 'contain',
    position: 'relative',
    transition: 'background-image 0.5s ease-in-out',
  },
  leftArrow: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  rightArrow: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '10px',
    width: '100%',
  },
  dot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    margin: '0 3px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};


// Usage Example
const imageUrls = ["https://images.unsplash.com/photo-1505409859467-3a796fd5798e?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8b2ZmaWNlJTIwc3BhY2V8ZW58MHx8MHx8fDA%3D",
  "https://images.unsplash.com/photo-1445116572660-236099ec97a0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhZmV8ZW58MHx8MHx8fDA%3D",
"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG9mZmljZSUyMHRlYW18ZW58MHx8MHx8fDA%3D"];

// In App component or wherever you want to use it
// <ImageCarousel images={imageUrls} />
