import {useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';


const useAddFinancials = () => {
  const {stateApp,stateMyFinancials} = useAppInfo()
  const userId = stateApp.userId
  const username = stateApp.username
  const [error, setError] = useState(null);
  const {financialView} = stateMyFinancials
  const url = financialView === "personal" ? "addFinancials" : "addDemoFinancials"
  const addFinancials = async (data,dataName,urlCustom) => {
    let urlDb = urlCustom ? urlCustom : url
    try {
    const requests = await axios.post(`${BASE_URL}/${urlDb}/`, {
          userId,
          username,
          data,
          dataName,
        })

      setError("Success")
    } catch (err) {
      setError('Error adding Report data');
    }
  };

  return { addFinancials, error };
};

export default useAddFinancials;