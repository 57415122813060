import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


export async function getTechnicalRating({tickerList, period = "daily"}) {
  try {
    const response = await axios(`${BASE_URL}/technicalRating/`, {
      params: {
        ticker: JSON.stringify(tickerList),
        period: period,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
}

export default function useTechnicalRating(tickerList, period = "daily") {
  const [technicalRating, setTechnicalRating] = useState([]);
  const [tRLoading, setMarketInfoLoading] = useState(false);

  useEffect(() => {
    
    async function fetchData() {
      setMarketInfoLoading(true);
      try {
        const data = await getTechnicalRating({tickerList, period});
        setTechnicalRating(data);
      } catch (error) {
        console.error(error);
        setMarketInfoLoading(false);
        // Handle the error as needed
      } finally {
        setMarketInfoLoading(false);
      }
    }
    if (Array.isArray(tickerList) && tickerList.length>0){
      // console.log("Ticker",tickerList)
    fetchData();}
  }, [tickerList, period]);

  return { technicalRating, tRLoading };
}
