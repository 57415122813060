import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import React from 'react';
import { Button } from '@mui/material';

export default function PaginationButtons({totalItems,currentPage,handlePage,itemsPerPage=50,itemsDisplaying=0}) {
    const nPages = Math.ceil(totalItems/itemsPerPage)
    
    const startPage = Math.max(1, currentPage - 9);
    const endPage = Math.min(nPages, startPage + 9);
   
    let buttons = [];
    for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <Button
            key={i}
            onClick={() => handlePage(i)}
            style={i === currentPage ? 
              {background:"var(--selectBlue)",color:"white",border:"none",minWidth:0,padding:"0.3rem",margin:0,lineHeight:"1"} 
              : {border:"none",padding:"0.3rem",margin:0,minWidth:0,lineHeight:"1"}}
          >
            {i}
          </Button>
        );
      }

      const handleSelectChange = (e) => {
        const selectedPage = parseInt(e.target.value, 10); // Parse the selected value to an integer
        handlePage(selectedPage);
      };
    
    return (
      <div style={{display:"flex",width:"100%",padding:"0.5rem"
        ,boxSizing:"border-box",alignItems:"center"
        }}>
      <h4>Displaying: {itemsDisplaying} of {totalItems}</h4>
      
       
     
      <div style={{padding:"0.2rem",
        display:"flex",gap:"0.5rem",alignItems:"center",marginLeft:"auto"}}>      

    {nPages>1 && 
    <>
    {buttons}
    <Button 
  disabled={currentPage <= 1 || nPages <= 1}
  style={{borderRadius:"4px",margin:0,padding:"0.3rem",minWidth:0}} key="prev" onClick={() => handlePage(currentPage - 1)}>
  <KeyboardDoubleArrowLeftIcon/>
</Button>
<Button 
  disabled={currentPage >= nPages || nPages <= 1}
  style={{borderRadius:"4px",margin:0,padding:"0.3rem",minWidth:0}} key="next" onClick={() => handlePage(currentPage + 1)}>
  <KeyboardDoubleArrowRightIcon/>
</Button></>}
    {nPages>10 && <select onChange={handleSelectChange} value={currentPage}>
        {Array.from({ length: nPages }, (_, index) => (
          <option key={index + 1} value={index + 1}>
            {index + 1}
          </option>
        ))}
      </select>}
    </div>

    </div>
    )

  };