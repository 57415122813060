import { getUniqueID } from '@syncfusion/ej2-base';
import { getDownloadURL, ref, uploadBytesResumable,deleteObject } from 'firebase/storage';
import { storage } from 'firebaseConfig/firebaseConfig';

export const processFile = ({
    file,
    acceptedFileTypes = ["image", "video", "csv","application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
    mediaBaseUrl = "uploads",
    mediaFolder = "user",
    userId,
    handleFileData,
    handleProgress,
    handleUrl,
  }) => {
    const fileTypeCategory = acceptedFileTypes.find((type) => file.type.startsWith(type));
    if (!fileTypeCategory) {
      alert(`Invalid file type. Please upload a file of type: ${acceptedFileTypes.join(", ")}`);
      return;
    }
  
    const fileObj = {name:file.name,type:file.type}
    // Prerender with handleFileData for preview
    if (typeof handleFileData === "function") {
      // console.log(file)
      // console.log({...file, url: URL.createObjectURL(file)})
      handleFileData({
        ...fileObj,
        url: URL.createObjectURL(file),
      });
    }
    const id = getUniqueID()
    // Proceed to upload the file
    const filePath = `${mediaBaseUrl}/${mediaFolder}/${userId}/${fileTypeCategory}/${`${file.name}_${id}`}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        if (typeof handleProgress === "function") {
          handleProgress({ ...fileObj, percent });
        }
      },
      (err) => console.error("Upload Error:", err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          if (typeof handleUrl === "function") {
            handleUrl({ ...fileObj, url });
          }
        });
      }
    );
  };


export const deleteMediaFile = ({ fileUrl, previewUrl,onSuccess,onError}) => {
    // Delete the preview URL
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
  
    // Delete the uploaded file from Firebase storage

    if (fileUrl) {
      const storageRef = ref(storage, fileUrl);
      deleteObject(storageRef)
        .then(() => {onSuccess && onSuccess("File deleted successfully"); console.log("File deleted successfully")})
        .catch((error) => {onSuccess && onError("Error deleting file"); console.error("Error deleting file:", error)});
    }
  };