import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindFinancialVault = () => {
  const { dispatchMyFinancials, stateApp,stateMyFinancials } = useAppInfo()
  const userId = stateApp.userId
  const [loading,setLoaded] = useState(false)
  const [data,setData] = useState([])
  const findFinancials = async () => {
    setLoaded(false)
    try {
      if (userId) {
        const response = await axios.post(`${BASE_URL}/findFinancialVault/`, { userId });
        const data = response.data;
        const dataSelected = data?.vault || []
        
       setData(dataSelected);
       setLoaded(true)
      }
    } catch (err) {
      if (err.response && err.response.data.error === 'No data found') {
       setLoaded(true)
      } else {
        console.log('Error', err);
        // dispatchMyFinancials({'noDataFound':true});
      }
      setLoaded(true)
    }
  };

  useEffect(() => {
    if (userId) {
      findFinancials();
    }
  }, [userId]);

  return {data,loading};
};

export default useFindFinancialVault;