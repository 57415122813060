import AccountBoxIcon from '@mui/icons-material/AccountBox';
import React, { useEffect, useRef, useState } from 'react';
import { Typography, Button, List, ListItem, ListItemText, Divider, Tabs, Tab } from '@mui/material';
import useHandleUserEvents from './useHandleUserEvents';
import TextWithBorders from 'components/Text/TextWithBorders';
import TabLine from 'components/Tabs/TabLine';
import MessageTape from './MessageTape';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useAppInfo } from 'AppState';
import { assistants, assistantDetails } from 'appState/initialStateAssistant';

export default function ExploreAssitants() {
    const [expanded, setExpanded] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const {stateAssistant,dispatchAssistant} = useAppInfo()
    const {assistantSelected} = stateAssistant

    // Refs for each category to scroll to
    const categoryRefs = useRef([]);
    const observerRef = useRef();
  
    // Toggle the expansion of a specific subcategory
    const handleToggle = (category, subCategory) => {
      const key = `${category}-${subCategory}`;
      setExpanded((prev) => ({
        ...prev,
        [key]: !prev[key]
      }));
    };
  
    // Handle tab change
    const handleTabChange = (event,newValue) => {
      setActiveTab(newValue);
      categoryRefs.current[newValue].scrollIntoView({top:-150, behavior: 'smooth' });
    };
  
    // Use Intersection Observer to detect which category is in view
    useEffect(() => {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = categoryRefs.current.indexOf(entry.target);
              setActiveTab(index);
            }
          });
        },
        { rootMargin: '-50px 0px 50px 0px', threshold: 0.7}
      );
  
      // Observe each category div
      categoryRefs.current.forEach((ref) => {
        if (ref) observerRef.current.observe(ref);
      });
  
      // Cleanup on unmount
      return () => {
        if (observerRef.current) observerRef.current.disconnect();
      };
    }, []);
  return (
    <div style={{ width: '100%', boxSizing: 'border-box' }}>
        <h2>Assistant Selection</h2> <br/>
        <h4 style={{color:"gray"}}>
Like different professionals, different AI Assistants have different expertise.<br/>

Choose a specific Assitant that is most appropriate with a particular question or task you have.<br/>
If you have a diverse question or don't know which is the most appropriate Assistant - ask the Facilitator, it will direct you to the right AI Assistant.</h4>


      {/* Tabs for categories */}
      
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        scrollButtons="auto"
        style={{ paddingTop:"1rem",position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1}}
      >
        {assistantProfile.map((category, index) => (
          <Tab key={index} label={category.category} />
        ))}
      </Tabs>

      {assistantProfile.map((category, index) => (
        <div
          key={index} 
          ref={(el) => (categoryRefs.current[index] = el)}   
          style={{ marginBottom: 20, padding: '1rem 0' }}
        >
          {category.category !== "Facilitator" && 
          <TextWithBorders sx={{fontSize:"1.2rem",padding:"1rem 0"}} text={category.category}/>}

          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
            {category.subCategory.map((sub, idx) => (
             <div
             key={idx}
             onClick={() => dispatchAssistant({ assistantSelected: sub.assistantId })}
             style={{
               padding: '1rem',
               maxWidth: '300px',
               minWidth: '300px',
               background: '#fafafa',
               borderRadius: '10px',
               minHeight: "300px",
               display: "flex",
               flexDirection: "column",
               boxShadow: sub.assistantId === assistantSelected ? "var(--boxShadowGeneral)" : "none",
               transition: "box-shadow 0.3s ease-in-out",
               cursor: "pointer"
             }}
             onMouseEnter={(e) => e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.15)"}
             onMouseLeave={(e) => e.currentTarget.style.boxShadow = sub.assistantId === assistantSelected ? "var(--boxShadowGeneral)" : "none"}
           >
                <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
                {/* <AccountBoxIcon/>  */}
                <Typography variant="h5" style={{ marginBottom: 5,fontWeight:"bold"}}>
                  {sub.assistant}
                </Typography>
                </div>
               
                <Typography variant="h6" style={{ marginBottom: 5, fontSize: '1rem'}}>
                  {sub.description}
                </Typography><br/>
                <div style={{flex:1}}>
                <Typography variant="h6" style={{ marginBottom: 5, fontSize: '1rem',fontWeight:"bold"}}>
                  Core Expertise
                </Typography>
                {sub.expertise.map((obj,index) => 
                <div key={index}  style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                  <KeyboardArrowRightIcon/>

                   <Typography variant="h6" style={{ marginBottom: 5, fontSize: '1rem' }}>
                  {obj}
                </Typography>
                </div>
               )}
               </div>
               <div style={{display:"flex",alignItems:"center",paddingTop:"1rem"}}>

                  <Button onClick={()=>dispatchAssistant({assistantSelected:sub.assistantId})} variant='contained'>{assistantSelected === sub.assistantId ? "Selected" : "Select"}</Button>
                  </div>
              </div>
            ))}
          </div>
        </div>
      ))}

    </div>
  )
}

const assistantProfile = [
  {
    category: "Facilitator",
    subCategory: [
      {
        assistant: "Facilitator",
        assistantId:assistants.Facilitator,
        description: "I connect you to the right professional for your specific needs, ensuring you receive the best guidance.",
        icon: "",
        expertise: [
          "Ask me anything finance related.",
        ]
      }
    ]
  },
  {
    category: "Personal Finance",
    subCategory: [
      {
        assistant: "Financial Advisor",
        assistantId: assistants.FinancialPlanning,
        description: "I provide guidance on budgeting, savings, and personal financial planning to help you achieve your goals.",
        icon: "",
        expertise: [
          "Ask me about your budgeting strategy.",
          "Get help with planning your retirement.",
          "Learn how to manage debt efficiently.",
          "Find strategies to grow your wealth over time.",
          "Understand the best investment options for you."
        ]
      },
      // {
      //   assistant: "Goal Planner",
      //   assistantId:assistants.FinancialPlanning,
      //   description: "I help you set, track, and achieve your financial goals with personalized advice and step-by-step planning.",
      //   icon: "",
      //   expertise: [
      //     "Create and track your financial goals.",
      //     "Plan for major life events like buying a house or starting a business.",
      //     "Set realistic timelines for your goals.",
      //     "Prioritize and organize your financial objectives.",
      //     "Identify potential roadblocks and strategies to overcome them."
      //   ]
      // },
      {
        assistant: "Portfolio Manager",
        assistantId: assistants.PortfolioManager,
        description: "I help you optimize your investment portfolio to align with your risk tolerance and financial objectives.",
        icon: "",
        expertise: [
          "Analyze your current portfolio's performance.",
          "Understand how to balance risk and return.",
          "Discover asset allocation strategies.",
          "Rebalance your portfolio to match your goals.",
          "Get insights into diversifying your investments."
        ]
      }
    ]
  },
  {
    category: "Opportunity Finder",
    subCategory: [
      {
        assistant: "Trend Spotter",
        assistantId:assistants.OpportunityFinder,
        description: "I identify the latest financial and market trends to help you stay ahead of opportunities.",
        icon: "",
        expertise: [
          "Spot trends in stock markets.",
          "Identify emerging industries.",
          "Analyze financial data for trends.",
          "Find investment opportunities in niche sectors.",
          "Stay updated on macroeconomic shifts."
        ]
      },
      
    ]
  },
  {
    category: "Financial Markets",
    subCategory: [
      {
        assistant: "Market Analyst",
        assistantId: assistants.TickerAnalyst,
        description: "I provide insights into market movements, helping you understand the broader financial landscape.",
        icon: "",
        expertise: [
          "Analyze stockS, bond, etf, crypto markets.",
          "Identify key drivers of market trends.",
          "Understand technical and fundamental analysis.",
          "Monitor geopolitical impacts on markets.",
          "Get insights into sector-specific developments."
        ]
      },
      {
        assistant: "Economy Analyst",
        assistantId: assistants.EconomyAnalyst,
        description: "I analyze economic indicators and trends to give you a comprehensive view of the global economy.",
        icon: "",
        expertise: [
          "Interpret economic indicators like GDP and inflation.",
          "Analyze fiscal and monetary policy impacts.",
          "Stay updated on employment and consumer data.",
          "Understand global economic trends.",
          "Predict economic scenarios based on key metrics."
        ]
      },
      // {
      //   assistant: "Equity Analyst",
      //   assistantId: assistants.TickerAnalyst,
      //   description: "I provide detailed analysis of company performance and stock valuations to help you make informed equity investments.",
      //   icon: "",
      //   expertise: [
      //     "Analyze company financial statements.",
      //     "Understand valuation techniques.",
      //     "Identify growth and value stocks.",
      //     "Predict earnings based on company data.",
      //     "Monitor sector-specific performance trends."
      //   ]
      // }
    ]
  },
  {
    category: "News & Report",
    subCategory: [
      {
        assistant: "News Specialist",
        assistantId: assistants.NewsAssistant,
        description: "I keep you informed on the latest news and developments in finance and the economy.",
        icon: "",
        expertise: [
          "Get real-time financial news updates.",
          "Analyze the impact of news on markets.",
          "Monitor geopolitical events affecting finance.",
          "Stay updated on regulatory changes.",
          "Understand breaking news in a broader context."
        ]
      },
      {
        assistant: "Report Specialist",
        assistantId: assistants.ReportBuilder,
        description: "I provide detailed reports and analysis on financial and economic topics for deeper insights.",
        icon: "",
        expertise: [
          "Generate personal financial report.",
          "Generate portfolio report.",
          "Generate economy report.",
          "Generate market report.",
        ]
      }
    ]
  }
];
