import React, {useEffect} from 'react';
import ScreenerTemplate from './ScreenerTemplate'
import ScatterUI from 'components/Charts/ScatterUI';
import TreeMap from 'components/Charts/TreeMap';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import PaginationButtons from 'components/PaginationButtons';
import PeerOverviewMetricComp from 'analytics/ticker/stocks/Peer/PeerOverviewMetricComp';
import ScreenerTable from './ScreenerTable';
import NoMatchingResults from './NoMatchingResults';
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable';

export default function StockScreener() {
      
  const {stateStock,dispatchScreener,stateScreener} = useAppInfo();
  const {stocks,dataView,columns,scatterMetricsSel,filterData,currentPage,sortDirection,sortedColumn,pageData,totalItems} = stateScreener
  const itemsPerPage = 200

 
  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    if (stocks.length > 0) {
      const pageData = stocks.slice(indexOfFirstItem, indexOfLastItem);
      const tableData = getSpecificArrayObjKeys(pageData, columns);
      const tickerlist = pageData.map(obj => obj[dfin.ticker]);
  
      dispatchScreener({
        filterData: stocks,
        tickerList: tickerlist,
        pageData: pageData,
        tableData: tableData,
        totalItems: stocks.length
      });
    }
  }, [stocks]);
  
  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    if (filterData.length > 0) {
      const pageData = filterData.slice(indexOfFirstItem, indexOfLastItem);
      const tableData = getSpecificArrayObjKeys(pageData, columns);
      const tickerlist = pageData.map(obj => obj[dfin.ticker]);
  
      dispatchScreener({
        tickerList: tickerlist,
        pageData: pageData,
        tableData: tableData,
        totalItems: filterData.length
      });
    } else {
      dispatchScreener({
        pageData: [],
        tableData: [],
        totalItems: 0
      });
    }
  }, [currentPage, itemsPerPage, filterData, columns]);
  
  const handlePageNav = (page) => {
    dispatchScreener({ currentPage: page });
  };
  

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    if (filterData.length > 0) {
      const sortedData = filterData.sort((a, b) => {
        const valueA = a[sortedColumn];
        const valueB = b[sortedColumn];
  
        if (typeof valueA === "number" && typeof valueB === "number") {
          return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
        } else if (typeof valueA === "string" && typeof valueB === "string") {
          return sortDirection === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else if (
          typeof valueA === "object" &&
          valueA instanceof Date &&
          typeof valueB === "object" &&
          valueB instanceof Date
        ) {
          return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
        } else {
          return 0;
        }
      });
  
      const pageData = sortedData.slice(indexOfFirstItem, indexOfLastItem);
      const tableData = getSpecificArrayObjKeys(pageData, columns);
  
      dispatchScreener({
        filterData: sortedData,
        pageData: pageData,
        tableData: tableData,
        currentPage: 1
      });
    }
  }, [sortedColumn, sortDirection]);
  
  function handleResetFilter() {
    dispatchScreener({
      filters: {},
      filterData: stocks
    });
  }
  
  function handleFilterModal() {
    dispatchScreener({ filterModal: true });
  }
  


const metricOptions = stateStock.metrics.numCols


  return (
    <ScreenerTemplate filterName={"filters"}>
 <div style={{height:"100%",width:"100%"}}>
     
     {!stocks.length>0 && <LoadingSkeletonTable length={100}/>}
     {(stocks.length>0 && filterData.length===0) && 
     <NoMatchingResults handleChangeFilter={handleFilterModal} 
     handleResetFilter={handleResetFilter}/>}
     
     {pageData.length>0 && 
     <div style={{display:"flex",flexDirection:"column",height:"100%",width:"100%"}}>
     <div style={{overflow:"auto",width:"100%",flex:1}}>

     {dataView === "Table" && <ScreenerTable/>}
     {dataView === "Scatter" && <ScatterUI data={pageData} defaultMet={scatterMetricsSel}/>}
     {dataView==="Heatmap" && <TreeMap data={pageData}/>}
     {dataView==="Brand Visuals" && <PeerOverviewMetricComp
      data={pageData}
      infoName={"Brand Viusals"}
      storeId={"IndustrySectorVisuals"}
      defaultMet={[dfin.marketCap,dfin.ebitda,dfin.revenue]}
      metricOptions={metricOptions}
      chartTypeDefault={"BrandVisuals"}
      chartTypeOptions={["BrandVisuals"]}
    />}
    </div>

      <PaginationButtons 
    totalItems={totalItems} 
    currentPage={currentPage}  
    itemsDisplaying={stateScreener.tableData.length}
    itemsPerPage={itemsPerPage}
    handlePage={handlePageNav}
    />
      </div>
    }
    </div>



    </ScreenerTemplate>
  )
}
