import React, { useEffect, useState } from 'react';
import { Badge, Button, Tooltip } from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CalculateIcon from '@mui/icons-material/Calculate';
import FullScreen from './FullScreen';
import { useAppInfo } from 'AppState';
import SearchIcon from "@mui/icons-material/Search";
import AlertButton from 'myFinancials/AlertButton';
import PageHeaderIcons from 'nav/PageHeaderIcons';
// import SearchNav from 'nav/SearchNav';


const RightSidebar = () => {
  const { dispatchSidebar, stateSidebar, stateChats,dispatchSearch,stateSearch} = useAppInfo();
  const { unreadChatCount } = stateChats;

  const handleSidebar = (value) => {
    if (!["full screen", "settings","search","search (ctrl + k)","alerts","guide"].includes(value)) {
      const isSameContent = value === stateSidebar.sidebarRightContent;
      const sidebarOpen = isSameContent ? !stateSidebar.sidebarOpen : true;
      dispatchSidebar({ sidebarOpen, sidebarRightContent: value });
    }
    if (value === "search (ctrl + k)"){
      dispatchSearch({'searchClick':!stateSearch.searchClick });
    }
  };

  const sidebarButtons = [
    { group: "mainItems", items: [
      { title: "search (ctrl + k)", icon: <><SearchIcon fontSize="large"/></>},
      { title: "alerts", icon: <AlertButton displayType="sidebar"/> },
    ]},
    { group: "tickerLists", items: [
      { title: "watchlist", icon: <ListIcon fontSize='small'/> },
      { title: "peers", icon: <Diversity2Icon fontSize='small'/> },
      { title: "hotlist", icon: <OfflineBoltIcon fontSize='small'/> },
    ]},
    { group: "user", items: [
      { title: "chat", icon: <Badge badgeContent={unreadChatCount} color="primary"><ChatBubbleOutlineIcon fontSize='small'/></Badge> },
      // { title: "notifications", icon: <NotificationsIcon /> },
      // { title: "guide", icon: <PageHeaderIcons variant='sidebar' name="Guide"/> },
      { title: "information", icon: <InfoOutlinedIcon fontSize='small'/> },
      { title: "notes", icon: <CreateRoundedIcon fontSize='small'/> },
    ]},
    { group: "iconsLast", items: [
      { title: "calculator", icon: <CalculateIcon fontSize='small'/> },
      { title: "full screen", icon: <FullScreen fontSize='small'/> },
      { title: "help", icon: <HelpOutlineIcon fontSize='small'/> },
    ]},
  ];

  const [showExpander, setShowExpander] = useState(false);

  return (
    <>
      <div 
        onMouseEnter={() => setShowExpander(true)}
        onMouseLeave={() => setShowExpander(false)}
        id="rightSidebar"
        className="right-sidebar" 
        style={{ width: !stateSidebar.showSidebarRight && "0px", overflow: "visible", padding: !stateSidebar.showSidebarRight && 0 }}>
        {stateSidebar.showSidebarRight && (
          <>
            {sidebarButtons.map(({ group, items }) => (
              <div key={group} style={group === "iconsLast" ? {marginTop:"auto",marginBottom:"1rem",display:"flex",flexDirection:"column"}:{}}>
                {items.map(({ title, icon }) => (
                  <Tooltip key={title} title={title} placement="left" arrow>
                    <Button
                      id={title}
                      sx={{ minWidth: "0", justifyContent: "center", width: "100%" }}
                      className={`sidebar-button ${stateSidebar.sidebarRightContent === title && stateSidebar.sidebarOpen ? "active" : ""}`}
                      onClick={() => handleSidebar(title)}>
                      {icon}
                    </Button>
                  </Tooltip>
                ))}
                {["tickerLists","mainItems"].includes(group) && <div style={{ margin: "0 0.3rem", borderBottom: "1px solid #ddd" }}></div>}
              </div>
            ))}
          </>
        )}
        {((!stateSidebar.sidebarOpen && showExpander) || !stateSidebar.showSidebarRight) && (
          <div 
            style={{ position: "absolute", borderRadius: "10px 0px 0px 10px", transform: "translateY(-50%)", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", top: "50%", left: "-15px", height: "100px", width: "15px", background: "#eeeeee", zIndex: "100", color: "black" }}>
            {!stateSidebar.showSidebarRight ? 
              <ExpandLessIcon sx={{ transform: "rotate(-0.25turn)" }} onClick={() => dispatchSidebar({ showSidebarRight: true })} /> : 
              <ExpandMoreIcon sx={{ transform: "rotate(-0.25turn)" }} onClick={() => dispatchSidebar({ showSidebarRight: false })} />
            }
          </div>
        )}
      </div>
    </>
  );
};

export default RightSidebar;