import TextInput from 'components/Inputs/TextInput';
import SelectBox from 'components/Inputs/SelectBox';
import DatePicker from 'components/Inputs/DatePicker';
import { compoundingOptions, paybackPeriodOptions, periodTypes } from 'appState/initialStateMyFinancials';
import ColorPicker from 'components/Inputs/ColorPicker';
import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Paper, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import MultiSelectBox from 'components/Inputs/MultiSelectBox';
import { coreExpertise, professionalCategory } from './info';
import TextArea from 'components/Inputs/TextArea';
import MultiSelect from 'components/Inputs/MultiSelect';


export function InputText({labelFontSize="1rem",fieldKey="", handleChange, label="", fieldObj, disabled = false, variant = "outlined",placeholder="" }) {
  return (
    <TextInput
      labelFontSize={labelFontSize}
      label={label}
      value={fieldObj?.[fieldKey] || ""}
      placeholder={placeholder}
      onChange={(v) => handleChange(fieldKey, v)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputTextArea({maxLength,height="300px",fieldKey="",placeholder="", handleChange, label = "", fieldObj, disabled = false, variant = "outlined" }) {
  return (
    <TextArea
      maxLength={maxLength}
      height={height}
      label={label}
      placeholder={placeholder}
      value={fieldObj?.[fieldKey] || ""}
      onChange={(v) => handleChange(fieldKey, v)}
      disabled={disabled}
      variant={variant}
    />
  );
}


export function InputSingleOptions({ handleChange, label="",fieldKey="", fieldObj, disabled = false, 
  variant = "standard",options=[] }) {
  return (
    <SelectBox
      label={label}
      value={fieldObj?.[fieldKey]}
      options={options}
      onChange={(v) => handleChange(fieldKey, v)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputMultipleOptions({required=true,handleChange, label="",fieldKey="", fieldObj, disabled = false, 
  variant = "outlined",options=[] }) {

  return (
    <MultiSelect
    required={required}
    label={label}
    options={options}
    values={fieldObj?.[fieldKey] || []}
    onChange={(v) => handleChange(fieldKey, v)}
    disabled={disabled}
    variant={variant}
    tags={true}
    limitTags={5}
    labelFontSize={"1rem"}
  />
  );
}


export function InputWebsite({ handleChange, label = true, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <TextInput
      label={label ? 'Website' : ''}
      value={fieldObj?.name}
      onChange={(v) => handleChange('website', v, fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}


export function InputLanguages({ handleChange, label = true, fieldObj, disabled = false, variant = "standard" }) {
  const [languages, setLanguages] = useState([{language:"",proficiency:""}]);

  useEffect(() => {
    if (fieldObj && fieldObj?.languages && fieldObj?.languages?.length > 0) {
      setLanguages(fieldObj.languages);
    }
  }, [fieldObj]);

  function handleUpdate(n,v,index){
    const updatedLanguages = languages.map((obj, i) => (i === index ? { ...obj, [n]: v } : obj));
    handleChange("languages",updatedLanguages);
  }
 
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",width:"100%"}}>
      <Typography variant='h6' sx={{fontSize:"1rem",color:"rgba(0,0,0,0.6)"}}>Languages</Typography>
      {languages.map((obj, index) => (
        <div key={index} style={{display:"flex",gap:"1rem",width:"100%"}}>
         <SelectBox required sx={{flex:1}} variant='outlined' onChange={(e,v)=>handleUpdate("language",v,index)} label="Language" value={obj?.language}  options={["English", "Spanish", "French", "German","Italian","Hindi","Portuguese","Arabic","Russian","Korean","Japanese","Mandarin"]} />
         <SelectBox required  variant='outlined' onChange={(e,v)=>handleUpdate("proficiency",v,index)} label="Proficiency" value={obj?.proficiency} options={["Basic", "Fluent", "Native"]} />
         {languages.length>1?<Button onClick={() => handleChange("languages",languages.filter((_, i) => i !== index))}>Remove</Button>:null}
        </div>
      ))}
     <Button sx={{alignSelf:"flex-start"}} onClick={() => handleChange("languages",[...languages, {language:"",proficiency:""}])}>Add Language</Button>
    </div>
  )
}

export function InputServices({ handleChange, label = true, fieldObj, disabled = false, variant = "standard" }) {
  const [services, setServices] = useState([{title:"",description:""}]);

  useEffect(() => {
    if (fieldObj && fieldObj?.services && fieldObj?.services?.length > 0) {
      setServices(fieldObj.services);
    }
  }, [fieldObj]);

  function handleUpdate(n,v,index){
    const updatedServices = services.map((obj, i) => (i === index ? { ...obj, [n]: v } : obj));
    handleChange("services",updatedServices);
  }
 
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",width:"100%"}}>
      <Typography variant='h6' sx={{fontSize:"1rem",color:"rgba(0,0,0,0.6)"}}>Services</Typography>
      <div style={{display:"flex",flexDirection:"column",gap:"2rem",width:"100%"}}>
      {services.map((obj, index) => (
        <div key={index} style={{display:"flex",flexDirection:"column",gap:"0.25rem",width:"100%"}}>
         <div style={{display:"flex",gap:"1rem"}}>
         <TextInput  required sx={{flex:1}} variant='outlined' 
         onChange={(v)=>handleUpdate("title",v,index)} 
         label="Service Title" value={obj?.title} />
         {services.length>1?<Button onClick={() => handleChange("services",services.filter((_, i) => i !== index))}>Remove</Button>:null}
         </div>
         <TextArea placeholder={"Description of the Service"} required  
         variant='outlined' onChange={(v)=>handleUpdate("description",v,index)} 
         label="" value={obj?.description} />
         
        </div>
      ))}
      </div>
     <Button sx={{alignSelf:"flex-start"}} onClick={() => handleChange("services",[...services, {title:"",description:""}])}>Add Services</Button>
    </div>
  )
}

export function InputCertifications({
  handleChange,
  label,
  fieldKey="certifications",
  fieldObj,
  disabled,
  variant="outlined",
  options
}) {
  const [certifications, setCertifications] = useState(fieldObj[fieldKey] || []);

  useEffect(() => {
    if (fieldObj && fieldObj?.certifications && fieldObj?.certifications?.length > 0) {
      setCertifications(fieldObj.certifications);
    }
  }, [fieldObj]);
  const handleAddCertification = () => {
    const updatedCertifications = [...certifications, { issuingAuthority: "", issuedDate: "", expiryDate: "" }]
    handleChange(fieldKey, updatedCertifications);
    // setCertifications([...certifications, { issuingAuthority: "", issuedDate: "", expiryDate: "" }]);
  };

  const handleRemoveCertification = (index) => {
    const updatedCertifications = certifications.filter((_, i) => i !== index);
    handleChange(fieldKey, updatedCertifications);
    // setCertifications(certifications.filter((_, i) => i !== index));
  };

  const handleCertificationChange = (index, key, value) => {
    const updatedCertifications = certifications.map((certification, i) => (i === index ? { ...certification, [key]: value } : certification));
    handleChange(fieldKey, updatedCertifications);
    // setCertifications(
    //   certifications.map((certification, i) =>
    //     i === index ? { ...certification, [key]: value } : certification
    //   )
    // );
  };

  return (
    <div style={{display:"flex","flexDirection":"column","gap":"1rem","width":"100%"}}>
       <Typography variant='h6' sx={{fontSize:"1rem",color:"rgba(0,0,0,0.6)"}}>Certifications</Typography>
      {certifications.map((certification, index) => (
        <div key={index} style={{display:"flex",flexDirection:"column",gap:"0.5rem",width:"100%"}}>
          <div style={{display:"flex",gap:"1rem"}}>
          <TextInput
            label="Issuing Authority"
            fieldKey="issuingAuthority"
            value={certification.issuingAuthority}
            onChange={(v) => handleCertificationChange(index, "issuingAuthority", v)}
            disabled={disabled}
            variant={variant}
          />
          <Button onClick={() => handleRemoveCertification(index)}>Remove</Button>
          </div>
          <div style={{display:"flex",gap:"1rem"}}>
          <DatePicker
            required
            label="Issued Date"
            fieldKey="issuedDate"
            date={certification.issuedDate}
            handleDate={(v) => handleCertificationChange(index, "issuedDate", v)}
            disabled={disabled}
            variant={variant}
          />
          <DatePicker
            required
            label="Expiry Date"
            fieldKey="expiryDate"
            date={certification.expiryDate}
            handleDate={(v) => handleCertificationChange(index, "expiryDate", v)}
            disabled={disabled}
            variant={variant}
          />
          </div>
          
        </div>
      ))}
      <Button onClick={handleAddCertification}>Add Certification</Button>
    </div>
  );
}

export function InputQualifications({
  handleChange,
  label,
  fieldKey="qualifications",
  fieldObj,
  disabled,
  variant="outlined",
  options
}) {
  const [qualifications, setQualifications] = useState(fieldObj[fieldKey] || []);

  useEffect(() => {
    if (fieldObj && fieldObj?.qualifications && fieldObj?.qualifications?.length > 0) {
      setQualifications(fieldObj.qualifications);
    }
  },[fieldObj]);

  const handleAddQualification = () => {
    const updatedQualifications = [...qualifications, { school: "", degree: "", issuedDate: "" }]
    handleChange(fieldKey, updatedQualifications);
  };

  const handleRemoveQualification = (index) => {
    const updatedQualifications = qualifications.filter((_, i) => i !== index);
    handleChange(fieldKey, updatedQualifications);
  };

  const handleQualificationChange = (index, key, value) => {
    const updatedQualifications = qualifications.map((qualification, i) => (i === index ? { ...qualification, [key]: value } : qualification));
    handleChange(fieldKey, updatedQualifications);
  };

  return (
    <div style={{display:"flex","flexDirection":"column","gap":"1rem","width":"100%"}}>
       <Typography variant='h6' sx={{fontSize:"1rem",color:"rgba(0,0,0,0.6)"}}>Qualifications</Typography>
      {qualifications.map((qualification, index) => (
        <div key={index} style={{display:"flex",flexDirection:"column",gap:"0.5rem",width:"100%"}}>
          <div style={{display:"flex",gap:"1rem"}}>
          <TextInput
            required
            label="School"
            fieldKey="school"
            value={qualification?.school}
            onChange={(v) => handleQualificationChange(index, "school", v)}
            disabled={disabled}
            variant={variant}
          />
          <Button onClick={() => handleRemoveQualification(index)}>Remove</Button>
          </div>
          <TextInput
            required
            label="Degree"
            fieldKey="degree"
            value={qualification?.degree}
            onChange={(v) => handleQualificationChange(index, "degree", v)}
            disabled={disabled}
            variant={variant}
          />
           <DatePicker
            required
            label="Issued Date"
            date={qualification?.issuedDate}
            handleDate={(v) => handleQualificationChange(index, "issuedDate", v)}
            disabled={disabled}
            variant={variant}
          />
          
        </div>
      ))}
      <Button onClick={handleAddQualification}>Add Qualification</Button>
    </div>
  );
}
  
export function InputFAQ({ handleChange, label = true, fieldObj, disabled = false, variant = "standard" }) {
  const [faq, setFaq] = useState([{question:"",answer:""}]);

  useEffect(() => {
    if (fieldObj && fieldObj?.faq && fieldObj?.faq?.length > 0) {
      setFaq(fieldObj.faq);
    }
  }, [fieldObj]);

  function handleUpdate(n,v,index){
    const updatedServices = faq.map((obj, i) => (i === index ? { ...obj, [n]: v } : obj));
    handleChange("faq",updatedServices);
  }
 
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",width:"100%"}}>
      <Typography variant='h6' sx={{fontSize:"1rem",color:"rgba(0,0,0,0.6)"}}>FAQ</Typography>
      <div style={{display:"flex",flexDirection:"column",gap:"2rem",width:"100%"}}>
      {faq.map((obj, index) => (
        <div key={index} style={{display:"flex",flexDirection:"column",gap:"0.25rem",width:"100%"}}>
         <div style={{display:"flex",gap:"1rem"}}>
         <TextInput  required sx={{flex:1}} variant='outlined' 
         onChange={(v)=>handleUpdate("question",v,index)} 
         label="Question" value={obj?.question} />
         {faq.length>1?<Button onClick={() => handleChange("faq",faq.filter((_, i) => i !== index))}>Remove</Button>:null}
         </div>
         <TextArea placeholder={"Answer"} required  
         variant='outlined' onChange={(v)=>handleUpdate("answer",v,index)} 
         label="" value={obj?.answer} />
         
        </div>
      ))}
      </div>
     <Button sx={{alignSelf:"flex-start"}} onClick={() => handleChange("faq",[...faq, {question:"",answer:""}])}>Add FAQ</Button>
    </div>
  )
}


    


const date = new Date();

export const financeProfessionalFormDetails = {
      // userId: generateUniqueId(),
      loginDate: date,
      name: "Maria Doe",
      organization: "Financial Insights Inc.",
      organizationAchievements: "Financial Insights Inc. has this many clients...",
      role: "Senior Financial Advisor",
      professionalCategory: ["Financial Advisor", "Financial Planner"],
      coreExpertise: ["Retirement Planning", "Wealth Management", "Estate Planning"],
      descriptionShort: "I can help with Financial Planning and Retirement Planning.",
      descriptionLong: "With over 10 years of experience in financial planning and retirement strategies, I help individuals and families achieve financial security and long-term goals. I specialize in personalized plans, investment management, tax-efficient strategies, and sustainable retirement income. My approach empowers you to make confident decisions while balancing current needs and future aspirations. Let’s work together to secure your financial future.",
      services: [
        { title: "Family Financial Planning", description: ""},
        { title: "Retirement Planning", description: ""}
      ],
      locationServed: ["New York", "New Jersey", "Connecticut"],
      languages: [{ language: "", proficiency: "" }],
      profileImage: "https://plus.unsplash.com/premium_photo-1670884442927-e647436e12ff?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHByb2Zlc3Npb25hbHxlbnwwfHwwfHx8MA%3D%3D",
      marketingMedia: [{fileType:"image",url:"https://images.unsplash.com/photo-1505409859467-3a796fd5798e?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8b2ZmaWNlJTIwc3BhY2V8ZW58MHx8MHx8fDA%3D"},
        {fileType:"image",url:"https://images.unsplash.com/photo-1445116572660-236099ec97a0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhZmV8ZW58MHx8MHx8fDA%3D"},
        {fileType:"image",url:"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG9mZmljZSUyMHRlYW18ZW58MHx8MHx8fDA%3D"},
      ],
      website: "",
      socialMedia: {
          instagram: "https://instagram.com/johndoe_advisor",
          linkedin: "https://linkedin.com/in/johndoe",
          facebook: "https://facebook.com/johndoeadvisor",
          whatsapp: "https://wa.me/1234567890"
        }
      ,
      address: "123 Finance St, Suite 101, New York, NY, 10001",
      location: { city: "", state: "", country: "" },
      email: "johndoe@example.com",
      phoneNumber: "+1-123-456-7890",
      availability: [{ date: "", time: "" }],
      responseTime: "",
      fees: [
        { basic: "$100/hr", details: [] },
        { standard: "$150/hr", details: [] },
        { premium: "$200/hr", details: [] },
        { ultra: "$300/hr", details: [] }
      ],
      certifications: [
        {
          issuingAuthority: "Certified Financial Planner Board",
          issuedDate: "2020-01-15",
          expiryDate: "-"
        },
        {
          issuingAuthority: "Chartered Financial Analyst Institute",
          issuedDate: "2020-01-15",
          expiryDate: "-"
        }
      ],
      qualifications: [
        {
          school: "Duke University",
          degree: "Master of Business Administration",
          issuedDate: "2020-01-15",
        },
      ],
      showDetails: [{ email: true, phone: false, website: true, location: true, socialMedia: true }],
      faq: [{ question: "", answer: "" }],
      reviews: [
        {
          star: 0,
          comment: "",
          location: "",
          date: ""
        },
      ],
      reviewsStats: {
        averageRating: 4.8,
        totalReviews: 16,
        starCounts: { 5: 15, 4: 0, 3: 1, 2: 0, 1: 0 },
              },
      categoryRatings: [
                { label: 'Communication Level', rating: 4.6 },
                { label: 'Quality of delivery', rating: 4.8 },
                { label: 'Value of delivery', rating: 4.9 }
              ],
      averageResponseTime :'1 Hour',
      onlineStatus: 'Offline',
    }