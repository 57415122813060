import Animation from 'animation/Animation'
import React from 'react'

export default function GoalIcons({iconType="Travel",width="100px",loop=false}) {

  const goalIcons = {
    "Travel":<Animation type='Travel' loop={loop}/>,
    "Home":<Animation type="Home" loop={loop}/>,
    "Other":<Animation type="Financials" loop={loop}/>,
    "Retirement":<Animation type='Portfolio' loop={loop}/>,
    "Education":<Animation type="Knowledge" loop={loop}/>,
  }

  return (
    <div style={{width:width}}>
      {goalIcons[iconType]}
    </div>
  )
}
