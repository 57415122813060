import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Button, Divider, List, ListItem, ListItemIcon, ListItemText, Card, IconButton, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import LoopIcon from '@mui/icons-material/Loop';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LikeShareReport from 'finanaceProfessional/LikeShareReport';

const feePackages = [
    {
      label: 'Basic',
      price: 'CA$250.00',
      description: 'Ideal for clients seeking foundational financial advice, covering budgeting, goal setting, and initial investment strategies.',
      delivery: '7-day delivery',
      revisions: '2 Revisions',
      features: [
        'Up to 1-hour consultation',
        'Basic financial assessment',
        'Budgeting guidance',
        'Goal setting & planning',
        'Initial investment strategy'
      ],
    },
    {
      label: 'Standard',
      price: 'CA$500.00',
      description: 'Comprehensive plan with tailored strategies, covering investment, retirement, and risk management.',
      delivery: '5-day delivery',
      revisions: '3 Revisions',
      features: [
        'Up to 2 hours consultation',
        'Comprehensive financial assessment',
        'Investment portfolio suggestions',
        'Retirement planning',
        'Risk management & insurance advice'
      ],
    },
    {
      label: 'Premium',
      price: 'CA$1000.00',
      description: 'Full premium financial service including tax planning, advanced investments, and personalized growth strategies.',
      delivery: '3-day delivery',
      revisions: 'Unlimited Revisions',
      features: [
        'Up to 3 hours consultation',
        'In-depth financial review',
        'Tax planning strategies',
        'Advanced investment guidance',
        'Personalized growth & wealth-building strategies'
      ],
    },
  ];
  

const FeesDisplay = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4 }}>
       <LikeShareReport/>
       <Box mt={2} textAlign="center">
        <Button variant="outlined" fullWidth>
          Contact me
        </Button>
        <Typography variant="caption" color="textSecondary" sx={{ mt: 1, display: 'block' }}>
          <CheckCircleIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
          Offers free consultations
        </Typography>
      </Box>
      <Card sx={{ p: 3, mt: 2, boxShadow: 3 }}>
      <div style={{paddingBottom:"1rem"}}>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        {feePackages.map((pkg, index) => (
          <Tab key={index} label={pkg.label} />
        ))}
      </Tabs>
      </div>
        <Typography variant="h4" color="primary" gutterBottom>
          {feePackages[selectedTab].price}{' '}
          <Tooltip title="Save up to 20% with Subscribe to Save">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Save up to 20% with <Button size="small" color="primary">Subscribe to Save</Button>
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>
          {feePackages[selectedTab].label} Package
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          {feePackages[selectedTab].description}
        </Typography>
        
        <Box display="flex" alignItems="center" gap={1}>
          <AccessTimeIcon fontSize="small" color="action" />
          <Typography variant="body2" color="textSecondary">{feePackages[selectedTab].delivery}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          <LoopIcon fontSize="small" color="action" />
          <Typography variant="body2" color="textSecondary">{feePackages[selectedTab].revisions}</Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <List dense>
          {feePackages[selectedTab].features.map((feature, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleIcon color="action" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>

        <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Continue
        </Button>
        <Button variant="text" color="secondary" fullWidth>
          Compare packages
        </Button>
      </Card>

     
    </Box>
  );
};

export default FeesDisplay;
