import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAppInfo } from 'AppState';
import ReportFooter from './ReportFooter';
import DropZone from './page/DropZone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import useAddReport from 'data/user/reports/useAddReport';
import useDeleteReport from 'data/user/reports/useDeleteReport';
import ReportLeftSideBarFloat from './ReportLeftSidebar/ReportLeftSidebarFloat';
import { debounce } from 'lodash';
import { CreateNewPage} from './utilsReport';
import CrudModal from 'components/Modal/CrudModal';

export default function ReportBuilder() {
  const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()

  const {pages,reportLoaded,addNewReport,reportNames} = stateReportBuilder
  const pageFooterRef = useRef(null)
  const containerRef = useRef(null);
  const {addReport} = useAddReport()


  useEffect(()=>{
    if (reportLoaded && reportNames.length===0){
      dispatchReportBuilder({addNewReport:true})
    }
  },[reportLoaded && reportNames])

  useEffect(() => {
    if (containerRef.current) {
      // Scroll to the last added pages
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    if (pageFooterRef.current) {
      // Scroll to the last added pages
      pageFooterRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, [pages]);

  
 

  // Create a debounced function using useCallback to ensure it doesn't change unless necessary
  const debouncedSaveChanges = useCallback(
    debounce(() => {
      addReport(stateReportBuilder.reportNameSel, stateReportBuilder.pages);
    }, 5000), // Delay of 10 seconds
    [stateReportBuilder.reportNameSel, stateReportBuilder.pages] // Dependencies for useCallback
  );

  useEffect(() => {
    if (pages.length !== 0 && reportLoaded) {
      const currentReport = {[stateReportBuilder.reportNameSel]: stateReportBuilder.pages};
      dispatchReportBuilder({"reportData":{...stateReportBuilder.reportData, ...currentReport}});

      if (reportNames.length>0){
      // Call the debounced function
      debouncedSaveChanges();}
    }

    // Cleanup function to cancel the debounced call if the component unmounts
    return () => {
      debouncedSaveChanges.cancel();
    };
  }, [pages,stateReportBuilder.reportNameSel]); // Ensure the effect runs only if these dependencies change

  

  return (
    <>
      
      <div className='containerColumnFlex'>

      <div className='containerLeftMenuAndContent'>

      <ReportLeftSideBarFloat/>

      <div className='containerMainContent'>
      <div     
       ref={containerRef}
        className='containerColumnFlex'
        style={{marginBottom:"0.5rem"}}
      > 
        <div style={{flex:1,width:"100%",paddingBottom:"0.5rem",boxSizing:"border-box"}}>
        <DropZone/></div>
          <PageUpDown/>
          </div>
          <ReportFooter pageFooterRef={pageFooterRef}/>
      </div>
      
      </div>
     
      </div>

      {stateReportBuilder.addNewReport && <AddReport/>}

      {stateReportBuilder.deleteReport && 
      <DeleteReport/>}
      
    </>
  );
}



function AddReport(){
  const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
 
  const navigate = useNavigate()

  function handleCloseModal() {
    dispatchReportBuilder({"addNewReport":false})
  }

  function handleAddReport(reportName){
    const newPage = CreateNewPage(0)
    dispatchReportBuilder({
      pages: [newPage],
      reportNames: [...stateReportBuilder.reportNames, reportName],
      reportNameSel: reportName,
      addNewReport: false
    });
  }

  return(
    <CrudModal 
    title={"Report"}
    onClose={handleCloseModal}
    placeholder={"Enter a Report Name"} handleCreate={(v)=>handleAddReport(v)}
    />
    
  )
}






function DeleteReport(){
  const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
  const {deleteReport} = useDeleteReport()

  function handleDeleteReport() {
    const reportData = { ...stateReportBuilder.reportData };
    const reportNames = stateReportBuilder.reportNames.filter(names => names !== stateReportBuilder.reportNameSel);
  
    if (reportNames.length === 0) {
      dispatchReportBuilder({
        addNewReport: true,
        pages: [
          {
            PageIndex: 0,
            PageName: "",
            PageContent: []
          }
        ],
        pageCurrent: 0
      });
    } else {
      const pageData = reportData[reportNames[0]];
      dispatchReportBuilder({
        reportNameSel: reportNames[0],
        pages: pageData,
        pageCurrent: 0
      });
    }
  
    delete reportData[stateReportBuilder.reportNameSel];
  
    dispatchReportBuilder({
      reportNames: reportNames,
      reportData: reportData,
      deleteReport: false
    });
  
    deleteReport(stateReportBuilder.reportNameSel);
  }

  return(
    <CrudModal 
    modalType='delete'
    title={stateReportBuilder.reportNameSel}
    handleDelete={handleDeleteReport} 
    onClose={()=>dispatchReportBuilder({"deleteReport":false})}/>
  )
  
}


function PageUpDown(){
  const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
  const {pages,reportLoaded,display} = stateReportBuilder
  function handlePageMove(v){
    const pageNext = stateReportBuilder.pageCurrent + v
    const targetElement = document.getElementById(`reportPage${pageNext}`);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    dispatchReportBuilder({"pageCurrent":pageNext})
  }

  return (
    <>
    {!stateReportBuilder.pageFooter && 
    <div style={{display:"flex",padding:"0 1rem",marginLeft:"auto",gap:"1rem"}}>
           
        <button disabled={stateReportBuilder.pageCurrent === 0}  className='btn' style={{padding:"0 0.5rem"}} onClick={()=>handlePageMove(-1)}>
           {display==="vertical"?<KeyboardArrowUpIcon/>:<KeyboardArrowLeftIcon/>}
          </button>

        <button 
        disabled={stateReportBuilder.pageCurrent >= (stateReportBuilder.pages.length - 1)}
      className='btn'
      style={{padding:"0 0.5rem"}} 
      onClick={()=>handlePageMove(1)}>
        {display==="vertical"?<KeyboardArrowDownIcon/>:<KeyboardArrowRightIcon/>}
        </button>
    </div>}
    </>
  )
}





 // const [timeoutId, setTimeoutId] = useState(null);

  // useEffect(() => {
  //     // Function to handle the actual database update
  //     const saveChanges = () => {
  //             addReport(stateReportBuilder.reportNameSel, stateReportBuilder.pages);
  //     };

  //     if (pages.length !== 0 && reportLoaded) {
  //       const currentReport = {[stateReportBuilder.reportNameSel]: stateReportBuilder.pages};
  //       dispatchReportBuilder({type: "reportData", payload: {...stateReportBuilder.reportData, ...currentReport}});

  //         // Clear the existing timeout to reset the debounce period
  //         if (timeoutId) clearTimeout(timeoutId);

  //         // Set a new timeout to save changes after 500ms of inactivity
  //         const newTimeoutId = setTimeout(saveChanges, 1000*10);  // Adjust delay as necessary
  //         setTimeoutId(newTimeoutId);
  //     }

  //     console.log("Timeout",timeoutId)
  //     // Cleanup function to clear timeout if component unmounts or inputs change
  //     return () => {
  //         if (timeoutId) clearTimeout(timeoutId);
  //     };
  // }, [pages]);
