import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useAppInfo } from "AppState";
import WatchListMenu from "./WatchListMenu";
import { Button, Tooltip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styles from "./WatchListDropDown.module.css"
import WatchlistPriceTable from "./WatchlistPriceTable";

function WatchList() {
  const {
    dispatchSearch,
    stateSearch,
    stateWatchlist,
    dispatchWatchlist,
  } = useAppInfo();
  const {watchlistData} = stateWatchlist
  const watchlistNames = watchlistData ? Object.keys(watchlistData) : [];

  function handleSearchClick() {
    dispatchSearch({"searchClick":!stateSearch.searchClick });
  }

  function handleCreateWatchlist() {
    dispatchWatchlist({"createWatchlist":!stateWatchlist.createWatchlist,});
  }

  function handleWatchlistMenu(){
    dispatchWatchlist({"watchlistMenu":!stateWatchlist.watchlistMenu})
    }

  return (
    <div
    className="containerColumnFlexNoGap"
    >
      {watchlistNames.length > 0 && 
      
        <>
        <div
          style={{
            display: "flex",
            gap:"0.5rem",padding:"0.25rem",alignItems:"center"
          }}
        >
          <div style={{ flex: 1 }}>
          <Button variant="highlight" onClick={handleWatchlistMenu} 
              className={`${styles.wlDropDown} ${stateWatchlist.watchlistMenu ? styles.active : ''}`}
              >
                  
              {`Watchlist: ${stateWatchlist.watchlistSelected}`}
              {stateWatchlist.watchlistMenu && <KeyboardArrowUpIcon sx={{marginLeft:"auto"}}/>}
              {!stateWatchlist.watchlistMenu &&<ExpandMoreIcon sx={{marginLeft:"auto"}}/>}
              </Button>
          </div>

          <Tooltip title="Add tickers to watchlist.">
            <Button
            onClick={handleSearchClick}
            >
              <AddIcon  />
            </Button>
          </Tooltip>
        </div>
      

      {stateWatchlist.watchlistMenu && (
        <div
          style={{
            boxSizing: "border-box",
            maxWidth: "100%",
            width: "100%",
            flex: 1,
            overflow: "auto",
          }}
        >
          <>
            <WatchListMenu />
          </>
        </div>
      )}
      {!stateWatchlist.watchlistMenu &&
      <div style={{
        boxSizing: "border-box",
        maxWidth: "100%",
        width: "100%",
        flex: 1,
        overflow: "auto"}}>
        <WatchlistPriceTable/>
        </div>}

        {watchlistNames.length > 0 && (
          <div style={{padding:"0.25rem",width:"100%",boxSizing:"border-box"}}>
          <Button variant="highlight" sx={{width:"100%"}} startIcon={<AddIcon/>} 
          onClick={handleCreateWatchlist}>Create New Watchlist</Button>
          </div>
        )}
          </>
          }

        {!watchlistNames.length > 0 && (
          <>
            <h3 style={{ textAlign: "center", margin: "1rem 0rem" , fontWeight:"bold"}}>
              You have no Watchlists!
            </h3>
            <Button startIcon={<AddIcon/>} sx={{background:"#F8F8F8",fontWeight:"bold"}} onClick={handleCreateWatchlist}>
               Create New WatchList
              </Button>
          </>
        )}

    </div>
  );
}

export { WatchList };
