import { useAppInfo } from 'AppState';
import React, { useEffect, useMemo, useState } from 'react';

export default function useCheckFinancialHealth() {
    const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
    const { summaryStats, assets, liabilities, goalAnalytics,liabilityStats} = stateMyFinancials;
    const { totalAssets, totalLiabilities, totalExpense, totalIncome, netWorth, totalNetCashflow } = summaryStats;
    const { goalSuccessRateBeforeWithdrawal, goalSuccessRateAfterWithdrawal } = goalAnalytics;

    const calculateTotal = (items, types) => 
        items.reduce((total, item) => types.includes(item.type) ? total + item.amount : total, 0);

    const totalCash = useMemo(() => calculateTotal(assets, ["Cash"]), [assets]);
    const totalGrowingAssets = useMemo(() => calculateTotal(assets, ["Savings", "Investment Portfolio", "Real Estate", "Hedge Fund", "Private Equity"]), [assets]);
    const totalInsurance = useMemo(() => calculateTotal(assets, ["Insurance"]), [assets]);
    const totalSavings = useMemo(() => calculateTotal(assets, ["Savings"]), [assets]);
    const totalCashSavings = useMemo(() => calculateTotal(assets, ["Cash", "Savings", "Investment - Liquid"]), [assets]);
    const totalCreditCard = useMemo(() => calculateTotal(liabilities, ["Credit Card"]), [liabilities]);

    const [checks, setChecks] = useState([]);

    useEffect(()=>{
        
        const checkUpdate = [
            {
                category: "Asset Management",
                name: "Savings",
                amount: totalSavings,
                bestPractice: "Have savings to meet contingencies & retirement",
                check: totalSavings > 0,
                link: "/MyFinancials/Assets",
                unit:"currency",
            },
            {
                category: "Asset Management",
                name: "Growing Assets",
                amount: totalGrowingAssets,
                bestPractice: "Have assets that grow over time like savings, investment portfolio, real estate, etc.",
                check: totalGrowingAssets > 0,
                link: "/MyFinancials/Assets",
                unit:"currency",
            },
            {
                category: "Asset Management",
                name: "Diversified Assets",
                amount: assets.length,
                bestPractice: "Diversify assets across at least 3 asset classes",
                check: assets.length >= 3,
                link: "/MyFinancials/Assets",
                unit: "length",
            },
            {
                category: "Debt Management",
                name: "Credit Card Debt",
                amount: totalCreditCard,
                bestPractice: "Keep zero credit card debt",
                check: totalCreditCard === 0,
                link: "/MyFinancials/Liabilities",
                unit:"currency",
            },
            {
                category: "Debt Management",
                name: "Debt-to-Income Ratio",
                amount: liabilityStats?.totalMonthlyPayments / totalIncome?.m * 100,
                bestPractice: "Keep debt-to-income ratio under 30%",
                check: liabilityStats?.totalMonthlyPayments / totalIncome?.m < 0.3,
                link: "/MyFinancials/Liabilities",
                unit: "ratio",
            },
            {
                category: "Debt Management",
                name: "Liability Coverage",
                amount: totalLiabilities>0 ? totalAssets / totalLiabilities : undefined,
                bestPractice: "Ensure liabilities are covered by assets. Have a Liability Coverage greater than 1.5",
                check: totalLiabilities>0 ? totalAssets / totalLiabilities >= 1.5 : undefined,
                link: "/MyFinancials/Liabilities",
                unit: "ratio",
            },
            {
                category: "Cashflow Management",
                name: "Cash Coverage (Total Cash/Total Expense)",
                amount: totalCash / totalExpense?.m,
                bestPractice: "Have enough cash in hand or at bank to meet monthly expenses.",
                check: totalCash >= totalExpense?.m,
                link: "/MyFinancials/Assets",
                unit: "ratio",
            },
            {
                category: "Cashflow Management",
                name: "Positive Monthly Net Cashflow",
                amount: totalNetCashflow?.m,
                bestPractice: "Have a Positive Cashflow per Month.",
                check: totalNetCashflow?.m > 0,
                link: "/MyFinancials/Cashflow",
                unit:"currency",
            },
            {
                category: "Cashflow Management",
                name: "Minimum Net Cashflow (% of Income per Month)",
                amount: (1 - totalExpense?.m / totalIncome?.m) *100,
                bestPractice: "Have at least 5% of your income saved per month to contribute to savings, investments, or goals.",
                check: 1 - totalExpense?.m / totalIncome?.m > 0.05,
                link: "/MyFinancials/Cashflow",
                unit: "percent",
            },
            {
                category: "Cashflow Management",
                name: "Income Stability",
                amount: totalIncome?.m,
                bestPractice: "Maintain a periodic source of income.",
                check: totalIncome?.m > 0,
                link: "/MyFinancials/Cashflow",
                unit:"currency",
            },
            {
                category: "Expense Management",
                name: "Spending Rate Monthly",
                amount: (totalExpense?.m / totalIncome?.m) * 100,
                bestPractice: "Keep spending rate under 95% of income",
                check: totalExpense?.m / totalIncome?.m < 0.95,
                link: "/MyFinancials/Cashflow",
                unit: "percent",
            },
            {
                category: "Goal Planning",
                name: "Goal Success Rate Before Investment/Savings Withdrawal",
                amount: goalSuccessRateBeforeWithdrawal,
                bestPractice: "Achieve a goal success rate of > 80%",
                check: goalSuccessRateBeforeWithdrawal > 80,
                link: "/MyFinancials/Goals",
                unit: "percent",
            },
            {
                category: "Goal Planning",
                name: "Goal Success Rate After Investment/Savings Withdrawal",
                amount: goalSuccessRateAfterWithdrawal,
                bestPractice: "Achieve a goal success rate of > 90%",
                check: goalSuccessRateAfterWithdrawal > 90,
                link: "/MyFinancials/Goals",
                unit: "percent",
            },
            {
                category: "Risk Management",
                name: "Life Insurance Coverage",
                amount: totalInsurance / totalIncome?.y,
                bestPractice: "Ensure life insurance coverage is at least 7 to 10 times of your annual income to protect your dependents.",
                check: totalInsurance > totalIncome?.y * 8,
                link: "/MyFinancials/RiskManagement",
                unit: "ratio",
            },
            {
                category: "Crisis Management",
                name: "Emergency Fund",
                amount: totalCashSavings,
                bestPractice: "Have an emergency fund equivalent to 6-9 months of expenses.",
                check: totalCashSavings >= 6 * totalExpense?.m,
                link: "/MyFinancials/RiskManagement",
                unit:"currency",
            },
        ]
        setChecks(checkUpdate);
        const score = (checkUpdate.filter(item => item.check).length / checkUpdate.length) * 100;
        const notBestPractice = checkUpdate.filter(item => !item.check);
        dispatchMyFinancials({ financialHealthDetails: checkUpdate, financialHealthScore: score, financialHealthIssues: notBestPractice });
    },[assets, liabilities, totalGrowingAssets, totalCreditCard, 
        totalLiabilities,totalInsurance,totalSavings,totalCashSavings,
        goalAnalytics,liabilityStats,totalCash,totalAssets,totalIncome,totalNetCashflow,totalExpense,
        goalSuccessRateBeforeWithdrawal,
        goalSuccessRateAfterWithdrawal])
    
}

// const checks = useMemo(() => [
//     {
//         category: "Asset Management",
//         name: "Savings",
//         amount: totalSavings,
//         bestPractice: "Have savings to meet contingencies & retirement",
//         check: totalSavings > 0,
//         link: "/MyFinancials/Assets",
//         unit:"currency",
//     },
//     {
//         category: "Asset Management",
//         name: "Growing Assets",
//         amount: totalGrowingAssets,
//         bestPractice: "Have assets that grow over time like savings, investment portfolio, real estate, etc.",
//         check: totalGrowingAssets > 0,
//         link: "/MyFinancials/Assets",
//         unit:"currency",
//     },
//     {
//         category: "Asset Management",
//         name: "Diversified Assets",
//         amount: assets.length,
//         bestPractice: "Diversify assets across at least 3 asset classes",
//         check: assets.length >= 3,
//         link: "/MyFinancials/Assets",
//         unit: "length",
//     },
//     {
//         category: "Debt Management",
//         name: "Credit Card Debt",
//         amount: totalCreditCard,
//         bestPractice: "Keep zero credit card debt",
//         check: totalCreditCard === 0,
//         link: "/MyFinancials/Liabilities",
//         unit:"currency",
//     },
//     {
//         category: "Debt Management",
//         name: "Debt-to-Income Ratio",
//         amount: liabilityStats?.totalMonthlyPayments / totalIncome?.m * 100,
//         bestPractice: "Keep debt-to-income ratio under 30%",
//         check: liabilityStats?.totalMonthlyPayments / totalIncome?.m < 0.3,
//         link: "/MyFinancials/Liabilities",
//         unit: "ratio",
//     },
//     {
//         category: "Debt Management",
//         name: "Liability Coverage",
//         amount: totalAssets / totalLiabilities,
//         bestPractice: "Ensure liabilities are covered by assets. Have a Liability Coverage greater than 1.5",
//         check: totalAssets / totalLiabilities >= 1.5,
//         link: "/MyFinancials/Liabilities",
//         unit: "ratio",
//     },
//     {
//         category: "Cashflow Management",
//         name: "Cash Coverage (Total Cash/Total Expense)",
//         amount: totalCash / totalExpense?.m,
//         bestPractice: "Have enough cash in hand or at bank to meet monthly expenses.",
//         check: totalCash >= totalExpense?.m,
//         link: "/MyFinancials/Assets",
//         unit: "ratio",
//     },
//     {
//         category: "Cashflow Management",
//         name: "Positive Monthly Net Cashflow",
//         amount: totalNetCashflow?.m,
//         bestPractice: "Have a Positive Cashflow per Month.",
//         check: totalNetCashflow?.m > 0,
//         link: "/MyFinancials/Cashflow",
//         unit:"currency",
//     },
//     {
//         category: "Cashflow Management",
//         name: "Minimum Net Cashflow (% of Income per Month)",
//         amount: (1 - totalExpense?.m / totalIncome?.m) *100,
//         bestPractice: "Have at least 5% of your income saved per month to contribute to savings, investments, or goals.",
//         check: 1 - totalExpense?.m / totalIncome?.m > 0.05,
//         link: "/MyFinancials/Cashflow",
//         unit: "percent",
//     },
//     {
//         category: "Cashflow Management",
//         name: "Income Stability",
//         amount: totalIncome?.m,
//         bestPractice: "Maintain a periodic source of income.",
//         check: totalIncome?.m > 0,
//         link: "/MyFinancials/Cashflow",
//         unit:"currency",
//     },
//     {
//         category: "Expense Management",
//         name: "Spending Rate Monthly",
//         amount: (totalExpense?.m / totalIncome?.m) * 100,
//         bestPractice: "Keep spending rate under 95% of income",
//         check: totalExpense?.m / totalIncome?.m < 0.95,
//         link: "/MyFinancials/Cashflow",
//         unit: "percent",
//     },
//     {
//         category: "Goal Planning",
//         name: "Goal Success Rate Before Investment/Savings Withdrawal",
//         amount: goalSuccessRateBeforeWithdrawal,
//         bestPractice: "Achieve a goal success rate of > 80%",
//         check: goalSuccessRateBeforeWithdrawal > 80,
//         link: "/MyFinancials/Goals",
//         unit: "percent",
//     },
//     {
//         category: "Goal Planning",
//         name: "Goal Success Rate After Investment/Savings Withdrawal",
//         amount: goalSuccessRateAfterWithdrawal,
//         bestPractice: "Achieve a goal success rate of > 90%",
//         check: goalSuccessRateAfterWithdrawal > 90,
//         link: "/MyFinancials/Goals",
//         unit: "percent",
//     },
//     {
//         category: "Risk Management",
//         name: "Life Insurance Coverage",
//         amount: totalInsurance / totalIncome?.y,
//         bestPractice: "Ensure life insurance coverage is at least 7 to 10 times of your annual income to protect your dependents.",
//         check: totalInsurance > totalIncome?.y * 8,
//         link: "/MyFinancials/RiskManagement",
//         unit: "ratio",
//     },
//     {
//         category: "Crisis Management",
//         name: "Emergency Fund",
//         amount: totalCashSavings,
//         bestPractice: "Have an emergency fund equivalent to 6-9 months of expenses.",
//         check: totalCashSavings >= 6 * totalExpense?.m,
//         link: "/MyFinancials/RiskManagement",
//         unit:"currency",
//     },
// ], [assets, liabilities, summaryStats, goalAnalytics,liabilityStats]);
