import React, { useMemo, useState } from 'react';
import Modal from 'components/Modal/Modal';
import { Typography } from '@mui/material';
import { useAppInfo } from 'AppState';
import { dfin, assetCode } from 'content/constants';
import useRealTimePrice from 'data/eod/useRealTimePrice';
import PortfolioFitTicker from 'opportunities/PortfolioFitTicker';
import PageHeaderIcons from 'nav/PageHeaderIcons';
import Guidance from 'resource/guidance/Guidance';
import { numberDecimal } from 'utils/utilGeneral';
import CompanyESGButton from './stocks/Overview/CompanyESGButton';
import {WatchListAddButton} from 'watchlist/WatchListAddButton';

export default function TickerHeader({ticker}) {
  const { stateTicker,stateStock} = useAppInfo(); 
  const {tickerInfo} = stateTicker

  const tickerSelInfo = useMemo(() => {
    if (ticker) {
      const tickerSelInfo = stateTicker.tickerInfo.filter(
        obj =>
          obj[dfin.ticker] === ticker ||
          obj[dfin.tickerCode] === ticker ||
          obj[dfin.yfTicker] === ticker
      );
      return tickerSelInfo[0];
    } else {
      return stateTicker.tickerSelInfo;
    }
  }, [stateTicker.tickerInfo, stateTicker.tickerSelInfo, ticker]);
 
  const { price } = useRealTimePrice({ tickerSel: tickerSelInfo?.[dfin.tickerCode]});
  const tickerType = tickerSelInfo?.[dfin.tickerType]
 
  return (
    <>
    <div className='page_header_box'>


    <div
      style={{gap:"0.5rem",display:"flex",alignItems:"center",padding:"0 0.25rem",flex:1}}>
        
            <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
            {tickerSelInfo?.[dfin.coLogo] && (
                <a style={{display:"flex",alignItems:"center",justifyContent:"center"}} target="blank" href={tickerSelInfo?.[dfin.coWebsite]}>
                  <img
                    src={tickerSelInfo?.[dfin.coLogo]}
                    height={"35px"}
                    width={"35px"}
                  />
                </a>
              )}
            <Typography style={{fontWeight:"bold"
            ,overflow:"hidden",textOverflow:"ellipsis"
            ,whiteSpace:"nowrap",flex:1,maxWidth:"100%",fontSize:"1.2rem"}}>
              {tickerSelInfo?.[dfin.coName]} 
            </Typography>
            <Typography style={{fontWeight:"bold"
            ,overflow:"hidden",textOverflow:"ellipsis"
            ,whiteSpace:"nowrap",maxWidth:"100%",fontSize:"1.2rem"}}>
              ({tickerSelInfo?.[dfin.ticker]})
            </Typography>
           
            </div>
          
         {tickerType !==assetCode?.Forex && 
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 5,
              marginLeft:"0.5rem"
            }}
          >
            {/* <div>Ask:Bid</div> */}

            <Typography style={{fontSize:"1rem",fontWeight:"bold"}}>{numberDecimal(price?.close)}</Typography>

            <Typography
              style={{
                color: price?.change > 0 ? "green" : "red",
                display: "flex",
                gap: "0.2rem",
                fontSize:"0.9rem",fontWeight:"bold",whiteSpace:"nowrap"
              }}
            >
              {price?.change > 0 ? "▲" : "▼"} {numberDecimal(price?.change)} (
              {numberDecimal(price?.change_p)}%)
            </Typography>
          </div>}

                      
            {[assetCode?.Stocks].includes(tickerType) && 
            <CompanyESGButton coInfo={tickerSelInfo}/>
            }

<PortfolioFitTicker/>

{!["Forex"].includes(tickerType) &&
          <div style={{paddingLeft:"1rem"}}>
            {/* <InfoIcon/> */}
            <WatchListAddButton tickerName={stateTicker.tickerSelected}/>
          </div>}
          </div>

    <div style={{display:"flex",alignItems:"center",gap:"1rem",paddingRight:"1rem"}}>
   
    {/* <PageHeaderIcons name="Explore"/> */}
    {/* <PageHeaderIcons name="Guide" guideType={"Ticker"}/> */}
    </div>
    </div>
    </>
  );
}
