import { fetchFinancialProducts } from 'data/financialProducts/useFinancialProducts'
import { getWebSearchTavily } from 'data/searchWeb/SearchTavily'
import { FinancialProductInfo } from 'financialProducts/info'
import React from 'react'

export default function useAssistantFinancialProduct() {
    
    async function GetFinancialProduct({product_type,country,message}){
        const data = await fetchFinancialProducts(product_type)
        const financialProductSel = FinancialProductInfo.filter(obj=>obj.productName===product_type)[0]
        const webSearch = await getWebSearchTavily(message)
        if (country === "USA"){
            return {financialProductSel,data,webSearch}}
        
        return {financialProductSel,webSearch}
      }

    return {GetFinancialProduct}
}
