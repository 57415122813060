import React, { useEffect, useState } from 'react'
import LeftSidebarButton from 'sidebar/LeftSidebar/LeftSidebarButton'
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating'
import TickerCommonInfoList from './TickerCommonInfoList'
import { Paper } from '@mui/material'
import StocksInfoList from './StocksInfoList'
import { useAppInfo } from 'AppState'
import { assetCode } from 'content/constants'

export default function TickerVsPeers({contentType="All",infoType}) {
    const { stateTicker } = useAppInfo()
    const { tickerType } = stateTicker
    const [infoSel, setInfoSel] = useState("Price vs Peers")
    const [contentList, setContentList] = useState({
        "Price vs Peers": <TickerCommonInfoList info={"Price vs Peers"} />,
        "Performance": <TickerCommonInfoList info={"Performance"} />,
        "Volatility": <TickerCommonInfoList info={"Volatility"} />,
        "Correlation": <TickerCommonInfoList info={"Correlation"} />,
        "Beta": <TickerCommonInfoList info={"Beta"} />,
        "Indicators": <TickerCommonInfoList info={"Indicators"} />,
    })

    const contentStock = {
        "Stats vs Peers": <StocksInfoList info={"Stats vs Peers"} />,
        "Scatter": <StocksInfoList info={"Scatter"} />,
        "Financials vs Peers": <StocksInfoList info={"Financials vs Peers"} />,
    }

    useEffect(()=>{
      if (infoType) {
        setInfoSel(infoType)
      }
    },[infoType])

    useEffect(() => {
        if (tickerType === assetCode?.Stocks) {
            setContentList(prevContent => ({
                ...prevContent,
                ...contentStock
            }))
        }
    }, [tickerType])

    return (
        <div style={{height:"90vh",width:"100%"}}>

        
        <div className='containerLeftMenuAndContent'>
            {contentType === "All" && 
            <LeftSidebarFloating header={"Information"}>
            <div style={{display:"flex",flexDirection:"column"}}>
                {Object.keys(contentList).map((info, key) => (
                    <LeftSidebarButton
                        key={key}
                        selected={infoSel === info}
                        name={info}
                        onClick={() => setInfoSel(info)}
                    />
                ))}
            </div>
            </LeftSidebarFloating>}

            <Paper className='containerMainContent'>
                {contentList?.[infoSel]}
            </Paper>
        </div>
        </div>
    )
}

