import AccordionTSCustom from 'components/Surface/AccordionTSCustom'
import ReinvestingBenefits from 'myFinancials/Asset/ReinvestingBenefits'
import NetCashAllocation from 'myFinancials/Cashflow/NetCashAllocation'
import LiabilityDetails from 'myFinancials/Liability/LiabilityDetails'
import PortfolioInfoList from 'myFinancials/Portfolio/PortfolioInfoList'
import PortfolioOverview from 'myFinancials/Portfolio/PortfolioOverview'
import React from 'react'

export default function NetworthStepsToGrow() {
  return (
    <div style={{flex:1,minWidth:"400px",padding:"0.25rem",display:"flex",flexDirection:"column",gap:"1rem"}}>
    <div sx={{display:"flex",flexDirection:"column",gap:"0.5rem",height:"auto"}}>
    <h3 style={{fontWeight:"bold",color:"green",padding:"0.5rem"}}>Steps to grow your Networth</h3>
    <AccordionTSCustom title={"1. Allocate Net Cash periodically to build long-term assets"}>
      <NetCashAllocation/>

    </AccordionTSCustom>
    <AccordionTSCustom title={"2. Reinvest returns on savings & investments"}>
      <ReinvestingBenefits/>
    </AccordionTSCustom>
    <AccordionTSCustom title={"3. Build & Manage Portfolio that are diversified and meet your risk profile & goals"}>
      <PortfolioInfoList menu={"Overview"}/>
    </AccordionTSCustom>
    <AccordionTSCustom title={"4. Manage Liabilities"}>
      <LiabilityDetails/>
    </AccordionTSCustom>
    </div>
    
    </div>
  )
}
