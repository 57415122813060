
import React, { useEffect, useState } from "react";
import { useAppInfo } from "AppState";
import { Alert, Badge, Typography } from "@mui/material";
import ProfileLogo from "components/Logo/ProfileLogo";
import { Close } from "@mui/icons-material";
import { collection, doc, getDoc, getDocs, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebaseConfig';
import useOnboardingMessage from "./useOnboardingMessage";

export default function ChatNotfication () {
 
  const { dispatchChats,stateApp,stateChats,dispatchSidebar} = useAppInfo();
  const {userDetails} = stateApp 
  const {chatId,unreadChatIds,unreadRecentChat,chatHomeNotification} = stateChats

  useEffect(()=>{
    updateUserChat(userDetails)
  },[userDetails])

  useOnboardingMessage({stateApp})


  const handleSelect = (u) => {
    let chatId = 
      userDetails?.uid > u?.uid
        ? userDetails.uid + u.uid
        : u.uid + userDetails.uid

        dispatchSidebar({sidebarOpen: true,
          sidebarRightContent: "chat",})

        dispatchChats({
          user: u,
          chatId: chatId,
          chatting: true,
          chatHomeNotification: false
      });
  };


useEffect(() => {
  // Defines a function to fetch unread chats from Firebase.
  const getUnreadChat = () => {
    // Subscribe to changes in the user's chat data.
    const unsubscribe = onSnapshot(doc(db, "userChats", userDetails.uid), (doc) => {
      const data = doc.data(); // Fetch data from the document snapshot.
      
      let unreadCounter = 0; // Initialize a counter for unread messages.
      let unreadChatIdUpdate = {}; // Initialize an object to store unread counts by chat ID.
      let unreadRecentChat = {}; // Initialize an object to store chat details for unread messages.
      
      // Check if there is any data received.
      if (data) {
        // Sort the chats based on the timestamp of the last message (newest first).
        const sortedChats = Object.keys(data).sort((a, b) => data[b].lastMessage?.date?.toDate() - data[a].lastMessage?.date?.toDate());
        
        sortedChats.slice(0,1).forEach(chatKey => {
          const unreadCount = data[chatKey]?.unreadCount; // Get the unread count for this chat.
          
          if (unreadCount > 0) {
            const unread = data[chatKey]; 
            unreadRecentChat[chatKey] = unread;
          }
        });

        sortedChats.forEach(chatKey => {
          const unreadCount = data[chatKey]?.unreadCount; // Get the unread count for this chat.
          
          // Check if there are any unread messages.
          if (unreadCount > 0) {
            unreadChatIdUpdate[chatKey] = unreadCount; // Update the unread count for this chat ID.
            unreadCounter += unreadCount; // Increment the total unread counter.
          }
        });
      }
      
      // If there are unread messages, trigger a notification in the sidebar.
      if (unreadCounter > 0) {
        dispatchChats({"chatHomeNotification":true});
      }
      
      // Update the state with the latest counts and details for unread messages.
      dispatchChats({"unreadChatIds":unreadChatIdUpdate,"unreadChatCount":unreadCounter,"unreadRecentChat":unreadRecentChat});
    });
    
    // Return a cleanup function to unsubscribe from the Firebase listener.
    return () => unsubscribe();
  };

  // Execute the getUnreadChat function if a userDetails is defined.
  if (userDetails?.uid) {
    getUnreadChat();
  }

}, [userDetails]); // This effect depends on userDetails.

  const [mouseEnter,setMouseEnter] = useState(false)
  return (
    <>
    {(Object.entries(unreadRecentChat).length>0 && chatHomeNotification) &&  <div style={{display:"flex",gap:"0.25rem"
    ,flexDirection:"column",position:"absolute"
    ,bottom:"10px",right:"60px",zIndex:10000,background:"var(--bgWoodSmoke)",borderRadius:"4px",padding:"0.25rem"}}>
      <>
      <div style={{display:"flex",gap:"0.5rem",color:"white"}}>
        <Typography>New Message</Typography> 
        <Close sx={{marginLeft:"auto",cursor:"pointer"}} fontSize="small" onClick={()=>dispatchChats({"chatHomeNotification":false})}/></div>
      {Object.entries(unreadRecentChat).map((chat) => (
        <div
         onMouseEnter={()=>setMouseEnter(true)}
         onMouseLeave={()=>setMouseEnter(false)}
         style={{cursor:"pointer",display:"flex",gap:"0.5rem"
         ,padding:"0.75rem 0.25rem 0.25rem 0.75rem",borderRadius:"5px",maxWidth:"100%"
         ,overflow:"hidden",border:mouseEnter ? "2px solid var(--selectBlue)":"2px solid transparent",boxSizing:"border-box"}}
          key={chat[0]}
          onClick={() => {handleSelect(chat[1].userInfo)}}
        >
          
          
          {/* <img style={{width:"40px",height:"40px",borderRadius:"50%",objectFit:"contain"}} src={chat[1].userInfo?.photoURL} alt="" /> */}
          <Badge badgeContent={unreadChatIds[chat[0]]} 
          overlap="circular"
          color="primary" anchorOrigin={{
    vertical: 'top',
    horizontal: 'left',
        }}>
          <ProfileLogo profileType="nonOwner" imgSrc={chat[1].userInfo?.photoURL} displayName={chat[1].userInfo?.displayName}/>
          </Badge>
          
          <div style={{display:"flex",flexDirection:"column",flex:1}}>
            <h4 style={{fontWeight:"bold",color:"white"}}>{chat[1].userInfo?.displayName}</h4>
            <h5 style={{whiteSpace:"nowrap",color:"white",textOverflow:"ellipsis",overflow:"hidden",width:"200px"}}>{chat[1].lastMessage?.text}</h5>
          </div>
          
        </div>
      ))}
      </>
      
    </div>}
    </>

  );
};


const updateUserChat = async (userDetails) => {
  try {
   
     const userChatsDocRef = doc(db, "userChats", userDetails.uid);
     const docUserChats = await getDoc(userChatsDocRef);
     if (!docUserChats.exists()){
       // Also create an empty document for user chats
       await setDoc(doc(db, "userChats", userDetails.uid), {});
     }
  } catch (error) {
    console.error(error);
  }
};



async function updateAllUserChats() {
    const usersRef = collection(db, "users");
  
    try {
      const snapshot = await getDocs(usersRef);
      if (snapshot.empty) {
        console.log('No matching documents.');
        return;
      }
      console.log(snapshot)
      snapshot.docs.forEach(async (docu) => {
        const uid = docu.id; // The document ID is assumed to be the user's uid
        const userChatsRef = doc(db, 'userChats', uid);
        
        // Update userChats document for each user
        try {
          await setDoc(userChatsRef, {}); // Using setDoc with merge:true to effectively "update" or clear fields
          console.log(`Updated userChats for UID: ${uid}`);
        } catch (error) {
          console.error(`Error updating userChats for UID: ${uid}`, error);
        }
      });
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }
  