import { useEffect, useState} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";
import useAddPortfolio from "./useAddPortfolio";

const useFindPortfolio = () => {
  const {dispatchPortfolio,stateApp,stateMyFinancials,statePortfolio} = useAppInfo()
  const userId = stateApp.userId
  const {financialView} = stateMyFinancials
  const {portfolioLoaded,portfolioDefault,portfolioDefaultLoaded,portfolio} = statePortfolio
  const {addPortfolio} = useAddPortfolio()

  const collectionName = financialView === "personal" ? "Portfolio" : "DemoPortfolio"

  const findPortfolio = async () => {
    dispatchPortfolio({ portfolioLoaded: false});
    try {
      if (userId) {
        const response = await axios.post(`${BASE_URL}/findPortfolio/`, {
          userId,
          collectionName
        });
        const portfolio = response.data;
        const accountNames = Object.keys(portfolio);

      
        if (portfolio && accountNames.length > 0) {
          const portfolioSel = portfolio?.[accountNames[0]];
          dispatchPortfolio({
            portfolio: portfolio,
            portfolioSel: portfolioSel,
            accountNameSel: accountNames[0],
            accountNames: accountNames,portfolioLoaded:true
          });
        }
        else{
          if (financialView === "personal"){
          dispatchPortfolio({
            portfolio:{},
            accountNameSel:undefined,
            accountNames:[],
            portfolioSel:[],portfolioLoaded:true});}
            if (financialView !== "personal"){
              dispatchPortfolio({
                portfolio: { "Default": portfolioDefault },
                portfolioSel: portfolioDefault,
                accountNameSel: "Default",
                accountNames: ["Default"],
                portfolioLoaded:true
              });
              addPortfolio("Default",portfolioDefault)
            }
        }
      }
    } catch (err) {
      if (err.response && err.response.data.error === 'No data found') {
        if (financialView === "personal"){
        dispatchPortfolio({
          portfolio:{},
          accountNameSel:undefined,
          accountNames:[],
          portfolioSel:[]});}
          if (financialView !== "personal"){
            dispatchPortfolio({
              portfolio: { "Default": portfolioDefault },
              portfolioSel: portfolioDefault,
              accountNameSel: "Default",
              accountNames: ["Default"],
            });
          addPortfolio("Default",portfolioDefault)}
      } else {
        console.log('Error', err);
        // dispatchMyFinancials({'noDataFound':true});
      }
      dispatchPortfolio({ portfolioLoaded: true });
    }

    
  };

  useEffect(() => {
    if (userId && portfolioDefaultLoaded && financialView === "personal") {
      findPortfolio();
    }
  }, [userId,financialView,portfolioDefault,portfolioDefaultLoaded]);


  return null;
};

export default useFindPortfolio;

