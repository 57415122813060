import React from 'react';
import { Box, Typography, Paper, Avatar, Rating, Stack, Divider, TextField, IconButton, Select, MenuItem, Grid, Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';

const reviews = [
  {
    star: 5,
    comment: "John provided exceptional advice for my retirement planning.",
    location: "New York, NY",
    date: "2024-10-22",
    user: "mem94566",
    country: "United States",
    price: "CA$75",
    duration: "2 days"
  },
  {
    star: 4,
    comment: "Very knowledgeable and helpful.",
    location: "Jersey City, NJ",
    date: "2024-09-15",
    user: "user12345",
    country: "Canada",
    price: "CA$50",
    duration: "3 days"
  }
];

// Simulated average ratings and star counts for demonstration
const ratingSummary = {
  averageRating: 4.8,
  totalReviews: 16,
  starCounts: { 5: 15, 4: 0, 3: 1, 2: 0, 1: 0 },
  categoryRatings: [
    { label: 'Communication Level', rating: 4.6 },
    { label: 'Quality of delivery', rating: 4.8 },
    { label: 'Value of delivery', rating: 4.9 }
  ]
};

const Reviews = () => {
  return (
    <Box sx={{ p: 3,}}>
      <Typography variant="h6" gutterBottom>
        Reviews
      </Typography>
      <Typography variant="subtitle1">{ratingSummary.totalReviews} reviews for this Service</Typography>

      {/* Rating Summary */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6}>
          {Object.entries(ratingSummary.starCounts).map(([star, count]) => (
            <Box key={star} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body2" sx={{ width: 50 }}>{star} Stars</Typography>
              <Box sx={{ width: '100%', bgcolor: 'grey.300', borderRadius: 1, overflow: 'hidden', mx: 1 }}>
                <Box sx={{ width: `${(count / ratingSummary.totalReviews) * 100}%`, bgcolor: 'gold', height: 8 }} />
              </Box>
              <Typography variant="body2">({count})</Typography>
            </Box>
          ))}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Rating Breakdown</Typography>
          {ratingSummary.categoryRatings.map((category, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body2" sx={{ flex: 1 }}>{category.label}</Typography>
              <Rating
                value={category.rating}
                readOnly
                precision={0.1}
                sx={{ color: 'gold' }}
                icon={<StarIcon fontSize="small" />}
                emptyIcon={<StarIcon fontSize="small" sx={{ color: 'grey.300' }} />}
              />
              <Typography variant="body2" sx={{ ml: 1 }}>{category.rating.toFixed(1)}</Typography>
            </Box>
          ))}
        </Grid>
      </Grid>

      {/* Search and Sort */}
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Search reviews"
          size="small"
          sx={{ flex: 1 }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            )
          }}
        />
        <Select defaultValue="Most relevant" size="small" sx={{ ml: 2 }}>
          <MenuItem value="Most relevant">Most relevant</MenuItem>
          <MenuItem value="Newest">Newest</MenuItem>
          <MenuItem value="Highest rating">Highest rating</MenuItem>
          <MenuItem value="Lowest rating">Lowest rating</MenuItem>
        </Select>
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* Individual Reviews */}
      {reviews.map((review, index) => (
        <Paper key={index} sx={{ p: 2, mb: 2 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar sx={{ bgcolor: 'primary.main' }}>{review.user.charAt(0)}</Avatar>
            <Box>
              <Typography variant="body1" fontWeight="bold">{review.user}</Typography>
              <Typography variant="caption" color="textSecondary">{review.country}</Typography>
            </Box>
          </Stack>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Rating
              value={review.star}
              readOnly
              precision={0.1}
              sx={{ color: 'gold' }}
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarIcon fontSize="inherit" sx={{ color: 'grey.300' }} />}
            />
            <Typography variant="body2" sx={{ ml: 1 }}>{review.star.toFixed(1)}</Typography>
            <Typography variant="caption" color="textSecondary" sx={{ ml: 2 }}>{new Date(review.date).toLocaleDateString()}</Typography>
          </Box>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {review.comment}
          </Typography>
          <Box sx={{ display: 'flex', mt: 1, color: 'text.secondary' }}>
            <Typography variant="caption" sx={{ mr: 2 }}>Up to {review.price}</Typography>
            <Typography variant="caption">Duration: {review.duration}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Typography variant="caption" color="text.secondary">Helpful?</Typography>
            <Button size="small" sx={{ ml: 1 }}>Yes</Button>
            <Button size="small">No</Button>
          </Box>
        </Paper>
      ))}
    </Box>
  );
};

export default Reviews;
