import useFindFinancials from 'data/user/financials/useFindFinancials';
import React, { useEffect } from 'react'
import useMyFinancialsDetails from './useMyFinancialsDetails';
import useAddFinancials from 'data/user/financials/useAddFinancials';
import { useAppInfo } from 'AppState';
import { initializeFinancials, initializeFinancialsNoData } from 'appState/initialStateMyFinancials';
import useFindDefaultFinancials from 'data/user/financials/useFindDefaultFinancials';
import { UpdateAssetWithPortfolio } from './useSetPortfolio';

export default function useSetMyFinancials() {
    const {dispatchMyFinancials,stateMyFinancials,statePortfolio} = useAppInfo()
    const {portfolioResults} = statePortfolio
    const {noDataFound,financialsLoaded,financialView,demoProfile} = stateMyFinancials
    const { addFinancials, error } = useAddFinancials();
    useFindFinancials()
    useMyFinancialsDetails()

    const {data:defaultFinancials} = useFindDefaultFinancials()

    // console.log("DEMO",portfolioResults)
    // useEffect(() => {
    //   if (defaultFinancials && financialView !== "personal"){
    //     Object.entries(defaultFinancials).map(([name,value])=>{
    //       {!["_id","userId","username","dummy_name"].includes(name) 
    //           && dispatchMyFinancials({[name]:value });
    //       }
    //   })
    //   const assets = defaultFinancials?.assets
    //   const assetUpdate = UpdateAssetWithPortfolio({portfolioResults,assets})
    //   dispatchMyFinancials({assets:assetUpdate})
    //   }
    // },[financialView,defaultFinancials,portfolioResults])


    useEffect(() => {
      if (financialsLoaded && defaultFinancials && financialView !== "personal"){
        // const initialData = initializeFinancials;
        let initialData = defaultFinancials
        // const initialData = defaultFinancials
        Object.entries(initialData).forEach(([name, value], index) => {
          dispatchMyFinancials({[name]:value});
        });
        dispatchMyFinancials({'financials':initialData});
        // Object.entries(initialData).forEach(([name,value],index)=>{
        //   setTimeout(()=>{addFinancials(value, name)},500*index);
        // })        
      }

      if (financialsLoaded && noDataFound && financialView === "personal") {
        let initialData = initializeFinancialsNoData
        Object.entries(initialData).forEach(([name, value], index) => {
          dispatchMyFinancials({[name]:value});
        });
        dispatchMyFinancials({'financials':initialData});
         Object.entries(initialData).forEach(([name,value],index)=>{
          setTimeout(()=>{addFinancials(value, name)},500*index);
        })        
      }
    }, [noDataFound,financialsLoaded,financialView,defaultFinancials,demoProfile]);
  
}
