import { useAppInfo } from 'AppState'
import CircleScore from 'components/Charts/CircleScore'
import React from 'react'
import { formatNumber } from 'utils/utilGeneral'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { Button, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FinancialAdvisorConnect from './FinancialAdvisorConnect';

export default function MyFinancialHealthScore({displayType="detailed"}) {
 const {stateMyFinancials} = useAppInfo()
 const {financialHealthScore:score,financialHealthIssues} = stateMyFinancials
 const navigate = useNavigate()
 
  return (
    <>
    {displayType === "icon" && 
    <Tooltip title="Financial Health">
     <div onClick={()=>navigate("/MyFinancials/FinancialHealth")} style={{display:"flex",gap:"1rem",padding:"0"
                ,borderRadius:"5px",alignItems:"center"}}>
                     <Button
    sx={{
      minWidth:"0",marginLeft:"auto",padding:0
      ,flexDirection:"column",textTransform:"none",color:"green"}}>
      <div style={{display:"flex"}}> <HealthAndSafetyIcon/></div>
      
      <span style={{fontSize:"0.8rem"}}>
      Health: {`${formatNumber(score)}%`}</span>
            </Button>          
            </div></Tooltip>}

     {displayType === "overview" && 
     <div style={{display:"flex",gap:"1rem",background:"#fafafa",padding:"0.5rem"
                ,borderRadius:"5px",alignItems:"center"}}>
                <CircleScore width='60px' height='60px' totalScore={100} score={score} />
                <div>
                <h4 style={{fontWeight:"bold"}}>Financial Health Score: {`${formatNumber(score)}%`}</h4>
                
                </div>           
            </div>}

    
    {displayType === "detailed" && 
     <div style={{display:"flex",gap:"1rem",background:"#fafafa",padding:"0.5rem"
                ,borderRadius:"5px",alignItems:"center"}}>
                <CircleScore totalScore={100} score={score} />
                <div>
                <h4 style={{ fontWeight: "bold" }}>
  Your Financial Health Score is {`${formatNumber(score)}%`}
</h4>

{financialHealthIssues.length > 0 && (
  <>
    {score > 80 && (
      <h5>
        You can improve your financials by addressing the following best practices:
      </h5>
    )}
    {score <= 80 && score > 60 && (
      <h5>
        You are mostly doing good; however, here are some issues that, once resolved, can make your financial health better:
      </h5>
    )}
    {score <= 60 && (
      <h5>
        There is a lot of room for improvement. Below are some major issues:
      </h5>
    )}
    {/* {financialHealthIssues.map((obj, index) => (
      <h5 key={index}>{obj?.bestPractice}</h5>
    ))} */}
  </>
)}
{/* <FinancialAdvisorConnect/> */}
                </div>           
            </div>}
    </>
  )
}
