import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { DragAndDropMedia, InputFileMedia } from 'components/Upload/UploadMedia';
import { Button, IconButton, LinearProgress, Typography } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import LoadingSkeletonPage from 'components/Loading/LoadingSkeletonPage';
import { deleteMediaFile } from 'components/Upload/processMedia';
import { Delete } from '@mui/icons-material';

const MarketingMedia = ({ onUploadSuccess,handleDeleteFile}) => {
  const [mediaItems, setMediaItems] = useState([]); // Stores each media box info
 const [progress, setProgress] = useState(0);

  const handleFileData = (file) => {
    // Add new media item for each selected file with initial preview URL and type
   
    setMediaItems(prevItems => [
      ...prevItems,
      { 
        id: file.name, // Unique identifier
        previewUrl: file.url, 
        fileType: file.type,
      }
    ]);
  };

  const handleProgress = (file) => {
    const progress = file.percent;
    setMediaItems(prevItems =>
      prevItems.map(item =>
        item.id === file.name ? { ...item, progress } : item
      )
    );
  };

  const handleUploadSuccess = (file) => {
    const url = file?.url;
    setMediaItems(prevItems =>
      prevItems.map(item =>
        item.id === file?.name ? { ...item, uploadedUrl: url, progress: 100 } : item
      )
    );
    onUploadSuccess && onUploadSuccess({url,fileType:file.type});
  };

  function handleDeleteSuccess(v) {
  }
  function handleDelete(item) {
    setMediaItems(prevItems => prevItems.filter(mediaItem => mediaItem.id !== item.id));
    deleteMediaFile({fileUrl:item.uploadedUrl,previewUrl:item.previewUrl,onSuccess:handleDeleteSuccess})
    handleDeleteFile && handleDeleteFile(item.uploadedUrl)
  }

  return (

    <div style={{width:"100%",minHeight:"200px",boxSizing:"border-box"}}>
        <DragAndDropMedia
        handleUrl={handleUploadSuccess}
        handleFileData={handleFileData}
        handleProgress={handleProgress}
        acceptedFileTypes={['image', 'video']}
        mediaFolder="user">
      <div
        style={{display:"flex",flexWrap:"wrap",alignItems:"center"
            ,justifyContent:"center",border:"2px dashed #ddd",gap:"2rem"
            ,width:"100%",height:"100%",boxSizing: 'border-box',padding:"2rem"}}
      >
        {/* Render each media box */}
        {mediaItems.map((item, index) => (
          <Box key={index} style={{ position: 'relative' }} width={150} height={150} 
          borderRadius="8px" overflow="hidden">
            {/* Preview based on file type */}
            {item?.fileType && item?.fileType.startsWith('image') ? (
              <img src={item.previewUrl} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            ) : (
              <video src={item.previewUrl} style={{ width: '100%', height: '100%', objectFit: 'cover' }} controls />
            )}
            {item?.progress === 100 && <Button style={{ position: 'absolute', top: 0, right: 0,zIndex:100}} 
            onClick={()=>handleDelete(item)}>
                <Delete/>
                </Button>}
            
            {/* Overlay for upload progress */}
            {item.progress < 100 && (
              <div
                style={{ backdropFilter: 'blur(4px)'
                    ,background:"rgba(0, 0, 0, 0.05)",zIndex:1000,position:"absolute",top:0,left:0,width:"100%",height:"100%"}}
              >
                <LoadingSkeletonPage/>
                <CircularProgress variant="determinate" value={item.progress} />
              </div>
            )}
          </Box>
        ))}

        {/* Add New Media Button */}
        
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={150}
          height={150}
          bgcolor="#e0e0e0"
          borderRadius="8px"
          sx={{ cursor: 'pointer', transition: 'background 0.3s', '&:hover': { bgcolor: '#ccc' } }}
        >
        <Button sx={{height:"100%",width:"100%",display:"flex"
            ,flexDirection:"column",alignItems:"center",justifyContent:"center",position:"relative",cursor: 'pointer', }} role={undefined} component="label" color="primary">
         <InputFileMedia
          handleUrl={handleUploadSuccess}
          handleFileData={handleFileData}
          handleProgress={handleProgress}
          acceptedFileTypes={['image', 'video']}
          mediaFolder="user"
         />     
          
            <AddPhotoAlternateIcon fontSize="large" />
          
          <Typography variant="caption" color="textSecondary">
            Add Media
          </Typography>
          </Button>
        </Box>
      </div>

      {/* Hidden UploadMedia Component */}
     
            
      </DragAndDropMedia>
    </div>
  );
};

export default MarketingMedia;
