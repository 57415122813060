import { useAppInfo } from 'AppState';


export default function useAssistantPortfolioManager() {
        const {stateMyFinancials,statePortfolio} = useAppInfo()

        function GetPortfolioSummary() {
            const {portfolioResults} = statePortfolio
            const {investmentPreference} = stateMyFinancials

            const info =  [
              portfolioResults,investmentPreference,{"react_component":["PortfolioValue","PortfolioCurrentPosition"]}
          ]

            return info
          }
    
          async function GetPortfolioValue() {
            const {portfolioResults,portfolioTotal,portfolioValue} = statePortfolio
            const portfolioValue1y = portfolioValue?.slice(0,300)
            const info =  [
              portfolioTotal,portfolioResults,{"react_component":["PortfolioValue"]},
              {"learn_more":["/MyFinancials/Portfolio"]}
          ]
       
            return info
          }

             
          async function GetPortfolioHoldings() {
            const {portfolioResults} = statePortfolio
          
            const info =  [
              portfolioResults,{"react_component":"PortfolioCurrentPosition","learn_more":["/MyFinancials/Portfolio"]}
          ]

            return info
          }

          async function GetPortfolioAssetAllocation() {
            const {portfolioResults} = statePortfolio
            const {investmentPreference} = stateMyFinancials

            return [{"react_component":["PortfolioAssetAllocation"]},portfolioResults,investmentPreference,
              {"learn_more":["/MyFinancials/Portfolio"]}]
          }

          async function GetPortfolioVsBenchmark() {

            const info =  [{"react_component":["PortfolioVsBenchmark"],"learn_more":["/MyFinancials/Portfolio"]}]

            return info
          }
        
        function GetPortfolioCharts ({component}){
            const infoOptions = {"Portfolio Value":"PortfolioValue","Portfolio Current Position":"PortfolioCurrentPosition",
                "Portfolio Asset Allocation":"PortfolioAssetAllocation"}

                const filteredOptions = Object.keys(infoOptions)
                .filter(key => component.includes(key))  // Filter keys based on presence in the component array
                .reduce((acc, key) => {
                    acc[key] = infoOptions[key];  // Build the filtered object
                    return acc;
                }, {});

                return filteredOptions
        }
        return {
          GetPortfolioSummary,GetPortfolioValue,GetPortfolioHoldings,GetPortfolioAssetAllocation,
          GetPortfolioVsBenchmark,GetPortfolioCharts
          }
        
}
