import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import { DragAndDropMedia, InputFileMedia } from 'components/Upload/UploadMedia';
import { Button, CircularProgress } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { deleteMediaFile } from 'components/Upload/processMedia';
import Modal from 'components/Modal/Modal';

const ProfilePictureUpload = ({ onUploadSuccess,profilePicture}) => {
  const [img,setImg] = useState(null);
  const [progress, setProgress] = useState(0);
  const handleFileData = (file) => {
    if (img !== file?.url) {
      deleteMediaFile({previewUrl:img});
    }
    setImg(file?.url);
  }
  const handleUploadSuccess = (file) => {
    const url = file?.url;
    
    if (img !== url) {
      deleteMediaFile({fileUrl:img});
    }
    setImg(url);
    onUploadSuccess && onUploadSuccess(url);
  };
  const [modal,setModal] = useState(false);
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
    <Avatar onClick={()=>{setModal(true)}}  src={profilePicture || img} sx={{ width: 100, height: 100 }}>
      <IconButton sx={{height:"100%",width:"100%"}}>
        <AccountCircleIcon/>
        <AddIcon/>
      <InputFileMedia
      handleFileData={handleFileData}
          handleUrl={handleUploadSuccess}
           handleProgress={(v)=>{setProgress(v?.percent)}}
           acceptedFileTypes={['image']}
           mediaFolder="user"/>
      </IconButton>
    </Avatar>
    {modal && <Modal onClose={()=>setModal(false)} width='auto' height='auto' 
    sx={{height:"auto",width:"auto",maxHeight:"500px",padding:0}}>
      <div style={{background:"var(--bgWoodSmoke)",padding:"1rem",overflow:"auto",display:"flex",flexDirection:"column",gap:"1rem",minHeight:"300px",minWidth:"400px"
        ,justifyContent:"center",alignItems:"center"}}>
      
      <Avatar src={img} sx={{ width: 200, height: 200 }}></Avatar>
      <Button variant="contained" component="label" sx={{width:"max-content"}} role={undefined}>
      Upload Profle Picture
    <InputFileMedia
      handleFileData={handleFileData}
          handleUrl={handleUploadSuccess}
           handleProgress={(v)=>{setProgress(v?.percent)}}
           acceptedFileTypes={['image']}
           mediaFolder="user"/>
    </Button></div>
      </Modal>}
   
    </div>
  );
};

export default ProfilePictureUpload;


   // <div style={{width:"100px",height:"100px",borderRadius:"50%",background:"#ddd"}}>
    // <DragAndDropMedia
    //   handleUrl={handleUploadSuccess}
    //   handleFileData={handleFileData}
    //   handleProgress={(v)=>{setProgress(v?.percent)}}
    //   acceptedFileTypes={['image']}
    //   mediaFolder="user"
    //   buttonContent={(
    //     <Avatar src={img} sx={{ width: 100, height: 100 ,position:"relative",backgroundColor:"#ddd",zIndex:100}}>
    //       {(img && progress < 100) && <div style={{position:"absolute",top:0,width:"100%",height:"100%",zIndex:100}}>
    //         <CircularProgress/>
    //         </div>}
    //       <IconButton
    //         component="label"
    //         role={undefined}
    //         style={{
    //           position: 'absolute',
    //           bottom: 0,
    //           right: 0,
    //           backgroundColor: '#fff',
    //           borderRadius: '50%',
    //           height:"100%",width:"100%",zIndex:100
    //         }}
    //       >

    //         <AddIcon color="primary" />
    //       </IconButton>
    //     </Avatar>
    //   )}
    // />
    // </div>