import Modal from 'components/Modal/Modal';
import React from 'react';

const FinancialProductModal = ({ card,closeModal}) => {
  if (!card) return <p>Loading...</p>;
  return (
    <Modal onClose={closeModal}  width="80%" height="80%" sx={{maxWidth:"400px",borderRadius:"5px",padding:0}}>
    <div style={{ display: 'flex', flexDirection:"column", gap: '20px',width:"100%",height:"100%",overflow:"auto"}}>
        <div
          style={{
            // border: '1px solid #e0e0e0',
            borderRadius: '8px',
            // padding: '20px',
            // maxWidth: '400px',
            width: '100%',
            // marginBottom: '20px',
            boxSizing:"border-box",
            padding:"1rem",
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          {/* Card Image */}
          <div style={{ textAlign: 'center' }}>
            <img src={card["Image URL"]} alt={`${card["Card Title"]} Image`} style={{ maxWidth: '100%', borderRadius: '8px' }} />
          </div>

          <h2 style={{ textAlign: 'center', margin: '10px 0' }}>
            <a href={card["Card Link"]} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#0077cc' }}>
              {card["Card Title"]}
            </a>
          </h2>

          {/* Card Details */}
          <p><strong>Rating:</strong> {card["Rating Value"]} ⭐</p>

          <p><strong>Best For:</strong> {card["Card Best For"]}</p>
          <p><strong>Reward:</strong> {card["Reward Rate"]}</p>
          <p><strong>Reward Description:</strong> {card["Reward Description"]}</p>

          {/* Rating */}
          
          {/* Facts Table */}
          <h3 style={{ margin: '20px 0 10px' }}>Facts</h3>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <tbody>
              {card.Facts.map((fact, factIndex) => {
                const [key, value] = Object.entries(fact)[0];
                return (
                  <tr key={factIndex} style={{ borderBottom: '1px solid #e0e0e0' }}>
                    <td style={{ padding: '10px', fontWeight: 'bold', color: '#333' }}>{key}</td>
                    <td style={{ padding: '10px', color: '#666' }}>{value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
    </div>
    </Modal>
  );
};

export default FinancialProductModal;