import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Alert,
} from "@mui/material";
import { Delete, Close, ArrowBack, ArrowForward } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SelectBox from "components/Inputs/SelectBox";
import TextInput from "components/Inputs/TextInput";
import { deleteMediaFile } from "components/Upload/processMedia";
import { DragAndDropMedia, UploadMediaButton } from "components/Upload/UploadMedia";
import useAddFinancialVault from "data/user/financialVault/useAddFinancialVault";
import useFindFinancialVault from "data/user/financialVault/useFindFinancialVault";
import { debounce, over } from "lodash";
import Modal from "components/Modal/Modal";
import LoadingSkeletonPage from "components/Loading/LoadingSkeletonPage";

const today = new Date()
const FinancialVault = () => {
  const [mediaItems, setMediaItems] = useState([]);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {data,loading} = useFindFinancialVault()
  const { addFinancialVault, error } = useAddFinancialVault()

  useEffect(() => {
    if (data.length>0) {
      setMediaItems(data)
    }
  },[data])

  

  const openModal = (index) => {
    setSelectedMediaIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMediaIndex(null);
  };

  const nextMedia = () => {
    if (selectedMediaIndex !== null && selectedMediaIndex < mediaItems.length - 1) {
      setSelectedMediaIndex((prev) => prev + 1);
    }
    else {
      setSelectedMediaIndex(0);
    }
  };

  const previousMedia = () => {
    if (selectedMediaIndex > 0) {
      setSelectedMediaIndex((prev) => prev - 1);
    }
    else {
      setSelectedMediaIndex(mediaItems.length - 1);
    }
  };

 
  const handleFileData = (file) => {
    // Add new media item for each selected file with initial preview URL and type
    setMediaItems(prevItems => [
      ...prevItems,
      { 
        id: file.name, // Unique identifier
        category:"",
        date:today,
        name: file.name,
        url: file?.url, 
        fileType: file.type,
      }
    ]);
  };

  const handleProgress = (file) => {
    const progress = file.percent;
    setMediaItems(prevItems =>
      prevItems.map(item =>
        item.id === file.name ? { ...item, progress } : item
      )
    );
  };

  const debouncedAddFinancialVault = useCallback(
    debounce((payload) => {
      addFinancialVault(payload);
    }, 1000), // Adjust debounce delay as needed
    []
  );


  // Clean up the debounce function on unmount
  useEffect(() => {
    return () => {
      debouncedAddFinancialVault.cancel();
    };
  }, [debouncedAddFinancialVault]);

  const handleUploadSuccess = (file) => {
    const url = file?.url;
  
    // Use a temporary variable to store updated items
    let updatedMediaItems;
    
    setMediaItems(prevItems => {
      updatedMediaItems = prevItems.map(item =>
        item.id === file?.name ? { ...item, url: url, progress: 100 } : item
      );
      if (updatedMediaItems && updatedMediaItems.length > 0) {
        addFinancialVault({data:updatedMediaItems});}
      return updatedMediaItems; // Return updated items to set the state
    })
    // console.log(updatedMediaItems,file)
    // if (updatedMediaItems && updatedMediaItems.length > 0) {
    // console.log("Add Vault",updatedMediaItems);
    // addFinancialVault({data:updatedMediaItems});}
    // Safely use updatedMediaItems here
  };
  const handleDeleteSuccess = () => {
    // onUploadSuccess && onUploadSuccess({url,fileType:file.type});
  }
 
  
 

  // Placeholder for file upload
  const handleChange = (fieldName, value,index,id) => {
    let updatedMediaItems;
    setMediaItems((prevItems) =>{
      updatedMediaItems = prevItems.map((item,i) =>
        i === index ? { ...item, [fieldName]: value } : item
      )
      return updatedMediaItems
    }
    );

    debouncedAddFinancialVault({
      dataId: id,
      dataKey: fieldName,
      updateType: "update_by_key",
      data: value,
    });
  };

  function handleDelete(item) {
    const mediaItemUpdate = mediaItems.filter(mediaItem => mediaItem.id !== item.id);
    setMediaItems(mediaItemUpdate);
    deleteMediaFile({fileUrl:item.uploadedUrl,previewUrl:item.previewUrl,onSuccess:handleDeleteSuccess})
    addFinancialVault({data:mediaItemUpdate});
    // handleDeleteFile && handleDeleteFile(item.uploadedUrl)
  }

  const handleSubmit = () => {
    addFinancialVault({data:mediaItems});
  
  };

  const openPDF = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <div style={styles.vaultContainer}>
        <div>
        <h2>My Financial Vault</h2>
          <h4>All your financial documents at one place for easy access and to automate your financial applications.</h4>
          {/* <Alert severity="info">
          Experimental Feature
          </Alert> */}
        </div>
      
      <DragAndDropMedia
        mediaBaseUrl = "user"
        mediaFolder = "vault"
       acceptedFileTypes={[
        "image",
        "video",
        "csv",
        "application/pdf",
        "application/msword",
        ".xlsx","text/plain",".csv", ".xls"
      ]}
      handleFileData={handleFileData}
      handleProgress={handleProgress}
      handleUrl={handleUploadSuccess}
      >
      {loading && mediaItems.length === 0 &&
       <div style={{display:"flex",gap:"1rem",padding:"1rem 0",flexWrap:"wrap"}}>
          {["Bank Statements", "Tax Documents", "Insurance", "Mortgage Papers"].map(
          (docType, index) => (
                      <div key={index} style={{background:"#f7f7f7",minWidth:"250px",padding:"1rem",borderRadius:"1rem"}}>
                        <p>{docType}</p>
                        <p style={{fontSize:"0.8rem",color:"gray"}}>Upload {docType}</p>
                      </div>
                    )
                  )}       
        </div>}
        <div>
          <Button variant="contained" role={undefined}>
            Upload
          </Button>
      {/* <UploadMediaButton
        mediaBaseUrl="user"
        mediaFolder="vault"
        acceptedFileTypes={[
          "image",
          "video",
          "csv",
          "application/pdf",
          "application/msword",
          ".xlsx","text/plain",".csv", ".xls"
        ]}
        handleFileData={handleFileData}
        handleProgress={handleProgress}
        handleUrl={handleUploadSuccess}
      >
        Upload
      </UploadMediaButton> */}
      
      </div>
      </DragAndDropMedia>


      {loading && mediaItems.length > 0 &&
      <VaultTable mediaItems={mediaItems} handleChange={handleChange} 
      handleDelete={handleDelete} handleModal={(v)=>openModal(v)}/>}

 
      {(selectedMediaIndex !== null && isModalOpen) && (
        <Modal
  closeIcon={false}
  onClose={closeModal}
  sx={{
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.8)",
    padding: 0,position:"relative",borderRadius:"0"
  }}
>
  {/* Left Arrow */}
 <IconButton
        sx={{
          position: "absolute",
          left: "1rem",
          top: "50%",
          transform: "translateY(-50%)",
          color: "white",
          background:"black",
          zIndex: 1,
        }}
        onClick={previousMedia}
      >
        <ArrowBack />
      </IconButton>
    {/* Media Content Section */}
     {/* Right Arrow */}
     <IconButton
        sx={{
          position: "absolute",
          right: "1rem",
          top: "50%",
          transform: "translateY(-50%)",
          color: "white",
          background:"black",
          zIndex: 1,
        }}
        onClick={nextMedia}
      >
        <ArrowForward />
      </IconButton>
  <div style={{ display: "flex", flexDirection: "column",width: "100%",alignItems:"center", 
    height: "100%",position:"relative",gap:"2rem",overflow:"auto"}}>
    {/* Header Section */}
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        background: "transparent",
        padding: "1rem",
        alignItems: "center",
        width:"100%",boxSizing:"border-box",minHeight:"max-content",position:"sticky",top:"0",zIndex:"100"
      }}
    >
      <h3 style={{color:"white"}}>{mediaItems[selectedMediaIndex]?.name}</h3>
      <IconButton style={{ color: "white" }} onClick={closeModal}>
        <Close />
      </IconButton>
    </div>
 
    <div style={styles.modalContent}>
      <div style={{width:"90%",height:"100%",justifySelf:"center",alignSelf:"center",display:"flex",alignItems:"center",justifyContent:"center"}}>
      {mediaItems[selectedMediaIndex]?.fileType.includes("application/pdf")&&
        <iframe src={mediaItems[selectedMediaIndex]?.url} width="100%" height="100%"></iframe>

      }
            {mediaItems[selectedMediaIndex]?.fileType.includes("application/msword")&&
        <h2 style={{color:"white"}}>Currently MS Word documents are not supported</h2>
      }
        {mediaItems[selectedMediaIndex]?.fileType.includes("image") && (
          <img
            src={mediaItems[selectedMediaIndex]?.url}
            alt={mediaItems[selectedMediaIndex]?.name}
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
          />
        )}
        {mediaItems[selectedMediaIndex]?.fileType.includes("video") && (
          <video
            src={mediaItems[selectedMediaIndex]?.url}
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
            controls
          />
        )}
        </div>
    </div>
  </div>
</Modal>

      )}
    </div>
  );
};

const styles = {
  vaultContainer: {
    padding: "1rem",
    display: "flex",
    flexDirection:"column",
    gap:"1rem"
  },
  modalContent: {
    position: "relative",
    flex: 1,
    background: "transparent",
    color: "white",
    maxWidth: "100%",
    width:"100%",
    height:"100%",
    display:"flex",alignItems:"center",justifyContent:"center",
    padding:"2rem",boxSizing:"border-box",
    minHeight:"400px",
  },
  carouselContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    gap: "1rem",
    padding:"0 2rem",
    boxSizing: "border-box",
    position: "relative",
  },
};

export default FinancialVault;

function VaultTable({ mediaItems,handleChange,handleDelete,handleModal}) {

  return (
  <div style={{width:"100%",overflow:"auto"}}>
<table style={{tableLayout:"fixed"}}>
  <thead>
    <tr>
      <th>Doc</th>
      <th>Category</th>
      <th>Name</th>
      <th>Date</th>
      {/* <th>Ask AI</th> */}
      <th></th>
    </tr>
    </thead>
    <tbody>
    {mediaItems.length > 0 &&
    mediaItems.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item, index) => 
    (<tr style={{position:"relative"}} key={index}>
      
      <td onClick={() => handleModal(index)} style={{maxWidth:"50px",maxHeight:"50px",cursor:"pointer"}}>
      {item?.progress < 100  && (
                    <div
                      style={{ backdropFilter: 'blur(4px)'
                          ,background:"rgba(0, 0, 0, 0.05)",zIndex:1000,position:"absolute",top:0,left:0,width:"100%",height:"100%"}}
                    >
                      <LoadingSkeletonPage/>
                    </div>
                  )}
      <div style={{width:"50px",height:"50px",position:"relative"}}>
      {item.fileType.includes("image") && (
          <img
            src={item.url}
            alt={item.name}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            onClick={() => handleModal(index)}
          />
        )}
        {item.fileType.includes("pdf") && (
          <PictureAsPdfIcon
            style={{ fontSize: "50px", cursor: "pointer" }}
            onClick={() => handleModal(index)}
          />
        )}
        {item.fileType.includes("doc") && (
          <DescriptionIcon
            style={{ fontSize: "50px", cursor: "pointer" }}
            onClick={() => handleModal(index)}
          />
        )}
        {item.fileType.includes("csv") && <ListAltIcon />}
        {item.fileType.includes("video") && (
          <video
            src={item.url}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            controls={false}
            onClick={() => handleModal(index)}
          />
        )}
        </div>
      </td>
      <td>
      <SelectBox
          label="Category"
          options={vaultCategories}
          value={item.category}
          onChange={(e,v) => handleChange("category",v, index, item?.id)}
        />
      </td>
      <td> <TextInput
          label="Name"
          value={item.name}
          onChange={(v) => handleChange("name", v, index, item?.id)}
        /></td>
      <td>{formatDate(item?.date)}</td>
      {/* <td>coming soon!</td> */}
      <td> <Button onClick={() => handleDelete(item)}>
          <Delete />
        </Button></td>
      </tr>
      ))}

    </tbody>
</table>
</div>
  )
}

const vaultCategories = [
  "Identity Documents",
  "Government Documents",
  "Bank Statements",
  "Brokerage Documents",
  "Investment Documents",
  "Tax Documents",
  "Insurance Documents",
  "Mortgage Papers",
  "Power of Attorney",
  "Others",
];

const formatDate = (date) => {
  // Parse the date if it's a string
  if (typeof date === "string") {
    date = new Date(date);
  }

  // Check if it's a valid date
  if (!(date instanceof Date) || isNaN(date)) {
    return date; // Return as-is if not a valid date
  }

  const now = new Date();

  // Helper function to add zero padding to single-digit numbers
  const padZero = (num) => (num < 10 ? `0${num}` : num);

  // Compare the given date with today
  // const isToday =
  //   now.toDateString() === date.toDateString();

  // // Compare the given date with yesterday
  // const yesterday = new Date(now);
  // yesterday.setDate(now.getDate() - 1);
  // const isYesterday =
  //   yesterday.toDateString() === date.toDateString();

  // if (isToday) {
  //   const hours = date.getHours();
  //   const minutes = padZero(date.getMinutes());
  //   const suffix = hours >= 12 ? "pm" : "am";
  //   const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
  //   return `${formattedHours}:${minutes} ${suffix}`;
  // }

  // if (isYesterday) {
  //   return "Yesterday";
  // }

  // For dates before yesterday, return formatted as 'Mar 24, 2024'
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

