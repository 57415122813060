import React, { useEffect, useRef, useState } from "react";
import TargetIcon from "@mui/icons-material/TrackChanges";
import SpeedIcon from "@mui/icons-material/Speed";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import GrowFinanciallyToday from "home/GrowFinanciallyToday";
import FeatureAskAI from "./FeatureAskAI";
import LifeStages from "./LifeStages";
import TextWithBorders from "components/Text/TextWithBorders";
import QuestionsHome from "home/QuestionsHome";

const Features = () => {

  return (
    <div style={{padding:"0",width:"100%",boxSizing:"border-box",position:"relative"}}>
        <FeatureAskAI/>
        <div style={{padding:"0rem 4rem",paddingTop:"4rem",textAlign:"center",position:"relative"}}>
        <h1
  style={{
    color: "white",
    fontSize: "6rem",
    // fontWeight: "900",
    color:"black",
    // textShadow: "0px 4px 6px rgba(0, 0, 0, 0.5), 0px 0px 15px rgba(255, 255, 255, 0.7),0px 0px 15px rgba(255, 255, 255, 0.7)"
  }}
>
          AI for Your <br/>Financial Journey
        </h1>

        
        </div>
        <FeatureTrack/>

        {/* <h1 className='gradient_text' style={{fontSize:"3rem",textAlign:"center"}}>
        Track. Optimize. Invest. Analyze. Plan.
          </h1> */}
        <FeaturScores />
        <FeatureGeneric/>
        
        <QuestionsHome/>
        {/* <UseCases/> */}
        <GrowFinanciallyToday/>
       
      </div>
  );
};

export default Features;


function FeatureTrack () {
  return (
    <div style={{padding:"2rem"}}>
        <LifeStages/>
{/* <h1
  style={{
    color: "rgba(0, 0, 0, 0.3)",
    fontSize: "18rem",
    position: "absolute",
    top: "0%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    whiteSpace: "nowrap", // Prevents text wrapping
    textAlign: "center", // Centers the text in case it has multiple lines
  }}
>
  Track
</h1> */}
        {/* <ZigZagBoxes/> */}
    </div>
  )
}

function FeatureGeneric () {
    const dataOne = [
        { title: "Track", description: ["Networth","Cash Flows","Portfolio"] },
        { title: "Optimize", description: ["Financial Health","Next Best Action"] },
        { title: "Invest", description: ["Public Market","Private Market"] },
        { title: "Plan", description: ["Life Goals","Affordability Tools"] },
        { title: "Analyze", description: ["Assets","Markets","Economy"] },
        { title: "Consult", description: ["AI","Professionals"] },
        { title: "Transact", description: ["Trade","Financial Products"] },
        { title: "Grow", description: ["Next Best Action","Guidance"] },
      ];
    
    const datatwo = [
       
      ];
    return (
      <div style={{padding:"4rem",textAlign:"center",display:"flex",alignItems:"center"
      ,flexDirection:"column",gap:"1rem",position:"relative"}}>
        <h1 style={{fontSize:"6rem",zIndex:1}}>Your Financial World, <br/> Fully Connected</h1>
        <h2 style={{fontSize:"2rem",paddingBottom:"2rem",zIndex:1}}>bank, broker, and beyond – all in one place</h2>
        <div style={{zIndex:1,position:"relative"}}>

        <ZigZagBoxes data={dataOne}/></div>
        
      </div>
    )
  }
  

const FeaturScores = () => {
  const scoreData = [
    {
      icon: <HealthAndSafetyIcon style={{ fontSize: "8rem", color: "green" }} />,
      title: "Financial Health Score",
      points: [
        "Financial best practices",
        "How to improve your finances",
        "Next best action",
      ],
    },
    {
      icon: <TargetIcon style={{ fontSize:"8rem", color: "blue" }} />,
      title: "Goal Success Rate",
      points: [
        "Whether you will meet your goals",
        "Based on simulation - forecast, inflation, growth rates",
        "Steps to realize goals",
      ],
    },
    {
      icon: <SpeedIcon style={{ fontSize: "8rem", color: "orange" }} />,
      title: "Portfolio Fit Score",
      points: [
        "How well an Investment Opportunity (Stock, Crypto, ETF, Mutual Fund, Bonds, Forex, etc) will fit with your situation and portfolio strategy",
      ],
    },
  ];

  return (
    <div style={{ textAlign: "center", padding: "2rem 4rem",paddingBottom:"4rem",position:"relative"}}>
 {/* <h1 style={{color:"rgba(0, 0, 0, 0.3)",fontSize:"6rem"}}>Optimize</h1>
    */}
     <div style={{position:"relative",zIndex:1}}>
      <TextWithBorders sx={{fontSize:"4rem",}} textAlign="center" text="3 Scores">
      </TextWithBorders>
      <h2>
        to evaluate your finances, goals and investment
        opportunities
      </h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginTop: "40px",
          flexWrap: "wrap",
          position:"relative",width:"100%",
        }}
      >
      



        {scoreData.map(({ icon, title, points }, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              padding: "20px",
              width: "300px",
              minWidth:"300px",
              color:"rgba(0, 0, 0, 0.5)",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              position:"relative",
            }}
          >

            <div style={{display:"flex",
              flexDirection:"column",gap:"1rem",zIndex:10,position:"relative"}}>
            <div>{icon}</div>
            <h3 style={{ fontSize: "1.5rem",color:"black"}}>{title}</h3>
            <div style={{padding:"0 1rem"}}>
            {points.map((point, idx) => (
                <h4 key={idx} style={{ marginBottom: "10px",textAlign:"center",fontWeight:"bold"}}>
                  {point}
                </h4>
              ))}
              </div>
              </div>
          </div>
        ))}
      </div>
    </div>
  );
};


function FeatureMarketPersonal (){
  return (
    <div style={{}}>
        
    </div>
  )
}


const ZigZagBoxes = ({data}) => {

  const styles = {
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      margin: "50px",
    },
    boxWrapper: {
      position: "relative",
      width: "300px",
      height: "400px",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    box: {
      width: "100%",
      height: "100%",
      borderRadius: "10px",
    //   background:"#fafafa",
    //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      background: "linear-gradient(to top, white, rgba(76, 175, 80, 0.4), white)",
      color: "black",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    title: {
      fontSize: "4rem",
      fontWeight: "bold",
      color:"#013220",   
    },
    description: {
      fontSize: "14px",
      marginTop: "8px",
      display: "flex",
      flexWrap:"wrap",
    //   width:"100%",
      gap:"0.5rem",
    },
    upBox: {
      transform: "translateY(-50px)",
    },
    downBox: {
      transform: "translateY(50px)",
    },
  };

  return (
    <div style={styles.container}>
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            ...styles.boxWrapper,
            ...(index % 2 === 0 ? styles.upBox : styles.downBox),
          }}
        >
          <div style={styles.box}>
            <div style={styles.title}>{item.title}</div>
            <div style={styles.description}>
                {item.description.map((desc, index) => 
                <div key={index} style={{padding:"0.5rem",
                borderRadius:"5px",display:"flex",justifyContent:"center",alignItems:"center",boxSizing:"border-box"}}>
                    <h4 style={{fontSize:"1rem",fontWeight:"bold"}}>{desc}</h4>
                </div>)}</div>
          </div>
       
        </div>
      ))}
    </div>
  );
};
