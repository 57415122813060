import { Button, Typography } from '@mui/material';
import { useAppInfo } from 'AppState';
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export default function ChatHistory() {
  const { stateAssistant, dispatchAssistant } = useAppInfo();

  function handleChat(obj) {
    const { chats, componentProps } = obj;
    dispatchAssistant({ chats, componentProps, chatStatus: 'idle', chatWindow: true });
  }

  // Function to categorize dates
  const categorizeDate = (date) => {
    const today = new Date();
    const chatDate = new Date(date);

    // Calculate the difference in days between today and the chat date
    const diffInTime = today.getTime() - chatDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

    if (diffInDays === 0) return 'Today';
    if (diffInDays === 1) return 'Yesterday';
    if (diffInDays <= 7) return 'Previous 7 Days';
    if (diffInDays <= 30) return 'Previous 30 Days';
    return chatDate.toLocaleDateString(); // Return date string for older dates
  };

  // Function to sort and group chat history by date
  const getSortedGroupedChats = () => {
    const groupedChats = stateAssistant.chatHistory.reduce((acc, chat) => {
      const category = categorizeDate(chat.date);
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(chat);
      return acc;
    }, {});

    // Sort chats within each category by date (newest to oldest)
    Object.keys(groupedChats).forEach((category) => {
      groupedChats[category] = groupedChats[category].sort((a, b) => new Date(b.date) - new Date(a.date));
    });

    // Sort the grouped chats by the order of categories
    const categoryOrder = ['Today', 'Yesterday', 'Previous 7 Days'];
    const sortedGroupedChats = Object.keys(groupedChats)
      .sort((a, b) => {
        // Sort based on predefined order, and then by date for custom categories
        const indexA = categoryOrder.indexOf(a);
        const indexB = categoryOrder.indexOf(b);
        if (indexA === -1 && indexB === -1) return new Date(b) - new Date(a);
        return (indexA !== -1 ? indexA : Infinity) - (indexB !== -1 ? indexB : Infinity);
      })
      .map((category) => ({ category, chats: groupedChats[category] }));

    return sortedGroupedChats;
  };

  // Sorted and grouped chat history
  const sortedGroupedChats = getSortedGroupedChats();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {sortedGroupedChats.map(({ category, chats }) => (
        <React.Fragment key={category}>
          <Typography variant="p" style={{ padding: '0.5rem', fontWeight: 'bold' }}>
            {category}
          </Typography>
          {chats.map((obj, index) => (
            <React.Fragment key={index}>
            <CustomWidthTooltip title={obj.label} placement="right">
              <Button style={{ justifyContent: 'flex-start',color:"gray"}} onClick={() => handleChat(obj)}>
                <Typography
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {obj.label}
                </Typography>
              </Button>
            </CustomWidthTooltip>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 100,
  },
});
