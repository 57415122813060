import { useAppInfo } from 'AppState'
import React, { useEffect } from 'react'
import useUpdateSettings from './useUpdateSettings'
import { navLinksSelectedDefault } from 'nav/menu'


export default function useAddDefaultSettings() {
  const {stateSidebar,dispatchSidebar} = useAppInfo()
  const {navLinksSelected,settingsLoaded} = stateSidebar
  const {UpdateSettings} = useUpdateSettings()

  useEffect(()=>{
    if (settingsLoaded && !navLinksSelected.length) {
        dispatchSidebar({navLinksSelected:navLinksSelectedDefault})
        UpdateSettings({data:navLinksSelectedDefault,dataName:"navLinkSelected"})
    }
  },[settingsLoaded])

  return null
}
