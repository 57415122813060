import React, {
  useEffect,
  useState,
} from 'react';

import ScatterUI from 'components/Charts/ScatterUI';
import TreeMap from 'components/Charts/TreeMap';
import PaginationButtons from 'components/PaginationButtons';
import TableGen from 'components/Table/TableGen';

import { Paper } from '@mui/material';

import { useAppInfo } from 'AppState';
import {
  dfin,
  growthMetric,
} from 'content/constants';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import PeerOverviewMetricComp
  from 'analytics/ticker/stocks/Peer/PeerOverviewMetricComp';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import LoaderScanner from 'components/Loading/LoaderScanner';
import TickerCard from './TickerCard';

export default function MarketCallStocks({strategy}) {
  const [tabIndex,setTabIndex] = useState(0)
  const {stateOpportunities} = useAppInfo()
  const {mScaleSel,dataView} = stateOpportunities
  const {stateScreener,stateStock} = useAppInfo()
  const {scatterMetricsSel} = stateScreener
  const defaultCols = [dfin.coLogo,dfin.ticker,dfin.coName,"CLOSE"]
  const [cols,setCols] = useState(defaultCols)
  const [tickerSel,setTickerSel] = useState([])
  const [table,setTable] = useState([])
  const [tableDisplay,setTableDisplay] = useState([])
  const [totalItems,setTotalItems] = useState()
  const [currentPage,setCurrentPage] = useState(1)
  const {close,trendTable,marketInfoLoading} = useMultiTickerInfo(tickerSel)
  const metricOptions = stateStock.metrics.numCols

  useEffect(()=>{
    setCurrentPage(1)

  },[strategy])

  useEffect(() => {
    if (stateScreener.stocks.length>0){
      const filteredTable = stateScreener.stocks
      .filter(obj=> obj[dfin.country] === "USA" && mScaleSel.includes(obj[dfin.mScale]))
      .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap]);
      setTable(filteredTable);
    }
  }, [mScaleSel, stateScreener.stocks]);

  useEffect(() => {
    let tableToDisplay = table;
    let startIndex =  (currentPage * 10) - 10
    let endIndex = currentPage * 10
    if (tableToDisplay.length > 0) {
      if (strategy === "Dividend") {
        tableToDisplay = tableToDisplay.filter(obj => obj[dfin.dividendYield] > 0.05);
        setCols([...defaultCols,dfin.dividendYield])
      } else if (strategy === "Growth") {
        tableToDisplay = tableToDisplay.filter(obj => obj[dfin.growthRating] > 8);
        setCols([...defaultCols,dfin.growthRating,growthMetric(dfin.revenue),growthMetric(dfin.ebitda)])
      } else if (strategy === "Long Term Value") {
        tableToDisplay = tableToDisplay.filter(obj => obj[dfin.MoS] > 0.5 && obj[dfin.fiancialRating] > 7);
        setCols([...defaultCols,dfin.dividendYield,dfin.pe,dfin.MoS])
      }
      else if (strategy === "Day Trade"){
        tableToDisplay = tableToDisplay.filter(obj => obj["MOVING AVERAGES RATING"]==="Strong Buy" && obj["OSCILLATORS RATING"]==="Buy");
        setCols([...defaultCols,dfin.performance1D,dfin.performance1M])
      }
      else if (strategy === "Positional"){
        tableToDisplay = tableToDisplay.filter(obj => obj["TECHNICAL OVERALL SCORE"]>8 && obj["OSCILLATORS RATING"]==="Buy");
        setCols([...defaultCols,dfin.performance3M,dfin.performance6M])
      }
      setTotalItems(tableToDisplay.length)
      const tableToDisplayX = tableToDisplay.slice(startIndex,endIndex)
      setTickerSel(tableToDisplayX.map(obj => obj[dfin.ticker]));
      setTableDisplay(tableToDisplayX);
    }
  }, [strategy, table,currentPage]);


  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true);
    }, 200);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  if (!shouldRender) {
    return null; // Render nothing until the timer completes
  }


  
  return (
    <Paper elevation={dataView==="Information"? 0:3} style={{display:"flex",flexDirection:"column",flex:1,minWidth:0,overflow:"auto"}}>

    <div style={{flex:1,overflow:"auto",width:"100%"}}>
    <>

  {(!tableDisplay.length>0) && <LoaderScanner/>}
  {(tableDisplay.length>0) && 
  <>
  {dataView === "Scatter" && <ScatterUI data={tableDisplay} defaultMet={scatterMetricsSel}/>}
     {dataView==="Heatmap" && <TreeMap data={tableDisplay}/>}
     {dataView==="Brand Visuals" &&     <PeerOverviewMetricComp
    data={tableDisplay}
    infoName={"Brand Viusals"}
      storeId={"StockOpportunities"}
      defaultMet={[dfin.marketCap,dfin.ebitda,dfin.revenue]}
      metricOptions={metricOptions}
      chartTypeDefault={"BrandVisuals"}
      chartTypeOptions={["BrandVisuals"]}
    />}
   {dataView === "Information" && 
   <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"0.25rem"}}>
   {
   tableDisplay.map((data,index)=>(
    <React.Fragment key={index}>
<TickerCard data={data} tickerPrice={close}/>
    </React.Fragment>
    
   ))}</div>
   }

   {dataView === "Table" &&
   <TableGen
   tickerPrice={close} 
   pagination={false}
   tableLayout='auto'
   logo={true}
   data={getSpecificArrayObjKeys(tableDisplay,cols)}/>}

   </>}
   </>
    </div>
    <PaginationButtons totalItems={totalItems} currentPage={currentPage} 
    itemsDisplaying={tableDisplay.length}
    itemsPerPage={10} handlePage={(v)=>setCurrentPage(v)}/>   
    </Paper>
  )
}