import React from 'react'

export default function ReinvestingBenefits() {
return (
    <div style={styles.container}>
      <h2>Benefits of Reinvesting</h2>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <strong>Compound Growth:</strong> Reinvesting allows your returns to generate their own returns, leading to exponential growth over time.
        </li>
        <li style={styles.listItem}>
          <strong>Increased Wealth Over Time:</strong> Consistently reinvesting can significantly boost your total investment portfolio.
        </li>
        <li style={styles.listItem}>
          <strong>Take Advantage of Market Growth:</strong> Staying invested ensures you benefit from market upswings and capital appreciation.
        </li>
        <li style={styles.listItem}>
          <strong>Tax Efficiency:</strong> In tax-advantaged accounts, reinvested returns can grow tax-deferred, enhancing overall returns.
        </li>
        <li style={styles.listItem}>
          <strong>Achieve Financial Goals Faster:</strong> More capital from reinvested returns helps you reach your financial objectives sooner.
        </li>
        <li style={styles.listItem}>
          <strong>Mitigate Inflation:</strong> Reinvested returns can help your portfolio keep pace with or exceed inflation, preserving purchasing power.
        </li>
      </ul>
    </div>
  );
};


  // Optional: Inline styles for basic styling
  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
  };
  
  