import { useAppInfo } from 'AppState';
import PriceChartMulti from 'components/Charts/PriceChartMulti';
import Correlation from 'analytics/technicals/general/Correlation';
import IndicatorUI from 'analytics/technicals/indicators/IndicatorUI';
import PerformanceClose from 'analytics/technicals/general/PerformanceClose';
import TrendTable from 'analytics/technicals/general/TrendTable';
import Volatility from 'analytics/technicals/general/Volatility';

export default function PortfolioAssetAnalytics({info}){
    const {statePortfolio} = useAppInfo()
    const content = {
      "Trend":<PriceChartMulti close={statePortfolio.close} marketInfoLoading={false}/>,
      "Trend Table" : <TrendTable tickerPrice={statePortfolio.close} data={statePortfolio.trendTable} marketInfoLoading={statePortfolio.marketInfoLoading}/>,
      "Technical Indicators":statePortfolio.tickerSelected.length>0 && <IndicatorUI tickerList={statePortfolio.tickerSelected}/>,
      "Performance":<PerformanceClose close={statePortfolio.close} marketInfoLoading={false}/>,
      "Volatility":<Volatility close={statePortfolio.close} marketInfoLoading={false}/>,
      "Correlation":statePortfolio.tickerSelected.length>0 && <Correlation tickerList={statePortfolio.tickerSelected}/>,
    }
    return (
      <>
      {content[info]}
      </>
    )
  }