import { Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TrendConditions from './TrendConditions'
import TradeSignals from './TradeSignals'
import TextWithBorders from 'components/Text/TextWithBorders'
import TickerHeader from 'analytics/ticker/TickerHeader'
import TickerPerformanceOverview from './TickerPerformanceOverview'

export default function TickerBuySellCondition({tickerHeader=false,ticker}) {

 
  return (
    <div style={{width:"100%",height:"100%",padding:"0.25rem",boxSizing:"border-box"}}>
      {ticker && 
      <Paper style={{minHeight:"100%",width:"100%",display:"flex"
      ,flexDirection:"column",gap:"1rem",padding:"0.5rem",boxSizing:"border-box",overflow:"auto"}}>
        {tickerHeader && <TickerHeader ticker={ticker}/>}
        <h3 style={{fontWeight:"bold"}}>Trade Conditions</h3>
        <TextWithBorders text={"Trend & Relative Strength"}/>
        <TrendConditions ticker={ticker}/>
        <TextWithBorders text={"vs Benchmark"}/>
        <TickerPerformanceOverview ticker={ticker}/>
        <TextWithBorders text={"Technical Signals"}/>
        <TradeSignals ticker={ticker}/>
      </Paper>}
    </div>
  )
}




