import CreditCardIcon from "@mui/icons-material/CreditCard";
import SavingsIcon from "@mui/icons-material/Savings";
import HouseIcon from "@mui/icons-material/House";
import SecurityIcon from "@mui/icons-material/Security";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import SchoolIcon from "@mui/icons-material/School";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

export const productIcons = {
  "Credit Cards": <CreditCardIcon fontSize="small" />,
  "Savings Account": <SavingsIcon fontSize="small" />,
  "Mortgages": <HouseIcon fontSize="small" />,
  "Home Insurance": <SecurityIcon fontSize="small" />,
  "Life Insurance": <SecurityIcon fontSize="small" />,
  "Car Insurance": <CarRepairIcon fontSize="small" />,
  "Personal Loans": <AccountBalanceIcon fontSize="small" />,
  "Student Loans": <SchoolIcon fontSize="small" />
};