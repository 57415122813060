import React from 'react'
import NetCashAllocation from './NetCashAllocation'
import LiabilityPayback from 'myFinancials/Liability/LiabilityPayback'
import ExpenseDetails from './ExpenseDetails'
import AssetReturnProjections from './../Asset/AssetReturnProjections';
import NetCashflowOverview from './NetCashflowOverview'
import Income from './Income'
import NoFinancialData from 'myFinancials/Template/NoFinancialData'
import { useAppInfo } from 'AppState'
import AssetInfo from 'myFinancials/Asset/AssetInfo';
import LiabilityInfo from 'myFinancials/Liability/LiabilityInfo';
import NetCashFlowProjection from './NetCashFlowProjection';

export default function CashflowInfo({menu,subMenu,displayType='main'}) {
  const {stateMyFinancials} = useAppInfo()
  const {financialsLoaded,incomeMonthly,expenseMonthly} = stateMyFinancials

  const content = {
    "Net Cashflow":<NetCashflowOverview displayType={displayType}/>,
    "Net Cash Allocation":<NetCashAllocation/>,
    "Net Cash Projections":<NetCashFlowProjection/>,
    "Income":<Income/>,
    "Return on Savings & Investment":<AssetInfo info="AssetReturnProjections"/>,
    "Expense":<ExpenseDetails/>,
    "Liability Payback":<LiabilityInfo info={"Payback Details"}/>,
  }

  return (
    <>
    <div style={{width:"100%",height:"100%",position:"relative",minHeight:"50vh"}}>
    
    <NoFinancialData financialType="cashflow"/>
    {(financialsLoaded && (incomeMonthly.length > 0 || expenseMonthly.length > 0)) && content[menu]}
    </div>
    </>
  )
}
