import Animation from 'animation/Animation';
import { useAppInfo } from 'AppState';
import AddAsset from 'myFinancials/Asset/AddAsset';
import AddExpense from 'myFinancials/Cashflow/AddExpense';
import AddIncome from 'myFinancials/Cashflow/AddIncome';
import GoalAdd from 'myFinancials/Goals/GoalAdd';
import AddLiabilities from 'myFinancials/Liability/AddLiabilities';
import React from 'react';
import PortfolioAddTransactionModal from 'myFinancials/Portfolio/PortfolioAddTransactionModal';
import PortfolioCrud from 'myFinancials/Portfolio/PortfolioCrud';
import { Button } from '@mui/material';

export default function NoFinancialData({ financialType = "assets" }) {
  const { stateMyFinancials,statePortfolio} = useAppInfo();
  const { assets, liabilities, incomeMonthly, expenseMonthly, goalsPeriodic, financialsLoaded } = stateMyFinancials;
  const {portfolioSel,portfolioLoaded,portfolioDefaultLoaded,addTransaction,portfolio} = statePortfolio


  if (!financialsLoaded) return null;

  const financialInfo = {
    "assets": assets.length === 0 && <AddAsset variant="contained" />,
    "liabilities": liabilities.length === 0 && <AddLiabilities />,
    "networth":assets.length === 0 && liabilities.length === 0 && (
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <AddAsset/>
        <AddLiabilities/>
      </div>
    ),
    "portfolio":(portfolioLoaded && portfolioDefaultLoaded && portfolio && (Object.keys(portfolio).length === 0 || portfolioSel.length === 0)) && <NoPortfolioData/>,
    "goals": goalsPeriodic.length === 0 && <GoalAdd variant="contained" />,
    "income": incomeMonthly.length === 0 && (
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <AddIncome/>
      </div>
    ),
    "expenses": expenseMonthly.length === 0 && (
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <AddExpense/>
      </div>
    ),
    "cashflow": incomeMonthly.length === 0 && expenseMonthly.length === 0 && (
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <AddIncome/>
        <AddExpense/>
      </div>
    ),
  }[financialType];

  if (!financialInfo) return null;

  return (
    <div style={{
      padding: "1rem",
      boxSizing: "border-box",
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,0.05)",
      zIndex: 100,
      display: "flex",
      gap: "0.5rem",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <div style={{width:"200px"}}>
      {animation[financialType]}</div>
      {financialType === "networth" && <h3 style={{ fontWeight: "bold", marginBottom: "1rem" }}>
        Net Worth
      </h3>}
      {financialType === "portfolio" && <h3 style={{ fontWeight: "bold", marginBottom: "1rem" }}>
        Investment Portfolio
      </h3>}
      {financialType === "cashflow" && <h3 style={{ fontWeight: "bold", marginBottom: "1rem" }}>
        Cash Flow
      </h3>}
      {financialInfo}
    </div>
  );
}


const animation = {
  assets : <Animation type="Portfolio" />,
  liabilities: <Animation type="Home" />,
  networth: <Animation type="Financials" />,
  goals: <Animation type="Goals" />,
  income: <Animation type="Money" />,
  expenses: <Animation type="Money" />,
  cashflow: <Animation type="Money" />,
  portfolio:<Animation type="Portfolio" />
}

function NoPortfolioData() {
  const {statePortfolio,dispatchPortfolio} = useAppInfo()
  const {portfolioSel,portfolioLoaded,portfolioDefaultLoaded,addTransaction,portfolio} = statePortfolio


  return (
    <>
    {(portfolioLoaded && portfolio && Object.keys(portfolio).length===0) && 
       <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
       <PortfolioCrud crudOperation='create'/>
       </div>}
      {(portfolioLoaded && portfolioDefaultLoaded && portfolioSel.length === 0 && portfolio && Object.keys(portfolio).length>0) && 
      <>
      <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
        <Button variant='contained' onClick={()=>{dispatchPortfolio({"addTransaction":true})}}>Add Portfolio Assets</Button>
        <Button variant='outlined' onClick={()=>{dispatchPortfolio({"addTransaction":true})}}>Upload Portfolio</Button>
        </div>
        {addTransaction && <PortfolioAddTransactionModal/> }</>}</>
  )
}