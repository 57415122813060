import React, { useEffect } from 'react';
import PageComingSoon from 'pages/PageComingSoon';
import PortfolioCurrentPositions from './PositionsAndTransactions/PortfolioCurrentPositions';
import PortfolioHoldingSenate from './PortfolioHoldingSenate';
import PortfolioOverview from './PortfolioOverview';
import PortfolioTransactions, { PortfolioTransactionsAll, PortfolioTransactionsTicker } from './PositionsAndTransactions/PortfolioTransactions';
import PortfolioVsBenchmark from './PortfolioVsBenchmark';
import AssetAllocation from './AssetAllocation';
import PortfolioOverviewHome from './PortfolioOverviewHome';
import PortfolioAssetAnalytics from './PortfolioAssetAnalytics';
import { Button } from '@mui/material';
import { useAppInfo } from 'AppState';
import { useNavigate } from 'react-router-dom';
import PortfolioAccountSelection from './PortfolioAccountSelection';
import PortfolioAddTransactionModal from './PortfolioAddTransactionModal';
import PortfolioCrud from './PortfolioCrud';
import NoFinancialData from 'myFinancials/Template/NoFinancialData';

export default function PortfolioInfoList({menu,subMenu}) {
  const {statePortfolio,dispatchPortfolio} = useAppInfo()
  const {portfolioSel,portfolioLoaded,portfolioDefaultLoaded,addTransaction,portfolio} = statePortfolio
  const {navigate} = useNavigate()

  const infoList = {
    "Overview":<PortfolioOverview/>,
    "Overview Home":<PortfolioOverviewHome/>,
    "Current Positions":<PortfolioCurrentPositions/>,
    "Transactions":<PortfolioTransactions/>,
    "Transactions All":<PortfolioTransactionsAll uiType='trade'/>,
    "Transaction Ticker":<PortfolioTransactionsTicker type='ticker'/>,
    "Current Position Ticker":<PortfolioCurrentPositions positionType='Ticker'/>,
    "Asset Allocation":<AssetAllocation/>,
    "Portfolio vs Benchmark":<PortfolioVsBenchmark/>,
    "Asset Analytics":<PortfolioAssetAnalytics info={subMenu}/>,
    "Report":<PageComingSoon name={"Insights"}/>,
    "Insights":<PageComingSoon name={"Insights"}/>,
    "Holdings - Senate":<PortfolioHoldingSenate/>,
  }

  useEffect(()=>{
    if ( statePortfolio.portfolio){
    const tDetailAccount = statePortfolio.portfolio[statePortfolio.accountNameSel]
  
    if (tDetailAccount){
    dispatchPortfolio({"portfolioSel":tDetailAccount})
  }
  
  }
  },[statePortfolio.portfolio,statePortfolio.accountNameSel])

  
  return (
    <div style={{position:"relative",height:"100%",width:"100%",minHeight:"50vh"}}>
    <NoFinancialData financialType="portfolio"/>
    {(portfolioLoaded && portfolioDefaultLoaded && portfolioSel.length > 0) && 
    infoList[menu]
    }
    </div>
  )
}
