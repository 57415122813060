import React, { useState } from 'react'
import useEconCalendar from 'data/economy/useEconCalendar'
import TableGen from 'components/Table/TableGen';
import DateRangePicker from 'components/Inputs/DateRangePicker';
import { MetricSettingsBar } from 'components/Template/MetricSettings';

export default function EconomyCalendar() {
    const defaultFromDate = new Date();
    defaultFromDate.setMonth(defaultFromDate.getMonth() - 1);
    const defaultFromDateP = defaultFromDate.toISOString().split('T')[0];

    const defaultToDate = new Date().toISOString().split('T')[0];

  const [selectedDates, setSelectedDates] = useState({
        from: defaultFromDateP,
        to: defaultToDate,
      });
    
      // Handle changes in the date range
    const handleDateChange = ({ from, to }) => {
        setSelectedDates({ from, to });
      };
  const cols = ["country","type","date","actual","previous","estimate","change","change_percent"]
  const {econCalendar} = useEconCalendar({selectedDates})
  const econCalendarISO = econCalendar && econCalendar.map(event => {
    const iso2 = event.country.toLowerCase();
  
    // Extract only the desired keys from the event
    const filteredEvent = Object.keys(event)
      .filter(key => cols.includes(key))
      .reduce((obj, key) => {
        obj[key] = event[key];
        return obj;
      }, {});
  
    // Combine "ISO 2" and filtered keys
    return { "ISO 2": iso2, ...filteredEvent };
  });
  return (   
    <div className='containerColumnFlex'>
    <MetricSettingsBar 
    title='Economy Calendar'
    childrenPopover={<DateRangePicker onDateChange={handleDateChange}/>} 
    />
    {econCalendarISO && 
    <div className='containerColumnFlex1'>
    <TableGen tableLayout='fixed' data={econCalendarISO}/>
    </div>}
    </div>

  )
}
