import { useCombineReducer } from 'appState/reducer';
import React, { createContext, useContext } from 'react'
import { Outlet } from 'react-router-dom';

const AppContext = createContext(null);

export default function AppState({stateApp,dispatchApp}) {
  const storeContent = useCombineReducer()
  const store = {stateApp,dispatchApp,...storeContent}

  return (
    <AppContext.Provider value={store}>
        <Outlet/>
    </AppContext.Provider>
  )
}


function useAppInfo() {
    const context = useContext(AppContext);
    return context;
  }
  
  export { AppState, useAppInfo };