import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState';
import SearchInfoResource from './SearchInfoResource';
import TextInput from 'components/Inputs/TextInput';

export default function SearchInfoSidebar() {
    const [searchTerm,setSearchTerm] = useState()
    const {dispatchSidebar,stateSidebar} = useAppInfo()

  useEffect(()=>{
    if (stateSidebar.infoContent){
    setSearchTerm(stateSidebar.infoContent)}
  },[stateSidebar.infoContent])



   function handleSearch(v){
    setSearchTerm(v)
    // dispatchSidebar({"infoContent":v})
   }

   return (
<div style={{height:"100%",width:"100%",display:"flex",flexDirection:"column",gap:"1rem",padding:"0.5rem",boxSizing:"border-box"}}>
<div style={{position:"sticky",top:"0",background:"white",zIndex:5}}>
        <TextInput 
        variant='outlined'
        size='small'
        value={searchTerm} 
        onChange={handleSearch} 
        type="search" placeholder="Search Resource...." />
    </div>

   <div style={{width:"100%",flex:1}}>

   <SearchInfoResource searchTerm={searchTerm}/> 
 
  </div>

  </div>
  )
}

    
