import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import TableGen from 'components/Table/TableGen'
import { calculateAmortizationSchedule } from '../Utils/utils'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import { PageMainContentTitle } from 'components/Text/Title'
import NoFinancialData from 'myFinancials/Template/NoFinancialData'

export default function LiabilityPaybackSummary() {
    const {stateMyFinancials} = useAppInfo()
    const {assets,liabilities,cashflowDistribution,financialsLoaded} = stateMyFinancials
    const [tableData,setTableData] = useState([])
    useEffect(()=>{
        const liabilityDetails = liabilities.flatMap(obj => {
          const {totalAmount,periodicPayment,totalInterest,totalPrincipal}= calculateAmortizationSchedule(obj);
          const loanTermAdjusted = obj.loanTerm?.y + Math.floor(obj.loanTerm?.m/12)
          const startDate = new Date(obj?.date);

        // Calculate the end date by adding the adjusted years
        const endDate = new Date(startDate.setFullYear(startDate.getFullYear() + loanTermAdjusted));

          return {...obj,"loanAmount":obj.amount,
            "loanTerm (years)":loanTermAdjusted,totalInterest
            ,"totalPaybackAmount":totalAmount
            ,totalPrincipal
            ,periodicPayment,"endDate":endDate};
        });

        setTableData(liabilityDetails)
      },[liabilities])
  return (
    <>
    {financialsLoaded && liabilities.length === 0 && <NoFinancialData financialType="liabilities"/>}
    {financialsLoaded && liabilities.length > 0 && 
    <TableGen height='auto' data={getSpecificArrayObjKeys(tableData,
      ["type","name","loanAmount","totalPaybackAmount",
      "totalInterest","totalPrincipal","paybackPeriod","periodicPayment",
      "payback","loanTerm (years)","date","endDate"])} tableLayout={"fixed"}/>}
    </>
  )
}
