import React, { useState } from 'react';
import { useAppInfo } from 'AppState';
import { processFile } from './processMedia';
import { Button } from '@mui/material';


export const DragAndDropMedia = ({
  handleUrl,
  handleDragOver,
  handleFileData,
  handleProgress,
  acceptedFileTypes = ["image", "video", "csv"],
  mediaBaseUrl = "uploads",
  mediaFolder = "user",
  children,
}) => {
  const { stateApp } = useAppInfo();
  const { userId } = stateApp;
  const [dragOver, setDragOver] = useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    if (handleDragOver && typeof handleDragOver === "function") {
      handleDragOver(e);
    }
    const droppedFiles = Array.from(e.dataTransfer.files);
    droppedFiles.forEach((file) =>
      processFile({
        file,
        acceptedFileTypes,
        mediaBaseUrl,
        mediaFolder,
        userId,
        handleFileData,
        handleProgress,
        handleUrl,
      })
    );
  };

  
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    files.forEach((file) =>
      processFile({
        file,
        acceptedFileTypes,
        mediaBaseUrl,
        mediaFolder,
        userId,
        handleFileData,
        handleProgress,
        handleUrl,
      })
    );
  };

  return (
    <div 
      onDragOver={(e) => {
        e.preventDefault();
        setDragOver(true);
      }}
      onDragLeave={() => setDragOver(false)}
      onDrop={handleDrop}
      style={{
        position: "relative",
        padding: '0px',
        borderRadius: '8px',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        // border: dragOver ? '2px solid #2196f3' : '2px dashed #ccc',boxSizing: 'border-box',
      }}
    >
      {children}
      <input
        id="file-input"
        style={{
          opacity: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',cursor: 'pointer',
        }}
        type="file"
        multiple
        accept={acceptedFileTypes.map(type =>
          type.includes("/") || type.startsWith(".") ? type : `${type}/*`
        ).join(",")}
        onChange={handleFileChange}
      /> 
    </div>
  );
};



export function InputFileMedia({ handleUrl,
  handleFileData,
  handleProgress,
  acceptedFileTypes = ["image", "video", "csv"],
  mediaBaseUrl = "uploads",
  mediaFolder = "user",
  children,}){
    const { stateApp } = useAppInfo();
    const { userId } = stateApp;
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    files.forEach((file) =>
      processFile({
        file,
        acceptedFileTypes,
        mediaBaseUrl,
        mediaFolder,
        userId,
        handleFileData,
        handleProgress,
        handleUrl,
      })
    );
  };
  return(
        <input
        id="file-input"
        style={{
          opacity: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',cursor: 'pointer',
        }}
        type="file"
        multiple
        accept={acceptedFileTypes.map(type => `${type}/*`).join(",")}
        onChange={handleFileChange}
      /> )
}


export function UploadMediaButton({ handleUrl,
  handleFileData,
  handleProgress,
  acceptedFileTypes = ["image", "video", "csv"],
  mediaBaseUrl = "uploads",
  mediaFolder = "user",
  children,}){
    const { stateApp } = useAppInfo();
    const { userId } = stateApp;
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    files.forEach((file) =>
      processFile({
        file,
        acceptedFileTypes,
        mediaBaseUrl,
        mediaFolder,
        userId,
        handleFileData,
        handleProgress,
        handleUrl,
      })
    );
  };
  return(
    <Button
    role={undefined}
    variant="contained"
    component="label" color="primary"
    >
        <input
        id="file-input"
        style={{
          opacity: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',cursor: 'pointer',
        }}
        type="file"
        multiple
        accept={acceptedFileTypes.map(type =>
          type.includes("/") || type.startsWith(".") ? type : `${type}/*`
        ).join(",")}
        onChange={handleFileChange}
      /> 
      {children || "Upload"}
    </Button>)
}



// Option2
//     <Button
//       component="label"
//       variant="contained"
//       startIcon={<CloudUploadIcon />}
//     >
//       Upload file
//       <VisuallyHiddenInput
//         type="file"
//         accept="image/*,video/*,.csv"  // Allow images, videos, and CSV files
//         onChange={handleFileChange}
//       />
//     </Button>
// const VisuallyHiddenInput = styled('input')({
//   clip: 'rect(0 0 0 0)',
//   clipPath: 'inset(50%)',
//   height: 1,
//   overflow: 'hidden',
//   position: 'absolute',
//   bottom: 0,
//   left: 0,
//   whiteSpace: 'nowrap',
//   width: 1,
// });
