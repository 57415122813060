const initialstateReportBuilder = {
   pages:[],
   addPage:false,
   display:"vertical",
   pageCurrent:0,
   draggedItems:[],
   icons:[],
   elements:[],
   txt:[],
   table:[],
   charts:[],
   uploads:{},
   dragging:false,
   draggingObject:false,
   leftSidebar:false,
   leftSidebarContent:"Templates",
   pageFooter:true,
   dragId:undefined,
   reportNames:[],
   addNewReport:false,
   reportNameSel:"",
   reportData:{},
   objectInfocus:"",
   reportLoaded:false,
   reportSaved:false,
   deleteReport:false,
   stopEdit:false,
   defaultReports:[],
   defaultReportsLoaded:false
  };

export {initialstateReportBuilder}