import { Button } from '@mui/material'
import { useAppInfo } from 'AppState'
import { defaultFieldBlank, expenseTypes } from 'appState/initialStateMyFinancials'
import AddFinancialsModal from 'myFinancials/Utils/AddFinancialsModal'
import FieldFormGen from 'myFinancials/FormFields/FieldFormGen'
import React, { useState } from 'react'

export default function AddExpense() {
const {stateMyFinancials} = useAppInfo()
const {expenseMonthly} = stateMyFinancials
  const [add,setAdd] = useState(false)

  return (
    <>
    <Button sx={{width:"max-content"}} variant='contained' onClick={()=>setAdd(true)}>+ Expense</Button>
    {add && <AddFinancialsModal title="Expense" onClose={()=>setAdd(false)}>
    <FieldFormGen 
  defaultField={defaultFieldBlank.Expense} 
  showType
  typeOptions={expenseTypes}
  date={true}
  allocation={true} forecastPeriod={true} growthRate={true} period={true} 
  storeName={"expenseMonthly"} data={expenseMonthly} 
  formName='Expense' formHeading='Expense Category'/>
    </AddFinancialsModal>}
    </>
  )
}