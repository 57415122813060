import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { financialModelingPrepApiKey } from '../keys';

export default function useCompanyTranscriptsDates(ticker) {
  const [companyTranscriptsDates, setCompanyTranscriptsDates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (ticker) {
        const data = await fetchCompanyTranscriptDates(ticker);
        setCompanyTranscriptsDates(data);
      }
    };

    fetchData();
  }, [ticker]);

  return { companyTranscriptsDates };
}

export const fetchCompanyTranscriptDates = async (ticker) => {
  try {
    const response = await axios.get(
      `https://financialmodelingprep.com/api/v4/earning_call_transcript?symbol=${ticker}&apikey=${financialModelingPrepApiKey}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching company transcript dates:', error);
    return []; // Return an empty array in case of error
  }
};
