import React, { useState } from 'react';

import { useAppInfo } from 'AppState';
import TabLine from 'components/Tabs/TabLine';
import {
  defaultMetrics,
  dfin,
} from 'content/constants';
import CompanyFinancialsUI from 'analytics/ticker/stocks/CompanyFinancialsUI';

import CompanyVsIndustryUI from '../../VsIndustry/CompanyVsIndustryUI';
import {PageMainContentTitle} from 'components/Text/Title';

export default function CompanyProfitability() {
  const { stateStock } = useAppInfo();
  const {annualInfo} = stateStock
  const statementInfoCo = annualInfo
  .filter((obj) => obj[dfin.ticker] === stateStock.companySelected)
  .sort((a, b) => b.PERIOD.toString().localeCompare(a.PERIOD.toString()))
  .slice(0, 10);

  const [tabIndex,setTabIndex] = useState(0)

  const tabContent = []

  tabContent[0] = <>
   {statementInfoCo && 
    <div style={{width:"100%",height:"50%"}}>
    <CompanyFinancialsUI report={false} chart={"area"} metricSel={[dfin.grossProfit,dfin.ebitda,dfin.netIncome]}/>
    </div>}
  {statementInfoCo &&
    <div style={{width:"100%",height:"50%"}}>
    <CompanyFinancialsUI report={false} chart={"column"} 
    metricSel={[dfin.grossProfitMargin,dfin.ebitdaMargin,dfin.netProfitMargin,]}/>
    </div>}
 
  </>

  tabContent[1] = <CompanyVsIndustryUI defaultMet={defaultMetrics.Profit}/>

  return (
    <div className='containerColumnFlexNoGap'>
    <PageMainContentTitle>Profitablitity</PageMainContentTitle>
    <TabLine tabList={["Standalone","vs Industry"]} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>
    <>
    {tabContent[tabIndex]}
    </>
   </div>
  )
}
