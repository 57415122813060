import React, { useEffect, useState } from 'react'
import { Delete } from '@mui/icons-material'
import GoalIcons from './GoalIcons'
import RatingBar from 'components/RatingBar'
import PopoverButton from 'components/Modal/PopoverButton'
import { formatNumber, getDateString, getSpecificArrayObjKeys } from "utils/utilGeneral"
import CashflowChart from '../Cashflow/CashflowChart'
import { useAppInfo } from 'AppState'
import { IconButton, Tooltip } from '@mui/material'
import  { useHandleDeleteField } from 'myFinancials/FormFields/useFieldForm'
import { defaultFieldBlank } from 'appState/initialStateMyFinancials'
import GoalIndividualDetailEdit from './GoalIndividualDetailEdit'



export default function GoalIndivdiualDetails({edit=true}) {
    const {stateMyFinancials,} = useAppInfo()
    const {goalsPeriodic,currency,goalAnalytics} = stateMyFinancials  
    
    const {perGoalDetails,goalFunding} = goalAnalytics
    
    const postWithdrawal =  goalFunding === "netCashAfterWithdrawal" ? true : false

    const {handleDeleteField} = useHandleDeleteField({storeName:"goalsPeriodic"})
    
   
    function handleDeleteGoal(goal){
        handleDeleteField(goal?.id)
    }
    const [mouseEnter,setMouseEnter] = useState(-1)
  return (
    <div style={{position:"relative",display:"flex",flexDirection:"column",gap:"1rem"}}>
    <table style={{tableLayout:"fixed"}}>
      <thead>
        <tr>
          
          <th style={{width:"auto"}}>Goal</th>   
          <th>Priority</th> 
          {/* <th style={{width:"auto"}}>Success Rate</th>   */}
          <th>Cash Outflow</th> 
          <th>Period</th>
          <th>Projection</th>
          {edit && <th>Edit/Delete</th>}
        </tr>
      </thead>
      <tbody>
      {perGoalDetails && perGoalDetails.map((perGoal,index)=>
        <tr 
        onMouseEnter={()=>setMouseEnter(index)} 
        onMouseLeave={()=>setMouseEnter(-1)}
        key={index} 
        style={{padding:"0.25rem"}}>
          
          <td>
          <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
          <div style={{width:"50px"}}>
          <GoalIcons width='60px' iconType={perGoal?.type}/>
          </div>
          <div style={{maxWidth:"100px",minWidth:"100px"}}>  
          <h4 style={{fontWeight:"bold",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{perGoal?.name}</h4>
          <h5 style={{fontWeight:"bold",color:"gray"}}>{perGoal?.type}</h5>
          </div>
          <div style={{paddingLeft:"1rem"}}>
          
          <h5 style={{fontWeight:"bold"}}>Success Rate:{formatNumber(postWithdrawal? perGoal?.successRateAfterWithdrawal:perGoal?.successRateBeforeWithdrawal)}%</h5>
          <RatingBar
          totalScore={100}
          width='60px'
          score={postWithdrawal? perGoal?.successRateAfterWithdrawal:perGoal?.successRateBeforeWithdrawal} scoreMax={100}/>
          </div>
          </div>
         
          </td>
          
        <td>
          {perGoal?.priority}
          </td>
         
          <td>
          {currency?.symbol}{perGoal?.amount} 
            </td>
          <td>
          <h5>
            {perGoal?.period === "One Time" ? 
            `${perGoal?.period} - ${getDateString(perGoal?.date)}`:
            `${perGoal?.period} for ${perGoal?.forecastPeriod} years starting ${getDateString(perGoal?.date)}`
            }
            </h5>
          </td>
          
          <td>
          
          <PopoverButton iconSize='small' icon="chart" 
          sxBtn={{fontSize:"0.8rem",minWidth:0}} title='Projection' variant='highlight'>
          <div style={{height:"300px",width:"500px"}}>
            <CashflowChart data={perGoal?.cashflow}/></div></PopoverButton>
          </td>
          {edit && <td>
          <div style={{display:"flex",gap:"0.5rem"}}>
          <PopoverButton iconSize='small' icon='edit' sxBtn={{fontSize:"0.8rem",minWidth:0}} title='Edit/Details' variant='highlight'>
          <GoalIndividualDetailEdit goalData={[...goalsPeriodic].filter(obj=>obj.id===perGoal.id)[0]}/>
          </PopoverButton>
            <Tooltip title={`Delete Goal`}>
            <IconButton onClick={()=>handleDeleteGoal(perGoal)}>
            <Delete fontSize='small' style={{color:"gray"}}/>
            </IconButton></Tooltip>
            </div>
          </td>}
        </tr>
    
    )}
      </tbody>
    </table>
   

    </div>
  )
}


