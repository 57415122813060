import { useAppInfo } from 'AppState'
import { fetchMarketNews } from 'data/financialModelingPrep/News/useMarketNews'
import React from 'react'
import { generateUniqueId } from 'utils/utilGeneral'

export default function useAssistantNewsEvents() {
    const {dispatchAssistant} = useAppInfo()
    function GetMarketNews({newsType}) {
        // const newsInfo = await fetchMarketNews(newsType)
        const react_id = generateUniqueId()
        dispatchAssistant({componentProps:{id:react_id,newsType}})
        return {react_component:"News",react_id}
      }

      function GetTickerNews({newsType}) {
        // const newsInfo = await fetchMarketNews(newsType)
        const react_id = generateUniqueId()
        dispatchAssistant({componentProps:{id:react_id,newsType}})
        return {react_component:"News",react_id}
      }

   return {GetMarketNews}
}
