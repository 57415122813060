import {useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';


const useAddFinancialProfessional = () => {
  const {stateApp} = useAppInfo()
  const userId = stateApp.userId
const username = stateApp.username
  const [error, setError] = useState(null);
  
  const addFinancialProfessionalDetails = async (data) => {
    try {
    const requests =await axios.post(`${BASE_URL}/addFinancialProfessional/`, {
          data:{userId,username,...data},
        })

      setError("Success")
    } catch (err) {
      setError('Error adding Report data');
    }
  };

  return { addFinancialProfessionalDetails, error };
};

export default useAddFinancialProfessional;