export default function ComingSoon({name}) {
    return (
      <>
        <div style={{
          display: "flex",
          width: "100%",
          position: "relative",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <div style={{position:"absolute",width:"100%",height:"100%",
        backgroundImage: "url(https://images.unsplash.com/photo-1614332287897-cdc485fa562d?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
        backgroundSize: "contain", // This property ensures the background image covers the entire div
        backgroundRepeat: "repeat", // Prevents the background image from repeating
        height: "100%",
        opacity: 0.65}}></div>
          <h1 style={{
            textAlign: "center",
            color: "white",
            fontSize: "2rem",
            opacity: 1,
            // background:"black",
            width:"100%",
            padding:"1rem",
            zIndex: 1 // Setting a higher z-index to make sure it's in front
          }}>{name}</h1>
          {/* <h1 style={{paddingTop:"4rem",fontSize:"2.5rem"}}>{`${pageMain} ${pageName} currently not available. Coming Soon!`}</h1> */}
        </div>
      </>
    );
  }