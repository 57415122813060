import React, { useEffect, useState } from 'react';
import { useAppInfo } from 'AppState';
import { TotalAmount } from './Utils/Totals';
import { Alert } from '@mui/material';
import WhatshotIcon from '@mui/icons-material/Whatshot';

export default function AlertMyFinancials() {
    const {stateMyFinancials} = useAppInfo()
    const {warnings,demoProfile,financialView} = stateMyFinancials
    
    return (
        <div style={{width:"100%",display:"flex",flexDirection:"column",gap:"1rem"}}>
            {financialView !== "personal" && 
            <h3>
            {`You are viewing Alerts for Demo Profile - ${demoProfile}`}</h3>}
            {financialView === "personal" && 
            <h3>
            {`Alerts`}</h3>}
            {warnings.length === 0 && <Alert>No Alerts!</Alert>}
            {warnings.length> 0 && 
            <table style={{width:"100%",tableLayout:"fixed"}}>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Issue</th>
                        <th>Comment</th>
                        <th>Action</th>
                        <th>Priority</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {warnings.map((warning, index) => (
                        <tr key={index}>
                            <td>{warning.type}</td>
                            <td>{warning.issue}</td>
                            <td>{warning.warning.map((obj,index)=>
                            <Alert sx={{whiteSpace:"normal",wordBreak:"break-word",overflow:"hidden"}} key={index} severity={"warning"}>
                                {obj}
                            </Alert>
                        
                           )}</td>
                            <td>{warning.action.map((obj,index)=>
                                <Alert  key={index} style={{whiteSpace:"normal",wordBreak:"break-word",overflow:"hidden"}}>{obj}</Alert>                   
                        )}</td>
                            <td>{warning.priority === "High" ? <><WhatshotIcon sx={{color:"red"}}/><WhatshotIcon sx={{color:"red"}}/><WhatshotIcon sx={{color:"red"}}/></> : warning.priority === "Medium"?<><WhatshotIcon sx={{color:"red"}}/><WhatshotIcon sx={{color:"red"}}/></>: <WhatshotIcon sx={{color:"red"}}/>}</td>
                            <td><textarea style={{boxSizing:"borderBox",width:"100%",outline:"none",border:"1px solid #ddd",height:"100%"}}></textarea></td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </div>
    );
}