import React from 'react'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Button, IconButton } from '@mui/material';
import { useAppInfo } from 'AppState';
import BorderBox from 'components/Layout/BorderBox';
import { useNavigate } from 'react-router-dom';
import InfoBox from 'components/Layout/InfoBox';

export default function OverviewBox({header,children,navigateUrl,showNavigate=true}) {
  const navigate = useNavigate()
  function handleNavigate(){
    if (navigateUrl){
    navigate(navigateUrl)}
  }

  return (
    <InfoBox>
      <div style={{display:"flex",flexDirection:"column",height:"auto"}}>
       <div style={{display:"flex",gap:"1rem",alignItems:"center",flexWrap:"wrap"}}> 
       <h3 style={{fontWeight:"bold"}}>{header}</h3>
       {showNavigate && <Button endIcon={<ArrowOutwardIcon/>} sx={{marginLeft:"auto"}} onClick={handleNavigate}>
        Manage
       </Button>}
       </div>

        <div style={{padding:"0.25rem",flex:1,boxSizing:"border-box"}}>
        {children}
        </div>
        </div>
    </InfoBox>
  )
}
