import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Avatar, Card, CardContent, List, ListItem, ListItemText, Stack, IconButton } from '@mui/material';
import { VerifiedUser, Star } from '@mui/icons-material';
import ImageCarousel from './ImageCarousel';
import TabLine from 'components/Tabs/TabLine';
import Reviews from './Reviews/Reviews';
import { LocationOn, Email, Phone, Language, LinkedIn, Facebook, Instagram, WhatsApp } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { financeProfessionalFormDetails } from './FinanceProfessionalFormFields';
import MediaCarousel from './MediaCarousel';
import InfoBox from 'components/Layout/InfoBox';
import { professionalDetailsArray } from './info';


const StickyProfileSummary = ({ info, tabIndex, handleTabChange }) => (
  <Box sx={{ position: 'sticky', top: 0, zIndex: 1, bgcolor: 'white', p: 1,boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'}}>
    <Box>
      <div style={{ display: "flex", gap: "1rem" }}>
        <Avatar src={info.profileImage} alt={info.name} sx={{ width: 50, height: 50 }} />
        <Box style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
          <div>
            <Typography variant="h6">{info.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {info.role} | {info.organization}
            </Typography>
          </div>
          <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", marginLeft: "auto" }}>
            <VerifiedUser sx={{ color: 'green' }} />
            <Star sx={{ color: '#f1c40f' }} />
            <Typography variant="body2" color="textSecondary">{`${info?.reviewsStats?.averageRating} (${info?.reviewsStats?.totalReviews})`}</Typography>
          </div>
        </Box>
      </div>
    </Box>
  </Box>
);

const FinanceProfessionalBanner = ({details=financeProfessionalFormDetails}) => {
  const {idProfessional} = useParams()
  const [tabIndex, setTabIndex] = useState(0);
  const [showStickyProfile, setShowStickyProfile] = useState(false);
  const [info,setInfo] = useState(financeProfessionalFormDetails)

  useEffect(() => {
    if (idProfessional) {
      const infoProfessional = professionalDetailsArray.find((item) => Number(item.userId) === Number(idProfessional));
      if (infoProfessional){
        setInfo(infoProfessional)}
    }
  },[idProfessional])
  const scrollToSection = (index) => {
    setTabIndex(index);
    const sectionIds = ["about", "coreExpertise", "services", "certifications", "qualifications","reviews"];
    const sectionId = sectionIds[index] || "reviews";
    const section = document.getElementById(sectionId);
  
    if (section) {
      const elementPosition = section.getBoundingClientRect().top + window.scrollY;
      // console.log("Scrolling to:", sectionId, "Position:", elementPosition);
      section.scrollIntoView({ behavior: 'smooth' })
    }
  };

  const profileSection = useRef(null);
  const [isProfileVisible, setProfileVisible] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setProfileVisible(entry.isIntersecting);
      },
      { rootMargin: '-50px 0px 0px 0px' }
    );

    if (profileSection.current) {
      observer.observe(profileSection.current);
    }

    return () => {
      if (profileSection.current) {
        observer.unobserve(profileSection.current);
      }
    };
  }, []);

  return (
    <Box sx={{ p: 3, borderRadius: 2}}>
      {!isProfileVisible && (
        <StickyProfileSummary info={info} tabIndex={tabIndex} handleTabChange={scrollToSection} />
      )}

      <Box ref={profileSection} id="profileSection">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
        <Avatar
  src={info.profileImage}
  alt={info.name}
  sx={{
    width: 100,
    height: 100,
    border: '3px solid white',
    '& img': {
      objectFit: 'cover', // Ensures the image is fully contained within the Avatar
    },
  }}
/>
          <div>
            <Typography variant="h4" fontWeight="bold">{info.name}</Typography>
            <Typography variant="h5" color="textSecondary">{info.role} | {info.organization}</Typography>
            <div style={{ display: "flex", gap: "1rem", paddingTop: "0.5rem" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "0.25rem", color: "green" }}>
                <VerifiedUser sx={{ fontSize: '1.3rem' }} />
                <Typography variant="h6" color="green">Verified</Typography>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "0.25rem", color: "gray" }}>
                <Star sx={{ fontSize: '1.3rem', color: '#f1c40f' }} />
                <Typography variant="h6">{`${info?.reviewsStats?.averageRating} (${info?.reviewsStats?.totalReviews})`}</Typography>
              </div>
            </div>
          </div>
        </Box>
      </Box>

      <Box sx={{ padding: "1rem 0", paddingTop: "2rem" }}>
        <TabLine
          sx={{ overflow: "auto" }}
          tabIndex={tabIndex}
          handleTabChange={scrollToSection}
          tabList={["About", "Core Expertise", "Services", "Certifications", "Qualifications", "FAQ's", "Reviews"]}
        />
      </Box>

      <Box id="about" sx={{ mt: 2 }}>
        <Typography variant="h6" color="textSecondary">{info.descriptionLong}</Typography>
      </Box>

      <Box sx={{ mb: 3, height: "500px", p: "1.5rem 0" }}>
        <InfoBox sx={{ height: "100%" }}>
        <MediaCarousel mediaFiles={info?.marketingMedia}/>
        </InfoBox>
      </Box>

      <Box id="coreExpertise" sx={{ mt: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h6" fontWeight="bold">Core Expertise</Typography>
            <Stack direction="row" spacing={2} flexWrap="wrap">
              {info.coreExpertise.map((expertise, index) => (
                <Box key={index} sx={{ bgcolor: 'primary.light', color: 'white', p: 1, borderRadius: 1, mb: 1 }}>
                  {expertise}
                </Box>
              ))}
            </Stack>
          </CardContent>
        </Card>
      </Box>

      <Box id="services" sx={{ mt: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h6" fontWeight="bold">Services Offered</Typography>
            <List>
              {info.services.map((service, index) => (
                <ListItem key={index}>
                  <ListItemText primary={service.title} secondary={service.details} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Box>

      <Box id="certifications" sx={{ mt: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h6" fontWeight="bold">Certifications</Typography>
            <List>
              {info.certifications.map((cert, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={cert.issuingAuthority}
                    secondary={`Issued: ${cert.issuedDate} | Expires: ${cert.expiryDate}`}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Box>

      <Box id="qualifications" sx={{ mt: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h6" fontWeight="bold">Qualifications</Typography>
            <List>
              {info.qualifications.map((qualification, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${qualification.degree}, ${qualification.school}`}
                    secondary={`Graduated on ${qualification.issuedDate}`}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Box>
      <Box id="reviews" sx={{ mt: 4 }}>   
      <Reviews />
      </Box>
    </Box>
  );
};

export default FinanceProfessionalBanner;


function ContactInfo({ info }) {
    return(
        <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" fontWeight="bold">Contact Information</Typography>
          <List>
            <ListItem>
              <ListItemText primary={info.Address} secondary={<LocationOn fontSize="small" sx={{ mr: 1 }} />} />
            </ListItem>
            <ListItem>
              <ListItemText primary={info.Email} secondary={<Email fontSize="small" sx={{ mr: 1 }} />} />
            </ListItem>
            <ListItem>
              <ListItemText primary={info.PhoneNumber} secondary={<Phone fontSize="small" sx={{ mr: 1 }} />} />
            </ListItem>
            <ListItem>
              <Link href={info.Website} target="_blank" underline="none">
                <Typography color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Language fontSize="small" sx={{ mr: 1 }} />
                  Visit Website
                </Typography>
              </Link>
            </ListItem>
          </List>
          <Box sx={{ mt: 2 }}>
            <IconButton component="a" href={info.SocialMedia.linkedin} target="_blank"><LinkedIn /></IconButton>
            <IconButton component="a" href={info.SocialMedia.facebook} target="_blank"><Facebook /></IconButton>
            <IconButton component="a" href={info.SocialMedia.instagram} target="_blank"><Instagram /></IconButton>
            <IconButton component="a" href={info.SocialMedia.whatsapp} target="_blank"><WhatsApp /></IconButton>
          </Box>
        </CardContent>
      </Card>
    )}


    