import React, { useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings';
import useDeleteScreener from 'data/user/screener/useDeleteScreener';
import useAddScreener from 'data/user/screener/useAddScreener';
import { useAppInfo } from 'AppState';
import { Button } from '@mui/material';
import { applyFilters } from './utilsScreener';
import CrudModal from 'components/Modal/CrudModal';


export default function ScreenerSettings() {

    const { stateScreener, dispatchScreener,stateStock} = useAppInfo();
    
    const [settings, setSettings] = useState(false);
    const { addScreener } = useAddScreener();
    const { deleteScreener } = useDeleteScreener();


    function handleCreateScreen() {
        setSettings(false);
        dispatchScreener({ createScreen: !stateScreener.createScreen });
      }
    
    function handleAddScreener(screenerName) {
        addScreener(screenerName);
        handleCreateScreen();
        dispatchScreener({filterModal:true})
        const updatedDisp = {...stateScreener.screenerDataUser};
        updatedDisp[screenerName] = stateScreener.filters || {};
        const updatedUserNames = Array.from(new Set([...stateScreener.screenerNamesUser, screenerName]));
        
        dispatchScreener({
          screenerNameSel: screenerName,
          screenerDataUser: updatedDisp,
          screenerNamesUser: updatedUserNames,
          // filters: {},
          // filterData: stateScreener.stocks,
          currentPage: 1
        });
      }
    

    function handleDeleteScreen() {
        setSettings(false);
        const { screenerDataUser, screenerNamesUser, screenerNameSel } = stateScreener;
        deleteScreener(screenerNameSel);
    
        if (screenerNamesUser.length === 1) {
          dispatchScreener({
            screenerDataUser: {},
            screenerNamesUser: [],
            createScreen: true,
            filters: {},
            filterData: stateScreener.stocks,
            currentPage: 1,
            screenerNameSel: undefined
          });
        } else {
          delete screenerDataUser[screenerNameSel];
          const screenerNamesUpdate = Object.keys(screenerDataUser);
          const screenerNameSelUpdate = screenerNamesUpdate[0];
          const filtersUpdate = screenerDataUser[screenerNameSelUpdate];
          const filteredData = applyFilters(filtersUpdate, stateScreener.stocks);
    
          dispatchScreener({
            screenerDataUser: screenerDataUser,
            screenerNamesUser: screenerNamesUpdate,
            screenerNameSel: screenerNameSelUpdate,
            filters: filtersUpdate,
            filterData: filteredData,
            currentPage: 1
          });
        }
      }
  return (
    <>
    <Button sx={{ color: "black" }} onClick={() => setSettings(true)}><SettingsIcon /></Button>

    {settings && (
     <CrudModal 
     deleteSettings={stateScreener.screenerNamesUser.length > 0 && stateScreener.screenerNameSel}
     onClose={()=>setSettings(false)}
     modalType='settings' handleDelete={handleDeleteScreen}
     handleCreate={handleAddScreener}
     placeholder={"Enter a screener name"}
     title={"Screener"}/>
    )}

  {/* {stateScreener.createScreen && (<CrudModal 
  title={"Screener"} 
  placeholder={"Enter a screener name"}
  handleCreate={handleAddScreener} onClose={handleCreateScreen}/>)} */}
    </>
  )
}
