import MultiSelect from 'components/Inputs/MultiSelect';

const applyFilters = (filters,data) => {
    let filteredData = data;
    for (const key in filters) {

      const filter = filters[key];
      const { filterType, value } = filter;
      
      if (!filterType) continue; // Skip filtering if filterType is undefined
      
      filteredData = filteredData.filter((item) => {
        const itemValue = item[key];
        if (itemValue === null) {
            // Handle the case where itemValue is null
            return false; // You can choose to exclude null items from the result
          }
        if (typeof itemValue === "number" && filterType === "greaterThan") {
          return itemValue > value;
        } else if (typeof itemValue === "number" && filterType === "lessThan") {
            return itemValue < value;
        } 
        else if (typeof itemValue === "number" && filterType === 'inBetween') {
          // Check if the itemValue falls within the specified range
          const [min, max] = value;
          return itemValue >= min && itemValue <= max;
        }
        else if (typeof itemValue === "string" && filterType === "startsWith") {
          return itemValue.toLowerCase().startsWith(value.toLowerCase());
        } else if (typeof itemValue === "string" && filterType === "contains") {
          return itemValue.toLowerCase().includes(value.toLowerCase());
          
        }
        else if (typeof itemValue === "string" && filterType === "equals" && value !=="All") {
            return value === itemValue;} 
        else if (typeof itemValue === "string" && filterType === "multiSelect" && !value.includes("All")) {
                return value.includes(itemValue);} 
        else {
          return true; // No filter applied for other data types
        }
      });
    }
   
    return filteredData;
  };

const getUniqueValuesForKey = (key,data) => {
    const values = data.map((item) => item[key]);
    return ["All",...new Set(values)];
  };

  function createFilterObject(key, filterType, value) {
    return key ? { [key]: { filterType, value } } : {};
  }

  function NumberFilter({filters,filterMetric,handleFilterChange}){
    return (
    <div style={{display:"flex",gap:"0.5rem"}}>
                    <NumberFilterSelectBox 
                    value={filters?.[filterMetric]?.filterType}
                    onChange={(v)=>handleFilterChange(filterMetric, v, v === 'inBetween' ? [0, 0] : filters?.[filterMetric]?.value)}
                    />

                 {filters?.[filterMetric]?.filterType !== 'inBetween' && 
                    <NumberInput
                    value={filters?.[filterMetric]?.value}
                    onChange={(v)=>handleFilterChange(
                        filterMetric,
                        filters?.[filterMetric]?.filterType || "greaterThan",
                        +v)}
                    />
                    }
                {filters?.[filterMetric]?.filterType === 'inBetween' && (
                        <NumberInBetweenSelection values={filters?.[filterMetric]?.value} onChange={(v)=>handleFilterChange(filterMetric, 'inBetween', v)}/>
                )}
                  </div>)
}



function TextFilter({data,filters,filterMetric,handleFilterChange}){

    function handleMultiSelectChange(v, key) {
        let value = v 
        if (v.includes("All") && v.length>1){
          if (v[0]==="All"){
            value = v.filter(elem=>elem !=="All")}else{value=["All"]}
        }

        handleFilterChange(key,"multiSelect",value)
      }

    return (
        <MultiSelect
        limitTags={3}
        tags
        variant='outlined'
        values={filters && filters?.[filterMetric]?.value || ["All"]}
        options={getUniqueValuesForKey(filterMetric,data)}
        onChange={(v)=>handleMultiSelectChange(v,filterMetric)}
        />
    )
}

function NumberInput({value,onChange}){
    return (
        <input
                type="number"
                style={value?{border:"2px solid blue",padding:"0.5rem",width:"max-content"}:{padding:"0.5rem",width:"50px"}}
                value={value}
                onChange={(e) =>
                onChange(e.target.value)
                }
            />
    )
}

function NumberInBetweenSelection({values,onChange}){
   return(<div style={{display:"flex",width:"auto"}}>
    <input
      type="number"
      style={{ padding: '0.5rem',width:"50px"}}
      value={values[0]}
      onChange={(e) => {
        const newValue = [...values?.value];
        newValue[0] = +e.target.value;
        onChange(newValue);
      }}
    />
    <span>-</span>
    <input
      type="number"
      placeholder="end number"
      style={{ padding: '0.5rem',width:"50px" }}
      value={values[1]}
      onChange={(e) => {
        const newValue = [...values?.value];
        newValue[1] = +e.target.value;
        onChange(newValue);
      }}
    />
  </div>)
}

function NumberFilterSelectBox({value,onChange}){
    return (<select
    style={{ padding: '0.5rem' }}
    value={value}
    onChange={(e) =>
      onChange(e.target.value)
    }
  >
    <option value="greaterThan">Greater Than</option>
    <option value="lessThan">Less Than</option>
    <option value="inBetween">In Between</option>
  </select>)
}

function handleFilterChange(){
  
}



export {applyFilters,getUniqueValuesForKey,createFilterObject,TextFilter,NumberFilter}
