import { Typography } from '@mui/material';
import React, { useState } from 'react';

export default function TextArea({
  label,
  value,
  onChange,
  placeholder,
  height = "100%",
  required = true,
  resize = false,
  maxLength = null,  // New prop for max character limit
  sx = {},
}) {
  const [overflow, setOverflow] = useState(false);
  const [charCount, setCharCount] = useState(value.length);

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (!maxLength || newValue.length <= maxLength) {
      setCharCount(newValue.length);
      onChange(newValue);
    }
  };

  return (
    <div style={{ height, width: "100%", display: "flex", flexDirection: "column" }}>
      <Typography>{label}</Typography>
      <textarea
        onMouseEnter={() => setOverflow(true)}
        onMouseLeave={() => setOverflow(false)}
        required={required}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        style={{
          fontSize: "1rem",
          padding: "0.5rem",
          width: "100%",
          maxWidth: "100%",
          overflow: overflow ? "auto" : "hidden",
          boxSizing: "border-box",
          resize: resize ? "both" : "none",
          outline: "none",
          border: "1px solid #ddd",
          borderRadius: "4px",
          flex: 1,
          ...sx,
        }}
        name="textArea"
        id="textArea"
      ></textarea>
      {maxLength && (
        <Typography variant="caption" color={charCount >= maxLength ? "error" : "textSecondary"}>
          {`${charCount}/${maxLength} characters`}
        </Typography>
      )}
    </div>
  );
}


// import { Typography } from '@mui/material'
// import React, { useState } from 'react'

// export default function TextArea({label,value,onChange,placeholder,height="100%",required=true,resize=false,sx={}}) {
//   const [overflow,setOverflow] = useState(false)
//   return (
//     <div style={{height:height,width:"100%",display:"flex",flexDirection:"column"}}>
//     <Typography>
//         {label}</Typography>
//    <textarea 
//    onMouseEnter={()=>setOverflow(true)}
//    onMouseLeave={()=>setOverflow(false)}
//    required={required} value={value} onChange={(e)=>onChange(e.target.value)} 
//    placeholder={placeholder}
//    style={{ 
//    fontSize:"1rem", 
//    padding: "0.5rem",
//    width: "100%",maxWidth:"100%",overflow:overflow?"auto":"hidden"
//    ,boxSizing: "border-box", resize: resize ? "both":"none"
//    ,outline:"none",border:"1px solid #ddd",borderRadius:"4px",flex:1,...sx}} 
//    name="textArea" id="textArea">
//     </textarea>
//     </div>
//   )
// }
