import React, { useState } from 'react';
import { Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ImageCarousel from './ImageCarousel';
import { financeProfessionalFormDetails } from './FinanceProfessionalFormFields';
import { useNavigate, useParams } from 'react-router-dom';
import { professionalType } from './info';

export default function FinanceProfessionalCard({ cardData = financeProfessionalFormDetails}) {
  const {
    userId,
    name,
    role,
    organization,
    profileImage,
    loginDate,
    coreExpertise,
    reviewsStats,
    descriptionShort:description,
    professionalCategory,
    minimumFee,
  } = cardData;
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false);
  const {idProfessionalType} = useParams()
  const professionalUrl = professionalType.filter(obj=>obj.name === professionalCategory[0])[0]?.url

  function handleDetails(){
    if (idProfessionalType){
    navigate(`/AskProfessional/${idProfessionalType}/${userId}`)}
    else{
      navigate(`/AskProfessional/${professionalUrl}/${userId}`)}
  }

  function handleConnect(){
    handleDetails()
  }
 
  return (
    <div 
    onClick={(e)=>{e.preventDefault();handleDetails()}}
    onPointerEnter={() => setIsHovered(true)}
    onPointerLeave={() => setIsHovered(false)}
    style={{
      borderRadius: '12px',
      overflow: 'hidden',
      width: '350px',
      boxShadow: isHovered
          ? '0 12px 24px rgba(0, 0, 0, 0.15), 0 4px 8px rgba(0, 0, 0, 0.1)'
          : '0 8px 20px rgba(0, 0, 0, 0.08), 0 2px 6px rgba(0, 0, 0, 0.05)',
      transition: 'transform 0.3s, box-shadow 0.3s',
      cursor: 'pointer',
      backgroundColor: '#fafafa',
    }}>
      
      <div style={{height:"200px"}}>
      <ImageCarousel images={cardData?.marketingMedia.map((card) => card.url)}/>
      </div>
      <div style={{ backgroundColor: '#f5f5f5', padding: '16px' }}>
        <div style={{ display: 'flex', gap: '0.25rem', alignItems: "center" }}>
          <img src={profileImage} alt="Profile" style={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            marginRight: '16px',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
            objectFit: 'cover',
          }} />
          <div style={{ flex: 1 }}>
            <h3 style={{
              fontSize: '1.25rem',
              fontWeight: '600',
              margin: '0',
              color: '#2c3e50',
            }}>{name}</h3>
            <h3 style={{
              color: '#6b6b6b',
              fontSize: '1rem',
              fontWeight: '600',
            }}>{role}</h3>
            <h3 style={{
              fontSize: '1rem',
              color: '#95a5a6',
              fontWeight: '600',
            }}>{organization}</h3>
          </div>
        </div>
      </div>
      
      <div style={{ display: "flex", gap: "1rem", flexDirection: "column", padding: "16px" }}>
        <div style={{ display: "flex", gap: "1rem", width: "100%" }}>
          <div style={{
            display: "flex",
            fontSize: "1rem",
            gap: "0.25rem",
            color: "green",
            alignItems: "center"
          }}>
            <VerifiedUserIcon sx={{ fontSize: "1rem" }} />
            <h4 style={{ color: "green" }}>Verified</h4>
          </div>

          <div style={{
            display: "flex",
            fontSize: "1rem",
            gap: "0.25rem",
            color: "green",
            alignItems: "center"
          }}>
            <StarIcon style={{ color: '#f1c40f', fontSize: '1rem' }} />
            <h4>{reviewsStats?.averageRating ? `${reviewsStats.averageRating} (${reviewsStats.totalReviews})` : '4.8 (120)'}</h4>
          </div>
        </div>

        <h4>{description || "Financial Advisor focused on personalized financial solutions."}</h4>
        <h4>
          <strong>Core Expertise:</strong> <span>{coreExpertise.join(", ") || "Retirement Planning, Wealth Management"}</span>
        </h4>
        <h4>
          <strong>Min. Fees:</strong> <span>{minimumFee ? `${minimumFee}` : "$100/hr"}</span>
        </h4>
      </div>

      <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center', paddingBottom: '16px' }}>
        <Button 
        onClick={()=>handleConnect()}
        variant='contained'
        style={{
          borderRadius: '20px',
          padding: '6px 12px',
          fontSize: '0.875rem',
          textTransform: 'capitalize',
        }}>Connect</Button>
        <Button 
         onClick={()=>handleDetails()}
        style={{
          borderRadius: '20px',
          color: 'black',
          border: '1px solid #ddd',
          padding: '6px 12px',
          fontSize: '0.875rem',
          textTransform: 'capitalize',
        }}>View Profile</Button>
      </div>
    </div>
  );
}

// Dummy Data
const dummyData = {
  id: "1",
  loginDate: "2024-11-08",
  name: "Maria Doe",
  role: "Senior Financial Advisor",
  description: "Over 15 years of experience helping clients reach their financial goals.",
  coreExpertise: ["Retirement Planning", "Wealth Management", "Estate Planning"],
  organization: "Financial Insights Inc.",
  profileImage: "https://plus.unsplash.com/premium_photo-1670884442927-e647436e12ff?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHByb2Zlc3Npb25hbHxlbnwwfHwwfHx8MA%3D%3D",
  reviewsAggregate: "4.8 (120)",
  fees: [
    { basic: "$100/hr", details: [] },
    { standard: "$150/hr", details: [] },
    { premium: "$200/hr", details: [] },
    { ultra: "$300/hr", details: [] }
  ],
};

