import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { useAppInfo } from 'AppState'
import ProfileLogo from 'components/Logo/ProfileLogo'
import { useNavigate } from 'react-router-dom'
import MyFinancialsOverviewHome from 'myFinancials/MyFinancialsOverviewHome'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PortfolioMetricOverview from '../myFinancials/Portfolio/PortfolioMetricOverview'
import AlertButton from './AlertButton';
import ProfileCompletion from './ProfileCompletion'
import { demoAccounts } from './DemoAccounts'


export default function MyFinancialCard() {
    const {stateApp,dispatchSidebar,stateMyFinancials} = useAppInfo();
    const {financialView,demoProfile} = stateMyFinancials
    const {username} = stateApp
    const navigate = useNavigate();

  
    function handleNavigate(path) {
      if (["Sector & Industry", "News/Events", "Notification & Alerts", "Peers", "Watchlist", "Notes", "Ticker", "Market/Asset Class", "Economy"].includes(path)) {
        if (path === "News/Events") {
          navigate("/News");
        } else if (["Notification & Alerts", "Peers", "Watchlist", "Notes"].includes(path)) {
          dispatchSidebar({"sidebarOpen":true,"showSidebarRight":true,"sidebarRightContent":path.toLowerCase() });
        } else {
          navigate(`/Analytics/${path}`);
        }
      } else {
        navigate(`/${path}`);
      }
    }
  
  
    const buttons = [
      { label: "My Financials", action: "MyFinancials/Overview",content:<MyFinancialsOverviewHome/>},
      { label: "Investment Portfolio", action: "MyFinancials/Portfolio",content:<PortfolioMetricOverview/>},
    ];
  
  const demoProfileSelected = demoAccounts.filter(account => account.name === demoProfile)[0]

  return (
    <Card style={{ width: "100%",padding: "1rem",boxSizing:"border-box",flex:1}}>
    <div style={{ display: "flex",padding:"0 0.5rem",gap: "0.5rem",alignItems:"center",width:"100%"}}>
      {financialView === "personal" && 
      <>
      <ProfileLogo />
      <h3 style={{fontWeight:"bold"}}>{username}</h3></>}

      {financialView !== "personal" && 
      <>
      <Avatar src={demoProfileSelected?.img}/>
      <div>
      <h5>Demo Profile</h5>
      <h3 style={{fontWeight:"bold"}}>{`${demoProfileSelected?.name}`}</h3>
      </div>
      </>
      }
      {/* <IconButton style={{marginLeft:"auto"}}><MoreVertIcon/></IconButton> */}
    </div>
    <div style={{marginTop:"1rem",display: "flex", flexDirection: "column"
    , gap: "1rem", alignItems: "flex-start",width:"100%",flexWrap:"wrap"
    ,padding:"0 0.5rem",boxSizing:"border-box"}}>
       
      <ProfileCompletion displayType='main'/>

      {buttons.map((button, index) => (
        <div key={index} style={{display:"flex",flexDirection:"column",gap:"0.25rem",alignItems:"flex-start",width:"100%"}}>
        <Button 
        style={{
        padding:"0.5rem",
        borderRadius:0,
        textAlign:"left",justifyContent:"flex-start",borderBottom:"1px solid #ddd",width:"100%",fontWeight:"bold"}}  
        onClick={() => handleNavigate(button.action)}>
          {button.label}
          <ArrowOutwardIcon sx={{marginLeft:"auto",fontSize:"0.9rem"}}/>
        </Button>
        <div style={{width:"100%",paddingLeft:"0.75rem",boxSizing:"border-box"}}>
        {button.content}
        </div>
        </div>
      ))}

      <AlertButton/>
      {/* <FinancialAdvisorConnect/> */}
    </div>
  </Card>
  )
}


