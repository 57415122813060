import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { useAppInfo } from 'AppState';

export default function useUpdateSettings() {
    const {stateApp} = useAppInfo()
    

    async function UpdateSettings({data,dataName}) {

        try {
          const response = await axios.post(`${BASE_URL}/addUserSettings/`, {
            username: stateApp.username,
            userId: stateApp.userId,
            data: data,
            dataName:dataName
          });
          return response.data; // Return the response data if needed
        } catch (error) {
          console.error("Error updating onboarding status: ", error);
          return error; // Return the error object for further handling if needed
        }
      }
    
    return {UpdateSettings}
    
}