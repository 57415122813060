import useFinancialProducts from 'data/financialProducts/useFinancialProducts';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { PageMainLayout } from 'layout/PageLayout';
import FinancialProductInfoListTemplate from './FinancialInfoTemplate';
import { FinancialProductInfo } from './info';
export default function FinancialProducts() {
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname.split("/")
  const subPage = pathname[2]
   
  const {data} = useFinancialProducts({productType:subPage});
  const financialProductSel = FinancialProductInfo.filter(obj=>obj.productName===subPage)[0]
 
  return (
    <PageMainLayout>
      {data && <FinancialProductInfoListTemplate 
       productGeneralInfo={financialProductSel} data={data}/>}
    </PageMainLayout>
  )
}
