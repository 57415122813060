import React from 'react'
import ExploreQuestions from './ExploreQuestions'
import { useAppInfo } from 'AppState'
import Modal from 'components/Modal/Modal'

export default function ExploreQuestionsModal() {
    const {stateAssistant,dispatchAssistant} = useAppInfo()
    const {exploreQuestions} = stateAssistant
   
  return (
    <>{(exploreQuestions) && 
        <Modal width="80vw" height="80vh" 
        sx={{borderRadius:"5px"}}
        onClose={()=>dispatchAssistant({exploreQuestions:false})}> 
        
  <ExploreQuestions/>
        </Modal>
        }</>
  )
}