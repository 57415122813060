import React, { useEffect, useState } from 'react';
import {financeProfessionalFormDetails, InputCertifications, InputFAQ, InputLanguages, InputMultipleOptions, InputQualifications, InputServices, InputText, InputTextArea } from './FinanceProfessionalFormFields';
import { Button, Stepper, Step, StepLabel, Box } from '@mui/material';
import useAddFinancialProfessional from 'data/user/financeProfessional/useAddFinancialProfessional';
import { generateUniqueId } from 'utils/utilGeneral';
import UploadMedia from 'components/Upload/UploadMedia';
import MarketingMedia from './MarketingMedia';
import ProfilePictureUpload from './ProfilePictureUpload';
import ProfessionalCategorySelector from './ProfessionalCategorySelector';
import MediaCarousel from './MediaCarousel';
import useFindFinancialProfessional from 'data/user/financeProfessional/useFindFinancialProfessional';
import { coreExpertise } from './info';

const steps = [
  { step: 1, description: "Personal Info"},
  { step: 2, description: "Professional Details"},
  { step: 3, description: "About You" },
  { step: 4, description: "Certifications & Qualifications" },
  { step: 5, description: "Frequently Asked Questions" },
  { step: 6, description: "Marketing Media" },
  { step: 7, description: "Review & Submit"}
];

export default function FinanceProfessionalForm() {
  const { data, loading } = useFindFinancialProfessional();
  const { addFinancialProfessionalDetails } = useAddFinancialProfessional();
  const [formDetails, setFormDetails] = useState(financeProfessionalFormDetails);
  const [step, setStep] = useState(0);
  
  useEffect(() => {
    if (data && !loading && Object.keys(data).length > 0) {
      setFormDetails(data);
    }
  }, [data, loading]);
  const handleChange = (name, value) => {
    setFormDetails({ ...formDetails, [name]: value });
  };

  const handleSubmit = () => {

    if (step !== steps.length - 1){
      setStep(step + 1);
    } 
    else{
    addFinancialProfessionalDetails(formDetails);
  
    }
  };

  const renderStepContent = (currentStep) => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <ProfilePictureUpload profilePicture={formDetails?.profilePicture} onUploadSuccess={(v) => setFormDetails(prev => ({ ...prev, profilePicture: v }))} />
            <InputText fieldKey='name' label="Name" fieldObj={formDetails} handleChange={handleChange} />
            <InputText fieldKey='organization' label="Organization" placeholder='Your Organization Ltd.' fieldObj={formDetails} handleChange={handleChange} />
            <InputText fieldKey='role' label="Role" placeholder='Your Role: e.g. Senior Financial Advisor' fieldObj={formDetails} handleChange={handleChange} />
          </>
        );
      case 1:
        return (
          <>
            <ProfessionalCategorySelector handleCategorySelectionChange={(v) => setFormDetails(prev => ({ ...prev, professionalCategory: v }))} 
            categorySelected={formDetails?.professionalCategory}/>
            <InputMultipleOptions label='Core Expertise' options={coreExpertise} 
            fieldKey={"coreExpertise"} fieldObj={formDetails} handleChange={handleChange} />
            <InputServices fieldObj={formDetails} handleChange={handleChange}/>
           
          </>
        );
      case 2:
        return (
          <>
            <InputTextArea maxLength={100} height='80px' fieldKey='descriptionShort' label="How can you help?" placeholder='I can help you with... (200 characters max)' fieldObj={formDetails} handleChange={handleChange} />
            <InputTextArea maxLength={500} height='300px' fieldKey='descriptionLong' label="Detailed Description" placeholder='I have experience...' fieldObj={formDetails} handleChange={handleChange} />
            <InputLanguages fieldObj={formDetails} handleChange={handleChange}/>
          </>
        );
      case 3:
        return (
          <>
        <InputCertifications fieldKey={"certifications"} fieldObj={formDetails} handleChange={handleChange}/>
        <InputQualifications fieldKey={"qualifications"} fieldObj={formDetails} handleChange={handleChange}/>
        </>
      )
      case 4:
        return (
        <InputFAQ fieldObj={formDetails} handleChange={handleChange}/>
      )
      case 5:
        return (
          <>
            <MarketingMedia 
              handleDeleteFile={(v) => setFormDetails(prev => ({ ...prev, marketingMedia: prev.marketingMedia.filter(obj => obj.url !== v) }))}
              onUploadSuccess={(v) => setFormDetails(prev => ({ ...prev, marketingMedia: [...prev.marketingMedia, v] }))}
            />
            <div style={{height:"500px",width:"100%"}}>
            <MediaCarousel mediaFiles={formDetails?.marketingMedia} /></div>
          </>
        );
      case 6:
        return <h3>Review all your information before submitting!</h3>;
      default:
        return <></>;
    }
  };

  return (
    <Box sx={{ width: '100%', boxSizing: 'border-box'
    ,height:"100%",display:"flex",flexDirection:"column"}}>
     
      <form style={{flex:1,paddingTop:"2rem",boxSizing:"border-box",width:"100%",display:"flex",flexDirection:"column",alignItems:"center"}} onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>

        <Box sx={{ display: 'flex', flexDirection: 'column',width:"50%",minWidth:"500px"
        ,boxSizing:"border-box",alignItems:"center", gap:'2rem'
          ,flex:1,justifyContent:"center"}}>
          {renderStepContent(step)}
        </Box>
        <Box  sx={{position:"sticky",padding:"2rem",
          bottom:0,background:"white",zIndex:"1000",width:"100%",boxSizing:"border-box",display:"flex",flexDirection:"column",gap:"3rem"}}>
       
      
        <Box sx={{display: 'flex', 
          gap:"1rem",justifyContent: 'center',alignItems:"center", width: '100%',boxSizing: 'border-box', marginTop: '1rem' }}>
            {step > 0 && <Button variant="outlined" onClick={() => setStep(step - 1)}>Previous</Button>}
            {step < steps.length - 1 && <Button  type="submit" variant="contained">Next</Button>}
            {step === steps.length -1 && <Button type="submit" variant="contained">Submit</Button>}
          </Box>
          <Stepper activeStep={step} alternativeLabel>
        {steps.map((stepObj, index) => (
          <Step key={stepObj.step}>
            <StepLabel>{stepObj.description}</StepLabel>
          </Step>
        ))}
      </Stepper>
      </Box>
      </form>
          
    </Box>
  );
}

