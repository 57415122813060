import { getAnalytics } from 'firebase/analytics';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB-K6-yDVjbJFddEtrZRdx2uC1n0VK8fSY",
    authDomain: "takestock.ai",
    projectId: "takestock-9e486",
    storageBucket: "takestock-9e486.appspot.com",
    messagingSenderId: "935908617321",
    appId: "1:935908617321:web:d219c884e8b5ea7a5c86f8",
    measurementId: "G-FHJHL80H6G"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

// setPersistence(auth, inMemoryPersistence)

export default app;
// Create a root reference
export const storage = getStorage();
export const db = getFirestore(app);
// Google Auth Provider
const googleProvider = new GoogleAuthProvider();

export {
  createUserWithEmailAndPassword,
  googleProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
};
