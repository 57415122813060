import React, { useState } from 'react'
import {
    Badge,
    Button,
    Tooltip,
  } from '@mui/material';
import Modal from 'components/Modal/Modal';
import AlertMyFinancials from './AlertMyFinancials';
import { useAppInfo } from 'AppState';
import { menuIcons } from 'nav/menuIcons';
import AlertSVG from 'svg/AlertSVG';

export default function AlertButton({displayType="main"}) {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {warnings,goalAnalytics,currency} = stateMyFinancials
    const [alertModal,setAlertModal] = useState(false)

  return (
    <>
    {(displayType === "main" && warnings.length>0) && <Button variant='highlight' onClick={()=>setAlertModal(true)} 
      sx={{display:"flex",gap:"0.5rem",color:warnings.length>0?"red":"green",fontSize:"0.9rem",justifyContent:"flex-start"}}>
     <>
      {menuIcons?.Alerts}
      <span style={{color:"red"}}>{`You have ${warnings.length} alerts`}</span>
      </>
    </Button>}
    {(displayType === "header" && warnings.length> 0) && <Tooltip title="Alert">
    <Button 
    sx={{minWidth:"0",padding:0,display:"flex"
    ,flexDirection:"column"
    ,textTransform:"none",color:warnings.length>0 && "#e56161"}} onClick={()=>setAlertModal(true)}>
      
      <Badge
      max={9}
  overlap="circular"
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  badgeContent={warnings.length}
  sx={{
    "& .MuiBadge-badge": {
      backgroundColor: "red",
      color: "white",
      width: "15px",
      height: "15px",
      fontSize: "0.8rem",
      borderRadius: "50%", // makes it circular
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }}
>
  {menuIcons?.Alerts}
</Badge>

      <span style={{fontSize:"0.8rem",color:"red"}}>Alerts</span>  
    </Button></Tooltip>}

    {(displayType === "sidebar") && 
    <Button 
    sx={{minWidth:"0",padding:0,display:"flex"
    ,flexDirection:"column"
    ,textTransform:"none",color:warnings.length>0 ? "#e56161" :"black"}} onClick={()=>setAlertModal(true)}>
      
      <Badge
      max={9}
  overlap="circular"
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  badgeContent={warnings.length}
  sx={{
    "& .MuiBadge-badge": {
      backgroundColor: warnings.length>0 ? "red" : "black",
      color: "white",
      width: "15px",
      height: "15px",
      fontSize: "0.8rem",
      borderRadius: "50%", // makes it circular
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }}
>
  {menuIcons?.Alerts}
</Badge>
    </Button>}
    {alertModal && 
    <Modal onClose={()=>setAlertModal(false)} width='90%'>
        <div style={{width:"100%",height:"100%",overflow:"auto"}}>
        <AlertMyFinancials/>
        </div>
    </Modal>
    }
    </>
  )
}
