import { useAppInfo } from 'AppState';
import { assetCode, assetMarket, dfin } from 'content/constants';
import PageHeaderIcons from 'nav/PageHeaderIcons';
import React from 'react'

export default function ActiveTickerButton({buttonType="Industry"}) {
    const { dispatchIndustry, stateIndustry, stateTicker,dispatchMarket} = useAppInfo();
    const { tickerSelInfo, tickerType } = stateTicker;
    
    function handleActiveTicker() {
        if (tickerType === assetCode?.Stocks && buttonType==="Industry") {
          dispatchIndustry({
            analysisOptionSel: "Active Ticker",
            sectorSel: [tickerSelInfo?.[dfin.gicSector]],
            industrySel: [tickerSelInfo?.[dfin.gicIndustry]],
            countrySel: [tickerSelInfo?.[dfin.country]],
            searchIndSec: false,
          });
        }
        if (buttonType==="Market"){
        dispatchMarket({"assetClassSel":assetMarket?.[tickerType]})}
        // handleModal()
      }
  return (
    <PageHeaderIcons onClick={handleActiveTicker} name="Active Ticker" 
    title={buttonType==="Market"?"See Market of Active Ticker":"See Industry/Sector of Active Ticker"}>
          
    </PageHeaderIcons>
  )
}
