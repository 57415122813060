import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState';
import { formatNumber } from "utils/utilGeneral";
import MyFinancialsHeaderTemplate from '../Template/MyFinancialsHeaderTemplate';
import { menuIcons } from 'nav/menuIcons';

export default function NetworthHeader({type="Networth",title="Networth",displayType="main"}) {
    const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
    const { summaryStats,currency,assets} = stateMyFinancials;
   
 const { netWorth, totalAssets, totalLiabilities, totalAssetTypes, totalLiabilityTypes } = summaryStats;
 
 const [details,setDetails] = useState({})

 useEffect(()=>{
  let summary = netWorth
  let icon = menuIcons?.Networth
  let color = "green"
  
  if (type === "Assets"){
   summary = totalAssets
   icon = menuIcons?.Asset
  }
 
  else if (type === "Liabilities"){
   summary = totalLiabilities
   icon = menuIcons?.Liability
   color = "red"
  }
 
  else {
   summary = netWorth
   icon = menuIcons?.Networth
   color = netWorth > 0 ? "green":"red"
  }

  setDetails({summary,icon,color})
 },[netWorth,totalAssets,totalLiabilities,type])



 
 return (
    <MyFinancialsHeaderTemplate name={title} 
    icon={details?.icon}
    >
<h3 style={{color:details?.color}}>{currency?.symbol} {formatNumber(details?.summary)}</h3>
    </MyFinancialsHeaderTemplate>
  )
}