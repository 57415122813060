import React, {useState } from 'react'
import useRevenueSegment from 'data/financialModelingPrep/useRevenueSegment'
import TabLine from 'components/Tabs/TabLine';
import { useAppInfo } from 'AppState';
import useRevenueSegmentGeo from 'data/financialModelingPrep/useRevenueSegmentGeo';
import TimeSeriesCategoryChart from 'components/Charts/TimeSeriesCategoryChart';
import NoTickerInfo from 'components/NoTickerInfo';
import {PageMainContentTitle} from 'components/Text/Title';

export default function RevenueSegment() {
  const { stateTicker ,stateStock} = useAppInfo();
  const {revenueSegment} = useRevenueSegment(stateTicker.tickerStock)
  const {revenueSegmentGeo} = useRevenueSegmentGeo(stateTicker.tickerStock)

  const [tabIndex,setTabIndex] = useState(0)
  const tabList = ["Product","Geography"]
  const {outputData:revenueProductSegment,metric} = revenueSegment.length>0 && convertData(revenueSegment)
  const {outputData:revenueGeoSegment,metricGeo} = revenueSegmentGeo.length>0 && convertData(revenueSegmentGeo)
  const metricSelectedGeo = revenueGeoSegment && Object.keys(revenueGeoSegment[0]).filter(key => key !== "DATE");
  const metricSelectedRevenue = revenueProductSegment && Object.keys(revenueProductSegment[0]).filter(key => key !== "DATE");
  const tabContent = []
  tabContent[0] = 
    <>
    {!revenueProductSegment && <NoTickerInfo/>}
    {revenueProductSegment && 
    <>
    <div style={{width:"100%",height:"100%"}}>
    <TimeSeriesCategoryChart data={revenueProductSegment} metricSelected={metricSelectedRevenue} chartType='column'/>
    </div>
    <div>
    {/* <CompanyFinancialsTable statementInfo={revenueProductSegment} statementMetric={metric} metricSelected={metric}/> */}
    </div>
    </>
    }
    </>
  
  tabContent[1] =     <>
  {!revenueGeoSegment && <NoTickerInfo/>}
  {revenueGeoSegment && 
  <>
  <div style={{height:"100%",width:"100%"}}>
  <TimeSeriesCategoryChart data={revenueGeoSegment} metricSelected={metricSelectedGeo} chartType='column'/>
  {/* <ColumnChart data={revenueGeoSegment} seriesName={"Geographic Segment"}/> */}
  </div>
  <div>

  {/* <CompanyFinancialsTable statementInfo={revenueProductSegment} statementMetric={metric} metricSelected={metric}/> */}
  </div>
  </>
  }
  </>

  
  return (
    <div className='containerColumnFlex'>
    <PageMainContentTitle>Revenue Segment</PageMainContentTitle>
    <TabLine height='40px' tabList={tabList} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>
    <div style={{flex:1,width:"100%",overflow:"auto"}}>
    {tabContent[tabIndex]}
    </div>
    </div>
  )
}
function convertData(inputData) {
    // Collect all unique category keys
    let allCategories = new Set();
    inputData.forEach(entry => {
        for (let date in entry) {
            let values = entry[date];
            for (let category in values) {
                allCategories.add(category);
            }
        }
    });

    // Convert the Set to an array
    let categoriesArray = Array.from(allCategories);
    let metric = categoriesArray.filter(obj=>obj !== "DATE")
    let outputData = [];
    inputData.forEach(entry => {
        for (let date in entry) {
            let values = entry[date];
            let outputEntry = { 'DATE': date };

            // Fill in all categories for each entry
            categoriesArray.forEach(category => {
                outputEntry[category] = values[category] || 0; // Fill empty ones as empty
            });

            outputData.push(outputEntry);
        }
    });

    // Sort the outputData based on the "Date" key in ascending order
    outputData.sort((a, b) => new Date(a.DATE) - new Date(b.DATE));

    return {outputData,metric};
}
