import React from "react";
import { useAppInfo } from "AppState";
import { dfin } from "content/constants";
import {PageMainContentTitle} from "components/Text/Title";
import { Typography } from "@mui/material";

export default function CompanyBusiness() {
  const { stateStock } = useAppInfo();
  const {coInfo} = stateStock
  let businessDescrip = coInfo?.[dfin.businessDescription];
  let companyName = coInfo?.[dfin.coName];
  return (
    <div className="containerColumnFlex">
      {businessDescrip && (
        <>
          <PageMainContentTitle>{companyName}'s Business</PageMainContentTitle>

          {<p style={{padding:"0 0.5rem",margin:0}}>{businessDescrip}</p>}
        </>
      )}
      <span style={{padding:"0.5rem"}}>Learn More: <a href={coInfo?.[dfin.coWebsite]} target="blank">{coInfo?.[dfin.coWebsite]}</a>
          </span>
    </div>
  );
}
