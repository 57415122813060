import React, { useState } from 'react';
import { useAppInfo } from 'AppState';
import { formatNumber } from "utils/utilGeneral";
import OverviewBox from '../Template/OverviewBox';
import NetWorthBalanceSheet from './NetWorthBalanceSheet';
import NetworthProjection from './NetworthProjection';
import NoFinancialData from 'myFinancials/Template/NoFinancialData';

export default function NetWorthOverview() {
    const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
    const { summaryStats,currency,assets,liabilities,financialsLoaded} = stateMyFinancials;
    const { netWorth, totalAssets, totalLiabilities, totalAssetTypes, totalLiabilityTypes } = summaryStats;
    const noData = totalAssets === 0 && totalLiabilities === 0;
    
    function handleNoData(v) {
        dispatchMyFinancials({
            menuSel: v,
            mainMenuSel: "Networth",
            subMenuSel: "Details"
          });
    }

    

    return (
        <div style={{position: "relative",width:"100%"}}>
          {financialsLoaded && (assets.length === 0 && liabilities.length === 0) &&
          <div style={{position:"relative",width:"100%",height:"100%",minHeight:"50vh"}}>
           <NoFinancialData financialType="networth"/></div>}

           {financialsLoaded && (assets.length > 0 || liabilities.length > 0) && (
            
            <OverviewBox sx={{ filter: noData && "blur(2px)" }}
            header={"Networth"}
            navigateUrl={"/MyFinancials/Networth"}
            >
                 
                <div style={{display:"flex",flexDirection:"column",gap:"1rem",flex:1,minWidth:"400px"}}>
                
                <div style={{display:"flex",gap:"1rem",background:"#fafafa",padding:"0.5rem"}}>

                
                <div style={{background:"#fafafa",padding:"0.5rem"
                    ,width:"max-content",borderRadius:"4px"}}>
                
                <h5 style={{fontWeight:"bold",color:"gray"}}> 
                    Current Networth<br/>
                    <span style={{color: netWorth >= 0 ? "green" : "red" }}>
                    {currency?.symbol} {formatNumber(netWorth)} 
                    </span>
                    </h5>
                    
                    <NetworthProjection displayType='overview'/>
                   
                </div>
                <div>
                <h4 style={{fontWeight:"bold"}}>{`Your current networth is ${currency?.symbol} ${formatNumber(netWorth)}`}</h4>
                <h4>{`Liabilities are ${currency?.symbol} ${formatNumber(totalLiabilities)} which are ${formatNumber(totalLiabilities/totalAssets * 100)}% of your Assets`}</h4>
                {/* <StackedBarChart assetAmount={totalAssets} 
                    liabilityAmount={totalLiabilities} networth={netWorth} strokeEmpty='red'/> */}

                </div>
                
                </div>

                <NetWorthBalanceSheet header={false} expanded={false} barChart/>
                </div>
            
            </OverviewBox> )}
        </div>
    );
}


const StackedBarChart = ({ assetAmount, liabilityAmount }) => {
  const networth = assetAmount - liabilityAmount;

  // Calculate the proportions relative to the total amount
  const total = assetAmount + liabilityAmount;
  const liabilityProportion = (liabilityAmount / total) * 100;
  const networthProportion = (assetAmount / total) * 100;

  const barContainerStyle = {
    display: 'flex',
    width: '200px',
    height: '10px',
    borderRadius: '10px', // Outer rounded corners
    overflow: 'hidden', // Ensures inner bars don't overflow the container
  };

  const barStyle = {
    height: '10px',
  };

  const liabilityStyle = {
    ...barStyle,
    width: `${liabilityProportion}%`,
    backgroundColor: 'red',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    display:"flex",
    justifyContent:"center"
  };

  const networthStyle = {
    ...barStyle,
    width: `${networthProportion}%`,
    backgroundColor: 'green',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    display:"flex",
    justifyContent:"center"
  };

  return (
    <div>
      <div style={barContainerStyle}>
        <div style={liabilityStyle}> 
        </div>
        <div style={networthStyle}></div>
      </div>
    </div>
  );
};
