import React from "react";
import { Box, Typography, List, ListItem, ListItemText, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { groupedNavLinks, navLinkCategories } from "nav/menu";
import TextWithBorders from "components/Text/TextWithBorders";

const SiteMap = () => {


  return (
    <div style={{padding:"2rem",width:"100%",boxSizing:"border-box"}}>
      <Typography variant="h4" align="center" gutterBottom>
        Site Map
      </Typography>
      <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
        {Object.keys(groupedNavLinks).map((section, index) => (
          <div key={index} style={{minWidth:"300px",borderRadius:"5px",padding:"1rem",boxSizing:"border-box"}}>
            <TextWithBorders text={section}/>
            <div style={{display:"flex",gap:"2rem",flexWrap:"wrap",paddingTop:"1rem"}}>
            {groupedNavLinks[section]?.map((page, subIndex) => (   
            <div key={subIndex}>
             <Link
                      to={`/${page?.link}`}
                      style={{
                        background:"#fafafa",padding:"1rem",borderRadius:"5px",
                        color: "var(--selectBlue)",fontSize:"1.2rem",display:"flex",alignItems:"center",gap:"0.5rem"}}
                    >
                    {page?.icon}
                    {page?.name}
            </Link>
            {page?.menu && page?.menu.length > 0 &&
              page.menu.map((subPage, subIndex) => (
                <div key={subIndex} style={{padding:"0.5rem"}}>
                   <Link
                      to={`/${page?.link}/${subPage?.link}`}
                      style={{color: "#003366" }}
                    >
                     {subPage?.name}
                    </Link>
                </div>
              ))}
            </div>
            ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SiteMap;

