import { Button, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFindGlossary from 'data/resource/useFindGlossary';
import { menuIcons } from 'nav/menuIcons';
import { PageContentWithLeftMenuLayout } from 'layout/PageLayout';
import PageHeader from 'layout/PageHeader';
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating';


const Glossary = () => {
  const [selectedLetter, setSelectedLetter] = useState("A");
  const {findGlossary, glossaryList, error,loading} = useFindGlossary()
  const navigate = useNavigate()
  const letters = [];
  for (let i = 65; i <= 90; i++) {
    letters.push(String.fromCharCode(i));
  }

  useEffect(()=>{
    findGlossary(selectedLetter)
  },[selectedLetter])
  
  function handleGlossary(obj){
    const topic = obj.Topic
    const heading = obj.Heading
    const subTopic = obj["Sub Topic"]
    const encodedHeading = encodeURIComponent(heading);
    const encodedTopic = encodeURIComponent(topic)
    const encodeSubTopic = encodeURIComponent(subTopic)
    navigate({pathname:`/Resource/Learn/`,search:`?topic=${encodedTopic}&subTopic=${encodeSubTopic}&heading=${encodedHeading}`})
  }

  const [overflow,setOverflow] = useState(false)

  return (
    <PageContentWithLeftMenuLayout
    showMenu={false}
    header={<PageHeader headerIcon={menuIcons?.Glossary} title={"Glossary"}/>}
    >
    
    <div 
    onMouseEnter={()=>setOverflow(true)}
    onMouseLeave={()=>setOverflow(false)}
    style={{padding:"0.5rem",minWidth:"150px",overflow:overflow ? "auto" : "hidden"}}>
    <div 
    style={{display:"flex",gap:"0.2rem",flexDirection:"column",background:"#fafafa",padding:"0.25rem"}}>
      {letters.map(letter => (
          <Button  size="large" key={letter} onClick={() => setSelectedLetter(letter)}
          style={{padding:0,
          background:selectedLetter===letter&&"#ddd",fontSize:"1.5rem",color:selectedLetter===letter&&"black"}} >
            {letter}
          </Button>
        ))}
      </div></div>

      <Paper elevation={0} style={{flex:1,padding:"1rem",overflow:"auto"}}>
      <h1>{selectedLetter}</h1>

      <div style={{display:"flex",flexWrap:"wrap",gap:"1rem"}}>
      {glossaryList && glossaryList.map((obj)=><Button onClick={()=>handleGlossary(obj)} style={{fontSize:"1.3rem"}} key={obj.Glossary}>{obj.Glossary}</Button>)}
      </div>
      </Paper>
    </PageContentWithLeftMenuLayout>
  );
};

export default Glossary;