import BackgroundVideo from 'components/Media/BackgroundVideo'
import React from 'react'
import GetStartedButton from './product/GetStartedButton'
import Logo from 'logo/Logo'

export default function GrowFinanciallyToday() {
  return (
    <div style={{display:"flex"
        ,gap:"2rem",position:"relative",width:"100%"
        ,minHeight:"90vh",flexDirection:"column",overflow:"hidden"
        ,padding:"2rem",boxSizing:"border-box",alignItems:"center",
        justifyContent:"center",background:"black",zIndex:"1"}}>
        

        <Logo height='50px' width="50"/>
        <div style={{
        borderRadius:"5px",padding:"1rem",width:"90vw",textAlign:"center"}}>
      <h1 className='gradient_text' style={{fontSize:"7rem"}}>Grow financially <br/>today</h1><br/>
      <h1  style={{fontSize:"2rem",color:"white"}}>Spend 5 minutes now to simplify your finances for life.</h1>
      </div> 
      <GetStartedButton icon={false} sx={{fontSize:"1.5rem",borderRadius:"40px",padding:"1rem 2rem"}}/>
      <div  
style={{position:"absolute",width:"100%",height:"100%",top:0,left:0,zIndex:"-1"}}>
<BackgroundVideo/>
</div>
      </div>

  )
}
