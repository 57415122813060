import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";

const useFinancialProducts = ({ productType = "creditCard" } = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const findFinancialProducts = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${BASE_URL}/financialProduct/`,{params:{product:productType}});
      setData(response.data);
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findFinancialProducts();
  }, [productType]);

  return { data, loading, error, findFinancialProducts };
};

export default useFinancialProducts;


export async function fetchFinancialProducts(productType) {
  try {
    const response = await axios.get(`${BASE_URL}/financialProduct/`,{params:{product:productType}});
    return response.data;
  } catch (error) {
    console.error(error);
    return "Some issue finding the content."
  }
}


