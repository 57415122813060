import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import { useAppInfo } from 'AppState';
import React, { useEffect, useState } from 'react'
import styles from "./PriceTable.module.css";
import { dfin } from 'content/constants';
import { Tooltip } from '@mui/material';
import PriceTableCell from './PriceTableCell';
import LogoTicker from 'components/LogoTicker';

export default function PriceTable({data,handleDelete}) {
    const {stateTicker} = useAppInfo()
    const [tickerInfo, setTickerInfo] = useState([]);
    const handleTickerChange = useHandleTickerChange()
    const tickerSelected = stateTicker.tickerSelected;
    
    useEffect(()=>{
        setTickerInfo(data)
    },[data])

    function handleDragStart(e, index) {
        e.dataTransfer.setData("dragIndex", index);
      }
    
    function handleDrop(e, newIndex) {
        e.preventDefault();
        const dragIndex = e.dataTransfer.getData("dragIndex");
        const updatedNavDetails = [...tickerInfo];
        const draggedItem = updatedNavDetails[dragIndex];
    
        // Remove the item from the old position
        updatedNavDetails.splice(dragIndex, 1);
    
        // Insert the dragged item at the new position
        updatedNavDetails.splice(newIndex, 0, draggedItem);
    
        setTickerInfo(updatedNavDetails);
      }
    
    function handleDragOver(e) {
        e.preventDefault();
      }

  return (
    <div
        // className={styles.tableContainer}
        style={{ position: "relative", overflow: "visible" }}
      >
    <table id="tablePeerList" className={styles.table}>
    <thead>
      <tr>
        <th>
          {/* Add a sort icon to indicate sorting direction */}
          <div>Ticker</div>
        </th>
        <th>
          <div>
            Last
            {/* <SortIcon order={sortOrder}  onClick={() => handleSort("TICKER")}/> */}
          </div>
        </th>
        <th>
          <div>Chg</div>
        </th>
        <th>
          <div>
            Chg %
            {/* <SortIcon order={sortOrder}  onClick={() => handleSort("TICKER")}/> */}
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      {tickerInfo.length > 0 &&
        tickerInfo.map((obj, index) => (
          <tr
            className={
              obj[dfin.ticker] === tickerSelected
                && styles["selectedRow"]
            }
            onClick={(e) => handleTickerChange({ticker:obj[dfin.tickerCode]})}
            key={`tab-${obj?.[dfin.ticker]}-${index}`}
            value={index}
            draggable // Enable dragging for the row
            onDragStart={(event) => handleDragStart(event, index)}
            onDragOver={(event) => handleDragOver(event, index)}
            onDrop={(event) => handleDrop(event, index)}
            // Add a class to highlight the dragged-over row
          >
            <td>
              {/* {obj[dfin.ticker]} */}
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <LogoTicker  tickerSel={obj[dfin.ticker]} />
                <Tooltip  placement="left-start" title={obj[dfin.coName]}>
                  {obj[dfin.ticker]}
                </Tooltip>
              </div>

            </td>
            <PriceTableCell
              key={`${obj[dfin.ticker]}-price`}
              data={obj}
              handleDelete={handleDelete}
            />
          </tr>
        ))}
    </tbody>
  </table>
  </div>
  )
}
