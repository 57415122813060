import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindDummyPortoflio = () => {
  const { dispatchPortfolio, stateApp,stateMyFinancials} = useAppInfo()
  const {demoProfile,financialView} = stateMyFinancials
  const userId = stateApp.userId
  const [data,setData] = useState()

  const findDummyPortfolio = async (demoName) => {
    try {
      dispatchPortfolio({'portfolioLoaded':false });
      if (userId) {
        const response = await axios.get(`${BASE_URL}/findDummyPortfolio/`, 
            {params: {
            dummyName:demoName
          }});

        const portfolio = response.data;
        const accountNames = Object.keys(portfolio);

        if (portfolio && accountNames.length > 0) {
          const portfolioSel = portfolio?.[accountNames[0]];
          dispatchPortfolio({
            portfolio: portfolio,
            portfolioSel: portfolioSel,
            accountNameSel: accountNames[0],
            accountNames: accountNames,portfolioLoaded:true
          });
        }
        setData(data)
      }
    } catch (err) {
        dispatchPortfolio({
            portfolio:{},
            accountNameSel:undefined,
            accountNames:[],
            portfolioSel:[]})
        dispatchPortfolio({'portfolioLoaded':false });
      // console.log(err);
    }
  };

  useEffect(() => {
    if (userId && demoProfile && financialView !== "personal") {
      const demoName = demoProfile ? demoProfile : "Ambitious Alex"
      findDummyPortfolio(demoName);
    }
  }, [userId,demoProfile,financialView]);



  return {data};
};

export default useFindDummyPortoflio;