import React from "react";
import Messages from "./Messages";
import Input from "./Input";

const Chat = () => {
  const [img,setImg] = React.useState(null);
  const [dragOver,setDragOver] = React.useState(false);
  function handleDragOver(e) {
    e.preventDefault();
    setDragOver(true);
  }

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    const file = droppedFiles[0];
    if (!file.type.startsWith('image/')) {
      alert("Only image files are allowed!");
      return;
    }
    setImg(file);
  };
  return (
    <div 
    onMouseLeave={(e) => setDragOver(false)}
    onDragLeave={(e) => setDragOver(false)}
    onDragOver={(e) => handleDragOver(e)}
    onDrop={(e)=>handleDrop(e)}
    style={{flex:1,width:"100%",display:"flex",flexDirection:"column"}}>
      <div style={{flex:1,width:"100%",overflow:"auto",padding:"0.5rem",boxSizing:"border-box"}}>
      <Messages />
      </div>
      <Input image={img}/>
      {dragOver && <div style={{position:"absolute",display:"flex",justifyContent:"center",alignItems:"center",top:0,left:0,height:"100%",width:"100%",zIndex:100,background:"rgba(0,0,0,0.5)"}}>
          <h3>Drag and Drop your file</h3>
      </div>}
    </div>
  );
};

export default Chat;