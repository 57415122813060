import React from 'react';
import { createTheme, ThemeProvider, Typography as MuiTypography } from '@mui/material';

// Create the theme with breakpoints and typography
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:768px)': {
        fontSize: '3rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '3.5rem',
      },
      '@media (min-width:1440px)': {
        fontSize: '4rem',
      },
    },
    h2: {
      fontSize: '1.75rem',
      '@media (min-width:600px)': {
        fontSize: '2.125rem',
      },
      '@media (min-width:768px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '3rem',
      },
      '@media (min-width:1440px)': {
        fontSize: '3.5rem',
      },
    },
    h3: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '1.75rem',
      },
      '@media (min-width:768px)': {
        fontSize: '2rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:1440px)': {
        fontSize: '3rem',
      },
    },
    h4: {
      fontSize: '1.25rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:768px)': {
        fontSize: '1.75rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '2rem',
      },
    },
    h5: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
      },
      '@media (min-width:768px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '1.75rem',
      },
    },
    h6: {
      fontSize: '0.875rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:768px)': {
        fontSize: '1.25rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '1.5rem',
      },
    },
    body1: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.125rem',
      },
      '@media (min-width:768px)': {
        fontSize: '1.25rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '1.375rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:768px)': {
        fontSize: '1.125rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '1.25rem',
      },
    },
    button: {
      fontSize: '0.875rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
    },
    caption: {
      fontSize: '0.75rem',
      '@media (min-width:600px)': {
        fontSize: '0.875rem',
      },
    },
    overline: {
      fontSize: '0.75rem',
      '@media (min-width:600px)': {
        fontSize: '0.875rem',
      },
    },
    subtitle1: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.125rem',
      },
      '@media (min-width:768px)': {
        fontSize: '1.25rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '1.375rem',
      },
    },
    subtitle2: {
      fontSize: '0.875rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:768px)': {
        fontSize: '1.125rem',
      },
      '@media (min-width:1024px)': {
        fontSize: '1.25rem',
      },
    },
    inherit: {
      fontSize: 'inherit',
    },
  },
});

// Custom Typography Component
const TypographyTS = ({ variant, children, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiTypography variant={variant} {...props}>
        {children}
      </MuiTypography>
    </ThemeProvider>
  );
};

export default TypographyTS;
