import React, { useState } from 'react'
import { useAppInfo } from 'AppState'
import { formatNumber } from "utils/utilGeneral"
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import OverviewBox from '../Template/OverviewBox'
import CircleScore from 'components/Charts/CircleScore'
import SelectBox from 'components/Inputs/SelectBox'
import FireSVG from 'svg/FireSVG'
import MoneySVG from 'svg/MoneySVG'
import PopoverButton from 'components/Modal/PopoverButton'
import CashflowChart from './CashflowChart'



export default function NetCashflowOverview({displayType="home"}){
  const navigateUrl = "/MyFinancials/Cashflow";

  return (
    <>
    {displayType === "home" && <OverviewBox header={"Cash Flow"} navigateUrl={navigateUrl}>
    <NetCashflowOverviewDetails/>
    </OverviewBox>}
    {displayType !== "home" && <NetCashflowOverviewDetails displayType={displayType}/>}
    </>
    
  )

}


export function NetCashflowOverviewDetails({displayType="home"}) {
  const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
  const navigate = useNavigate();
  const { summaryStats, incomeMonthly, expenseMonthly,currency,cashflowDistribution} = stateMyFinancials;
  const {incomeDist,expenseDist,assetReturnDist,liabilityDist,netCashflowDist} = cashflowDistribution
  const { totalAssetTypes, totalIncome, totalExpense, totalNetCashflow, 
    totalAssetReturnDist, totalLiabilityDist} = summaryStats;


  const [periodSel, setPeriodSel] = useState("m");

  const periodDict = { m: "monthly", q: "quarterly", y: "yearly" };
  const periods = ["m", "q", "y"];
  
  const tableData = [
    { label: "Beginning Cash Balance", data: totalAssetTypes?.Cash, color: "green",icon:<MoneySVG/>,projectionData:[]},
    { label: "Income", data: totalIncome, color: "green" ,icon:<MoneySVG/>,projectionData:incomeDist},
    { label: "Expected Return on Savings/Investment", 
      data: totalAssetReturnDist, color: "green",icon:<MoneySVG/>,projectionData:assetReturnDist},
    { label: "Expenses", data: totalExpense, color: "red",icon:<FireSVG/>,projectionData:expenseDist},
    { label: "Liabilities (Interest + Principal)", data: totalLiabilityDist, 
      color: "red" ,icon:<FireSVG/>,projectionData:liabilityDist},
    {
      label: "Net Cash",
      data: totalNetCashflow,
      icon: (totalNetCashflow?.m > 0 && totalNetCashflow?.q > 0 && totalNetCashflow?.y > 0) ? <MoneySVG /> : <FireSVG />,
      projectionData:netCashflowDist
    },
  ];


  const tablePeriodList = displayType === "home" ? [periodSel] : periods
  
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%",boxSizing: "border-box" }}>
          <div style={{ background: "#fafafa", display: "flex", gap: "1rem", padding: "0.5rem", borderRadius: "5px"}}>
            <div>
              <CircleScore
                score={totalNetCashflow?.[periodSel]}
                valueType="currency"
                unit={currency?.symbol}
                totalScore={totalIncome?.[periodSel]}
                title={`Net Cashflow (${periodDict[periodSel]})`}
              />
              <SelectBox
                label={"period"}
                variant="outlined"
                fontSize="small"
                onChange={(e,v) => setPeriodSel(v)}
                options={periods}
                value={periodSel}
              />
            </div>

            <div style={{ flex: 1 }}>
              <h4 style={{ fontWeight: "bold" }}>
                {`Your ${periodDict[periodSel]} net cash is ${currency?.symbol} ${formatNumber(totalNetCashflow?.[periodSel])}`}
              </h4>
              <div>
                {totalNetCashflow?.[periodSel] <= 0 && (
                  <span style={{ color: "red" }}>You currently don’t have enough cash to allocate to investment/savings.</span>
                )}
                {totalNetCashflow?.[periodSel] / totalIncome?.[periodSel] > 0.3 && (
                  <span style={{ color: "green" }}>
                    You will have net cash of more than 30% of your income to allocate to goals, savings & investments.
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* {displayType !=="home" && 
            <div style={{height:"400px"}}>
          <NetCashFlowProjection/>
              </div>
            } */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#fafafa",
              borderRadius: "5px",
              flex: 1,
              minWidth: "400px",
              padding: "0.5rem",
              boxSizing: "border-box",
            }}
          >
            <table style={{ width: "100%", borderCollapse: "collapse", background: "transparent" }}>
              <thead>
                <tr>
                  <th style={{ padding: "0.5rem 0" }}>Projected</th>
                  {tablePeriodList.map((period) => (
                    <th key={period} style={{ padding: "0.5rem 0" }}>
                      {periodDict[period].charAt(0).toUpperCase() + periodDict[period].slice(1)}
                    </th>
                  ))}
                  <th>Detailed Prjection</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td style={{ fontWeight: "bold", padding: "0.5rem 0" }}>
                      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                        {item.icon} {item.label}
                      </div>
                    </td>
                    {tablePeriodList.map((period) => (
                      <td
                        key={period}
                        style={{
                          fontWeight: "bold",
                          padding: "0.5rem 0",
                          color: item?.color ? item?.color : item.data?.[period] > 0 ? "green" : "red",
                        }}
                      >
                        {(formatNumber(item.data?.[period] || item.data) !== "N/A") ?  `${currency?.symbol} ${formatNumber(item.data?.[period] || item.data)}` : "-"}
                      </td>
                    ))}
                    <td>
                      {item?.projectionData?.length>0 && 
                      <PopoverButton title='Projection' icon='chart'>
                        <div style={{width:"500px",height:"300px"}}>
                        <CashflowChart title={`Projected ${item.label}`} data={item?.projectionData}/>
                        </div>
                       
                        </PopoverButton>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
}
