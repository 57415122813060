import React, { useEffect, useState } from 'react'
import { Button, IconButton, Typography } from '@mui/material'
import DefaultMessageBox from 'chatGPT/DefaultMessageBox'
import MessageBarBig from 'chatGPT/MessageBarBig'
import MessageTape from 'chatGPT/MessageTape'
import TypographyTS from 'components/Text/TypographyTS'


export default function HomePageChatLanding() {

  return (
    <>
   <div style={{ display: "flex", flexDirection: "column", 
    boxSizing: "border-box", position: "relative",background:"white",minHeight:"calc(100vh - 50px)"}}>

      
  <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", 
    padding: "1rem", gap: "2rem", flex: 1, 
     boxSizing: "border-box",paddingTop:"100px",zIndex:3}}>
    <TypographyTS variant='h3' sx={{ fontWeight: "bold", margin: 0, padding: 0,textAlign:"center"}}>
    TakeStock<br/><span className='gradient_text_green_dark' style={{fontWeight:"bold"}}>of your financial situation</span>
    </TypographyTS>

    <div style={{ width: "50%", maxWidth: "800px", minWidth: "400px" }}>
      <MessageBarBig />
    </div>

    <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
      <DefaultMessageBox />
    </div>
  </div>

  {/* Position MessageTape at the bottom */}
  <div style={{width: "100%",padding:"2rem",boxSizing:"border-box",position:"relative"}}>
    <MessageTape tickerTape={true}/>
  </div>
</div>
  </>
  )
}
