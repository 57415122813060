import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { financialModelingPrepApiKey } from '../keys';

export default function useCompanyTranscripts(ticker,year=2024) {
  const [companyTranscripts, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (ticker && year) {
        const data = await fetchCompanyTranscripts({ticker,year});
        setData(data);
      }
    };
    
    fetchData();

  }, [ticker,year]);

  return { companyTranscripts };
}


export const fetchCompanyTranscripts = async ({ticker,year=2024}) => {
  try {
    
    const response = await axios.get(
      `https://financialmodelingprep.com/api/v4/batch_earning_call_transcript/${ticker}?year=${year}&apikey=${financialModelingPrepApiKey}
      `
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return []
  }
};