import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FlagIcon from '@mui/icons-material/Flag';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function LikeShareReport() {
  return (
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      <Tooltip title="Like">
        <IconButton>
          <FavoriteIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share">
        <IconButton>
          <ShareIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Report">
        <IconButton>
          <FlagIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="More">
        <IconButton>
          <MoreHorizIcon color="primary" />
        </IconButton>
      </Tooltip>
    </div>
  );
}
