import React from 'react'
import FinanceProfessionalFilters from './Filters/FinanceProfessionalFilters'
import MessageBarProfessional from './Chat/MessgaeBarProfessional'
import { PageMainLayout } from 'layout/PageLayout'
import WorkIcon from '@mui/icons-material/Work';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { professionalType } from './info';

export default function FinanceProfessionalHome() {
  return (
    <>
    <div style={{display:"flex",flexDirection:"column",
    gap:"1rem",alignItems:"center"}}>
         <h1 style={{textAlign:"center",padding:"1rem"}}>
            Explore Professionals
        </h1>
        <FinanceProfessionalFilters/>
        <MessageBarProfessional/>
    </div>
    </>
  )
}


const ProfessionalExpertise = () => {
  const data = professionalType
  return (
    <div style={{ maxWidth: '900px', margin: '20px auto' }}>
      {data.map((professional) => (
        <div
          key={professional.url}
          style={{
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '16px',
            marginBottom: '20px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <WorkIcon style={{ color: '#1976d2', marginRight: '10px' }} />
            <h2 style={{ fontSize: '1.25rem', margin: 0 }}>{professional.name}</h2>
          </div>
          <div>
            {professional.coreExpertise.map((expertise, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px 0',
                }}
              >
                <CheckCircleIcon style={{ color: '#4caf50', marginRight: '8px' }} />
                <span style={{ fontSize: '0.95rem' }}>{expertise}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

