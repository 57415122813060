import React from 'react'
import SecurityIcon from "@mui/icons-material/Security";
import TargetIcon from "@mui/icons-material/TrackChanges";
import SpeedIcon from "@mui/icons-material/Speed";
import FavoriteIcon from '@mui/icons-material/Favorite';
import InsightsIcon from '@mui/icons-material/Insights';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import StreamIcon from '@mui/icons-material/Stream';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuestionsHome from './QuestionsHome';
import HomeFooterBig from './HomeFooterBig';

export default function WhyUs() {

    const scoreData = [
        {
          icon: <FavoriteIcon style={{ fontSize: "8rem", color: "#66BB6A" }} />, // Light Green
          title: "Personalized & Real-Time Financial Management",
          points: [
            "We tailor solutions to your unique financial goals and challenges.",
            "Providing specific, actionable guidance instead of generic answers.",
            "Adapting dynamically to changes in your life and market trends without limiting your options."
          ],
        },
        {
          icon: <InsightsIcon style={{ fontSize: "8rem", color: "#42A5F5" }} />, // Light Blue
          title: "Information Focused & Unbiased",
          points: [
            "Our focus is to empower your decision-making, not push trading or asset management.",
            "Delivering high-quality insights and strategies tailored to your financial context.",
            "Analyzing how every market and economic shift affects your finances, giving you clear, unbiased advice."
          ],
        },
        {
          icon: <SecurityIcon style={{ fontSize: "8rem", color: "#FFA726" }} />, // Light Orange
          title: "Secured",
          points: [
            "Your data is secured with robust encryption standards.",
            "We ensure transparent insights, straightforward pricing, and no hidden fees.",
            "Trustworthy practices that prioritize your privacy and security."
          ],
        },
        {
          icon: <StreamIcon style={{ fontSize: "8rem", color: "#AB47BC" }} />, // Light Purple
          title: "Holistic & Integrated",
          points: [
            "Integrating all aspects of your financial life into one comprehensive platform.",
            "Helping you connect the dots across investments, expenses, goals, and risks.",
            "A seamless, all-in-one approach to managing your finances effectively."
          ],
        },
        {
          icon: <AccessibilityIcon style={{ fontSize: "8rem", color: "#FF7043" }} />, // Soft Coral
          title: "Accessible & User-Friendly",
          points: [
            "Designed for ease of use, regardless of your financial expertise.",
            "Accessible from anywhere, on any device, with intuitive features.",
            "Empowering you to take charge of your finances with minimal effort."
          ],
        },
        {
          icon: <VisibilityIcon style={{ fontSize: "8rem", color: "#29B6F6" }} />, // Sky Blue
          title: "Transparent & Trustworthy",
          points: [
            "Complete transparency in data usage, pricing, and insights.",
            "Building trust through clear communication and reliable guidance.",
            "Helping you understand your finances without hidden surprises."
          ],
        },
      ];
      
  return (
    <div style={{overflow:"hidden",width:"100%",boxSizing:"border-box",position:"relative"
        ,display:"flex",flexDirection:"column",gap:"1rem"}}>
        <div style={{padding:"4rem",textAlign:"center",display:"flex",flexDirection:"column",gap:"1rem"}}>    
        <div style={{display:"flex",flexDirection:"column"
            ,alignItems:"center",justifyContent:"center",gap:"1rem"}}>
        <h1 style={{fontSize:"4rem",color:"black",maxWidth:"70vw"}}>Why Us?</h1>
        <h2 style={{maxWidth:"70vw"}}> We are not here to grow our asset under management or encourage more trading. <br/>
           We provide you with high-quality insights and guidance, empowering you to take stock of your finances.
       
        </h2>
        </div>
        
        

        <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
          position:"relative",width:"100%",
        }}
      >
{/* 
<div style={{position:"absolute",width:"100%",height:"100%",zIndex:0,top:"0%",left:0}}>
                <img src="https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/images%2FHome%2Fkisspng-watercolor-painting-drawing-image-vector-graphics-33-watercolor-circle-png-transparent-vol-3-only-5c00fccdbaab53.0637440015435685897646.png?alt=media&token=8204d778-4f91-4ff2-b505-e4891e47a826"
                    style={{width:"100%",height:"100%",objectFit:"contain"}}/>
                </div> */}

        {scoreData.map(({ icon, title, points }, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              padding: "20px",
            width: "350px",
              minWidth:"300px",
              color:"rgba(0, 0, 0, 0.5)",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              position:"relative",
            }}
          >

            <div style={{display:"flex",
              flexDirection:"column",gap:"1rem",zIndex:10,position:"relative",alignItems:"center"}}>
            <div style={{ marginBottom: "20px" }}>{icon}</div>
            <h3 style={{ fontSize: "1.5rem",color:"black"}}>{title}</h3>
            <div>
            {points.map((point, idx) => (
                <h4 key={idx} style={{ marginBottom: "10px",textAlign:"left",}}>
                  {point}
                </h4>
              ))}
              </div>
              </div>
          </div>
        ))}
      </div>
        </div>
        <QuestionsHome/>
        <HomeFooterBig/>
    </div>
  )
}
