import React, {
  useEffect,
  useState,
} from 'react';

import TabGeneral from 'components/Tabs/TabGeneral';

import { currencyCategory } from 'analytics/ticker/forex/forexKey';
import MarketInfoTemplate from './MarketInfoTemplate';
import MarketForexInfoTemplate from './MarketForexInfoTemplate';

export default function MarketForex({info,currencyBase,quoteType}) {
  const tabListRow = Object.keys(currencyCategory)

  const [tabRowIndex,setTabRowIndex] = useState(0)

  const [tickerList,setCurrencyList] = useState([])
  const [tickerDict,setTickerDict] = useState({})

  useEffect(() => {
    const category = Object.keys(currencyCategory)[tabRowIndex];
    setTickerDict(category)
    let tickerList = [...currencyCategory[category]];
  
    if (currencyBase && tickerList.includes(currencyBase)) {
      tickerList = tickerList.filter(currency => currency !== currencyBase);
    }
    if (currencyBase !="USD" && quoteType==="Direct"){
      tickerList = tickerList.map(currency => currencyBase + currency + "=X")
    }
    else if (quoteType==="Indirect"){
      tickerList = tickerList.map(currency => currency + currencyBase + "=X")
    }
    else{
    tickerList = tickerList.map(currency => currency + "=X")};
    setCurrencyList(tickerList);
  }, [currencyBase, tabRowIndex,quoteType]);

  return (
    <div style={{width:"100%",height:"90%",display:"flex",flexDirection:"column",gap:"0.2rem",boxSizing:"border-box"}}>
     {info !=="Exchange Rate" &&  
     <div>
    <TabGeneral tabList={tabListRow} tabIndex={tabRowIndex} 
    tabContent={[]} handleTabChange={(v)=>setTabRowIndex(v)}/>
    </div>}
    <div style={{overflow:"auto",flex:1}}>
    <MarketForexInfoTemplate tickerDict={tickerDict} tickerSelected={tickerList} tickerList={tickerList} info={info}/>
    </div>
    </div>
  )
}
