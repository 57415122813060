import { Button, Typography, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import Logo from '../logo/Logo'
import { useAppInfo } from 'AppState'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

export default function NavLanding() {
  const navigate = useNavigate()
  const location = useLocation()
  const page = location.pathname.split("/")[1]
  const { dispatchApp, dispatchHome } = useAppInfo()
  const isMobile = useMediaQuery('(max-width:600px)') // Define breakpoint for mobile screens
  const [menuOpen, setMenuOpen] = useState(false)

  function handleLogin(login = true) {
    dispatchApp({ "loginModal": login })
  }

  function handleRequestDemo() {
    dispatchHome({ "contactUs": true })
  }

  function handleNavigate(v) {
    navigate(`/${v}`)
    setMenuOpen(false) // Close the menu after navigation
  }

  function toggleDrawer(open) {
    setMenuOpen(open)
  }

  const topNavLinks = [{name:"How it Works",link:"HowItWorks",},
    {name:"Features",link:"Features"},
    {name:"Why Us",link:"WhyUs",},
    {name:"About",link:"About"}]

  return (
    <div className="nav_landing">
      <div className="nav_wrapper_landing" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className='gradient_text' style={{ display: "flex", gap: "0.5rem" }}>
          <Logo height='25px' width="150px" type='long' />
          <Typography>Beta</Typography>
        </div>
        {isMobile ? (
          <>
            <IconButton onClick={() => toggleDrawer(true)} edge="start">
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={menuOpen} onClose={() => toggleDrawer(false)}>
              <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '2rem' }}>
                <Logo height='25px' width="150px" type='long' style={{ marginBottom: '2rem' }} />
                <List>
                  {["Features", "About", "Contact"].map((obj) => (
                    <ListItem  key={obj} onClick={() => handleNavigate(obj)} style={{ textAlign: 'center' }}>
                      <ListItemText primary={obj} style={{ color: obj === page ? "var(--selectBlue)" : "black", fontWeight: obj === page && "bold" }} />
                    </ListItem>
                  ))}
                </List>
                <Button onClick={handleLogin} color="success" variant='contained' style={{ marginTop: '1rem' }}>Login</Button>
                <Button variant='outlined' onClick={handleRequestDemo} style={{ marginTop: '1rem' }}>Request Demo</Button>
              </div>
            </Drawer>
          </>
        ) : (
          <div style={{ display: "flex", flex: 1, gap: "2rem", alignItems: "center",justifyContent: "center", position: "relative", paddingLeft: "2rem" }}>
            {topNavLinks.map(obj => (
              <Typography
                onClick={() => handleNavigate(obj.link)}
                key={obj.name}
                style={{
                  cursor: "pointer",
                  color: obj.link === page ? "var(--selectBlue)" : "black",
                  fontWeight: obj.link === page && "bold"
                }}>{obj.name}</Typography>
            ))}
          </div>
        )}
        {!isMobile && (
          <div style={{ marginLeft: "auto", display: "flex", gap: "1rem" }}>
            <Button onClick={handleLogin} color="success" variant='contained'>Login</Button>
            <Button variant='text' onClick={handleRequestDemo}>Request Demo</Button>
          </div>
        )}
      </div>
    </div>
  )
}

// import { Button, Typography } from '@mui/material'
// import React from 'react'
// import Logo from '../logo/Logo'
// import { useAppInfo } from 'AppState'
// import { useLocation, useNavigate } from 'react-router-dom'

// export default function NavLanding() {
//     const navigate = useNavigate()
//     const location = useLocation()
//     const page = location.pathname.split("/")[1]
//     const {dispatchApp,dispatchHome} = useAppInfo()
//     function handleLogin(login=true){
//         dispatchApp({"loginModal":login})
//       }
  
//       function handleRequestDemo(){
//         dispatchHome({"contactUs":true})
//       }

//     function handleNavigate(v){
//         navigate(`/${v}`)
//     }
    
  
//   return (
//     <div className="nav_landing">
//     <div className="nav_wrapper_landing">
        
//         <div className='gradient_text' style={{display:"flex",gap:"0.5rem"}}>
//             <Logo height='25px' width="150px" type='long'/>
//             <Typography>Beta</Typography>
//         </div>
//         <div style={{display:"flex",flex:1,gap:"2rem",alignItems:"center"
//         ,position:"relative",paddingLeft:"2rem"}}>
//           {["Features","About","Contact"].map(obj=>
//           <Typography 
//           onClick={()=>handleNavigate(obj)}
//           key={obj} 
//           style={{cursor:"pointer",color:obj===page ? 
//           "var(--selectBlue)":"black",fontWeight:obj===page && "bold"}}>{obj}</Typography>)}
        
//         </div>
//         <div style={{ marginLeft: "auto", display: "flex", gap: "1rem" }}>
//             <Button onClick={handleLogin} color="success" variant='contained'>Login</Button>
//             <Button 
//             variant='outlined'
//             onClick={handleRequestDemo}
//            >Request Demo</Button>
//         </div>
//     </div>
// </div>
//   )
// }
