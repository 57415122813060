import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { useAppInfo } from 'AppState';
import PortfolioAddTransactionModal from './PortfolioAddTransactionModal';
import { Button,Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import MyFinancialsHeaderTemplate from '../Template/MyFinancialsHeaderTemplate';
import { menuIcons } from 'nav/menuIcons';
import PortfolioCrud from './PortfolioCrud';
import PortfolioAccountSelection from './PortfolioAccountSelection';


export default function PortfolioHeader() {
  const {dispatchPortfolio,statePortfolio,stateMyFinancials} = useAppInfo() 
  const {accountSettings,addTransaction,portfolio} = statePortfolio
  const {financialView} = stateMyFinancials

  function handleAddTransactions(){
    dispatchPortfolio({"addTransaction":true})
   }


  return (
    <>
    <MyFinancialsHeaderTemplate name={"Investment Portfolio"} icon={menuIcons.Portfolio}>
    
    {portfolio && Object.keys(portfolio).length>0 && 
    <div className='page_header_content_box' style={{gap:"0.5rem"}}>
    
    <PortfolioAccountSelection/>
    {financialView === "personal" && 
    <>
    <Tooltip title="Add Transaction">
      <Button onClick={handleAddTransactions}>
        <AddIcon/> </Button></Tooltip>

      <Tooltip title="Account Settings">
      <Button onClick={()=>dispatchPortfolio({accountSettings:!accountSettings})}
      sx={{border:"none",minWidth:0,color:"black",borderRadius:"5px"}}><SettingsIcon/></Button>
     </Tooltip>
     </>
      }
    </div>}

    
    </MyFinancialsHeaderTemplate>
  
    {accountSettings && <PortfolioCrud/>}
    {addTransaction && <PortfolioAddTransactionModal/> }
    
    </>
  )
}