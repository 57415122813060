import React, { useState } from 'react';
import { Chip, Typography, Box, Button } from '@mui/material';
import { professionalCategory } from './info';

export default function ProfessionalCategorySelector({
    categories = professionalCategory,
    required = true,
    categorySelected,
    handleCategorySelectionChange
}) {
    // const [categorySelected, setSelectedCategories] = useState([]);
    const [isCategoryValid, setIsCategoryValid] = useState(true); // Validation state

    const handleSelect = (category) => {
        const newSelectedCategories = categorySelected.includes(category) 
            ? categorySelected.filter(item => item !== category) 
            : [...categorySelected, category];
        handleCategorySelectionChange && handleCategorySelectionChange(newSelectedCategories);
        // setSelectedCategories(newSelectedCategories);
        if (required) {
            setIsCategoryValid(newSelectedCategories.length > 0); // Update validation status
        }
    };

    // const handleContinue = () => {
    //     if (required && categorySelected.length === 0) {
    //         setIsCategoryValid(false); // Show validation error if no category is selected
    //         return;
    //     }
    //     // Proceed to the next step or perform any other action
    //     console.log("Selected categories:", categorySelected);
    // };

    return (
        <Box>
            <Typography variant="h6" sx={{ mb: 2, fontSize: "1rem", color: "rgba(0, 0, 0, 0.6)" }}>
                Select Your Professional Categories
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {categories.map((category) => (
                    <Chip
                        key={category}
                        label={category}
                        clickable
                        onClick={() => handleSelect(category)}
                        sx={{
                            borderRadius: '8px',
                            padding: '8px',
                            fontWeight: 'bold',
                            fontSize: '0.9rem',
                            backgroundColor: categorySelected.includes(category) ? '#4caf50' : '#e0e0e0',
                            color: categorySelected.includes(category) ? '#fff' : '#333',
                            '&:hover': {
                                backgroundColor: categorySelected.includes(category) ? '#388e3c' : '#bdbdbd'
                            }
                        }}
                    />
                ))}
            </Box>
            {!isCategoryValid && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                    Please select at least one category to continue.
                </Typography>
            )}
            {/* <Button
                variant="contained"
                onClick={handleContinue}
                sx={{ mt: 2 }}
            >
                Continue
            </Button> */}
        </Box>
    );
}

// import React, { useState } from 'react';
// import { Chip, Typography, Box } from '@mui/material';
// import { coreExpertise, professionalCategory } from './info';



// export default function ProfessionalCategorySelector({categories=professionalCategory}) {
//     const [categorySelected, setSelectedCategories] = useState([]);

//     const handleSelect = (category) => {
//         setSelectedCategories(prev =>
//             prev.includes(category) 
//                 ? prev.filter(item => item !== category) // Remove if already selected
//                 : [...prev, category]                    // Add if not selected
//         );
//     };

//     return (
//         <Box>
//             <Typography variant="h6" sx={{ mb: 2,fontSize:"1rem",color:"rgba(0, 0, 0, 0.6)"}}>
//                 Select Your Professional Categories
//             </Typography>
//             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                 {categories.map((category) => (
//                     <Chip
//                         key={category}
//                         label={category}
//                         clickable
//                         onClick={() => handleSelect(category)}
//                         sx={{
//                             borderRadius: '8px',
//                             padding: '8px',
//                             fontWeight: 'bold',
//                             fontSize: '0.9rem',
//                             backgroundColor: categorySelected.includes(category) ? '#4caf50' : '#e0e0e0',
//                             color: categorySelected.includes(category) ? '#fff' : '#333',
//                             '&:hover': {
//                                 backgroundColor: categorySelected.includes(category) ? '#388e3c' : '#bdbdbd'
//                             }
//                         }}
//                     />
//                 ))}
//             </Box>
//         </Box>
//     );
// }
