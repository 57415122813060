import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindChatAI = () => {
  const { dispatchAssistant,stateApp} = useAppInfo()
  const userId = stateApp.userId


  const findChatAI = async () => {
    try {
      if (userId) {
        const response =  await axios.post(`${BASE_URL}/findChatAI/`, {
          userId
        });
        // Process the response data as needed
       const data = response.data;
        
       if (data){
        dispatchAssistant({chatHistory:data})
       }
        
      }
    } catch (err) {
      // Handle the error
    }
  };

  useEffect(() => {
    if (userId) {
      findChatAI();
    }
  }, [userId]);

  return null; // Since this hook is used for side effects, you don't need to return anything
};

export default useFindChatAI;

