import React, { useEffect, useState } from "react";
import { arrayUnion, doc, increment, Timestamp, updateDoc } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useAppInfo } from "AppState";
import { db, storage } from "../firebaseConfig/firebaseConfig";
import { Button } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./Input.module.css";

const Input = ({image}) => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);

  const { stateApp, stateChats } = useAppInfo();
  const { userDetails } = stateApp;
  const { chatId, user } = stateChats;

  useEffect(() => {if (image) setImg(image)},[image])

  const handleSend = async () => {
    if (!chatId) {
      alert("You have not added any contacts yet!");
      return;
    }
    try {
      const messageId = uuid();
      const messageObject = {
        id: messageId,
        text,
        senderId: userDetails?.uid,
        date: Timestamp.now(),
        seen: false
      };

      if (img) {
        const imageRef = `chatImages/${uuid()}`;
        const storageRef = ref(storage, imageRef);
        const uploadTaskSnapshot = await uploadBytesResumable(storageRef, img);
        const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
        messageObject.img = downloadURL;
      }

      await updateDoc(doc(db, "chats", chatId), {
        messages: arrayUnion(messageObject),
      });

      const recipientUnreadPath = `${chatId}.unreadCount`;
      const senderLastMessagePath = `${chatId}.lastMessage`;

      const userChatUpdates = {
        [senderLastMessagePath]: messageObject,
      };

      const chatUpdateReceiver = {
        [senderLastMessagePath]: messageObject,
        [recipientUnreadPath]: increment(1)
      };

      await Promise.all([
        updateDoc(doc(db, "userChats", userDetails.uid), userChatUpdates),
        updateDoc(doc(db, "userChats", user.uid), chatUpdateReceiver)
      ]);

      setText("");
      setImg(null);

    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file.type.startsWith('image/')) {
      alert("Only image files are allowed!");
      return;
    }

    setFileUploading(true);
    setImg(file);

    setTimeout(() => {
      setFileUploading(false);
    }, 2000);
  };

 

  return (
    <div 
      onKeyDown={(e) => e.key === 'Enter' && handleSend()} 
      className={styles.inputContainer}
    >
      <input
        // onDrop={(e) => handleDragFile(e)}
        // onDragOver={(e)=>e.preventDefault()}
        className={styles.textInput}
        type="text"
        placeholder="Type a message..."
        onChange={(e) => setText(e.target.value)}
        value={text}
      />

      <input 
        id="file" 
        className={styles.fileInput} 
        type="file" 
        onChange={handleFileUpload}
      />

      <label htmlFor="file" className={styles.fileLabel}>
        <AttachFileIcon sx={{ color: "black" }} />
      </label>
      <Button onClick={handleSend}><SendIcon /></Button>

      {img && (
        <ImageBox 
          loading={fileUploading} 
          handleSend={handleSend} 
          img={img} 
          setImg={setImg} 
        />
      )}
    </div>
  );
};

const ImageBox = ({ img, setImg, handleSend, loading }) => {
  
  return (
    <div className={styles.imageBox}>
      <Button onClick={() => setImg(null)} sx={{ marginLeft: "auto", minWidth: "0", padding: 0 }}>
        <CloseIcon fontSize="small" />
      </Button>
      <div className={styles.imageContainer}>
        <img
          src={URL.createObjectURL(img)}
          alt="Selected"
          className={styles.image}
        />
      </div>
      {loading && <div>...loading</div>}
      <Button 
      disabled={loading}
      onClick={handleSend}>
        <SendIcon fontSize="small" />
      </Button>
    </div>
  );
};

export default Input;
