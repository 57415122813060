import { menuIcons } from "./menuIcons"


const menuCashflow = [
  {name:"Net Cashflow",content:[]},
  {name:"Net Cash Allocation",content:[]},
  {name:"Income",content:[]},
  {name:"Return on Savings & Investment",content:[]},
  {name:"Expense",content:[]},
  {name:"Liability Payback",content:[]},
]



const menuRiskManagement = [
  {name:"Risk Tolerance",content:[]},
  {name:"Risk Mitigation Options",content:[]},
  {name:"Crisis Management",content:[]},
]

const menuScenarios = [
  {name:"Goals Success/Failure",content:[]},
  {name:"Withdrawal",content:[]},
  {name:"Loan Amortization",content:[]},
  {name:"Income vs Investment",content:[]}  
]
const menuGoals = [
  {name:"Overview",content:[]},
  {name:"Steps to realize Goals",content:[]},
  {name:"Projections",content:[]},
]

const menuMyPortfolio = [
  {name:"Overview",content:[]},
  {name:"Current Positions",content:[]},
  {name:"Transactions",content:[]},
  {name:"Asset Allocation",content:[]},
  {name:"Portfolio vs Benchmark",content:[]},
  {name:"Asset Analytics",content:["Trend","Trend Table","Technical Indicators","Performance","Volatility","Correlation",]},
  {name:"Insights",content:[]}
]

const menuLiabilities = [
  {name:"Overview",content:[]},
  {name:"Steps to manage Liabilities",content:[]},
  {name:"Payment Overview",content:[]},
  {name:"Allocation",content:[]},
  {name:"Projections",content:[]},
  {name:"Amortization Schedule",content:[]},
]

const menuAssets = [
  {name:"Overview",content:[]},
  {name:"Steps to grow Assets",content:[]},
  {name:"Allocation",content:[]},
  {name:"Projections",content:[]},
  {name:"Simulated Projections",content:[]},
]
const menuNetworth = [
  {name:"Overview",content:[]},
  {name:"Steps to grow Networth",content:[]},
  {name:"Projections",content:[]},
  {name:"Breakdown",content:[]},
  
]
const menuInvestmentPreference = [
  {name:"Market Region",content:[]},
  {name:"Time Horizon",content:[]},
  {name:"Asset Allocation",content:[]}
]
const menuMyFinancials = [
    { icon: menuIcons?.Profile, name:"Overview",label: 'Overview',link:"Overview",content:[]},
    {icon: menuIcons?.Health,name:"Financial Health",label:"Financial Health",link:"FinancialHealth"},
    // {icon: menuIcons?.Health,name:"Economy & Market Impact",label:"Economy & Market Impact",link:"MarketImpact"},
    {icon: menuIcons?.Goals,name:"Goals",label:"Goals",link:"Goals",content:[],menuOptions:menuGoals},
    {icon: menuIcons?.Networth,name:"Networth",label: 'Networth',link:"Networth",content:[],menuOptions:menuNetworth},
    {icon: menuIcons?.Asset,name:"Assets",label: 'Assets',link:"Assets",content:[],menuOptions:menuAssets},
    {icon: menuIcons?.Liability,name:"Liabilities",label: 'Liabilities',link:"Liabilities",content:[],menuOptions:menuLiabilities},
    {icon:menuIcons?.Portfolio,label:"Portfolio",name:"Portfolio",link:"Portfolio",content:["Overview","Current Positions","Transactions","Asset Allocation","Portfolio vs Benchmark","Assets Trend","Assets Trend Table","Technical Indicators","Performance","Volatility","Correlation","Report","Insights"],menuOptions:menuMyPortfolio},
    {icon: menuIcons?.Cashflow,name:"Cashflow",label: 'Cashflow',link:"Cashflow",content:[],menuOptions:menuCashflow},
    {icon: menuIcons?.InvestmentPreferences,name:"Investment Preferences",label:"Preference",link:"InvestmentPreferences",content:[],menuOptions:menuInvestmentPreference},
    {icon: menuIcons?.RiskManagement, name:"Risk Management",label:"Risk",link:"RiskManagement",content:["Risk Tolerance","Risk Mitigation Option","Crisis Management"],menuOptions:menuRiskManagement},
    // {icon: menuIcons?.Scenarios, name:"Scenarios",label:"Scenarios",link:"Scenarios",content:[]},
  ]

const menuStocks = [
    { name: "Overview", content: ["Profile"] },
    { name: "Stats", content: ["Stats vs Industry", "Stats vs Peers"] },
    { name: "Business & Shareholding", content: ["Business", "Management", "Shareholding", "Insider Transactions", "Senate Transactions"] },
    { name: "Charts", content: ["Technical Chart", "Price vs Benchmark", "Price vs Peers", "Price vs Financials", "Price vs Economy", "Peer Bar & Pie", "Peer Scatter"] },
    { name: "Technicals", content: ["Indicators", "Performance", "Volatility", "Correlation", "Beta"] },
    { name: "Financials", content: ["Statements", "Ratios", "Revenue Segment", "Common Size Statements", "Statement Breakdown", "Earning Trends", "Profitablity", "Capital Structure", "vs Price"] },
    { name: "Value", content: ["Price Multiple", "EV Multiple", "DCF", "Dividend"] },
    { name: "Derivative", content: ["Options"] },
    { name: "Peers", content: ["Peer Profile", "Stats vs Peers", "Price vs Peers", "Financials vs Peers", "Financial Ratios vs Peers", "Performance", "Volatility", "Correlation", "Beta", "Scatter"] },
    { name: "News & Events", content: ["News", "Press Release"] },
    { name: "Transcripts & Filings", content: ["Earning Transcripts", "Security Exchange Filings"] },
  ];
  
  const menuCrypto = [
    { name: "Overview", content: ["Profile"] },
    { name: "Chart", content: ["Technical Chart", "Price vs Benchmark", "Price vs Peers", "Price vs Economy"] },
    { name: "Technicals", content: ["Indicators", "Performance", "Volatility", "Correlation", "Beta"] },
    { name: "News", content: [] },
  ];
  
  const menuETF = [
    { name: "Overview", content: ["Profile"] },
    { name: "Holdings", content: ["Asset Allocation", "Top Holdings", "Holdings"] },
    { name: "Chart", content: ["Technical Chart", "Price vs Benchmark", "Price vs Peers", "Price vs Economy"] },
    { name: "Technicals", content: ["Indicators", "Performance", "Volatility", "Correlation", "Beta"] },
    { name: "News", content: [] }
  ];

  const menuEcon = [
    { name: "Overview", content: [] },
    { name: "Global", content: [] },
    { name: "Macro Econ Indicators", content: ["Interest Rates", "Inflation", "Monetary", "Business Indicators", "Employment", "National Accounts"] },
    { name: "vs Market", content: [] },
    { name: "Map", content: [] },
    { name: "Calendar", content: [] }
  ];
  

const menuTopInvestors = [
  {name:"Overview",content:[]},
  {name:"Holdings",content:[]},
  {name:"Analytics",content:[]},
]
const menuPortfolioGuide = [
  {name:"Portfolio Builder",content:[]},
  {name:"Portfolio Management",content:[]},
  {name:"Risk Management",content:[]}
]

const menuNews = [
  {name:"Stocks",content:[]},
  {name:"Crypto",content:[]},
  {name:"Forex",content:[]},
  {name:"General",content:[]},
]

const menuCalendar = [
  {name:"Earnings",content:[]},
  {name:"Dividend",content:[]},
  {name:"IPO",content:[]},
  {name:"Economy",content:[]},
]
const menuTradingActivities = [
  {name:"Congress Trading Activitis",content:[]}
]
const menuFilings = [
  {name:"Security Exchange Filings",content:[]}
]
const menuNewsEvents = [
  {icon:  menuIcons.News,label:"News",name:"News",link:"News",menuOptions:menuNews},
  {icon:  menuIcons.Calendar,label:"Calendar",name:"Calendar",link:"Calendar",menuOptions:menuCalendar},
  {icon:  menuIcons.MandA,label:"M&A" ,name:"Merger & Acquisitions",link:"MandA",menuOptions:[]},
  {icon:  menuIcons.Filings,label:"Filings" ,name:"Filings",link:"Filings",menuOptions:menuFilings},
  {icon: menuIcons.TradingActivities,label:"Trading Activities",name:"Trading Activities",link:"TradingActivities",menuOptions:menuTradingActivities}
]


const menuAnalytics = [
  { icon: menuIcons.Ticker, label: 'Ticker', name: 'Ticker',link:"Ticker"},
  { icon: menuIcons.Industry, label: 'Sector & Industry', name: 'Sector & Industry',link:"Industry" },
  { icon: menuIcons.Market, label: 'Market', name: 'Market',link:"Market"},
  { icon: menuIcons.Economy, label: 'Economy', name: 'Economy',link:"Economy"},
  { icon: menuIcons["Top Investors"], label: 'Top Investors', name: 'Top Investors',link:"Investors"}
]

const menuMarket = [
  { name: "Market Today", content: [] },
  { name: "Trend Chart", content: [] },
  { name: "Trend Table", content: [] },
  { name: "Indicators", content: [] },
  { name: "Performance", content: [] },
  { name: "Volatility", content: [] },
  { name: "Correlation", content: [] },
  { name: "Beta", content: [] },
  { name: "Constituents", content: [] },
  { name: "Scatter", content: [] },
  { name: "Yield Curve", content: [] },
  { name: "Market Breadth", content: [] },
  { name: "Heatmap", content: [] },
  { name: "Exchange Rate", content: [] },
  { name: "Guide", content: ["Market Analysis"] }
];


const menuResource = [
  { icon: menuIcons?.Guide, label: 'Guide', name: 'Guide',link:"Guide"},
    { icon: menuIcons?.Learn, label: 'Learn', name: 'Learn' ,link:"Learn"},
    { icon: menuIcons?.Glossary, label: 'Glossary', name: 'Glossary',link:"Glossary" },
    // { icon: menuIcons?.["Mind Map"], label: 'Mind Map', name: 'MindMap' ,link:"MindMap"},
    // { icon: menuIcons?.Doodle, label: 'Doodle', name: 'Doodle',link:"Doodle" },
  ]

const menuWhiteBoard = [
  { icon: menuIcons?.["Mind Map"], label: 'Mind Map', name: 'MindMap' ,link:"MindMap"},
    { icon: menuIcons?.Doodle, label: 'Doodle', name: 'Doodle',link:"Doodle" },
]

const menuScreener = [
  {name: "Stocks", link: "Stocks", label: "Stocks",icon:menuIcons.Stocks},
  {name: "Crypto", link: "Crypto", label: "Crypto",icon:menuIcons.Crypto},
  {name: "Etf", link: "Etf", label: "Etf",icon:menuIcons.Etf},
  {name: "Bonds", link: "Bonds", label: "Bonds",icon:menuIcons.Bonds},
  {name: "Forex", link: "Forex", label: "Forex",icon:menuIcons.Forex},
];



const menuTechnicals = [
  // {name: "Multi Trend", content: []},
  {name: "Indicators", content: []},
  {name: "Performance", content: []},
  {name: "Volatility", content: []},
  {name: "Correlation", content: []},
  {name: "Beta", content: []},
 
];

const menuVisuals = [
  {name: "Scatter", content: []},
  {name: "Bar & Pie", content: []},
  {name: "Heatmap", content: []}
]
const menuChart = [
  {name: "Technical Chart", content: []},
  {name:"Price vs Benchmark",content:[]},
  {name: "Price vs Peers", content: []},
  {name: "Price vs Economy", content: []},
]


const menuTrade = [
  {name: "Order", link: "Order", label: "Order",icon:menuIcons.Order,menuOptions:[]},
  {name: "Transactions", link: "Transactions", label: "Transactions",icon:menuIcons.Transactions,menuOptions:[]},
  {name: "Chart", link: "Chart", label: "Chart",icon:menuIcons.Chart,menuOptions:menuChart},
  // {name: "Portfolio Fit", link: "PortfolioFit", label: "Portfolio Fit",icon:menuIcons.Fit,menuOptions:[]},
  {name: "Trade Conditions", link: "TradeConditions", label: "Trade Conditions",icon:menuIcons.Checklist,menuOptions:[]},
  {name: "Technicals", link: "Technicals", label: "Technicals",icon:menuIcons?.Technicals,menuOptions:menuTechnicals},
]


const menuOpportunities = [
  {name: "Investment", link: "Investment", label: "Investment",icon:menuIcons.Investment,menuOptions:[]},
  {name: "Trading", link: "Trading", label: "Trading",icon:menuIcons.Trading,menuOptions:[]},
  {name: "Portfolio Fit", link: "PortfolioFit", label: "Portfolio Fit",icon:menuIcons.Fit,menuOptions:[]},
 
]

const menuReport = [
  {name: "TakeStock Reports", link: "TSReport", label: "TS Reports",icon:menuIcons.Report,menuOptions:[]},
  {name:"Report Builder",link:"ReportBuilder",label:"Builder",icon:menuIcons["Report Builder"],menuOptions:[]}
  ]


const menuTakeStock = ["Home","About","Contact","Product","Pricing","Support","Terms","Disclaimer"]

const menuFinancialProducts = [
  {name: "Mortgages", link: "Mortgages", label: "Mortgages", icon: menuIcons.Report, menuOptions: []},
  {name: "Home Insurance", link: "HomeInsurance", label: "Home Insurance", icon: menuIcons.Report, menuOptions: []},
  {name: "Life Insurance", link: "LifeInsurance", label: "Life Insurance", icon: menuIcons.Report, menuOptions: []},
  {name: "Car Insurance", link: "CarInsurance", label: "Car Insurance", icon: menuIcons.Report, menuOptions: []},
  {name: "Credit Cards", link: "CreditCard", label: "Credit Card Reports", icon: menuIcons.Report, menuOptions: []},
  {name: "Student Loans", link: "StudentLoans", label: "Student Loans", icon: menuIcons.Report, menuOptions: []},
  {name: "Savings Accounts", link: "SavingsAccount", label: "Savings Account", icon: menuIcons.Report, menuOptions: []},
 {name: "Pesonal Loans", link: "PersonalLoans", label: "Loans", icon: menuIcons.Report, menuOptions: []},
 
]

const menuAskProfessional = [
  { name: "My Team", link: "", label: "My Team", icon: menuIcons.Report, menuOptions: [] },
  { name: "Financial Advisor", link: "FinancialAdvisor", label: "Financial Advisor", icon: menuIcons.Report, menuOptions: [] },
  // { name: "Financial Planner", link: "FinancialPlanner", label: "Financial Planner", icon: menuIcons.Report, menuOptions: [] },
  // { name: "Wealth Manager", link: "WealthManager", label: "Wealth Manager", icon: menuIcons.Report, menuOptions: [] },
  // { name: "Portfolio Manager", link: "PortfolioManager", label: "Portfolio Manager", icon: menuIcons.Report, menuOptions: [] },
  // { name: "Investment Advisor", link: "InvestmentAdvisor", label: "Investment Advisor", icon: menuIcons.Report, menuOptions: [] },
  { name: "Mortgage Advisor", link: "MortgageAdvisor", label: "Mortgage Advisor", icon: menuIcons.Report, menuOptions: [] },
  { name: "Insurance Advisor", link: "InsuranceAdvisor", label: "Insurance Advisor", icon: menuIcons.Report, menuOptions: [] },
  // { name: "Loan Advisor", link: "LoanAdvisor", label: "Loan Advisor", icon: menuIcons.Report, menuOptions: [] },
  // { name: "Investment Analyst", link: "InvestmentAnalyst", label: "Investment Analyst", icon: menuIcons.Report, menuOptions: [] },
  // { name: "Trade Analyst", link: "TradeAnalyst", label: "Trade Analyst", icon: menuIcons.Report, menuOptions: [] },
  // { name: "Form", link: "form", label: "Trade Analyst", icon: menuIcons.Report, menuOptions: [] },
];

function Icon({imgSrc,height="25px",width="25px"}){
  return(<div style={{width:width,height:height}}>
   <img src={imgSrc} style={{objectFit:"cover"}} width={"100%"} height={"100%"}>
     </img></div>)
 }

 const navLinks = [
  {name:"Home",link:"Home",icon:menuIcons?.Home},
  {name:"Ask AI",link:"AskAI",icon:menuIcons?.ChatAI},
  {name:"Ask Professional",link:"AskProfessional",menu:menuAskProfessional,icon:menuIcons?.AskProfessional},
  {name:"My Financials",link:"MyFinancials",menu:menuMyFinancials,icon:menuIcons?.Asset},
  {name:"Scenarios",link:"Scenarios",icon:menuIcons?.Visuals},
  {name:"My Vault",link:"MyVault",icon:menuIcons?.Vault},
  {name:"Financial Products",link:"FinancialProducts",menu:menuFinancialProducts,icon:menuIcons?.Product},
  {name:"Opportunities",link:"Opportunities",menu:menuOpportunities,icon:menuIcons?.Opportunity},
  {name:"Charts/Trading",link:"Trade",menu:menuTrade,icon:menuIcons?.Trade},
  {name:"Analytics",link:"Analytics",menu:menuAnalytics,icon:menuIcons?.Analytics},
{name:"Screener",link:"Screener",menu:menuScreener,icon:menuIcons?.Filter},
{name:"News/Events",link:"NewsEvents",menu:menuNewsEvents,icon:menuIcons?.Calendar},
{name:"Reports",link:"Report",menu:menuReport,icon:menuIcons?.Report},
{name:"Education",link:"Resource",menu:menuResource,icon:menuIcons?.Education},
]

const selectedNames = ["Home","Ask AI","My Financials"];
// export const navLinksSelectedDefault = navLinks.filter((item) => selectedNames.includes(item.name));

export const navLinksSelectedDefault = ["Home","AskAI","MyFinancials"];

export const navLinkCategories = [
  {category:"Home",name:"Home",link:"Home",icon:menuIcons?.Home},
  {category:"Home", name:"Ask AI",link:"AskAI",icon:menuIcons?.ChatAI},
  {category:"Personal", name:"My Financials",link:"MyFinancials",menu:menuMyFinancials,icon:menuIcons?.Asset},
  {category:"Personal",name:"My Vault",link:"MyVault",icon:menuIcons?.Vault},
  {category:"Personal", name:"Scenarios",link:"Scenarios",icon:menuIcons?.Scenarios},
  {category:"Community",name:"Social",link:"Social",icon:menuIcons?.Social,comment:"coming soon"},
  {category:"Community", name:"Ask Professional",link:"AskProfessional",menu:menuAskProfessional,icon:menuIcons?.AskProfessional,comment:"experimental"},
  {category:"Market", name:"Financial Products",link:"FinancialProducts",menu:menuFinancialProducts,icon:menuIcons?.Product,comment:"experimental"},
  {category:"Market",name:"Opportunities",link:"Opportunities",menu:menuOpportunities,icon:menuIcons?.Opportunity},
  {category:"Market",name:"Charts/Trading",link:"Trade",menu:menuTrade,icon:menuIcons?.Trade},
  {category:"Market", name:"Analytics",link:"Analytics",menu:menuAnalytics,icon:menuIcons?.Analytics},
{category:"Market",name:"Screener",link:"Screener",menu:menuScreener,icon:menuIcons?.Filter},
{category:"Market",name:"News/Events",link:"NewsEvents",menu:menuNewsEvents,icon:menuIcons?.Calendar},
{category:"Reports & Education",name:"Reports",link:"Report",menu:menuReport,icon:menuIcons?.Report},
{category:"Reports & Education",name:"Education",link:"Resource",menu:menuResource,icon:menuIcons?.Education},
{category:"TakeStock",name:"Sitemap",link:"Sitemap"},
{category:"TakeStock",name:"Profile",link:"Profile"},
{category:"TakeStock",name:"Support",link:"Support"},
{category:"TakeStock",name:"About Us",link:"About"},
{category:"TakeStock",name:"Features",link:"Features"},
{category:"TakeStock",name:"Contact",link:"Contact"},
{category:"TakeStock",name:"Terms",link:"Terms"},
{category:"TakeStock",name:"Disclaimer",link:"Disclaimer"},
]

export const navCompany = [
  {category:"TakeStock",name:"Sitemap",link:"Sitemap"},
  {category:"TakeStock",name:"About Us",link:"About"},
  {category:"TakeStock",name:"Features",link:"Features"},
  {category:"TakeStock",name:"Contact",link:"Contact"},
  {category:"TakeStock",name:"Terms",link:"Terms"},
  {category:"TakeStock",name:"Disclaimer",link:"Disclaimer"},
]
 // {name:"Ask Professional",link:"AskProfessional",icon:menuIcons?.AskProfessional},

export const groupedNavLinks = navLinkCategories.reduce((acc, link) => {
  if (!acc[link.category]) acc[link.category] = [];
  acc[link.category].push(link);
  return acc;
}, {});

export {
  menuStocks,
  menuCrypto,
  menuETF,
  menuEcon,
  menuMarket,
  menuMyPortfolio,
  menuAnalytics,
  menuCashflow,
  menuMyFinancials,
  menuNewsEvents,
  menuOpportunities,
  menuReport,
  menuResource,
  menuScreener,
  menuTakeStock,
  menuTrade,
  navLinks
};