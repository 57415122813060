import { openaiAPI } from 'appState/initialStateAssistant';


export async function fetchExcelUpload({message,assistantId="asst_XO3rWGnYdJt4GrXwhBNzYWFg",assistantName}) {
    // dispatchAssistant({ loading: true, chatStatus: "running",loadingMessage:`Connecting to AI-${assistantName}`});
    let openai = openaiAPI
    try {
        // Create a new thread if not present
        const threadNew = await openai.beta.threads.create();
        let thread = threadNew.id;
        // console.log(thread)
        // dispatchAssistant({ threadId: thread });

        // Send the message to the assistant
        await openai.beta.threads.messages.create(thread, {
            role: 'user',
            content: message,
        });

        // Start streaming the assistant's response
        const run = await openai.beta.threads.runs.create(thread, {
            assistant_id: assistantId,
        });
        let runwait = await waitOnRun(run, thread);
        let messages = await openai.beta.threads.messages.list(thread)
        // console.log(messages?.data[0]?.content[0]?.text?.value)
        return messages?.data[0]?.content[0]?.text?.value

    } catch (error) {
        console.error('Error fetching data:', error);
        // const updatedChats = [...chats];
        // updatedChats[updatedChats.length - 1] = {
        //     role: "assistant",
        //     content: 'Sorry, something went wrong.',
        // };
    } finally {
        // dispatchAssistant({ loading: false,chatStatus:"complete","loadingMessage":null,runId:null});
    }
}


async function waitOnRun(run, thread) {
    while (run.status === "queued" || run.status === "in_progress") {
        run = await openaiAPI.beta.threads.runs.retrieve(thread,run.id);
        await new Promise(resolve => setTimeout(resolve, 500));
    }
    return run;
}