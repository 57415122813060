import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindFinancialProfessional = () => {
  const {stateApp } = useAppInfo()
  const [loading,setLoading] = useState(false)
  const [error,setError] = useState(null)
  const [data,setData] = useState({})
  const userId = stateApp.userId

  const findFinancialProfessional = async () => {
    try {
      setLoading(false)
      if (userId) {
        const response = await axios.post(`${BASE_URL}/findFinancialProfessional/`, { userId });
        const data = response.data;
       
        setData(data)
      }
    } catch (err) {
      if (err.response && err.response.data.error === 'No data found') {
        console.log('Error', err);
      } else {
        console.log('Error', err); 
      }
      setLoading(true)
    }
    finally {
      setLoading(true)
    }
  };

  useEffect(() => {
    if (userId) {
      findFinancialProfessional();
    }
  }, [userId]);

  return {data,loading,error};
};

export default useFindFinancialProfessional;