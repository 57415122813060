const initialStateApp = {
    authChecked:false,
    initialized:false,
    isLoggedIn: false,
    loginLoad: false,
    loginType: "",
    registerSuccess: false,
    googlePhoto: null,
    username: null,
    userId:null,
    displayName:null,
    userDetails:null,
    loginModal:false,
    notification:{message:"",open:false},
    onboarding: { 
      setDisplayName:false,
      terms:false,
      disclaimer:false,
      objectives: [],
      showAround: false,
      financials: false,
      financialView:"personal",
      setHomeDisplay:false},
  };

export {initialStateApp}