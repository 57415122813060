import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { formatNumber, numberDecimal } from 'utils/utilGeneral';
import { useAppInfo } from 'AppState';
import Animation from 'animation/Animation';
import LoadingSkeletonChart from 'components/Loading/LoadingSkeletonChart';

const CashflowChart = React.memo(({ data, title='Projected Cash Flow',hoveredItem="Default", cashBegining=0, nameKey="name", valueKey="amount", chartType="area", enableGrouping=true }) => {
  const [chartOptions, setChartOptions] = useState();
  const [groupingUnit, setGroupingUnit] = useState(['year', [1]]); // Default to yearly
  const chartRef = React.useRef(null);
  const  {stateMyFinancials} = useAppInfo()
  const {currency} = stateMyFinancials
  
  useEffect(() => {
    if (data.length > 0){
    const currentDate = new Date();
    // const filteredData = data.filter(item => new Date(item.date) >= currentDate);

    const processedData = data.reduce((acc, item) => {
      const time = Date.parse(item.date); // Convert date to milliseconds for Highcharts
      acc[item[nameKey]] = acc[item[nameKey]] || { data: [], color: item.color || null };
      
      if (Array.isArray(valueKey)) {
        valueKey.forEach(keys => acc[item[nameKey]].data.push([time, item[keys] + cashBegining]));
      } else {
        acc[item[nameKey]].data.push([time, item[valueKey] + cashBegining]);
      }
    
      return acc;
    }, {});
    
    const series = Object.keys(processedData).map(key => ({
      name: key,
      data: processedData[key]?.data,
      color: processedData[key]?.color,
      stack: 'amount',
      dataGrouping: enableGrouping ? {
        enabled: true,
        forced: true,
        units: [groupingUnit]
      } : {
        enabled: false
      },
      negativeColor: '#dc3545'  // Red for negative values
    }));

    const options = {
      chart: {
        type: chartType,
        events: {
          load: function() {
            this.xAxis[0].setExtremes(); // Resets the x-axis extremes whenever the chart is loaded
          }
        },
        panning: false,
        animation: false,
      },
      title: {
        text: title
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date'
        },
      },
      yAxis: {
        title: {
          text: 'Value'
        },
        labels: {
          formatter: function () {
            const value = this.value;
            if (value >= 1e12) {
              return (value / 1e12).toFixed(1) + 'T';
            } else if (value >= 1e9) {
              return (value / 1e9).toFixed(1) + 'B';
            } else if (value >= 1e6) {
              return (value / 1e6).toFixed(1) + 'M';
            } else if (value >= 1e3) {
              return (value / 1e3).toFixed(1) + 'K';
            } else {
              return value.toFixed(2);
            }
          }
        }
      },
      tooltip: {
        useHTML: true, // Enable HTML in tooltips
        formatter: function () {
          return ['<b>' + Highcharts.dateFormat('%d %b %Y', this.x) + '</b>'].concat(
              this.points ?
                  this.points.map(function (point) {
                      return point.series.name + ': ' + `${currency?.symbol}${formatNumber(point.y)}`;
                  }) : []
          );
      },
      split: true
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      series,
      legend: {
        enabled: true
      },
      rangeSelector: {
        allButtonsEnabled: true,
        buttons: [
          {
              type: 'm',
              text: 'M',
              events: {
                  click: () => setGroupingUnit(['month', [1]])
              }
          },
          {
              type: 'all',
              text: 'Q',
              events: {
                  click: () => setGroupingUnit(['month', [3]])
              }
          },
          {
              type: 'all',
              text: 'Y',
              events: {
                  click: () => setGroupingUnit(['year', [1]])
              }
          }
        ],
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      stockTools: {
        gui: {
          enabled: false
        }
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    };

    setChartOptions(options);}
  }, [groupingUnit, data, enableGrouping]);

  return (
    <div style={{width:"100%",height:"100%"}}>
      {data && data.length === 0 && <div style={{width:"100%",background:"#fafafa",height:"100%",flexDirection:"column",display:"flex",justifyContent:"center",alignItems:"center"}}>
      {/* <LoadingSkeletonChart chartType='multiline'/> */}
          <h3 style={{position:"absolute",top:"40%",left:"50%",background:"#fafafa",borderRadius:"10px",padding:"1rem",transform:"translate(-50%,-50%)"}}>No Data</h3>
        </div>}
      {(chartOptions &&data && data.length > 0) && 
      <HighchartsReact highcharts={Highcharts} 
      constructorType="stockChart" options={chartOptions} 
      containerProps={{ style: { height: '100%' , width:"100%"} }} 
      />}
    </div>
  );
});



  // useEffect(() => {
  //   if (chartRef.current && hoveredItem) {
  //     const chart = chartRef.current.chart;
  //     chart.series.forEach(series => {
  //       const updateOptions = {};
  //       if (series.name === hoveredItem) {
  //         updateOptions.lineWidth = 2; // Increase line width for highlight
  //         updateOptions.opacity = 1; // Set full opacity
  //       } else {
  //         updateOptions.lineWidth = 1; // Reset line width
  //         updateOptions.opacity = 1; // Reset opacity (adjust as needed)
  //         delete updateOptions.color; // Remove custom color if set
  //       }
  //       series.update(updateOptions);
  //     });
  //   }
  // }, [hoveredItem]);


export default CashflowChart;
