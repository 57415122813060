import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './MessageTape.module.css';
import { Button, duration } from '@mui/material';
import useHandleUserEvents from './useHandleUserEvents';
import { useAppInfo } from 'AppState';
import { assetCode, dfin } from 'content/constants';
import useRealTimePrice from 'data/eod/useRealTimePrice'
import { numberDecimal } from 'utils/utilGeneral';

const tapes = [
    {
      messages: [
        'How is my financial health?',
        'Am I beating the market?',
        'How can I grow my Networth?',
        'How much do I owe?',
        'How is my portfolio doing?',
        'Give me a detailed Asset Breakdown',
        'How much am I spending?',
        'How much am I saving?',
        'How much am I making compared to spendings?',
        'What goals should I priortize?',
        'Find opportunities that fit my Portfolio',
        'Do I meet my goals?',
        'Can I afford a car?',
        'Can I afford a child next year?',
        'Create a plan to purchase a house'
      ],
      duration: '60s',
    },
    // {
    //   messages: [
    //     'What goals should I priortize?',
    //     'Find opportunities that fit my Portfolio',
    //     'Do I meet my goals?',
    //     'Can I afford a car?',
    //     'Can I afford a child next year?',
    //     'Create a plan to purchase a house'
    //   ],
    //   duration: '60s',
    //   reverse:true
    // },
  ];
  
  const MessageTape = ({tickerTape=false}) => {
    // const {stateAssistant} = useAppInfo()
    // const {questionHistory} = stateAssistant

    // const questionHistoryMessages = useMemo(()=>{
    //   if (questionHistory.length>0){
    //     return [{messages:questionHistory,duration:'60s'}]
    //   }
    //   return undefined
    // },[questionHistory])
    return (
      <div className={styles.tickerContainer}>
        {tapes.map((tape, index) => (
          <Messages key={index} tape={tape} />
        ))}
        {/* {questionHistoryMessages && questionHistoryMessages.map((tape, index) => (
          <Messages key={index} tape={tape} />
        ))} */}
        {tickerTape && <Tape reverse={true}>
        <TickerPriceTape/>  
        </Tape>}
      </div>
    );
  };
  
  const Messages = memo(({ tape }) => {
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const repeatCount = 10; // Start with at least two repeats
    const {dispatchAssistant} = useAppInfo()
    
    const {handleSend} = useHandleUserEvents()
  
    return (
      <div className={styles.tickerWrapper} ref={containerRef}>
        <div
          className={styles.ticker}
          style={{
            animationDuration: tape.duration || '15s',
            animationDirection: tape.reverse ? 'reverse' : 'normal',
          }}
        >
    

          {[...Array(repeatCount)].map((_, i) => (
            <div
              className={styles.tickerContent}
              key={i}
              ref={i === 0 ? contentRef : null}
            >
              {tape.messages.map((message, idx) => (
                <Button
                  key={`${i}-${idx}`}
                  variant="highlight"
                  style={{ '&:hover': {
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Hover effect
                  },}}
                  className={styles.tickerButton}
                  onClick={()=>{dispatchAssistant({ "chatGptMessage": message });handleSend(message)}}
                >
                  {message}
                </Button>
              ))}
            </div>
          ))}

        
        </div>
      </div>
    );
  });

  const Tape = memo(({duration="50s",reverse=false,children})=>{
    return (<div className={styles.tickerWrapper}>
        <div
          className={styles.ticker}
          style={{
            animationDuration: duration || '15s',
            animationDirection: reverse ? 'reverse' : 'normal',
          }}
        >
            {children}
    </div>
    </div>)  
  })
  
  export default MessageTape;
  



  const TickerPriceButton = memo(({ data }) => {
    const tickerCode = data[dfin.tickerCode];
    const ticker = data[dfin.ticker];
    const name = data[dfin.coName]
    const coLogo = data[dfin.coLogo];
  
    const { price, loadingPrice } = useRealTimePrice({ tickerSel: tickerCode });
    const {dispatchAssistant} = useAppInfo()
    const { handleSend } = useHandleUserEvents();
  
    const closePrice = useMemo(() => {
      if (loadingPrice || !price) return undefined;
      return parseFloat(price.close) === 0 ? price.open : price.close;
    }, [price, loadingPrice]);
  
    const handleClick = useCallback(() => {
      dispatchAssistant({ "chatGptMessage": `Should I Buy ${name} (${ticker})?`});
      handleSend(`Should I Buy ${name} (${ticker})?`);
    }, [name, ticker]);
    
    return (
      <Button
        onClick={handleClick}
        variant="highlight"
        className={styles.tickerButton}
      >
        <span>
          {coLogo ? (
            <img
              src={coLogo}
              alt={`${ticker} Logo`}
              style={{ objectFit: 'contain', height: '25px', width: '25px',verticalAlign:"middle"}}
            />
          ) : (
            ticker
          )}
        </span>
        <span>{!loadingPrice && numberDecimal(closePrice)}</span>
        <span style={{ color: price?.change_p > 0 ? 'green' : 'red' }}>
          {!loadingPrice && numberDecimal(price?.change_p)}
        </span>
      </Button>
    );
  });
  

const TickerPriceTape = memo(() => {
  const { stateTicker } = useAppInfo();
  const { tickerInfo } = stateTicker;

  const tickerInfoSelected = useMemo(() => {
    if (!tickerInfo) return [];
    
    if (tickerInfo) {
      let stocks = tickerInfo
        .filter((obj) => obj[dfin.country] === 'USA')
        .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])
        .slice(0, 30);
        
      let crypto = tickerInfo
        .filter((obj) => obj["TYPE"] === assetCode?.Crypto)
        .slice(0, 10);
  
      let combined = [...stocks, ...crypto];
  
      // Fisher-Yates shuffle to randomize the array
      for (let i = combined.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [combined[i], combined[j]] = [combined[j], combined[i]]; // Swap elements
      }
  
      return combined;
    }
  }, [tickerInfo]);

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      {tickerInfoSelected.map((obj) => (
        <TickerPriceButton key={obj[dfin.tickerCode]} data={obj} />
      ))}
    </div>
  );
});
