import { fetchExcelUpload } from 'chatGPT/AssistantAPI';
import CSVUpload from 'myFinancials/CsvUpload';
import React, { useEffect, useState } from 'react';
import { Button, LinearProgress } from '@mui/material';
import InfoBox from 'components/Layout/InfoBox';

export default function UploadPortfolio({handleTableData}) {
  const [csvData, setCsvData] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading,setLoading] = useState(true)
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "text/csv" || file.type === "application/vnd.ms-excel")) {
      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result;
        setRawData(content);
        processCSV(content);
      };
      reader.onerror = () => {
        console.error("Error reading file");
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a valid CSV file");
    }
  };

  const processCSV = (data) => {
    const rows = data.split('\n').map((row) => row.split(',').map((cell) => cell.trim()));
    const headers = rows[0];
    const parsedData = rows.slice(1).map((row) => {
      let obj = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });
    setCsvData(parsedData);
  };

  useEffect(() => {
    if (rawData) {
      setLoading(true)
      const fetchData = async () => {
        try {
          const response = await fetchExcelUpload({ message: rawData });
          const tableData = JSON.parse(response)?.portfolio || []
          setTableData(tableData);  // Assuming response has a 'portfolio' key
          handleTableData(tableData);
          setLoading(false)
          setCsvData(null)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
   
    }
  }, [rawData]);

  return (
    <div style={{position:"relative"}}>
    <Button sx={{margin:0}} role={undefined} component="label" variant="contained">
        Upload Portfolio
      <input 
      type="file" accept=".csv" onChange={handleFileUpload} 
      style={{
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',cursor: 'pointer',
      }}
      />
      <div>

      </div>
    </Button>
    {csvData && 
    <div style={{position:"absolute"
    ,zIndex:100,bottom:"0",left:"0",boxSizing:"border-box",maxWidth:"500px",maxHeight:"400px",overflow:"auto",transform:"scale(0.8)"}}>
    <InfoBox sx={{display:"flex",flexDirection:"column",gap:"1rem",padding:"1rem",height:"100%",width:"100%",boxSizing:"border-box"}}>
    <div style={{flex:1,overflow:"hidden"}}>
    <CSVTable data={csvData} /></div>
    {loading && <h4>AI is processing your data</h4>}
    {loading && <LinearProgress/>}
    </InfoBox></div>}
    </div>
  );
}


function CSVTable({ data }) {
    return (
      <table border="1">
        <thead>
          <tr>
            {Object.keys(data[0]).map((key, index) => (
              <th key={index}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }