import { useAppInfo } from 'AppState';
import { TotalSummaryDist } from 'myFinancials/Utils/Totals';
import { calculateTimeHorizon, getMonthStart, normalizeDate } from 'myFinancials/Utils/utils';
import {calculateMonthlyGoals, calculateMonthlyNetCashFlow, extrapolateCashflow } from 'myFinancials/Utils/utils'
import React, { useEffect, useState } from 'react'

export default function useGoalDetails() {
    
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {cashflowDistribution,goalsPeriodic} = stateMyFinancials  
    const {netCashflowDist,netCashflowAssetWidthdrawalDist} = cashflowDistribution
  
    useEffect(() => {
        // Reducing Goals from Cashflow
       
        if (netCashflowDist && netCashflowAssetWidthdrawalDist && goalsPeriodic){

        const goalDist = [...extrapolateCashflow({fields:goalsPeriodic})]
        const totalGoals = TotalSummaryDist(goalDist)
        const goalsTimeHorizon = calculateTimeHorizon([...goalsPeriodic])
        
        const netGoalCashflowDist = calculateMonthlyGoals(netCashflowDist, goalDist,"Net Cash After Goals");
        const netGoalAferWithdrawal = calculateMonthlyGoals(netCashflowAssetWidthdrawalDist, goalDist,"Goals after Withdrawing Savings/Investments");
        const netGoalNegativeBeforeWithdrawl = netGoalCashflowDist.filter(obj=>obj.amount < 0 )
        const netGoalNegativeAferWithdrawl = netGoalAferWithdrawal.filter(obj=>obj.amount < 0)  

        const processCashflow = (cashflowDist, goals) => {
            const processedMonths = new Set();
            
            return cashflowDist.map((cashflow) => {
                const monthStart = getMonthStart(new Date(cashflow.date));
                const normalizedMonth = normalizeDate(monthStart);
    
                if (!processedMonths.has(normalizedMonth)) {
                    const correspondingGoal = goals.find((goalCashflow) =>
                        normalizeDate(getMonthStart(new Date(goalCashflow.date))) === normalizedMonth
                    );
    
                    processedMonths.add(normalizedMonth);
    
                    return {
                        ...cashflow,
                        amount: cashflow.amount - (correspondingGoal?.amount || 0),
                    };
                }
    
                return cashflow;
            });
        };
    
        const processGoalDetails = (goals, netCashflowDist, netCashflowAssetWithdrawalDist) => {
            let remainingNetCashflowDist = [...netCashflowDist];
            let remainingNetCashflowAssetWithdrawalDist = [...netCashflowAssetWithdrawalDist];
            
            const perGoalDetails = goals.map((goal) => {
              const extrapolatedGoal = goal.forecastPeriod ? extrapolateCashflow({ fields: [goal] }) : [goal];
          
              const goalNet = calculateMonthlyGoals(remainingNetCashflowDist, extrapolatedGoal, "Net Cash After Meeting Goal");
              remainingNetCashflowDist = processCashflow(remainingNetCashflowDist, [goal]);
          
              const goalNetAfterWithdrawal = calculateMonthlyGoals(remainingNetCashflowAssetWithdrawalDist, extrapolatedGoal, goal.name);
              remainingNetCashflowAssetWithdrawalDist = processCashflow(remainingNetCashflowAssetWithdrawalDist, [goal]);
          
              const netGoalNegative = goalNet.filter((obj) => obj.amount < 0);
              const netGoalNegativeAfterWithdrawal = goalNetAfterWithdrawal.filter((obj) => obj.amount < 0);
          
              return {
                ...goal,
                cashflow: [...extrapolatedGoal, ...goalNet],
                successRateBeforeWithdrawal: netGoalNegative.length > 0 ? (1 - netGoalNegative.length / goalNet.length) * 100 : 100,
                successRateAfterWithdrawal: netGoalNegativeAfterWithdrawal.length > 0 ? (1 - netGoalNegativeAfterWithdrawal.length / goalNet.length) * 100 : 100,
              };
            });
          
            return {
              perGoalDetails,
              remainingNetCashflowDist, // Remaining cashflow distribution after processing goals
              remainingNetCashflowAssetWithdrawalDist // Remaining cashflow after asset withdrawal
            };
          };
          
    
       
        const sortedGoals = [...goalsPeriodic].sort((a, b) => a?.priority - b?.priority);
        const {perGoalDetails,remainingNetCashflowDist:remainingCashflowAfterGoals,remainingNetCashflowAssetWithdrawalDist:remainingCashflowAndWithdrawAfterGoals} = processGoalDetails(
          sortedGoals, 
          netCashflowDist, 
          netCashflowAssetWidthdrawalDist);
        
        const goalSuccessRateBeforeWithdrawal = perGoalDetails.reduce((acc, goal) => acc + goal.successRateBeforeWithdrawal, 0) / perGoalDetails.length;
        const goalSuccessRateAfterWithdrawal = perGoalDetails.reduce((acc, goal) => acc + goal.successRateAfterWithdrawal, 0) / perGoalDetails.length;

        const goalAnalytics =  {
            goalsTimeHorizon,
            goalSuccessRateBeforeWithdrawal,
            goalSuccessRateAfterWithdrawal,
            perGoalDetails,netGoalNegativeBeforeWithdrawl,netGoalNegativeAferWithdrawl,
        }


        const summaryStats = {totalGoals}
        const cashflowDistribution = {goalDist,
            netGoalCashflowDist,netGoalAferWithdrawal}
        dispatchMyFinancials({goalAnalytics,summaryStats,cashflowDistribution})
    
        }
        
    }, [goalsPeriodic, netCashflowDist, netCashflowAssetWidthdrawalDist]);

    
}
