import { useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../apiConfig';
import { useAppInfo } from 'AppState';

const useFindOnboarding = () => {
  const {dispatchApp,stateApp,dispatchMyFinancials} = useAppInfo()
  const {userId,isLoggedIn} = stateApp


  const findOnboarding = async () => {
    dispatchApp({'initialized':false});
    try {
      if (userId) {
        const response =  await axios.post(`${BASE_URL}/findOnboarding/`, {
          userId,
          userId
        });;
        // Process the response data as needed
       const onboarding = response.data;
      dispatchMyFinancials({'financialView':onboarding?.financialView || "personal","demoProfile":onboarding?.demoProfile ? onboarding?.demoProfile : "Ambitious Alex"});
      dispatchApp({'onboarding':onboarding});
      }
    } catch (err) {
      // Handle the error
    }
    finally {
      dispatchApp({'initialized':true })
    }
  };

  useEffect(() => {
    findOnboarding();
  }, [userId]);

  return null; // Since this hook is used for side effects, you don't need to return anything
};

export default useFindOnboarding;
