import React, { useState, useEffect,useRef} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


export default function usePerformanceOverviewVsBenchmark(tickerList = [], benchmark) {
  const [previousPeerSelected, setPreviousPeerSelected] = useState([]);
  const [performanceOverview, setPerformanceOverview] = useState({});
  const [poLoading, setMarketInfoLoading] = useState(false);

  useEffect(() => {
    const isPeerSelectedEqual =
      tickerList.every((element) => previousPeerSelected.includes(element)) &&
      tickerList.length === previousPeerSelected.length;

    if (tickerList.length > 0 && !isPeerSelectedEqual) {
      getInfo();
      setPreviousPeerSelected(tickerList);
    }
  }, [tickerList, benchmark]);

  const getInfo = async () => {
    setMarketInfoLoading(true);
    try {
      const marketInfo = await fetchPerformanceOverview({tickerList, benchmark});
      setPerformanceOverview(marketInfo);
    } catch (error) {
      console.error(error);
    } finally {
      setMarketInfoLoading(false);
    }
  };

  return { performanceOverview, poLoading };
}



// Utility function to fetch performance overview data
export async function fetchPerformanceOverview({tickerList = [], benchmark}) {
  try {
    const response = await axios(`${BASE_URL}/performanceOverview/`, {
      params: {
        ticker: JSON.stringify([...tickerList]),
        benchmark:benchmark,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}