import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAppInfo } from 'AppState';
import usePerformanceOverviewVsBenchmark from 'data/technicals/usePerformanceOverview';
import TabLine from 'components/Tabs/TabLine';
import LoadingSkeletonPage from 'components/Loading/LoadingSkeletonPage';
import { dfin } from 'content/constants';

export default function TickerPerformanceOverview({ticker}) {
  const { stateTicker } = useAppInfo();

  const tabList = [
    'Annual Risk & Return',
    'Returns',
    'Volatility',
    'Value at Risk (VaR)',
    'Sharpe Ratio',
    'Risk:Reward Ratio',
  ];
  const [tabIndex, setTabIndex] = useState(0);

  const [tickerSel,setTickerSel] = useState([])
   
  useEffect(()=>{
    if (ticker) {
      const tickerSelInfo = stateTicker.tickerInfo.filter(
        obj =>
          obj[dfin.ticker] === ticker ||
          obj[dfin.tickerCode] === ticker ||
          obj[dfin.yfTicker] === ticker
      );
      const tickerSelYf = tickerSelInfo[0]?.[dfin.yfTicker];
      setTickerSel([tickerSelYf || ticker]);
      // return tickerSelYf ? [tickerSelYf] : [ticker];
    } else {
      const defaultTicker = stateTicker.tickerSelInfo?.[dfin.yfTicker];
      setTickerSel([defaultTicker] || []);
      // return defaultTicker ? [defaultTicker] : [];
    }
  }, [stateTicker.tickerInfo, stateTicker.tickerSelInfo, ticker])
  

  const { performanceOverview:chartData, poLoading } = usePerformanceOverviewVsBenchmark(tickerSel,'SPY');

  const tickers = [...tickerSel,"SPY"]

  const handleTabChange = (value) => {
    setTabIndex(value);
  };


  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={handleTabChange} />
      <Box sx={{ padding: 2 }}>
        {(poLoading || !chartData) &&  
        <div style={{height:"300px",width:"100%"}}>
<LoadingSkeletonPage />
        </div>
        }
        {chartData && !poLoading && 
        <>
        {(tabIndex === 0 && chartData?.annualReturns) && 
        <AnnualReturnVolatility annualReturns={chartData?.annualReturns} 
        annualVolatility={chartData?.annualVolatility} tickerList={tickers}/>}
        {tabIndex === 1 && <RatioChart title={"Returns (%)"} dataKey={"returns"} data={chartData} tickers={tickers}/>}
        {tabIndex === 2 && <RatioChart title={"Volatility (%)"} dataKey={"volatility"} data={chartData} tickers={tickers}/>}
        {tabIndex === 3 && <RatioChart title={"Value at Risk (VaR) (%)"} dataKey={"VaR"} data={chartData} tickers={tickers}/>}
        {tabIndex === 4 && <RatioChart title={"Sharpe Ratio"} dataKey={"sharpeRatio"} data={chartData} tickers={tickers}/>}
        {tabIndex === 5 && <RatioChart title={"Risk:Reward Ratio"} dataKey={"riskReward"} data={chartData} tickers={tickers}/>}
        </>
        }
      </Box>
    </Box>
  );
}

export function AnnualReturnVolatility({ annualReturns, annualVolatility}) {
  const annualYears = annualReturns.map((item) => item.year);
  const tickerList =
    annualReturns.length > 0
      ? Object.keys(annualReturns[0]).filter((key) => key !== 'year')
      : [];

  // Prepare series data
  const seriesDataReturns = tickerList.map((ticker) => ({
    name: `${ticker} Return`,
    type: 'column',
    data: annualReturns.map((item) => item[ticker] ?? null),
    yAxis: 0,
  }));

  const seriesDataVolatility = tickerList.map((ticker) => ({
    name: `${ticker} Volatility`,
    type: 'spline',
    data: annualVolatility.map((item) => item[ticker] ?? null),
    yAxis: 1,
  }));

  // Check if there is no data to display
  const noData =
    annualYears.length === 0 ||
    seriesDataReturns.length === 0 ||
    seriesDataVolatility.length === 0;

  // Memoized chart options
  const chartOptions = useMemo(
    () => ({
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: 'Annual Risk and Return',
      },
      xAxis: {
        categories: annualYears,
        crosshair: true,
      },
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}%',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          title: {
            text: 'Return (%)',
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
        },
        {
          // Secondary yAxis
          title: {
            text: 'Volatility (%)',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            format: '{value}%',
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      series: [...seriesDataReturns, ...seriesDataVolatility],
      credits: { enabled: false },
    }),
    [annualYears, seriesDataReturns, seriesDataVolatility]
  );

  // Render No Data message if no data is available
  if (noData) {
    return <div>No data available</div>;
  }

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
}

  // Helper function for ratio charts (Sharpe Ratio, Risk:Reward Ratio)
function RatioChart ({title, dataKey,data,tickers}) {
    const dataPeriods = data[dataKey];

    if (!dataPeriods || Object.keys(dataPeriods).length === 0) {
      return (
        <Typography variant="h6" sx={{ padding: 2 }}>
          No data available for {title}.
        </Typography>
      );
    }

    const periods = Object.keys(dataPeriods);
    const categories = tickers;

    const series = periods.map((period) => ({
      name: period,
      data: categories.map((ticker) => dataPeriods[period][ticker] ?? null),
    }));

    const options = {
      chart: {
        type: 'column',
      },
      title: {
        text: title,
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        title: {
          text: title,
        },
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.2f}</b><br/>',
      },
      series: series,
      credits: { enabled: false },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };



  // Helper function to prepare data for bar charts
  const renderBarChart = (title,data, dataKey,tickers) => {
    const dataPeriods = data[dataKey];

    if (!dataPeriods || Object.keys(dataPeriods).length === 0) {
      return (
        <Typography variant="h6" sx={{ padding: 2 }}>
          No data available for {title}.
        </Typography>
      );
    }

    const periods = Object.keys(dataPeriods);
    const categories = tickers;

    const series = periods.map((period) => ({
      name: period,
      data: categories.map((ticker) => dataPeriods[period][ticker] ?? null),
    }));

    const options = {
      chart: {
        type: 'column',
      },
      title: {
        text: title,
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: title,
        },
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.2f}%</b><br/>',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      series: series,
      credits: { enabled: false },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };