import React, { useState } from 'react';
import {TextField, Button } from '@mui/material';
import { Image, Article, Edit } from '@mui/icons-material';
import ProfileLogo from 'components/Logo/ProfileLogo';
import Modal from 'components/Modal/Modal';

const SocialPost = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleKeyDown = () => setOpenModal(true);

  return (
    <>
    <div
        onKeyDown={handleKeyDown}
        onClick={() => setOpenModal(true)}
        style={{
          display: "flex",
          flexWrap:"wrap", 
          width:"max-content",
          maxWidth:"100%",
          minWidth:"300px", 
          gap: "1rem",
          boxSizing: "border-box", 
          background: "#f7f7f7",
          borderRadius: "25px", 
          padding: "0.75rem 0.5rem",
          position: "relative",
          background: "white",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
          alignItems:"center"
          }}
      >
    <ProfileLogo profileType="owner"/>
    <span style={{ color: '#555' }}>Start a post</span>
    <PostButtons/>
      {/* Buttons Section */}
      </div>
      {/* Modal Section */}
      {openModal && (
      <Modal  onClose={()=>setOpenModal(false)}>
        <div
          style={{
            width: '100%',
            background: 'white',
            borderRadius: '8px',
            padding: '20px',
            boxSizing:" border-box",
            overflow:"auto",height:"100%"
          }}
        >
          <h2>Create a Post</h2>
          <TextField
            multiline
            rows={6}
            placeholder="Write something..."
            variant="outlined"
            fullWidth
            style={{ marginBottom: '20px' }}
          />
          <div style={{ display:"flex",justifyContent:"center",alignItems:"center"}}>
            {/* <Button onClick={handleCloseModal} style={{ marginRight: '10px' }} variant="outlined">
              Cancel
            </Button> */}
            <Button variant="contained" color="primary">
              Post
            </Button>
          </div>
         

        </div>
      </Modal>)}
    
    </>
  );
};

export default SocialPost;


function PostButtons (){
  return (
    <div style={{ display: 'flex', gap:"1rem"}}>
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#555' }}
    >
      <Image style={{ marginRight: '5px' }} />
      <span>Media</span>
    </div>
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#555' }}
    >
      <Edit style={{ marginRight: '5px' }} />
      <span>Contribute expertise</span>
    </div>
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#555' }}
    >
      <Article style={{ marginRight: '5px' }} />
      <span>Write article</span>
    </div>
  </div>
  )
}