import axios from 'axios';
import React, { useEffect } from 'react';

const SearchTavily = () => {

  return <div>Check the console for the response.</div>;
};

export default SearchTavily;

export async function getWebSearchTavily(query,topic="news") {
    try {
      const response = await axios.post(
        'https://api.tavily.com/search',
        {
          api_key: process.env.REACT_APP_TAVILY,
          query: query,
          topic: topic,
          search_depth:"advanced"
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data.results;
    } catch (error) {
      return "Error fetching data";
      console.error('Error fetching data:', error);
    }
  
}