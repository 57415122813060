import React from 'react';
import { Autocomplete, Chip, Stack, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export default function MultiSelect({sx,variant="outlined", 
options, values, onChange, label, limitTags = 0, tags = false,size="small",required=false,labelFontSize}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
  return (
    <>
      <>
        <Autocomplete
          multiple
          disableCloseOnSelect
          limitTags={limitTags}
          sx={{ zIndex: 100,width:"100%",minWidth:0,...sx,padding:"0.35rem 0 0 0"}}
          value={values}
          options={options}
          onChange={(e, newValue) => onChange(newValue)}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          slotProps={{
            popper: {
              sx: { zIndex: 100000000}
            }
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                // style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField required={required && values.length === 0} {...params}  label={label} placeholder="" 
            variant={variant} size={size}
            slotProps={{
              inputLabel: {
                fontSize: labelFontSize && {labelFontSize}, // Adjust font size here
              },
            }}
            />
          )}
          renderTags={!tags ? () => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              <Chip
                size="small"
                label={`+ ${values.length}`}
              />
            </Stack>
          ) : undefined}
          ChipProps={{ sx: { backgroundColor: '#4caf50', color: "white",fontWeight:"bold"} }}
        />
      </>
    </>
  );
}