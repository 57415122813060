const initialStatePortfolio = {
    accountSettings:false,
    deleteAccount:false,
    portfolioDefaultLoaded:false,
    portfolioDefault:[],
    portfolioLoaded:false,
    portfolioSaved:false,
    addTransaction:false,
    portfolio:{},
    accountNameSel:undefined,
    accountNames:[],
    portfolioSel:[],
    portfolioTable:[],
    portfolioTransactionTable:[],
    portfolioOverview:undefined,
    tickerSelected:[],
    tickerSelectedCode:[],
    tickerCodeMap:{},
    close:[],
    trendTable:[],
    marketInfoLoading:false,
    portfolioResults:undefined,
    portfolioTotal:undefined,
    portfolioValue:[],
    topInvestorsOverview:[],
    topInvestorSel:"BERKSHIRE HATHAWAY INC",
    topInvestorSelCIK:"0001067983",
}


export {initialStatePortfolio}


