import React, { useEffect, useState } from 'react'
import styles from './TableScreener.module.css';
import { useAppInfo } from 'AppState';
import SortArrow from 'components/Table/SortArrow';
import ScreenerTableSettings from './ScreenerTableSettings';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import { useNavigate } from 'react-router-dom';
import { dfin } from 'content/constants';
import TabLine from 'components/Tabs/TabLine';
import { WatchListAddTableButton } from 'watchlist/WatchListAddButton';
import DetailTradeTickerButton from 'analytics/ticker/DetailTradeTickerButton';
import LogoTicker from 'components/LogoTicker';

export default function ScreenerTable() {
    
    const {stateStock,dispatchScreener,stateScreener} = useAppInfo();
    const handleTickerChange = useHandleTickerChange()
    const [tabList, setTabList] = useState([]);
    const navigate = useNavigate()
    const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
    const {tableData,columns,sortDirection,sortedColumn} = stateScreener
    const filters = stateScreener?.screenerDataUser?.[stateScreener.screenerNameSel] ? stateScreener?.screenerDataUser?.[stateScreener.screenerNameSel] : stateScreener?.filters
      

    useEffect(() => {
      if (stateScreener.pageColumns){
      const tabList = Object.keys(stateScreener.pageColumns);
      setTabList(tabList);}
    }, [stateScreener.pageColumns]);
  
    function handleTabChange(tab) {
      dispatchScreener({
        pageHeaderSel: tab,
        columns: stateScreener.pageColumns[tab],
        scatterMetricsSel: stateScreener.scatterMetrics[tab]
      });
    }

    function handleRowMouseEnter(index) {
        setHoveredRowIndex(index);
      }
      

      const handleSort = (columnName) => {
        if (columnName === sortedColumn) {
          const sDirection = sortDirection === "asc" ? "desc" : "asc";
          dispatchScreener({ sortDirection: sDirection });
        } else {
          dispatchScreener({
            sortedColumn: columnName,
            sortDirection: "asc"
          });
        }
      };
      
  
  return (
    <div style={{ height: "100%", overflow: "auto", width: "100%", display: "flex", flexDirection: "column" }}>
    <TabLine tabList={tabList} 
           tabIndex={tabList.indexOf(stateScreener.pageHeaderSel) || 0} 
           handleTabChange={(i) => handleTabChange(tabList[i])}
           settings
           settingsContent={<ScreenerTableSettings/>}
           />
       <div style={{ overflow: "auto", width: "100%", boxSizing: "border-box", flex: 1 }}>
  <table className={styles.tableScreener} id="tableScreener">
  <thead>
    <tr>
      <th>Add to Watchlist</th>
      {columns.map((column) => (
        column !== dfin.coLogo &&
        <th
          key={column}
          style={Object.keys(filters).includes(column) ? { background: "var(--selectBlue)", color: "white" } : {}}
        >
          <div>
            <div style={{ display: 'flex', gap: "1rem" }}>
              {column}
              <div onClick={() => handleSort(column)}><SortArrow /> </div>
            </div>
          </div>
        </th>
      ))}
    </tr>
  </thead>
  <tbody>
    {tableData.map((item, index) => (
      <tr
        onMouseEnter={() => handleRowMouseEnter(index)}
        onMouseLeave={() => handleRowMouseEnter(-1)}
        style={item[dfin.ticker] === stateStock.companySelected ? { border: "3px solid blue", position: "relative" } : { position: "relative" }}
        key={index}
        onClick={(e) => handleTickerChange({ticker:item[dfin.ticker],modal:false})}
      >
        <td>
          <WatchListAddTableButton ticker={item[dfin.ticker]}/>
        </td>
        <td>
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <LogoTicker tickerSel={item[dfin.ticker]}/>
            {item[dfin.ticker]}
          </div>
        </td>
        {Object.keys(item).map((column) => (
          (column !== dfin.ticker && column !== dfin.coLogo) &&
          <td key={column}>
            {item[column]}
          </td>
        ))}
        {(hoveredRowIndex === index) && (
            <DetailTradeTickerButton position='left' ticker={item[dfin.ticker]}/>
        )}
      </tr>
    ))}
  </tbody>
</table>
</div>
</div>
  )
}
